import { useEffect, useState } from "react";
import { Card, Grid } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import SoftLoader from "src/components/SoftLoader/SoftLoader";
import SoftTypography from "src/components/SoftTypography";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import {
  addPaymentMethod,
  createPaymentIntent,
} from "src/services/payments.api";
import { useClubs } from "src/features/club/ClubProvider";
import ModalWrapper from "../ModalWrapper/ModalWrapper";

import toastr from "toastr";

const schema = yup
  .object({
    name: yup
      .string()
      .required("Please provide the name as shown on the card."),
  })
  .required();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "15px",
  overflow: "visible",
  boxShadow: 24,
  p: 4,
};

function AddNewCardModal({
  isOpen,
  onClose = () => {},
  onDone = () => {},
  userId,
}) {
  const { selectedClubId, selectedLocationId } = useClubs();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [cardDetails, setCardDetails] = useState(null);
  const [cardToken, setCardToken] = useState(null);
  // const [unmountCardElement, setUnmountCardElement] = useState(null);

  useEffect(() => {
    // setIsLoading(false);
    console.log("isLoading", isLoading);
    setTimeout(() => {
      setIsLoading(false);
    }, 5000);
  }, []);

  const onSubmit = async (data) => {
    if (!userId || !cardDetails || !cardToken) {
      return;
    }
    setIsSubmitting(true);
    addPaymentMethod({
      userId,
      token: cardToken,
      ...cardDetails,
      type: "CARD_FWD",
      cardDetails: cardDetails.card,
      name: data.name,
      clubId: selectedClubId,
    })
      .then((result) => {
        if (result.error) {
          console.error(result.error.message);
          toastr.error(result.error.message);
          setIsSubmitting(false);
        } else {
          toastr.success("Card added successfully");
          console.log("Card added successfully", result);
          onDone({ success: true });
          onClose({ success: true });
          setIsSubmitting(false);
        }
      })
      .catch((error) => {
        console.error(error);
        toastr.error(error.message);
        setIsSubmitting(false);
      });
  };

  const addPaymentForm = async () => {
    const paymentIntent = await createPaymentIntent({
      amount: 100,
      currency: "USD",
      referenceId: "123",
      userId: userId,
      fees: {
        applicationFee: 0,
        preflightFee: 0,
        paymentFee: 0,
        clubFee: 0,
        pilotFee: 0,
        clubRevenue: 0,
        totalCharge: 0,
      },
      clubId: selectedClubId,
      locationId: selectedLocationId,
      description: "Add New Card",
    });
    setIsLoading(false);
    const paymentForm = await window.Forward.createCardElement({
      apiKey: import.meta.env.VITE_FWD_API_KEY,
      clientSecret: paymentIntent.client_secret,
    });
    paymentForm.mount("#payment-form", {
      onChange: (event) => {
        if (event.ready) {
          setCardDetails({
            type: event.paymentMethodType,
            card: { ...event.card, ...event.billingDetails.address },
          });
        }
      },
      onReady: (tokenize) => {
        tokenize();
      },
      onSuccess: async ({ token }) => {
        setCardToken(token);
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  useEffect(() => {
    addPaymentForm();
  }, []);

  return (
    <ModalWrapper isOpen={isOpen} onClose={onClose}>
      <Card sx={style}>
        <SoftBox
          p={2}
          component="form"
          role="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <SoftLoader isLoading={isLoading}>
            <SoftBox>
              <SoftTypography variant="h5" fontWeight="bold">
                Add New Card
              </SoftTypography>

              <SoftBox mt={1.625}>
                <SoftBox mt={1.625}>
                  <Grid container spacing={0}>
                    <Grid item xs={12}>
                      <SoftBox mb={2} lineHeight={1.25}>
                        <SoftBox mb={1} ml={0.5}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                          >
                            Cardholder&rsquo;s Name
                          </SoftTypography>
                        </SoftBox>
                        <SoftInput
                          placeholder="Cardholder's Name"
                          {...register("name", {
                            required: true,
                          })}
                        />
                        {errors?.name?.message && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.name.message}
                          </SoftTypography>
                        )}
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <SoftBox
                        mb={2}
                        ml={0.5}
                        lineHeight={0}
                        display="inline-block"
                      >
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          Card Details
                        </SoftTypography>
                      </SoftBox>
                      <div id="payment-form"></div>
                    </Grid>
                  </Grid>
                </SoftBox>

                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <SoftBox mt={4} mb={1}>
                      <SoftButton
                        variant="outlined"
                        color="dark"
                        fullWidth
                        onClick={onClose}
                        disabled={isSubmitting}
                      >
                        Cancel
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                  <Grid item xs={6}>
                    <SoftBox mt={4} mb={1}>
                      <SoftButton
                        variant="gradient"
                        color="info"
                        fullWidth
                        type="submit"
                        disabled={isSubmitting || !cardDetails || !cardToken}
                      >
                        Add Card
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </SoftBox>
          </SoftLoader>
        </SoftBox>
      </Card>
    </ModalWrapper>
  );
}

AddNewCardModal.propTypes = {
  onClose: PropTypes.func,
  user: PropTypes.object.isRequired,
};

export default AddNewCardModal;
