import InputAdornment from "@mui/material/InputAdornment";
import SoftInputRoot from "./SoftInputRoot";
import { InputBaseProps, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import {
  centToUnit,
  getNumberCurrencyDisplay,
  toCents,
} from "src/utils/currency.utils";
import SoftButton from "../SoftButton";

interface ISoftCurrencyInputv2 extends InputBaseProps {
  currencySymbol?: string;
  allowValueUpdate?: boolean;
  cents?: boolean;
  maxNumber?: number;
  allowNegative?: boolean;
  step?: number;
  showMaxButton?: boolean;
  maxWidth?: string;
  onChangedValue?: ({
    ...params
  }: {
    displayValue: string;
    valueInCents: number;
    value: number;
  }) => void;
  onBlurChangedValue?: ({
    ...params
  }: {
    displayValue: string;
    valueInCents: number;
    value: number;
  }) => void;
}
/**
 *
 * @param {number} value should be a number, integer. The component will handle the cents
 * @param {number} maxNumber must be a number in cents
 * @returns
 */
function SoftCurrencyInputv2({
  currencySymbol = "$",
  cents = false,
  allowValueUpdate = false,
  allowNegative = false,
  showMaxButton = false,
  step = 1,
  maxNumber,
  maxWidth = "200px",
  onChangedValue,
  onBlurChangedValue,
  ...rest
}: ISoftCurrencyInputv2) {
  const [displayValue, setDisplayValue] = useState(
    getNumberCurrencyDisplay(rest.value as any, cents)
  );

  useEffect(() => {
    if (allowValueUpdate)
      setDisplayValue(getNumberCurrencyDisplay(rest.value as any, cents));
  }, [allowValueUpdate, rest.value]);

  const parseValue = (displayValue: string) => {
    const sanitizedValue = displayValue
      .replace(/[^0-9.-]/g, "")
      .replace(/(?!^)-/g, "");
    const match = sanitizedValue.match(/^(-?\d+)(\.\d{0,2})?/);
    const stringValue = match ? match[0] : "";
    const parsedNumericValue = parseFloat(stringValue);
    const sanitizedNumericValue = isNaN(parsedNumericValue)
      ? 0
      : parsedNumericValue;
    const numericValue = cents
      ? toCents(sanitizedNumericValue)
      : sanitizedNumericValue;
    return {
      numericValue: isNaN(numericValue) ? 0 : Math.ceil(numericValue),
      stringValue: stringValue,
    };
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event.target;
    processInput(inputValue, 0);
  };

  const isMaxNumber = (displayValue: string | number, stepValue: number) => {
    if (maxNumber === undefined) return false;
    const numericValue =
      typeof displayValue === "number"
        ? displayValue
        : parseValue(displayValue).numericValue;
    return numericValue + stepValue >= Math.floor(maxNumber);
  };

  const processInput = (displayValue: string | number, step = 0) => {
    let numericValue = 0,
      stringValue = "";
    if (typeof displayValue === "string") {
      const valuesParsed = parseValue(displayValue);
      numericValue = valuesParsed.numericValue;
      stringValue = valuesParsed.stringValue;
    }

    if (typeof displayValue === "number") {
      numericValue = displayValue;
      stringValue = getNumberCurrencyDisplay(numericValue, cents);
    }

    let newValueInCents = (
      isMaxNumber(numericValue, step) ? maxNumber : numericValue + step
    ) as number;
    if (!allowNegative)
      newValueInCents = newValueInCents < 0 ? 0 : newValueInCents;

    const newStringValue =
      newValueInCents !== numericValue
        ? getNumberCurrencyDisplay(newValueInCents, true)
        : stringValue;
    setDisplayValue(newStringValue);
    onChangedValue?.({
      displayValue: newStringValue,
      valueInCents: newValueInCents,
      value: centToUnit(newValueInCents),
    });
  };

  const handleKeyUp = (e: any) => {
    if (e.key === "ArrowUp") {
      //if (isMaxNumber(e.target.value, toCents(step))) return;
      processInput(e.target?.value, toCents(step));
    }
    if (e.key === "ArrowDown") {
      //if (isMaxNumber(e.target.value, -toCents(step))) return;
      processInput(e.target?.value, -toCents(step));
    }
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>
  ) => {
    e.stopPropagation();
    e.preventDefault();
    const { numericValue } = parseValue(displayValue);
    const displayValueParsed = getNumberCurrencyDisplay(numericValue, true);
    setDisplayValue(displayValueParsed);
    onBlurChangedValue?.({
      displayValue: displayValueParsed,
      valueInCents: numericValue,
      value: centToUnit(numericValue),
    });
  };

  return (
    <Stack direction="row" sx={{ position: "relative" }} alignItems="center">
      <SoftInputRoot
        {...rest}
        type="text"
        inputMode="numeric"
        ownerState={{}}
        value={displayValue}
        onKeyDown={handleKeyUp}
        onChange={handleChange}
        sx={{
          maxWidth,
          ...(rest.disabled ? { backgroundColor: "#E9ECEF !important" } : {}),
        }}
        startAdornment={
          <InputAdornment position="start">{currencySymbol}</InputAdornment>
        }
        onBlur={handleBlur}
      />
      {showMaxButton && !!maxNumber && (
        <SoftButton
          variant="contained"
          color="light"
          disabled={isMaxNumber(displayValue, 0)}
          onClick={() => processInput(maxNumber, 0)}
          sx={{
            minHeight: "25px !important",
            minWidth: "30px !important",
            height: "25px !important",
            width: "30px !important",
            fontSize: "10px",
            position: "absolute",
            right: "7px",
          }}
        >
          Max
        </SoftButton>
      )}
    </Stack>
  );
}
export default SoftCurrencyInputv2;
