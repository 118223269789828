import { useEffect, useState } from "react";
import breakpoints from "src/assets/theme/base/breakpoints";

export const useTabOrientation = () => {
  const [tabsOrientation, setTabsOrientation] = useState<
    "horizontal" | "vertical" | undefined
  >("horizontal");

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    /**
     The event listener that's calling the handleTabsOrientation function when resizing the window.
     */
    window.addEventListener("resize", handleTabsOrientation);

    // Call the handleTabsOrientation function to set the state with the initial value.
    handleTabsOrientation();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);
  return { tabsOrientation };
};
