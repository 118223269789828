export enum paymentTypes {
  CARD_STRIPE = "CARD_STRIPE",
  TERMINAL_STRIPE = "TERMINAL_STRIPE",
  ACH_STRIPE = "ACH_STRIPE",
  CARD_FWD = "CARD_FWD",
  ACH_FWD = "ACH_FWD",
  CASH = "CASH",
  CHECK = "CHECK",
  LOAN = "LOAN",
  ACCOUNT = "ACCOUNT",
  PAYMENT = "PAYMENT",
  FUEL_REIMBURSEMENT = "FUEL_REIMBURSEMENT",
  FLIGHT_CREDIT = "FLIGHT_CREDIT",
  OTHER = "OTHER",
}
export interface IPayment {
  id: string;
  token?: string;
  additionalData: AdditionalData;
  type: paymentTypes;
  displayName: string;
  calculatedRate?: number;
  derivedCategory?: string;
}

export enum discountType {
  FIXED_AMOUNT = "FIXED_AMOUNT",
  PERCENTAGE = "PERCENTAGE",
}

export enum PaymentStatus {
  REQUESTED = "REQUESTED",
  SUCCESSFUL = "SUCCESSFUL",
  INCOMPLETE = "INCOMPLETE",
  ACTION_REQUIRED = "ACTION_REQUIRED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  DISPUTED = "DISPUTED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  REFUNDED = "REFUNDED",
  CANCELLED = "CANCELLED",
}

export const paymentStatusDisplay = {
  [PaymentStatus.REQUESTED]: {
    text: "Requested",
    icon: "send",
    circular: false,
    color: "dark",
  },
  [PaymentStatus.SUCCESSFUL]: {
    text: "Successful",
    icon: "done",
    circular: true,
    color: "success",
  },
  [PaymentStatus.INCOMPLETE]: {
    text: "Incomplete",
    icon: "running_with_errors",
    circular: false,
    color: "warning",
  },
  [PaymentStatus.ACTION_REQUIRED]: {
    text: "Action Required",
    icon: "priority_high",
    circular: false,
    color: "error",
  },
  [PaymentStatus.FAILED]: {
    text: "Failed",
    icon: "close",
    circular: true,
    color: "error",
  },
  [PaymentStatus.IN_PROGRESS]: {
    text: "In Progress",
    icon: "more_horiz",
    circular: false,
    color: "warning",
  },
  [PaymentStatus.DISPUTED]: {
    text: "Disputed",
    icon: "close",
    circular: false,
    color: "error",
  },
  [PaymentStatus.PARTIALLY_REFUNDED]: {
    text: "Partially Refunded",
    icon: "replay",
    circular: false,
    color: "dark",
  },
  [PaymentStatus.REFUNDED]: {
    text: "Refunded",
    icon: "replay",
    circular: false,
    color: "dark",
  },
  [PaymentStatus.CANCELLED]: {
    text: "Cancelled",
    icon: "not_interested",
    circular: false,
    color: "dark",
  },
};

export interface AdditionalData {
  type: string;
  accountId?: string;
  createdBy?: string;
  createdAt?: string;
  userId?: string;
  clubId?: string;
  paymentId?: string;
  date?: string;
  paymentCurrency?: string;
  status?: string;
  totalAmount?: number;
  unappliedAmount?: number;
  balance?: number;
  discounts?: Discount[];
  aircraftRates?: { [key: string]: { [key: string]: number } };

  id?: string;
  object?: string;
  allow_redisplay?: string;
  billing_details?: BillingDetails;
  card?: Card;
  created?: number;
  customer?: string;
  livemode?: boolean;
  metadata?: Metadata;
  radar_options?: Metadata;

  expMonth?: string;
  expYear?: string;
  lastFourDigits?: string;
  brand?: string;
  country?: string;
  firstSixDigits?: string;
  postalCode?: string;
}

export interface Discount {
  amount: number;
  type: discountType;
}

export interface AdditionalData2 {
  id: string;
  object: string;
  allow_redisplay: string;
  billing_details: BillingDetails;
  card: Card;
  created: number;
  customer: string;
  livemode: boolean;
  metadata: Metadata;
  radar_options: Metadata;
  type: string;
}

export interface BillingDetails {
  address: Address;
  email: null;
  name: string;
  phone: null;
}

export interface Address {
  city: string;
  country: string;
  line1: string;
  line2: string;
  postal_code: string;
  state: string;
}

export interface Card {
  brand: string;
  checks: Checks;
  country: string;
  display_brand: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  generated_from: null;
  last4: string;
  networks: Networks;
  three_d_secure_usage: ThreeDSecureUsage;
  wallet: null;
}

export interface Checks {
  address_line1_check: string;
  address_postal_code_check: string;
  cvc_check: string;
}

export interface Networks {
  available: string[];
  preferred: null;
}

export interface ThreeDSecureUsage {
  supported: boolean;
}

export interface Metadata {}
