import PropTypes from "prop-types";
import SoftBox from "src/components/SoftBox";

import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";
import SoftTypography from "src/components/SoftTypography";

const { badgeColors } = colors;
const { borderRadius } = borders;

const Grade = ({ value = "-" }) => {
  const getColorFromGrade = () => {
    switch (value) {
      case "S":
      case "1":
        return badgeColors["success"];
      case "4":
      case "3":
      case "2":
        return badgeColors["info"];
      case "U":
      case "5":
        return badgeColors["error"];
      case "I":
        return badgeColors["warning"];
      default:
        return badgeColors["secondary"];
    }
  };

  return (
    <SoftBox
      sx={{
        backgroundColor: getColorFromGrade().background,
        width: "fit-content",
        height: "1.5rem",
        padding: "0rem 0.35rem",
        borderRadius: borderRadius.sm,
      }}
    >
      <SoftTypography
        sx={{
          color: getColorFromGrade().text,
          m: "auto",
          textAlign: "center",
          fontSize: "0.9rem",
        }}
        fontWeight="medium"
      >
        {value || "-"}
      </SoftTypography>
    </SoftBox>
  );
};

Grade.propTypes = {
  value: PropTypes.oneOf(["S", "U", "I", "1", "2", "3", "4", "5", "-"]),
};

export default Grade;
