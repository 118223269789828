import { IPermission } from "./permission.interface";

export enum maintenanceRoleCategory {
  MX_OVERVIEW = "MX_OVERVIEW",
  MX_SQUAWKS = "MX_SQUAWKS",
  MX_REMINDERS = "MX_REMINDERS",
}

export enum maintenanceOverviewPermissionTypes {
  VIEW_MX_OVERVIEW = "VIEW_MX_OVERVIEW",
}

export enum maintenanceSquawksPermissionTypes {
  VIEW_MX_SQUAWKS = "VIEW_MX_SQUAWKS",
  VIEW_MX_RESOLVED_SQUAWKS = "VIEW_MX_RESOLVED_SQUAWKS",
  EXPORT_MX_SQUAWKS = "EXPORT_MX_SQUAWKS",
  CHANGE_SQUAWK_STATUS = "CHANGE_SQUAWK_STATUS",
  CHANGE_SQUAWK_GROUNDING = "CHANGE_SQUAWK_GROUNDING",
  RESOLVE_MX_SQUAWKS = "RESOLVE_MX_SQUAWKS",
  ADD_MX_SQUAWK_LOG = "ADD_MX_SQUAWK_LOG",
  VIEW_INTERNAL_SQUAWK_NOTES = "VIEW_INTERNAL_SQUAWK_NOTES",
}

export enum maintenanceRemindersPermissionTypes {
  VIEW_MX_REMINDERS = "VIEW_MX_REMINDERS",
  EXPORT_MX_REMINDERS = "EXPORT_MX_REMINDERS",
  CREATE_MX_REMINDERS = "CREATE_MX_REMINDERS",
  COMPLETE_MX_REMINDERS = "COMPLETE_MX_REMINDERS",
  UPDATE_MX_REMINDERS = "UPDATE_MX_REMINDERS",
  DELETE_MX_REMINDERS = "DELETE_MX_REMINDERS",
}

export const maintenancePermissions: IPermission[] = [
  {
    group: maintenanceRoleCategory.MX_OVERVIEW,
    label: "Maintenance",
    tooltipGroup: "",
    permissions: {
      [maintenanceOverviewPermissionTypes.VIEW_MX_OVERVIEW]: {
        label: "View MX Overview",
        tooltip: "",
      },
    },
  },
  {
    group: maintenanceRoleCategory.MX_SQUAWKS,
    label: "Maintenance - Squawks",
    tooltipGroup: "",
    permissions: {
      [maintenanceSquawksPermissionTypes.VIEW_MX_SQUAWKS]: {
        label: "View All Aircraft Squawks",
        tooltip: "",
      },
      [maintenanceSquawksPermissionTypes.VIEW_INTERNAL_SQUAWK_NOTES]: {
        label: "View Internal Sqauwk Notes",
        tooltip: "",
      },
      [maintenanceSquawksPermissionTypes.VIEW_MX_RESOLVED_SQUAWKS]: {
        label: "View Resolved Aircraft Squawks",
        tooltip: "",
      },
      [maintenanceSquawksPermissionTypes.EXPORT_MX_SQUAWKS]: {
        label: "Export Aircraft Squawks",
        tooltip: "",
      },
      [maintenanceSquawksPermissionTypes.CHANGE_SQUAWK_STATUS]: {
        label: "Change Squawk Status",
        tooltip: "",
      },
      [maintenanceSquawksPermissionTypes.CHANGE_SQUAWK_GROUNDING]: {
        label: "Change Squawk Grounding",
        tooltip:
          "This allows a user to change the grounding status of a squawk",
      },
      [maintenanceSquawksPermissionTypes.RESOLVE_MX_SQUAWKS]: {
        label: "Resolve Aircraft Squawks",
        tooltip: "",
      },
      [maintenanceSquawksPermissionTypes.ADD_MX_SQUAWK_LOG]: {
        label: "Add Sqauwk Log Entry",
        tooltip: "",
      },
    },
  },
  {
    group: maintenanceRoleCategory.MX_REMINDERS,
    label: "Maintenance - Reminders",
    tooltipGroup: "",
    permissions: {
      [maintenanceRemindersPermissionTypes.VIEW_MX_REMINDERS]: {
        label: "View All Aircraft Reminders",
        tooltip: "",
      },
      [maintenanceRemindersPermissionTypes.EXPORT_MX_REMINDERS]: {
        label: "Export All Aircraft Reminders",
        tooltip: "",
      },
      [maintenanceRemindersPermissionTypes.CREATE_MX_REMINDERS]: {
        label: "Create Aircraft Reminders",
        tooltip: "",
      },
      [maintenanceRemindersPermissionTypes.COMPLETE_MX_REMINDERS]: {
        label: "Complete Aircraft Reminders",
        tooltip: "",
      },
      [maintenanceRemindersPermissionTypes.UPDATE_MX_REMINDERS]: {
        label: "Update Aircraft Reminders",
        tooltip: "",
      },
      [maintenanceRemindersPermissionTypes.DELETE_MX_REMINDERS]: {
        label: "Delete Aircraft Reminders",
        tooltip: "",
      },
    },
  },
];
