import DefaultCell from "./components/DefaultCell";
import StatusCell from "./components/StatusCell";
import CustomerCell from "./components/CustomerCell";
import CurrencyCell from "./components/CurrencyCell";

import { format } from "date-fns";
import { getProfileImageURL } from "src/features/utils";

export const TableActions = {
  SELECTED: "SELECTED",
};

export const getTableColumns = () => [
  {
    Header: "date",
    accessor: "date",
    Cell: ({ value }) => (
      <DefaultCell
        value={value ? format(value.toDate(), "eee, MMM do yyyy") : "N/A"}
      />
    ),
  },
  {
    Header: "member",
    accessor: ({ member, pilot }) => member?.displayName || pilot?.displayName,
    Cell: ({ row: { original } }) => {
      const { pilot, member } = original;
      return (
        <CustomerCell
          image={getProfileImageURL({ pilot })}
          color="dark"
          name={pilot?.displayName || member?.displayName}
        />
      );
    },
  },
  {
    Header: "aircraft",
    accessor: ({ aircraft }) => aircraft?.label,
    width: 100,
    Cell: ({ value }) => {
      return <DefaultCell value={value || "N/A"} />;
    },
  },
  {
    Header: "rate",
    accessor: "rate",
    width: 100,
    Cell: ({ row: { original } }) => {
      return <CurrencyCell value={original.rate / 100} />;
    },
  },
  {
    Header: "quantity",
    accessor: "quantity",
    Cell: ({ value }) => {
      return <DefaultCell value={value} />;
    },
  },
  {
    Header: "total",
    accessor: "total",
    width: 100,
    Cell: ({ row: { original } }) => {
      return <CurrencyCell value={original.total / 100} />;
    },
  },
  {
    Header: "status",
    width: 100,
    accessor: "approved",
    Cell: ({ row: { original } }) => {
      if (original.approved) {
        return <StatusCell icon="check" color="success" status="Approved" />;
      } else {
        return <StatusCell icon="close" color="error" status="Not Approved" />;
      }
    },
  },
  {
    Header: "remaining",
    accessor: "unappliedAmount",
    width: 100,
    Cell: ({ value }) => {
      return <CurrencyCell value={value / 100} />;
    },
  },
];
