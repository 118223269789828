import { IInvoice, InvoiceStatus, LineItem } from "src/interfaces";

export const isInvoicePaid = (invoice?: IInvoice | null) => {
  if (!invoice) return false;
  const amountPaid =
    invoice?.payments?.reduce((acc, payment) => {
      if (payment?.status === "succeeded")
        return acc + (payment?.amount ?? 0) / 100;
      else return acc;
    }, 0) ?? 0;
  const paid =
    amountPaid >= (invoice?.totalCost ?? invoice.totalAmount) ||
    invoice.status === InvoiceStatus.PAID;
  return paid;
};

export const getLineItemTotal = (lineItem: LineItem, clubPaysFees: boolean) => {
  const currentValue = lineItem.quantity * lineItem.rate;
  const tax = lineItem.tax ?? 0;
  const fees =
    lineItem.payment && !lineItem.payment.clubPaysFees
      ? (lineItem.fees ?? 0) + (lineItem.payment?.fees ?? 0)
      : !lineItem.payment && !clubPaysFees
      ? lineItem.fees ?? 0
      : 0;
  const currentPrice = currentValue + tax + fees;
  return currentPrice;
};

export const calculateInvoiceSummary = (
  invoice?: IInvoice | null,
  clubPaysFees: boolean = false
) => {
  if (!invoice?.lineItems?.length)
    return { total: 0, tax: 0, discount: 0, fees: 0, refunded: 0 };
  return invoice?.lineItems.reduce(
    (acc, val) => {
      // const currentValue = val.quantity * val.rate;
      const tax = val.tax ?? 0;
      const fees = (val.fees ?? 0) + (val.payment?.fees ?? 0);
      const refunded = val.refunded ?? 0;
      const currentPrice = getLineItemTotal(val, clubPaysFees);
      acc.total += currentPrice;

      acc.tax += tax;
      if (val.payment && !val.payment.clubPaysFees) acc.fees += fees;
      else if (!val.payment && !clubPaysFees) acc.fees += fees;
      acc.refunded += refunded;

      const discount = 0;
      acc.discount += discount;

      return acc;
    },
    { total: 0, tax: 0, discount: 0, fees: 0, refunded: 0 }
  );
};
