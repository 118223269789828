import { apiUrls } from "src/config/apiUrls";
import { ApiWrapper } from "./fetchClient";
import { FlightCredit } from "src/interfaces";
import { removeUndefined } from "src/utils/utils";

export const createFuelReimbursement = async ({
  date,
  aircraft,
  rate,
  quantity,
  total,
  receipts,
  clubId,
  locationId,
  userId,
  description,
}: {
  date: Date;
  aircraft: { value: string; label: string };
  rate: number;
  quantity: number;
  total: number;
  receipts: string[];
  clubId: string;
  locationId: string;
  userId: string;
  description?: string;
}): Promise<FlightCredit[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.fuelReimbursement.createFuelReimbursement(
        clubId,
        locationId
      ),
      params: removeUndefined({
        date,
        aircraft,
        description,
        rate,
        quantity,
        total,
        receipts,
        userId,
      }),
    })
  ).call();
  return result;
};

export const deletedFuelReimbursement = async ({
  clubId,
  locationId,
  fuelReimbursementId,
}: {
  clubId: string;
  locationId: string;
  fuelReimbursementId: string;
}): Promise<{ success: boolean }> => {
  const result = await (
    await ApiWrapper.delete<any>({
      url: apiUrls.fuelReimbursement.deleteFuelReimbursement(
        clubId,
        locationId,
        fuelReimbursementId
      ),
    })
  ).call();
  return { success: result.success };
};
