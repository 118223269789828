const toNumber = (value: number | string) => {
  if (typeof value === "number") return value;
  if (typeof value === "string") return parseInt(value);
  return 0;
};

export const toCents = (value: number | string) => toNumber(value) * 100;

export const centToUnit = (valueInCents: number) =>
  toNumber(valueInCents) / 100;

export const getNumberCurrencyDisplay = (
  value: number | string,
  isCent = true
) => {
  const newValue = isCent ? centToUnit(toNumber(value)) : value;
  return newValue?.toLocaleString("en-US", {
    style: "decimal",
    minimumFractionDigits: 2,
  });
};

export const normalizeAircraftRate = (value: number | string) => {
  const valueNumber = toNumber(value);
  return valueNumber < 1000 ? toCents(valueNumber) : valueNumber;
};
