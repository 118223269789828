export const getAccentColor = (status) => {
  switch (status) {
    case "In progress":
      return "primary";
    case "Complete":
      return "success";
    default:
      return "secondary";
  }
};

const lessonPermissions = {
  all: {
    asOption: {
      value: "all",
      label: "All instructors",
    },
    unapprovedMessage: "You are not allowed to grade this lesson.",
  },
  assigned: {
    asOption: {
      value: "assigned",
      label: "ONLY the student's assigned instructor",
    },
    unapprovedMessage:
      "Only the student's assigned instructor may grade this lesson.",
  },
  unassigned: {
    asOption: {
      value: "unassigned",
      label: "All instructors EXCEPT the student's assigned instructor",
    },
    unapprovedMessage:
      "Only instructors who aren't assigned to this student may grade this lesson.",
  },
  custom: {
    asOption: {
      value: "custom",
      label: "Select Manually",
    },
    unapprovedMessage: "Only specified instructors may grade this lesson.",
  },
};

export const getUserFacingLessonPermissions = (permissions) => {
  switch (true) {
    case !permissions:
      return;
    case permissions.approvedInstructors?.length >= 1:
      return lessonPermissions.custom;
    case !permissions.allowAssignedInstructor:
      return lessonPermissions.unassigned;
    case !permissions.allowUnassignedInstructors:
      return lessonPermissions.assigned;
    default:
      return lessonPermissions.all;
  }
};

export const gradingScaleOptions = (gradingScales) => [
  { label: "Default", value: undefined },
  ...gradingScales
    .filter((scale) => !scale.deleted)
    .map((value) => ({ value: value.id, label: value.name })),
];

export const gradingOptions = Object.values(lessonPermissions).map(
  (value) => value.asOption
);

export const formDefaultGrading = (lessonData) =>
  getUserFacingLessonPermissions(lessonData?.gradingPermissions)?.asOption;

export const getUnapprovedMessage = (lessonData) =>
  getUserFacingLessonPermissions(lessonData?.gradingPermissions)
    ?.unapprovedMessage || lessonPermissions.all.unapprovedMessage;

export const getStageColor = (stage, selectedIndex) => {
  if (selectedIndex !== stage.index) {
    return "grey.100";
  }
  return stage.editable ? "primary.background" : "warning.background";
};

export const getCourseURL = (
  pathname,
  studentId,
  intendedPage,
  enrollmentId,
  enrolledCourseId,
  lessonId
) => {
  const isTraining = pathname.includes("training");
  const isLMS = pathname.includes("lms");

  if (!isTraining && !isLMS) {
    console.error("Error: url does not include `/lms` or `/training`");
  }

  const prefix = isTraining ? "/training/classroom" : "/lms/students";
  const postfix = lessonId ? "/" + lessonId : "";

  return `${prefix}/${studentId}/${intendedPage}/${enrollmentId}/${enrolledCourseId}${postfix}`;
};

export const getNextLessonUrl = (pathname, lessonId, nextLessonId) => {
  const patharr = pathname.split("/");
  patharr.splice(
    patharr.findIndex((s) => s === lessonId),
    1,
    nextLessonId
  );

  return patharr.join("/");
};

export const getColorFromGrade = (value) => {
  switch (value) {
    case "S":
    case "1":
      return "success";
    case "4":
    case "3":
    case "2":
      return "info";
    case "U":
    case "5":
      return "error";
    case "I":
      return "warning";
    default:
      return "secondary";
  }
};

export const enrolledCoursesOnly = (enrollments) => {
  return enrollments.map((e) => e.enrolledCourses).flat();
};

export const getCourseStatus = (progress) => {
  const { stages, lessons, tasks } = progress;
  let text = "Not started";
  if (stages.completed === stages.total) {
    text = "Complete";
  } else if (stages.completed || lessons.completed || tasks.completed) {
    text = "In progress";
  }
  return text;
};
