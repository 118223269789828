import { Menu, MenuItem, Stack } from "@mui/material";
import SoftButton from "src/components/SoftButton";
import {
  IPayment,
  discountType,
  paymentTypes,
} from "src/interfaces/payment.interface";
import SoftLoader from "src/components/SoftLoader/SoftLoader";
import {
  usePopupState,
  bindTrigger,
  bindMenu,
} from "material-ui-popup-state/hooks";
import SoftTypography from "src/components/SoftTypography";
import { ExpandMore } from "@mui/icons-material";
import { LineItem, LineItemType } from "src/interfaces";
import get from "lodash/get";
import { normalizeAircraftRate } from "src/utils/currency.utils";
import { formatCurrency } from "src/features/utils";
import SoftBox from "src/components/SoftBox";
import { format, parseISO } from "date-fns";
import { cloneElement } from "react";
import { openModal$ } from "../modalConfiguration";
import { toTitleCase } from "src/features/utils";
import { getPaymentId } from "./payment.configs";

const formatDate = (isoString: string) => {
  const date = parseISO(isoString);
  const formattedDate = format(date, "do, MMM yy");
  return formattedDate.replace(/\b(\d{1,2})(th|st|nd|rd)\b/g, "$1");
};

const calculateRateWithDiscounts = (
  payment: IPayment,
  lineItem: LineItem,
  aircraftId: string
) => {
  const rate =
    lineItem.type === LineItemType.AIRCRAFT_RENTAL
      ? normalizeAircraftRate(lineItem.rate)
      : lineItem.rate;
  if (
    payment.type === paymentTypes.PAYMENT &&
    aircraftId == lineItem?.aircraftId &&
    Object.keys(payment?.additionalData?.aircraftRates ?? {}).includes(
      aircraftId
    )
  ) {
    const standardRate = get(
      payment,
      `additionalData.aircraftRates.${aircraftId}.standard`,
      null
    );
    if (!standardRate) return rate;
    if (payment.additionalData?.discounts?.length) {
      const rateAfterDiscounts = (
        payment.additionalData?.discounts ?? []
      ).reduce((acc, val) => {
        if (val.type === discountType.FIXED_AMOUNT) return acc - val.amount;
        if (val.type === discountType.PERCENTAGE)
          return acc - (acc * val.amount) / 100;

        return acc;
      }, normalizeAircraftRate(standardRate));
      return rateAfterDiscounts;
    }
    return normalizeAircraftRate(standardRate);
  }
  return (payment.additionalData?.discounts ?? []).reduce((acc, val) => {
    if (val.type === discountType.FIXED_AMOUNT) return acc - val.amount;
    if (val.type === discountType.PERCENTAGE)
      return acc - (acc * val.amount) / 100;

    return acc;
  }, rate);
};

const CreditCardView = ({ payment }: { payment: IPayment }) => {
  if (payment.id === "ADD_CARD") {
    return (
      <SoftTypography sx={{ fontSize: "14px" }}>
        <i>+ Add New Card</i>
      </SoftTypography>
    );
  }
  if (payment.type === paymentTypes.CARD_STRIPE) {
    return (
      <Stack direction="row" gap="12px" flex="1">
        <SoftTypography sx={{ fontSize: "14px", fontWeight: 600 }}>
          {(payment.additionalData.card?.display_brand ?? "").toUpperCase()}:
          ****
          {payment.additionalData.card?.last4}
        </SoftTypography>
        <Stack direction="row" marginLeft="auto">
          <SoftTypography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#7A7A7A" }}
          >
            Exp {payment.additionalData.card?.exp_month}/
            {payment.additionalData.card?.exp_year}
          </SoftTypography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap="12px" flex="1">
      <SoftTypography sx={{ fontSize: "14px", fontWeight: 600 }}>
        {toTitleCase(payment?.additionalData?.brand?.replace("_", " ") ?? "")}:
        ****
        {payment.additionalData.lastFourDigits}
      </SoftTypography>
      <Stack direction="row" marginLeft="auto">
        <SoftTypography
          sx={{ fontSize: "12px", fontWeight: 600, color: "#7A7A7A" }}
        >
          Exp {payment.additionalData.expMonth}/{payment.additionalData.expYear}
        </SoftTypography>
      </Stack>
    </Stack>
  );
};

const PaymentView = ({
  payment,
  lineItem,
  aircraftId,
}: {
  payment: IPayment;
  lineItem?: LineItem;
  aircraftId?: string;
}) => {
  return (
    <Stack direction="row" gap="4px" flex="1">
      {lineItem && aircraftId && (
        <>
          {" "}
          <SoftTypography
            sx={{ fontSize: "12px", fontWeight: 400, color: "#7A7A7A" }}
          >
            Rate
          </SoftTypography>
          <SoftTypography sx={{ fontSize: "14px", fontWeight: 600 }}>
            {formatCurrency(
              calculateRateWithDiscounts(payment, lineItem, aircraftId),
              true
            )}
          </SoftTypography>
        </>
      )}
      <Stack
        direction="row"
        marginLeft={lineItem ? "auto" : ""}
        justifyContent="center"
        alignItems="center"
        gap="2px"
      >
        <SoftTypography sx={{ fontSize: "14px", fontWeight: 600 }}>
          {formatCurrency(payment.additionalData.unappliedAmount, true)}
        </SoftTypography>
        <SoftTypography
          sx={{ fontSize: "10px", fontWeight: 600, color: "#7A7A7A" }}
        >
          /{formatCurrency(payment.additionalData.totalAmount, true)}
        </SoftTypography>
        {payment?.additionalData?.createdAt && (
          <SoftTypography
            sx={{
              fontSize: "12px",
              fontWeight: 600,
              color: "#7A7A7A",
              marginLeft: "6px",
            }}
          >
            ({formatDate(payment.additionalData.createdAt)})
          </SoftTypography>
        )}
      </Stack>
    </Stack>
  );
};

const AccountView = ({
  payment,
  name,
}: {
  name: string;
  payment: IPayment;
}) => {
  return (
    <Stack direction="row" gap="10px" alignItems="center" width="100%">
      <Stack
        justifyContent="center"
        sx={{
          background: "#EAF3F5",
          padding: "2px 8px 2px 8px",
          fontSize: "12px",
          borderRadius: "16px",
          width: "fit-content",
          fontWeight: 600,
          color: "#5299A8",
        }}
      >
        {name}
      </Stack>
      <SoftTypography
        sx={{
          fontSize: "12px",
          fontWeight: 400,
        }}
      >
        Balance{" "}
        <strong>{formatCurrency(payment.additionalData?.balance, true)}</strong>
      </SoftTypography>
      <SoftTypography
        sx={{
          fontWeight: 200,
          fontStyle: "italic",
          fontSize: "12px",
          color: "#414041",
          marginLeft: "auto",
        }}
      >
        (Pay on Account)
      </SoftTypography>
    </Stack>
  );
};

const FlightCreditView = ({
  payment,
  name,
}: {
  name: string;
  payment: IPayment;
}) => {
  return (
    <Stack direction="row" gap="10px" alignItems="center" width="100%">
      <Stack
        justifyContent="center"
        sx={{
          background: "#EAF3F5",
          padding: "2px 8px 2px 8px",
          fontSize: "12px",
          borderRadius: "16px",
          width: "fit-content",
          fontWeight: 600,
          color: "#5299A8",
        }}
      >
        {name}
      </Stack>
      <SoftTypography
        sx={{
          fontSize: "12px",
          fontWeight: 400,
        }}
      >
        Remaining Credits:{" "}
        <strong>{payment.additionalData?.balance} hrs</strong>
      </SoftTypography>
      {/* <SoftTypography
        sx={{
          fontWeight: 200,
          fontStyle: "italic",
          fontSize: "12px",
          color: "#414041",
          marginLeft: "auto",
        }}
      >
        (Pay on Account)
      </SoftTypography> */}
    </Stack>
  );
};

const FlightCreditViewMenuItem = ({
  payment,
  name,
  onSelected,
}: {
  name: string;
  payment: IPayment;
  onSelected: () => void;
}) => {
  return (
    <MenuItem
      sx={{
        padding: "10px 6px",
        paddingLeft: "0",
        borderBottom: "1px solid #E4E5E9",
        borderRadius: 0,
      }}
      key={getPaymentId(payment)}
      onClick={onSelected}
    >
      <FlightCreditView name={name} payment={payment} />
    </MenuItem>
  );
};

const AccountViewMenuItem = ({
  payment,
  name,
  onSelected,
}: {
  name: string;
  payment: IPayment;
  onSelected: () => void;
}) => {
  return (
    <MenuItem
      sx={{
        padding: "10px 6px",
        paddingLeft: "0",
        borderBottom: "1px solid #E4E5E9",
        borderRadius: 0,
      }}
      key={getPaymentId(payment)}
      onClick={onSelected}
    >
      <AccountView name={name} payment={payment} />
    </MenuItem>
  );
};

export const RenderPayment = ({
  payment,
  lineItem,
  aircraftId,
  enableAccountView = false,
}: {
  payment: IPayment;
  lineItem?: LineItem;
  aircraftId?: string;
  enableAccountView?: boolean;
}) => {
  if (
    payment.type === paymentTypes.CARD_STRIPE ||
    payment.type === paymentTypes.CARD_FWD
  )
    return <CreditCardView payment={payment} />;
  if (payment.type === paymentTypes.PAYMENT)
    return (
      <PaymentView
        payment={payment}
        aircraftId={aircraftId}
        lineItem={lineItem}
      />
    );
  if (enableAccountView && payment.type === paymentTypes.ACCOUNT) {
    return <AccountView payment={payment} name={payment.displayName} />;
  }
  if (payment.type === paymentTypes.FUEL_REIMBURSEMENT) {
    return <AccountView payment={payment} name={payment.displayName} />;
  }
  if (payment.type === paymentTypes.FLIGHT_CREDIT) {
    return <FlightCreditView payment={payment} name={payment.displayName} />;
  }
  return null;
};

interface IPaymentOptions {
  onSelected: ({
    payment,
    rate,
    category,
  }: {
    payment: IPayment;
    rate: number;
    category: string;
  }) => void;
  userId: string;
  lineItem?: LineItem;
  aircraftId: string;
  groupedPayments: Partial<Record<string, IPayment[]>> | null;
  isLoading?: boolean;
  CustomButton?: any;
  handleCardReaload?: () => void;
}
function PaymentOptions({
  onSelected,
  userId,
  groupedPayments,
  isLoading,
  aircraftId,
  lineItem,
  CustomButton,
  handleCardReaload = () => {},
}: IPaymentOptions) {
  const popupState = usePopupState({ variant: "popover", popupId: "demoMenu" });
  if (!groupedPayments || !Object.keys(groupedPayments).length) {
    return (
      <SoftButton
        color="secondary"
        onClick={() =>
          openModal$.next({
            modalName: "ADD_CARD_MODAL",
            modalProps: {
              userId,
              onDone: (success: boolean) => {
                return success && handleCardReaload?.();
              },
            },
          })
        }
      >
        Add Credit Card
      </SoftButton>
    );
  }

  return (
    <SoftLoader isLoading={!!isLoading}>
      {groupedPayments && (
        <SoftBox>
          {CustomButton ? (
            cloneElement(CustomButton, { ...bindTrigger(popupState) })
          ) : (
            <SoftButton
              color="primary"
              variant="outlined"
              {...bindTrigger(popupState)}
              sx={{
                padding: "4px 8px 4px 8px",
                border: "2px solid #5299A8 !important",
                width: "fit-content",
                margin: "auto",
                minHeight: "24px",
              }}
            >
              Select <ExpandMore />
            </SoftButton>
          )}
          <Menu
            {...bindMenu(popupState)}
            PaperProps={{
              sx: { width: "500px" },
            }}
          >
            {Object.entries(groupedPayments).map(([key, payments]) =>
              payments?.length === 1 &&
              (payments![0].type === paymentTypes.ACCOUNT ||
                payments![0].type === paymentTypes.FUEL_REIMBURSEMENT ||
                payments![0].type === paymentTypes.FLIGHT_CREDIT) ? (
                <>
                  {payments![0].type === paymentTypes.FLIGHT_CREDIT ? (
                    <FlightCreditViewMenuItem
                      name={key}
                      payment={payments[0]}
                      onSelected={() => {
                        onSelected({
                          payment: payments[0],
                          rate: lineItem
                            ? calculateRateWithDiscounts(
                                payments[0],
                                lineItem,
                                aircraftId
                              )
                            : 0,
                          category: key,
                        });
                        popupState.close();
                      }}
                    />
                  ) : (
                    <AccountViewMenuItem
                      name={key}
                      payment={payments[0]}
                      onSelected={() => {
                        onSelected({
                          payment: payments[0],
                          rate: lineItem
                            ? calculateRateWithDiscounts(
                                payments[0],
                                lineItem,
                                aircraftId
                              )
                            : 0,
                          category: key,
                        });
                        popupState.close();
                      }}
                    />
                  )}
                </>
              ) : (
                <Stack key={key} direction="column">
                  <SoftBox
                    sx={{
                      borderBottom: "1px solid #E4E5E9",
                      padding: "10px 2px",
                    }}
                  >
                    <Stack
                      justifyContent="center"
                      sx={{
                        background: "#EAF3F5",
                        padding: "2px 8px 2px 8px",
                        fontSize: "12px",
                        borderRadius: "16px",
                        width: "fit-content",
                        fontWeight: 600,
                        color: "#5299A8",
                      }}
                    >
                      {key}
                    </Stack>
                  </SoftBox>
                  {payments!.map((payment) => (
                    <MenuItem
                      sx={{
                        padding: "10px 6px",
                        paddingLeft: "30px",
                        borderBottom: "1px solid #E4E5E9",
                        borderRadius: 0,
                      }}
                      key={getPaymentId(payment)}
                      onClick={() => {
                        onSelected({
                          payment,
                          rate: lineItem
                            ? calculateRateWithDiscounts(
                                payment,
                                lineItem,
                                aircraftId
                              )
                            : 0,
                          category: key,
                        });
                        popupState.close();
                      }}
                    >
                      <RenderPayment
                        payment={payment}
                        lineItem={lineItem}
                        aircraftId={aircraftId}
                      />
                    </MenuItem>
                  ))}
                </Stack>
              )
            )}
          </Menu>
        </SoftBox>
      )}
    </SoftLoader>
  );
}
export default PaymentOptions;
