import { useState, useEffect } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import { useClubs } from "src/features/club/ClubProvider";

import { differenceInMinutes } from "date-fns";

let weatherTimer;

function WeatherCard({ color = "info", title = "Weather - METAR", icon }) {
  const { selectedLocation } = useClubs();
  const [airportWeather, setAirportWeather] = useState();
  const token = import.meta.env.VITE_AVWX_API_KEY;
  const [timeAgo, setTimeAgo] = useState(0);

  useEffect(() => {
    const avwxUrl = `https://avwx.rest/api/metar/${selectedLocation?.icao}`;
    const fetchWeather = async () => {
      const response = await fetch(avwxUrl, {
        method: "GET",
        headers: {
          Authorization: `BEARER ${token}`,
          "Content-type": "application/json; charset=UTF-8",
        },
      });
      const data = await response.json();
      if (data.error) {
        console.error(data.error);
        setAirportWeather(null);
        setTimeAgo(0);
        return;
      }

      setAirportWeather(data);

      setTimeAgo(differenceInMinutes(new Date(), new Date(data?.time?.dt)));
    };
    fetchWeather();
    if (!weatherTimer) {
      weatherTimer = setInterval(() => {
        fetchWeather();
      }, 300000);
    }
  }, [selectedLocation]);

  return (
    <Card sx={{ display: "block", marginBottom: 3 }}>
      {airportWeather && (
        <>
          <SoftBox
            bgColor={color}
            p={2}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            variant="gradient"
          >
            <Grid container spacing={2} alignItems="center">
              <Grid
                item
                xs={8}
                sx={{
                  lineHeight: 0,
                }}
              >
                <SoftTypography
                  variant="button"
                  color={color === "light" ? "dark" : "white"}
                  textTransform="capitalize"
                  fontWeight="medium"
                  opacity={0.7}
                >
                  {title}
                </SoftTypography>
                <SoftTypography
                  variant="h5"
                  color={color === "light" ? "dark" : "white"}
                  fontWeight="bold"
                >
                  {selectedLocation?.icao}: {airportWeather?.temperature?.value}
                  &deg;
                  {airportWeather?.units?.temperature} /{" "}
                  {airportWeather?.dewpoint?.value}&deg;
                  {airportWeather?.units?.temperature}
                </SoftTypography>
                <SoftBox mt={1}>
                  <SoftTypography
                    variant="h6"
                    color={color === "light" ? "dark" : "white"}
                  >
                    Wind:{" "}
                    {airportWeather?.wind_speed?.value === 0
                      ? `Calm`
                      : `${airportWeather?.wind_direction?.value} @ ${airportWeather?.wind_speed?.value} ${airportWeather?.units?.wind_speed}`}
                  </SoftTypography>
                  <SoftTypography
                    variant="h6"
                    color={color === "light" ? "dark" : "white"}
                  >
                    Visibility: {airportWeather?.visibility?.value}
                    {airportWeather?.units?.visibility}
                  </SoftTypography>
                  <SoftTypography
                    variant="h6"
                    color={color === "light" ? "dark" : "white"}
                  >
                    Clouds:{" "}
                    {airportWeather?.clouds?.length > 0
                      ? `${airportWeather?.clouds[0]?.type} @ ${
                          (airportWeather?.clouds[0]?.altitude || 0) * 100
                        }ft`
                      : "Sky Clear"}
                  </SoftTypography>
                  <SoftTypography
                    variant="h6"
                    color={color === "light" ? "dark" : "white"}
                  >
                    Altimeter: {airportWeather?.altimeter?.value}{" "}
                    {airportWeather?.units?.altimeter}
                  </SoftTypography>
                </SoftBox>
              </Grid>
              {icon && (
                <Grid
                  item
                  xs={4}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  alignContent="center"
                >
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <SoftBox
                      component="img"
                      src={icon.component}
                      width="85px"
                      ml="auto"
                      alt="weather icon"
                    />
                    <SoftBox ml="auto" mr={1}>
                      <SoftTypography
                        variant="h5"
                        color={color === "light" ? "dark" : "white"}
                        textTransform="capitalize"
                      >
                        {icon.text}
                      </SoftTypography>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              )}
            </Grid>
          </SoftBox>
          <SoftBox
            sx={{
              position: "absolute",
              bottom: "10px",
              right: "20px",
            }}
          >
            <SoftTypography
              variant="caption"
              color={timeAgo > 45 ? "error" : "white"}
              textTransform="capitalize"
            >
              {timeAgo === 0 ? `Just Now...` : `${timeAgo}m ago`}
            </SoftTypography>
          </SoftBox>
          <SoftBox
            bgColor={
              airportWeather?.flight_rules === "VFR"
                ? "success"
                : airportWeather?.flight_rules === "MVFR"
                ? "blue"
                : "error"
            }
            borderRadius="md"
            px={2}
            sx={{
              position: "absolute",
              top: "15px",
              right: "20px",
            }}
          >
            <SoftTypography
              variant="h6"
              color="white"
              textTransform="capitalize"
            >
              {airportWeather?.flight_rules}
            </SoftTypography>
          </SoftBox>
        </>
      )}
    </Card>
  );
}

// Typechecking props for the WeatherCard
WeatherCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  title: PropTypes.string,
  icon: PropTypes.shape({
    text: PropTypes.string.isRequired,
    component: PropTypes.string.isRequired,
  }),
};

export default WeatherCard;
