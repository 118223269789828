import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for SoftInput
import SoftTextareaAutosizeRoot from "src/components/SoftTextareaAutosize/SoftTextareaAutosizeRoot";

const SoftTextareaAutosize = forwardRef(
  ({ size = "medium", disabled = false, ...rest }, ref) => (
    <SoftTextareaAutosizeRoot
      {...rest}
      ref={ref}
      ownerState={{
        size,
        disabled,
      }}
    />
  )
);

// Typechecking props for the SoftTextareaAutosize
SoftTextareaAutosize.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  disabled: PropTypes.bool,
};

export default SoftTextareaAutosize;
