import { IPermission } from "./permission.interface";

export enum lmsRoleCategory {
  LMS_STUDENTS = "LMS_STUDENTS",
  LMS_COURSES = "LMS_COURSES",
}

export enum lmsPermissionTypes {
  VIEW_LMS_STUDENTS = "VIEW_LMS_STUDENTS",
  VIEW_ALL_LMS_STUDENTS = "VIEW_ALL_LMS_STUDENTS",
  VIEW_LMS_COURSES = "VIEW_LMS_COURSES",
  CREATE_LMS_COURSE = "CREATE_LMS_COURSE",
  EDIT_LMS_COURSES = "EDIT_LMS_COURSES",
  ENROLL_STUDENTS = "ENROLL_STUDENTS",
  EDIT_LMS_ENROLLMENTS = "EDIT_LMS_ENROLLMENTS",
  DELETE_LMS_COURSES = "DELETE_LMS_COURSES",
  LMS_GRADE_ANY_LESSON = "LMS_GRADE_ANY_LESSON",
}

export const lmsPermissions: IPermission[] = [
  {
    group: lmsRoleCategory.LMS_COURSES,
    label: "LMS - Courses",
    tooltipGroup: "",
    permissions: {
      [lmsPermissionTypes.VIEW_LMS_COURSES]: {
        label: "View All Courses",
        tooltip: "Allows users to view all of the LMS courses in the system",
      },
      [lmsPermissionTypes.CREATE_LMS_COURSE]: {
        label: "Create Courses",
        tooltip: "",
      },
      [lmsPermissionTypes.EDIT_LMS_COURSES]: {
        label: "Edit Courses",
        tooltip: "",
      },

      [lmsPermissionTypes.DELETE_LMS_COURSES]: {
        label: "Delete Courses",
        tooltip: "",
      },
    },
  },
  {
    group: lmsRoleCategory.LMS_STUDENTS,
    label: "LMS - Students",
    tooltipGroup: "",
    permissions: {
      [lmsPermissionTypes.VIEW_LMS_STUDENTS]: {
        label: "View LMS Students Page",
        tooltip:
          "Disabling this permission will hide the Students menu option from LMS menu",
      },
      [lmsPermissionTypes.VIEW_ALL_LMS_STUDENTS]: {
        label: "View All Students",
        tooltip:
          "Allows Instructors to see all students from the classroom, not just their own students",
      },
      [lmsPermissionTypes.ENROLL_STUDENTS]: {
        label: "Enroll New Students",
        tooltip: "",
      },
      [lmsPermissionTypes.EDIT_LMS_ENROLLMENTS]: {
        label: "Edit/Delete Enrolled New Students",
        tooltip: "",
      },
      [lmsPermissionTypes.LMS_GRADE_ANY_LESSON]: {
        label: "Grade Any Students Lessons",
        tooltip: "",
      },
    },
  },
];
