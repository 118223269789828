import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Billing page components
import Invoices from "./components/Invoices/Invoices";
import Memberships from "./components/Memberships";
import { Transactions } from "./components/Transactions/Transactions";

import { useClubs } from "src/features/club/ClubProvider";
import { getUserWithId } from "src/features/user/utils";
import { SoftTab, SoftTabs } from "src/components/SoftTabs/SoftTabs";
import { ACCOUNTS_TAB, accountTabs } from "./accounts.config";
import { SoftCard } from "src/components/SoftCard/SoftCard";
import Payments from "./components/Payments/Payments";
import { IUser } from "src/interfaces";

function AccountDetails({ userId }: { userId: string }) {
  const [searchParams] = useSearchParams();
  const accountId = searchParams.get("accountId");
  const { selectedLocationId } = useClubs();

  const [user, setUser] = useState<IUser | null | undefined>(null);
  // const [userPermissions, setUserPermissions] = useState(null);

  const getUser = async () => {
    const userDoc = await getUserWithId(userId);
    // const userPermissionsDoc = await getUserPermissionsWithId(userId);
    setUser(userDoc.data() as IUser);
    // const perms = userPermissionsDoc?.docs?.filter(
    //   (doc) => doc.data().locationId === selectedLocationId
    // );
    // if (perms?.length > 0) {
    //   setUserPermissions(perms[0].data());
    // }

    // const clubAccountBalancesDoc = await getClubAccountBalancesWithId(
    //   userId,
    //   selectedClubId
    // );
    // setClubAccountBalances(clubAccountBalancesDoc.data());
  };

  useEffect(() => {
    getUser();
  }, [userId, selectedLocationId]);
  const [selectedTab, setSelectedTabValue] = useState<accountTabs>(
    ACCOUNTS_TAB[0]
  );

  const handleChange = (
    _event: React.SyntheticEvent,
    newValue: accountTabs
  ) => {
    setSelectedTabValue(newValue);
  };

  if (!user || !userId) return null;

  return (
    <>
      <SoftBox
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        mb="16px"
      >
        <SoftCard
          styles={{
            flexDirection: "row",
            alignItems: "center",
            marginBottom: "12px",
            margin: 0,
            padding: "6px 0",
            paddingBottom: "6px",
          }}
        >
          <SoftTabs value={selectedTab} onChange={handleChange}>
            {ACCOUNTS_TAB.map((tab) => (
              <SoftTab
                key={tab}
                label={tab}
                value={tab}
                sx={{ fontWeight: 600 }}
              />
            ))}
          </SoftTabs>
        </SoftCard>
      </SoftBox>
      {selectedTab === accountTabs.DASHBOARD && <div>Dashboard</div>}
      {selectedTab === accountTabs.TRANSACTIONS && (
        <Transactions userId={userId} accountId={accountId} />
      )}
      {selectedTab === accountTabs.INVOICES && <Invoices userId={userId} />}
      {selectedTab === accountTabs.PAYMENTS && (
        <Payments userId={userId} accountId={accountId} />
      )}
      {selectedTab === accountTabs.MEMBERSHIPS && (
        <Memberships userId={userId} />
      )}
    </>
  );
}

export default AccountDetails;
