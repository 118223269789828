import { apiUrls } from "src/config/apiUrls";
import { ApiWrapper } from "./fetchClient";
import { removeUndefined } from "src/utils/utils";
import { IBooking, IInvoice } from "src/interfaces";

interface ICompleteBooking {
  clubId: string;
  locationId: string;
  bookingId: string;
  hobbsStart: number;
  hobbsEnd: number;
  tach1Start: number;
  tach1End: number;
  endBooking: boolean;
  instructionHours: number;
  instructionHoursGround?: number;
  selectedRateId: string;
  preheatReimbursement?: boolean;
  fuelReimbursement: {
    type: string;
    quantity: number;
    rate: number;
    total: number;
    receipt?: {
      createdAt: Date;
      documentType: any;
      url: string;
      storagePath: string;
      fileType: string;
      fileName: string;
    };
  };
}

export const completeBooking = async ({
  ...props
}: ICompleteBooking): Promise<{ invoice: IInvoice; booking: IBooking }> => {
  const { clubId, locationId, bookingId, ...rest } = props;
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.booking.complete(clubId, locationId, bookingId),
      params: removeUndefined(rest),
    })
  ).call();

  const invoice = result?.invoice || result.invoices[0];
  const booking = result.booking;
  return { invoice, booking };
};
