export const formatNumber = (number: number, decimal = 1) =>
  parseFloat(number.toFixed(decimal));

export const formatPhoneNumber = (input: string | number) => {
  if (typeof input === "number") input = input.toString();
  const cleaned = input.replace(/\D/g, "");
  const countryCode = cleaned.slice(0, 1);
  const firstPart = cleaned.slice(1, 4);
  const secondPart = cleaned.slice(4, 7);
  const thirdPart = cleaned.slice(7, 12);

  const formatted = `${countryCode} (${firstPart}) ${secondPart} ${thirdPart}`;
  return `+${formatted}`;
};
