import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

// Billing page components
import BaseLayout from "src/containers/LayoutContainers/BaseLayout";

import { useClubs } from "src/features/club/ClubProvider";
import { getUserWithId } from "src/features/user/utils";
import { IUser } from "src/interfaces";
import AccountDetails from "./AccountDetails";

function AccountDetailsPage({ forUserId }: { forUserId: string }) {
  const { userId } = useParams();
  const { selectedLocationId } = useClubs();

  const [user, setUser] = useState<IUser | null | undefined>(null);

  const getUser = async () => {
    const userDoc = await getUserWithId(userId || forUserId);
    setUser(userDoc.data() as IUser);
  };

  useEffect(() => {
    getUser();
  }, [userId, forUserId, selectedLocationId]);

  if (!user || !userId) return null;

  return (
    <BaseLayout stickyNavbar pageTitle={user?.displayName}>
      <AccountDetails userId={userId} />
    </BaseLayout>
  );
}

export default AccountDetailsPage;
