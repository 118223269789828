import { useEffect, useState, useMemo } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

// Data
import { getTableColumns } from "./tableColumns";
import { query, orderBy, where } from "firebase/firestore";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
// import { getClubTransactionsCollection } from "src/features/transaction/collection";
import { Modal } from "@mui/material";
import { AddTransactionModal } from "src/modals/AddTransactionModal";
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  isWithinInterval,
  startOfDay,
  endOfDay,
} from "date-fns";
import { getPaymentRequestsCollection } from "src/features/billing/collections";
import { getClubInvoices } from "src/features/club/collections";
import { isNewInvoice } from "src/interfaces";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import { systemPermissions } from "src/interfaces/roles/role.interface";

function InvoiceList() {
  const navigate = useNavigate();
  const { selectedClubId, selectedClub, selectedLocation, selectedLocationId } =
    useClubs();
  const [menu, setMenu] = useState(null);
  const [transactionRows, setTransactionRows] = useState([]);
  const [openAddNewTransaction, setOpenAddNewTransaction] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (filter) => {
    setMenu(null);
    if (filter !== "all") {
      setIsFiltered(true);
      const rows = [
        // ...allTransactions,
        ...allPaymentRequests.map((value) => ({
          ...value,
          totalCost: value.amount,
          isPaymentRequest: true,
          member: {
            displayName: `${value.member?.firstName} ${value.member?.lastName}`,
          },
          type: "paymentRequest",
        })),
      ]
        .filter((row) => row.type === filter)
        .sort((a, b) => {
          return b.createdAt.toDate() - a.createdAt.toDate();
        });
      setTransactionRows(rows);
      return;
    } else {
      const rows = [
        // ...allTransactions,
        ...allPaymentRequests.map((value) => ({
          ...value,
          totalCost: value.amount,
          isPaymentRequest: true,
          member: {
            displayName: `${value.member?.firstName} ${value.member?.lastName}`,
          },
          type: "paymentRequest",
        })),
      ].sort((a, b) => {
        return b.createdAt.toDate() - a.createdAt.toDate();
      });
      setTransactionRows(rows);
      setIsFiltered(false);
    }
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(menu)}
      onClose={() => closeMenu()}
      keepMounted
    >
      {/* <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem> */}
      <MenuItem onClick={() => closeMenu("booking")}>Type: Booking</MenuItem>
      {/* <MenuItem onClick={() => closeMenu("manual")}>Type: Booking</MenuItem> */}
      <Divider
        sx={{
          margin: "0.5rem 0",
        }}
      />
      <MenuItem onClick={() => closeMenu("all")}>
        <SoftTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

  // const clubsTransactionsCollectionRef = query(
  //   getClubTransactionsCollection(selectedClubId),
  //   where("createdAt", ">=", dateRange[0]),
  //   where("location.id", "==", selectedLocationId),
  //   orderBy("createdAt", "desc")
  // );

  // const { data: allTransactions, isDataLoaded: isTransactionsLoaded } =
  //   useRealtimeCollectionData(clubsTransactionsCollectionRef, true);

  const clubsInvoicesCollectionRef = query(
    getClubInvoices(selectedClubId),
    where("locationId", "==", selectedLocationId),
    where("createdAt", ">=", dateRange[0]),
    orderBy("createdAt", "desc")
  );

  const { data: allInvoices, isDataLoaded: isInvoicesLoaded } =
    useRealtimeCollectionData(clubsInvoicesCollectionRef, true);

  const clubsPaymentRequestsCollectionRef = query(
    getPaymentRequestsCollection(selectedClubId),
    where("location.id", "==", selectedLocationId),
    where("createdAt", ">=", dateRange[0])
  );
  const { data: allPaymentRequests, isDataLoaded: isPaymentRequestsLoaded } =
    useRealtimeCollectionData(clubsPaymentRequestsCollectionRef, true);

  useEffect(() => {
    if (
      // !isTransactionsLoaded &&
      !isInvoicesLoaded &&
      !isPaymentRequestsLoaded
    ) {
      return;
    }
    const rows = [
      // ...allTransactions.filter(
      //   (transaction) =>
      //     transaction?.createdAt &&
      //     isWithinInterval(transaction.createdAt.toDate(), {
      //       start: dateRange[0],
      //       end: dateRange[1],
      //     })
      // ),
      ...allInvoices
        .filter(
          (invoice) =>
            invoice?.createdAt &&
            isWithinInterval(invoice.createdAt.toDate(), {
              start: dateRange[0],
              end: dateRange[1],
            })
        )
        .map((value) => ({
          ...value,
          isNewInvoice: true,
        })),
      ...allPaymentRequests.map((value) => ({
        ...value,
        totalCost: value.amount,
        isPaymentRequest: true,
        member: {
          displayName: `${value.member?.firstName} ${value.member?.lastName}`,
        },
        type: "PaymentRequest",
      })),
    ].sort((a, b) => {
      return b.createdAt.toDate() - a.createdAt.toDate();
    });
    setTransactionRows(rows);
  }, [
    // allTransactions,
    allPaymentRequests,
    allInvoices,
    selectedLocationId,
    dateRange,
  ]);

  useEffect(() => {
    updateCsvData(tableRows);
  }, [tableRows]);

  const updateCsvData = (rows) => {
    const csvDataFromRows = rows.map((row) => ({
      ID: row.id,
      Date: row.createdAt
        ? format(row.createdAt.toDate(), "yyyy/MM/dd hh:mm:ss")
        : "---",
      Member: row.member?.displayName || row.pilot?.displayName || "---",
      "Transaction Type": row.type,
      "Total Amount": row?.totalCost || row.totalAmount,
      "Amount Paid":
        Math.round(
          (row.payments?.length > 0
            ? row.payments.reduce(
                (acc, payment) =>
                  payment?.status === "succeeded"
                    ? acc + payment.amount / 100
                    : acc,
                0
              )
            : 0) * 100
        ) / 100,
      "Service Fees": Math.round((row.fees?.applicationFee || 0) * 100) / 100,
      Revenue: Math.round((row.fees?.clubRevenue || 0) * 100) / 100,
    }));

    setCsvData(csvDataFromRows);
  };

  const onRowSelected = (row) => {
    if (row.type === "paymentRequest") {
      navigate({
        pathname: `/billing/paymentRequest/${row.id}`,
        search: createSearchParams({
          isNewInvoice: isNewInvoice(row),
        }).toString(),
      });
    } else {
      if (row.isNewInvoice) {
        navigate({
          pathname: `/billing/invoice/${row.id}`,
          search: createSearchParams({
            isNewInvoice: true,
          }).toString(),
        });
      } else {
        navigate(`/billing/invoices/${row.id}`);
      }
    }
  };

  const addNewTransaction = () => {
    navigate("/billing/invoice/add");
    // TODO: Remove old transaction modal
    // setOpenAddNewTransaction(true);
  };

  const handleNewTransactionClose = () => {
    setOpenAddNewTransaction(false);
  };

  const tableColumns = useMemo(() => getTableColumns(), []);
  const tableData = useMemo(() => transactionRows, [transactionRows]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox>
            <SoftButton
              variant="gradient"
              color="info"
              onClick={addNewTransaction}
            >
              New Invoice
            </SoftButton>
          </SoftBox>
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftButton
              variant={menu || isFiltered ? "contained" : "outlined"}
              color="dark"
              onClick={openMenu}
            >
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </SoftButton>
            {renderMenu}
            {csvData && (
              <SoftBox ml={1}>
                <SoftBox>
                  <CSVLink
                    data={csvData}
                    filename={`${selectedClub.name}-${
                      selectedLocation?.icao
                    }-billing_invoices-${format(new Date(), "MM/dd/yyyy")}.csv`}
                    target="_blank"
                  >
                    <SoftButton variant="outlined" color="dark">
                      <Icon>description</Icon>
                      &nbsp;export csv
                    </SoftButton>
                  </CSVLink>
                </SoftBox>
              </SoftBox>
            )}
          </SoftBox>
        </SoftBox>
        <Card>
          {transactionRows && transactionRows.length > 0 && (
            <DataTable
              onRowSelect={onRowSelected}
              entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
              table={{
                columns: tableColumns,
                rows: tableData,
              }}
              onRowsChanged={(rows) => {
                setTableRows(rows.map((row) => row.original));
              }}
              canSearch
            />
          )}
        </Card>
      </SoftBox>
      <Footer />
      <Modal
        open={openAddNewTransaction}
        onClose={handleNewTransactionClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddTransactionModal handleClose={handleNewTransactionClose} />
        </SoftBox>
      </Modal>
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_INVOICES,
  InvoiceList
);
