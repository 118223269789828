import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { formatCurrency } from "src/features/utils";
import { format, endOfDay, startOfDay } from "date-fns";
import { useClubs } from "src/features/club/ClubProvider";
import { query, where, getCountFromServer, getDocs } from "firebase/firestore";
import { getMembershipsCollectionGroup } from "src/features/user/collections";
import { getClubMembershipsCollection } from "src/features/club/collections";
import { getClubTransactionsCollection } from "src/features/transaction/collection";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";

const columns = [
  {
    Header: "Title",
    accessor: "label",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Members",
    accessor: "memberCount",
    Cell: ({ value }) => <DefaultCell value={value.toString() || "0"} />,
  },
  {
    Header: "Cost",
    accessor: "cost.amount",
    Cell: ({ value }) => (
      <DefaultCell value={value ? formatCurrency(value) : "---"} />
    ),
  },
  {
    Header: "Billing Cycle",
    accessor: "cost.billed",
    Cell: ({ value }) => (
      <DefaultCell textTransform="capitalize" value={value || "---"} />
    ),
  },
  {
    Header: "Total Revenue",
    accessor: "totalRevenue",
    Cell: ({ value }) => (
      <DefaultCell value={value ? formatCurrency(value) : "---"} />
    ),
  },
  {
    Header: "Active",
    accessor: "active",
    Cell: ({ value }) => (
      <DefaultCell textTransform="capitalize" value={`${value}`} />
    ),
  },
  {
    Header: "Deleted",
    accessor: "deleted",
    Cell: ({ value }) => (
      <DefaultCell textTransform="capitalize" value={`${value}`} />
    ),
  },
];

function MembershipsReport() {
  const { selectedClubId, selectedClub, selectedLocation, selectedLocationId } =
    useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [dateRange, setDateRange] = useState([]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const clubMembershipsCollectionRef = getClubMembershipsCollection(
    selectedClubId,
    selectedLocationId
  );
  const { data: allMemberships, isDataLoaded: membershipsLoaded } =
    useRealtimeCollectionData(clubMembershipsCollectionRef, true);

  const addMemberData = async (memberships) => {
    const rowData = await Promise.all(
      memberships.map(async (membership) => {
        const memberCount = await getCountFromServer(
          query(
            getMembershipsCollectionGroup(),
            where("membershipPlan", "==", membership.id)
          )
        );
        let totalRevenue = 0;
        const membershipTransactions = await getDocs(
          query(
            getClubTransactionsCollection(selectedClubId),
            where("membership.id", "==", membership.id),
            where("createdAt", ">=", dateRange[0])
          )
        );
        membershipTransactions.forEach((transaction) => {
          if (transaction.createdAt > dateRange[1]) return;
          const transactionData = transaction.data();
          totalRevenue +=
            transactionData.totalCost -
            transactionData.preflightFees -
            transactionData.stripeFees -
            transactionData.taxes;
        });

        const fullMembershipData = {
          ...membership,
          memberCount: memberCount?.data()?.count,
          totalRevenue,
        };
        return fullMembershipData;
      })
    );

    const csvDataFromRows = rowData.map((row) => ({
      Title: row.label,
      Members: row.memberCount,
      Cost: row.cost?.amount,
      "Billing Cycle": row.cost?.billed,
      "Total Revenue": row.totalRevenue,
      Active: row.active,
      Deleted: row.deleted,
    }));

    setCsvHeaders([
      "Title",
      "Members",
      "Cost",
      "Billing Cycle",
      "Total Revenue",
      "Active",
      "Deleted",
    ]);
    setCsvData(csvDataFromRows);

    setRows(rowData);
  };

  useEffect(() => {
    if (!membershipsLoaded || !allMemberships) return;
    addMemberData(allMemberships);
  }, [allMemberships, membershipsLoaded]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                headers={csvHeaders}
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-memberships-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MembershipsReport;
