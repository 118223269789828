import PropTypes from "prop-types";
import { formatCurrency } from "src/features/utils";

// @mui material components
import { Grid, Table, TableBody, TableRow } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";
import { useEffect, useState } from "react";

function TransactionInfo({ transaction }) {
  console.log("transaction", transaction);
  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;
  const [lineItems, setLineItems] = useState([]);

  useEffect(() => {
    if (transaction?.lineItems) {
      setLineItems(transaction.lineItems);
    } else {
      const newLineItems = [];
      if (transaction?.description && transaction?.description !== "") {
        newLineItems.push({
          item: `${transaction?.description}`,
          price: transaction?.amount / 100,
          qty: 1,
          total: transaction?.amount / 100,
          type: "debit",
        });
      } else {
        newLineItems.push({
          item: `Payment Request`,
          price: transaction?.amount / 100,
          qty: 1,
          total: transaction?.amount / 100,
          type: "debit",
        });
      }
      setLineItems(newLineItems);
    }
  }, [transaction]);

  const renderPaymentDetails = () => (
    <Grid item xs={12}>
      <SoftBox>
        <SoftBox width="100%" overflow="auto">
          <Table
            sx={{
              minWidth: "32rem",
            }}
          >
            <SoftBox component="thead">
              <TableRow>
                <SoftBox
                  component="th"
                  width={{
                    xs: "45%",
                    md: "50%",
                  }}
                  py={1.5}
                  px={1}
                  textAlign="left"
                  borderBottom={borderBottom}
                >
                  <SoftTypography variant="h6" color="text" fontWeight="medium">
                    Item
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  component="th"
                  py={1.5}
                  pl={3}
                  pr={1}
                  textAlign="left"
                  borderBottom={borderBottom}
                >
                  <SoftTypography variant="h6" color="text" fontWeight="medium">
                    Qty
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  component="th"
                  py={1.5}
                  pl={3}
                  pr={1}
                  textAlign="left"
                  borderBottom={borderBottom}
                >
                  <SoftTypography variant="h6" color="text" fontWeight="medium">
                    Rate
                  </SoftTypography>
                </SoftBox>
                <SoftBox
                  component="th"
                  py={1.5}
                  pl={3}
                  pr={1}
                  textAlign="left"
                  borderBottom={borderBottom}
                >
                  <SoftTypography variant="h6" color="text" fontWeight="medium">
                    Amount
                  </SoftTypography>
                </SoftBox>
              </TableRow>
            </SoftBox>
            <TableBody>
              {lineItems?.map((item) => (
                <TableRow key={item.id}>
                  <SoftBox
                    component="td"
                    textAlign="left"
                    p={1}
                    borderBottom={borderBottom}
                  >
                    <SoftTypography variant="body2" color="text">
                      {item?.item}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                    component="td"
                    textAlign="left"
                    py={1}
                    pr={1}
                    pl={3}
                    borderBottom={borderBottom}
                  >
                    <SoftTypography variant="body2" color="text">
                      {item?.qty}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                    component="td"
                    textAlign="left"
                    py={1}
                    pr={1}
                    pl={3}
                    borderBottom={borderBottom}
                  >
                    <SoftTypography variant="body2" color="text">
                      {formatCurrency(
                        item.type === "credit" || item.type === "accountBalance"
                          ? item.price * -1
                          : item.price
                      )}
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox
                    component="td"
                    textAlign="left"
                    py={1}
                    pr={1}
                    pl={3}
                    borderBottom={borderBottom}
                  >
                    <SoftTypography variant="body2" color="text">
                      {formatCurrency(
                        item.type === "credit" || item.type === "accountBalance"
                          ? item.total * -1
                          : item.total
                      )}
                    </SoftTypography>
                  </SoftBox>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </SoftBox>
      </SoftBox>
    </Grid>
  );

  return (
    <>
      <Grid container spacing={3} alignItems="center">
        {lineItems?.length > 0 && renderPaymentDetails()}
      </Grid>
    </>
  );
}

TransactionInfo.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default TransactionInfo;
