import {
  IQuickbookClass,
  IQuickbookItem,
} from "src/interfaces/quickbook.interface";
import { ApiWrapper } from "./fetchClient";
import { apiUrls } from "src/config/apiUrls";

const groupItemsByParent = (items: IQuickbookItem[]): IQuickbookItem[] => {
  const itemMap: { [key: string]: IQuickbookItem } = {};
  const rootClasses: IQuickbookItem[] = [];

  // Populate classMap with each class object
  items.forEach((qbItem) => {
    itemMap[qbItem.Id] = { ...qbItem, children: [] };
  });

  // Organize classes into parent-child hierarchy
  items.forEach((qbItem) => {
    if (qbItem.ParentRef && itemMap[qbItem.ParentRef.value]) {
      const parent = itemMap[qbItem.ParentRef.value];
      parent.children!.push(itemMap[qbItem.Id]);
    } else {
      rootClasses.push(itemMap[qbItem.Id]);
    }
  });

  return rootClasses;
};

export const getQuickbookItems = async ({
  selectedClubId,
}: {
  selectedClubId: string;
}): Promise<IQuickbookItem[]> => {
  const result = await (
    await ApiWrapper.get<IQuickbookItem[]>({
      url: apiUrls.quickbooks.items.get(selectedClubId),
    })
  ).call();

  return groupItemsByParent(result);
};

const groupClassesByParent = (
  classes: IQuickbookClass[]
): IQuickbookClass[] => {
  const classMap: { [key: string]: IQuickbookClass } = {};
  const rootClasses: IQuickbookClass[] = [];

  // Populate classMap with each class object
  classes.forEach((qbClass) => {
    classMap[qbClass.Id] = { ...qbClass, children: [] };
  });

  // Organize classes into parent-child hierarchy
  classes.forEach((qbClass) => {
    if (qbClass.ParentRef && classMap[qbClass.ParentRef.value]) {
      const parent = classMap[qbClass.ParentRef.value];
      parent.children!.push(classMap[qbClass.Id]);
    } else {
      rootClasses.push(classMap[qbClass.Id]);
    }
  });

  return rootClasses;
};

export const getQuickbookClasses = async ({
  selectedClubId,
}: {
  selectedClubId: string;
}): Promise<IQuickbookClass[]> => {
  const result = await (
    await ApiWrapper.get<IQuickbookClass[]>({
      url: apiUrls.quickbooks.class.get(selectedClubId),
    })
  ).call();

  return groupClassesByParent(result);
};
