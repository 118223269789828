export enum accountTabs {
  DASHBOARD = "Dashboard",
  TRANSACTIONS = "Transactions",
  INVOICES = "Invoices",
  PAYMENTS = "Payments",
  FLIGHT_CREDITS = "Flight Credits",
  FUEL_REIMBURSEMENTS = "Reimbursements",
  MEMBERSHIPS = "Memberships",
}
export const ACCOUNTS_TAB = [
  //accountTabs.DASHBOARD,
  accountTabs.TRANSACTIONS,
  accountTabs.INVOICES,
  accountTabs.PAYMENTS,
  // accountTabs.FLIGHT_CREDITS,
  // accountTabs.FUEL_REIMBURSEMENTS,
  //accountTabs.MEMBERSHIPS,
];
