import SoftBox from "src/components/SoftBox";
import IconButton from "@mui/material/IconButton";
import { Icon } from "@mui/material";
import GradingScale from "./components/GradingScale";

const iconButtonStyles = ({ typography: { size }, breakpoints }) => ({
  px: 0.9,

  "& .material-icons, .material-icons-round": {
    fontSize: `${size.md} !important`,
  },

  "& .MuiTypography-root": {
    display: "none",

    [breakpoints.up("sm")]: {
      display: "inline-block",
      lineHeight: 1.2,
      ml: 0.5,
    },
  },
});

export const TableActions = {
  EDIT: "EDIT",
  DELETE: "DELETE",
  TOGGLE: "TOGGLE",
};

export const getTableColumns = (onAction) => [
  {
    Header: "Name",
    accessor: "name",
    width: "20%",
  },
  {
    Header: "Grading Scale",
    Cell: ({ row: { original } }) => {
      const { gradingScales } = original;
      if (!gradingScales) return null;
      return (
        <SoftBox sx={{ display: "flex", gap: "10px" }}>
          {gradingScales
            .sort((a, b) => a.index - b.index)
            .map((scale) => (
              <GradingScale gradingScale={scale} />
            ))}
        </SoftBox>
      );
    },
  },
  {
    Header: "Actions",
    width: "10%",
    Cell: ({ row: { original } }) => (
      <SoftBox sx={{ display: "flex" }}>
        <IconButton
          aria-label="delete"
          sx={iconButtonStyles}
          onClick={() => onAction(TableActions.DELETE, original)}
        >
          <Icon>delete</Icon>
        </IconButton>
        <IconButton
          aria-label="edit"
          sx={iconButtonStyles}
          onClick={() => onAction(TableActions.EDIT, original)}
        >
          <Icon>edit</Icon>
        </IconButton>
      </SoftBox>
    ),
  },
];
