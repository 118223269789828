import { useEffect, useMemo, useState } from "react";
import { useParams, useNavigate, createSearchParams } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";
// import { useUser } from "src/features/user/UserProvider";

import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { doc } from "firebase/firestore";
import { getFunctions, httpsCallable } from "firebase/functions";
import Swal from "sweetalert2";
import * as toastr from "toastr";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";
import { formatCurrency } from "src/features/utils";
import { getClubInvoices } from "src/features/club/collections";
import { formatNumber, formatPhoneNumber } from "src/utils/number.utils";
import { Stack } from "@mui/material";
import { formatTimestampOrString } from "src/utils/date.utils";
import {
  IInvoice,
  IUseClub,
  InvoiceStatus,
  LineItem,
  discountType,
} from "src/interfaces";
import { SoftCard } from "src/components/SoftCard/SoftCard";
import {
  ChevronLeft,
  CreditCard,
  CreditScore,
  CurrencyExchange,
  Edit,
} from "@mui/icons-material";
import SoftButton from "src/components/SoftButton";
import { openModal$ } from "src/modals/modalConfiguration";
import {
  calculateInvoiceSummary,
  isInvoicePaid,
} from "src/utils/invoice.utils";
import { InvoiceStatusIndicator } from "src/components/InvoiceStatusIndicator/InvoiceStatusIndicator";
import InstagramLogo from "src/assets/images/logos/gray-logos/logo-instagram.svg";
import FacebookLogo from "src/assets/images/logos/gray-logos/logo-facebook.svg";
import TwitterLogo from "src/assets/images/logos/gray-logos/logo-twitter.svg";
import LinkedinLogo from "src/assets/images/logos/gray-logos/logo-linkedin.svg";
import { pdf } from "@react-pdf/renderer";
import { InvoicePdf } from "../invoice-edit/components/InvoicePdf";

const SOCIAL_MEDIA = [InstagramLogo, FacebookLogo, TwitterLogo, LinkedinLogo];

type previewTypes = "preview" | "email" | "pdf";

const DividerInvoice = () => {
  return (
    <SoftBox
      sx={{
        margin: "26px -26px",
        borderBottom: "1px solid rgba(201, 204, 211, 1)",
      }}
    />
  );
};

function InvoiceDetailsNew() {
  const { selectedClub, selectedClubId, clubUsers, selectedLocation } =
    useClubs() as IUseClub;
  // const { user } = useUser();
  const navigate = useNavigate();
  const functions = getFunctions();
  const { invoiceId } = useParams();
  const [showPreview, setShowPreview] = useState<previewTypes>("preview");
  const [paidLineItems, setPaidLineItems] = useState<LineItem[]>([]);

  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;
  const invoiceDocRef = doc(getClubInvoices(selectedClubId), invoiceId);
  const { data: invoice, isDataLoaded: loadedInvoice } =
    useRealtimeDocumentData<IInvoice>(invoiceDocRef);

  const paid = isInvoicePaid(invoice);

  useEffect(() => {
    const lineItemsWithPayment =
      invoice?.lineItems?.filter(
        (line) => line?.payment?.id && line?.payment?.id !== ""
      ) || [];
    setPaidLineItems(lineItemsWithPayment);
  }, [invoice?.lineItems]);

  const goBack = () => {
    navigate("/billing/invoices", { replace: true });
  };

  // TODO: Check fees
  // useEffect(() => {
  //   if (invoice?.lineItems) {
  //
  //     const totalFees = invoice.lineItems.reduce((acc, line) => {
  //       if (line?.fees && line?.fees > 0) {
  //         return acc + line.fees;
  //       }
  //       return acc;
  //     }, 0);
  //     setTotalFees(totalFees);
  //   }
  // }, [invoice?.lineItems]);

  // const onResendPaymentRequest = async () => {
  //   // Resend payment request
  //   const resendPaymentRequest = httpsCallable(
  //     functions,
  //     "resendPaymentRequest"
  //   );
  //   resendPaymentRequest({
  //     clubId: selectedClubId,
  //     paymentRequestId: invoiceId,
  //   })
  //     .then((resendResult: any) => {
  //       if (resendResult.data.success) {
  //         toastr.success(
  //           `The payment request was resent to ${invoice!.pilot?.firstName}.`,
  //           "Success"
  //         );
  //       } else {
  //         toastr.error(
  //           `There was an error resending the payment request to ${
  //             invoice!.pilot?.firstName
  //           }.`,
  //           "Whoops!"
  //         );
  //       }
  //     })
  //     .catch(() => {
  //       toastr.error(
  //         `There was an error resending the payment request to ${
  //           invoice!.pilot?.firstName
  //         }.`,
  //         "Whoops!"
  //       );
  //     });
  // };

  // const onCancelPaymentRequest = async () => {
  //   const newSwal = Swal.mixin({
  //     customClass: {
  //       cancelButton: "button button-error",
  //     },
  //     buttonsStyling: false,
  //   });

  //   newSwal
  //     .fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       showCancelButton: true,
  //       confirmButtonText: "Yes, cancel request!",
  //       cancelButtonText: "No, keep it!",
  //       reverseButtons: true,
  //     })
  //     .then((result) => {
  //       if (result.value) {
  //         deleteDoc(doc(getPaymentRequestsCollection(), invoiceId));
  //         navigate("/billing/invoices");
  //       }
  //     });
  // };
  const onMarkAsPaid = async () => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "This will completed the payment request and deposit any Account Deposit funds specified to the member!",
        showCancelButton: true,
        confirmButtonText: "Yes!",
        cancelButtonText: "No",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          const completePaymentRequestWithoutPayment = httpsCallable(
            functions,
            "completePaymentRequestWithoutPayment"
          );
          completePaymentRequestWithoutPayment({
            paymentRequestId: invoiceId,
            clubId: selectedClubId,
          }).then((resendResult: any) => {
            if (resendResult.data.success) {
              toastr.success(
                `The payment request was marked as paid.`,
                "Success"
              );
            } else {
              toastr.error(
                `There was an error marking the payment request as paid`,
                "Whoops!"
              );
            }
          });
        }
      });
  };

  const summary = useMemo(
    () =>
      calculateInvoiceSummary(
        invoice,
        selectedLocation?.preferences?.clubPaysFeesOnAccount
      ),
    [invoice?.lineItems]
  );

  const generatePdfAndOpen = async () => {
    const doc = (
      <InvoicePdf
        invoice={invoice!}
        club={selectedClub}
        location={selectedLocation}
      />
    );
    const asPdf = pdf(doc);
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  if (!invoice && loadedInvoice) {
    // navigate("/billing");
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={8}>
              <SoftBox pt={2} px={2}>
                <SoftTypography>
                  Can not find this transaction...
                </SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }

  if (!loadedInvoice || !invoice) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox my={7}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={9}>
              <SoftBox pt={2} px={2}>
                <SoftTypography>Loading...</SoftTypography>
              </SoftBox>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <>
        <DashboardNavbar pageTitle={`Invoice #${invoice.transactionNumber}`} />
        <SoftBox my="16px">
          <SoftCard
            styles={{
              flexDirection: "row",
              alignItems: "center",
              fontWeight: 600,
              marginBottom: "12px",
              marginTop: 0,
            }}
          >
            <ChevronLeft sx={{ cursor: "pointer" }} onClick={goBack} />
            Invoices
          </SoftCard>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={12}>
              <Card sx={{ padding: "12px 26px" }}>
                {showPreview === "preview" && (
                  <>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      my="12px"
                      mb="16px"
                    >
                      <SoftBox
                        sx={{
                          display: "inline-flex",
                          gap: "8px",
                          alignItems: "center",
                        }}
                      >
                        Invoice{" "}
                        <SoftTypography color="primary">
                          {invoice.transactionNumber}
                        </SoftTypography>
                        <InvoiceStatusIndicator status={invoice.status} />
                      </SoftBox>

                      {selectedClub.logoUrl && (
                        <img
                          src={selectedClub.logoUrl}
                          alt="Logo"
                          width="102px"
                        />
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      sx={{
                        backgroundColor: "rgba(234, 243, 245, 1)",
                        padding: "22px",
                        fontSize: "12px",
                        margin: "0 -26px",
                      }}
                    >
                      <SoftBox>
                        Due date:{" "}
                        <b>
                          {formatTimestampOrString(
                            invoice?.dueDate,
                            "EEE, MMM do yyyy"
                          )}
                        </b>
                      </SoftBox>

                      <SoftBox>
                        Date:{" "}
                        <b>
                          {formatTimestampOrString(
                            invoice?.createdAt,
                            "EEE, MMM do yyyy"
                          )}
                        </b>
                      </SoftBox>
                    </Stack>
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="stretch"
                      sx={{ marginTop: "18px" }}
                    >
                      <Grid item>
                        <SoftTypography sx={fontTitle}>
                          {selectedClub.name}
                        </SoftTypography>
                        <SoftTypography sx={{ fontSize: "14px" }}>
                          {selectedLocation.address.addressLine1}
                        </SoftTypography>
                        <SoftTypography sx={{ fontSize: "14px" }}>
                          {selectedLocation.address.city},{" "}
                          {selectedLocation.address.state}{" "}
                          {selectedLocation.address.zip}
                        </SoftTypography>
                        <SoftTypography sx={{ fontSize: "14px" }}>
                          {formatPhoneNumber(selectedClub.number)}
                        </SoftTypography>
                      </Grid>
                      <Grid item>
                        <SoftTypography sx={fontTitle}>
                          Billed to
                        </SoftTypography>

                        <SoftTypography
                          sx={{
                            fontSize: "14px",
                            marginTop: "8px",
                            backgroundColor: "#F7F7F8",
                            padding: "8px 12px",
                            borderRadius: "200px",
                          }}
                        >
                          {invoice?.member?.displayName ??
                            clubUsers.get(invoice?.memberId ?? "")?.displayName}
                        </SoftTypography>
                        <SoftTypography
                          sx={{
                            fontSize: "14px",
                            marginTop: "8px",
                            backgroundColor: "#F7F7F8",
                            padding: "8px 12px",
                            borderRadius: "200px",
                          }}
                        >
                          {invoice?.member?.email ??
                            clubUsers.get(invoice?.memberId ?? "")?.email}
                        </SoftTypography>
                      </Grid>
                    </Grid>
                    <DividerInvoice />
                    <SoftTypography ml="5px" sx={fontTitle}>
                      Items
                    </SoftTypography>
                    <SoftBox pt={1}>
                      <SoftBox p={3} pl={0} pb={1}>
                        <SoftBox width="100%" overflow="auto">
                          <Table
                            sx={{
                              minWidth: "32rem",
                            }}
                          >
                            <SoftBox component="thead">
                              <TableRow>
                                <SoftBox
                                  component="th"
                                  py={1.5}
                                  px={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    #
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="th"
                                  py={1.5}
                                  px={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                ></SoftBox>
                                <SoftBox
                                  component="th"
                                  width={{
                                    xs: "45%",
                                    md: "50%",
                                  }}
                                  py={1.5}
                                  px={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    Item
                                  </SoftTypography>
                                </SoftBox>

                                <SoftBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    Rate
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    Qty
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    Amount
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    Discount
                                  </SoftTypography>
                                </SoftBox>
                                <SoftBox
                                  component="th"
                                  py={1.5}
                                  pl={3}
                                  pr={1}
                                  textAlign="left"
                                  borderBottom={borderBottom}
                                >
                                  <SoftTypography
                                    variant="h6"
                                    color="text"
                                    fontWeight="medium"
                                  >
                                    Tax/Fees
                                  </SoftTypography>
                                </SoftBox>
                              </TableRow>
                            </SoftBox>
                            <TableBody>
                              {invoice?.lineItems.map((item, index) => (
                                <TableRow key={item.description}>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    p={1}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {index + 1}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    p={1}
                                    borderBottom={borderBottom}
                                  >
                                    {item?.payment?.id && (
                                      <InvoiceStatusIndicator
                                        paymentId={item.payment.id}
                                        status={InvoiceStatus.PAID}
                                      />
                                    )}
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    p={1}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {item?.item || item?.description}
                                    </SoftTypography>
                                  </SoftBox>

                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {formatCurrency(item?.rate, true)}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {formatNumber(
                                        item.qty || item.quantity || 1,
                                        3
                                      )}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {formatCurrency(
                                        item.quantity * item.rate,
                                        true
                                      )}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {item.discount?.type ===
                                      discountType.FIXED_AMOUNT
                                        ? formatCurrency(
                                            item.discount?.amount,
                                            true
                                          )
                                        : `${item.discount?.amount} 
                                      ${
                                        item.discount?.type ===
                                          discountType.PERCENTAGE && "%"
                                      }
                                        `}
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox
                                    component="td"
                                    textAlign="left"
                                    py={1}
                                    pr={1}
                                    pl={3}
                                    borderBottom={borderBottom}
                                  >
                                    <SoftTypography
                                      variant="body2"
                                      color="text"
                                    >
                                      {formatCurrency(
                                        item.tax +
                                          (item.payment?.clubPaysFees
                                            ? 0
                                            : (item.fees || 0) +
                                              (item.payment?.fees || 0)),
                                        true
                                      )}
                                    </SoftTypography>
                                  </SoftBox>
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                          <Stack direction="column" ml="auto" mt="16px">
                            <Stack
                              direction="row"
                              justifyContent="flex-end"
                              gap="8px"
                            >
                              <SoftTypography fontWeight="bold">
                                Discount:
                              </SoftTypography>
                              <SoftTypography>
                                {formatCurrency(summary.discount, true)}
                              </SoftTypography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="flex-end"
                              gap="8px"
                            >
                              <SoftTypography fontWeight="bold">
                                Tax (
                                {selectedLocation?.preferences
                                  ?.defaultTaxRate || 0}
                                %):
                              </SoftTypography>
                              <SoftTypography>
                                {formatCurrency(summary.tax, true)}
                              </SoftTypography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="flex-end"
                              gap="8px"
                            >
                              <SoftTypography fontWeight="bold">
                                Fees:
                              </SoftTypography>
                              <SoftTypography>
                                {formatCurrency(summary.fees, true)}
                              </SoftTypography>
                            </Stack>
                            <Stack
                              direction="row"
                              justifyContent="flex-end"
                              gap="8px"
                            >
                              <SoftTypography fontWeight="bold">
                                Total:
                              </SoftTypography>
                              <SoftTypography fontWeight="bold">
                                {formatCurrency(summary.total, true)}
                              </SoftTypography>
                            </Stack>
                            {summary.refunded ? (
                              <Stack
                                direction="row"
                                justifyContent="flex-end"
                                gap="8px"
                              >
                                <SoftTypography fontWeight="bold" color="error">
                                  Refunded:
                                </SoftTypography>
                                <SoftTypography color="error">
                                  {formatCurrency(summary.refunded, true)}
                                </SoftTypography>
                              </Stack>
                            ) : null}
                          </Stack>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <DividerInvoice />
                    {!!invoice?.notes && (
                      <SoftBox>
                        <SoftTypography>Notes</SoftTypography>
                        <SoftTypography>{invoice.notes}</SoftTypography>
                        <DividerInvoice />
                      </SoftBox>
                    )}
                  </>
                )}
                {showPreview === "email" && (
                  <Stack
                    direction="column"
                    alignItems="center"
                    gap="42px"
                    p="24px 36px"
                    sx={{ maxWidth: "600px", margin: "auto" }}
                  >
                    {selectedClub.logoUrl && (
                      <img
                        src={selectedClub.logoUrl}
                        alt="Logo"
                        width="102px"
                      />
                    )}
                    <SoftBox
                      sx={{
                        background: "#F7F9FA",
                        fontWeight: 700,
                        fontSize: "24px",
                        padding: "16px 54px",
                        width: "100%",
                        textAlign: "center",
                      }}
                    >
                      Your invoice is ready!
                    </SoftBox>
                    <SoftBox sx={{ fontSize: "14px" }}>
                      Message goes here about whatever you want to write.
                    </SoftBox>

                    <Stack direction="column" alignItems="center">
                      <span style={{ fontSize: "12px" }}>Total</span>
                      <span style={{ fontSize: "32px", fontWeight: 500 }}>
                        {formatCurrency(invoice.totalAmount, true)}
                      </span>
                    </Stack>

                    <SoftBox
                      sx={{
                        borderRadius: "8px",
                        background: "#5299A8",
                        padding: "12px 32px",
                        color: "#FFF",
                        fontSize: "14px",
                      }}
                    >
                      View Details
                    </SoftBox>

                    <SoftBox
                      sx={{
                        background: "#609AA5",
                        color: "#FFFF",
                        padding: "28px 44px",
                        fontSize: "12px",
                        borderRadius: "0px 0px 12px 12px",
                      }}
                    >
                      You're receiving this email because you have an account in
                      PreFlight. If you are not sure why you're receiving this,
                      please contact us through our <b>Support Center</b>.
                    </SoftBox>
                    <Stack
                      direction="column"
                      sx={{ fontSize: "14px" }}
                      alignItems="center"
                    >
                      <span>Sent with care from Preflight</span>
                      <span>
                        228 Park Avenue SouthSuite 35600, New York 10003
                      </span>
                      <span
                        style={{
                          marginTop: "12px",
                          textDecoration: "underline",
                        }}
                      >
                        Unsubscribe
                      </span>
                    </Stack>
                    <Stack
                      direction="row"
                      width="100%"
                      border="1px solid rgba(100, 104, 125, 0.2)"
                    >
                      {SOCIAL_MEDIA.map((logo, i) => (
                        <Stack
                          direction="row"
                          key={logo}
                          sx={{
                            flex: 1,
                            borderLeft:
                              i === 0
                                ? ""
                                : "1px solid rgba(100, 104, 125, 0.2)",
                            justifyContent: "center",
                            padding: "12px 0",
                          }}
                        >
                          <img src={logo} />
                        </Stack>
                      ))}
                    </Stack>
                  </Stack>
                )}
                <Stack
                  py={2}
                  flexDirection="row"
                  justifyContent="space-between"
                >
                  <Stack gap={2} flexDirection="row">
                    <SoftButton
                      onClick={() => setShowPreview("preview")}
                      variant="outlined"
                      color={
                        showPreview === "preview" ? "primary" : "secondary"
                      }
                    >
                      {showPreview === "preview" && "•"} Preview
                    </SoftButton>
                    <SoftButton
                      onClick={() => setShowPreview("email")}
                      variant="outlined"
                      color={showPreview === "email" ? "primary" : "secondary"}
                    >
                      {showPreview === "email" && "•"} Email Preview
                    </SoftButton>
                    <SoftButton
                      onClick={generatePdfAndOpen}
                      disabled={!invoice}
                    >
                      PDF Preview
                    </SoftButton>
                  </Stack>
                  <Stack gap={2} flexDirection="row">
                    {paidLineItems.length > 0 && (
                      <SoftButton
                        color="error"
                        onClick={() => {
                          openModal$.next({
                            modalName: "INVOICE_REFUND_MODAL",
                            modalProps: {
                              invoice: invoice,
                              lineItems: paidLineItems,
                            },
                          });
                        }}
                      >
                        <CurrencyExchange sx={{ marginRight: 0.5 }} /> Refund
                      </SoftButton>
                    )}
                    <SoftButton
                      onClick={() => {
                        navigate({
                          pathname: `/billing/invoice/edit/${invoice.id}`,
                          search: createSearchParams({
                            isNewInvoice: "true",
                          }).toString(),
                        });
                      }}
                    >
                      <Edit sx={{ marginRight: 0.5 }} /> Edit Invoice
                    </SoftButton>
                    {invoice.status !== InvoiceStatus.PAID && (
                      <SoftButton
                        onClick={() => {
                          openModal$.next({
                            modalName: "PAYMENT_MODAL",
                            modalProps: { invoice: invoice },
                          });
                        }}
                      >
                        <CreditCard sx={{ marginRight: 0.5 }} /> Add Payment
                      </SoftButton>
                    )}
                    <SoftButton
                      color="primary"
                      onClick={onMarkAsPaid}
                      disabled={paid}
                    >
                      <CreditScore sx={{ marginRight: 0.5 }} /> Mark as Paid
                    </SoftButton>
                  </Stack>
                </Stack>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </>
    </DashboardLayout>
  );
}

export default InvoiceDetailsNew;

const fontTitle = {
  fontWeight: 600,
  fontSize: "16px",
};
