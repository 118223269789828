import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";

// Images
import masterCardLogo from "src/assets/images/logos/mastercard.png";
import visaCardLogo from "src/assets/images/logos/visa.png";

import { formatCurrency } from "src/features/utils";

function StripePaymentDetails({ payment }) {
  const { borderWidth, borderColor } = borders;

  const renderPayments = () => (
    <>
      <SoftTypography variant="h6" fontWeight="medium">
        Payment Details
      </SoftTypography>
      {payment?.paymentData?.map((paymentData) =>
        paymentData?.charges?.data?.map((charge) => {
          if (charge?.payment_method_details?.type === "us_bank_account") {
            return (
              <SoftBox
                border={`${borderWidth[1]} solid ${borderColor}`}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={charge.id}
                p={3}
                mt={2}
              >
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                >
                  <SoftTypography variant="h6" fontWeight="medium">
                    {charge?.payment_method_details?.us_bank_account?.bank_name}
                  </SoftTypography>
                  <SoftTypography variant="caption" fontWeight="medium">
                    Ending:{" "}
                    {charge?.payment_method_details?.us_bank_account?.last4}
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml="auto" lineHeight={0}>
                  <Tooltip
                    title="We do not store account details, just the last 4 digits"
                    placement="bottom"
                  >
                    <SoftButton
                      variant="outlined"
                      color="secondary"
                      size="small"
                      iconOnly
                      circular
                    >
                      <Icon
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        priority_high
                      </Icon>
                    </SoftButton>
                  </Tooltip>
                </SoftBox>
              </SoftBox>
            );
          }
          if (
            charge?.payment_method_details?.type === "Account" ||
            charge?.payment_method_details?.type === "Cash" ||
            charge?.payment_method_details?.type === "Check"
          ) {
            return (
              <SoftBox
                border={`${borderWidth[1]} solid ${borderColor}`}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={charge.id}
                p={3}
                mt={2}
              >
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                >
                  <SoftTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {charge?.payment_method_details?.type} Payment:{" "}
                    {formatCurrency((charge?.amount || 0) / 100)}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            );
          }
          if (charge?.payment_method_details?.type === "Refund") {
            return (
              <SoftBox
                border={`${borderWidth[1]} solid ${borderColor}`}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={charge.id}
                p={3}
                mt={2}
              >
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                >
                  <SoftTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    Account Refunded:{" "}
                    {formatCurrency((charge?.amount || 0) / 100)}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            );
          }
          if (charge?.payment_method_details?.type === "Marked As Paid") {
            return (
              <SoftBox
                border={`${borderWidth[1]} solid ${borderColor}`}
                borderRadius="lg"
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                key={charge.id}
                p={3}
                mt={2}
              >
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  alignItems="start"
                >
                  <SoftTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {charge?.payment_method_details?.type}:{" "}
                    {formatCurrency(charge.amount / 100)}
                  </SoftTypography>
                  <SoftTypography
                    variant="caption"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    By: {charge?.updatedBy?.displayName}
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            );
          }
          if (charge?.payment_method_details?.card_present) {
            charge.payment_method_details.card =
              charge.payment_method_details.card_present;
          }
          return (
            <SoftBox
              border={`${borderWidth[1]} solid ${borderColor}`}
              borderRadius="lg"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              key={charge.id}
              p={2}
              mt={2}
            >
              <SoftBox
                component="img"
                src={
                  charge?.payment_method_details?.card?.brand === "visa"
                    ? visaCardLogo
                    : masterCardLogo
                }
                alt={charge?.payment_method_details?.card?.brand}
                width="10%"
                mr={2}
              />
              <SoftBox display="flex" flexDirection="column" alignItems="start">
                <SoftTypography
                  display="block"
                  style={{ marginBottom: "5px" }}
                  variant="caption"
                  fontWeight="medium"
                >
                  Card Payment: {formatCurrency(charge.amount / 100)}
                </SoftTypography>
                <SoftTypography variant="h6" fontWeight="medium">
                  ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
                  {charge?.payment_method_details?.card?.last4}
                </SoftTypography>
              </SoftBox>
              <SoftBox ml="auto" lineHeight={0}>
                <Tooltip
                  title="We do not store card details, just the last 4 digits"
                  placement="bottom"
                >
                  <SoftButton
                    variant="outlined"
                    color="secondary"
                    size="small"
                    iconOnly
                    circular
                  >
                    <Icon
                      sx={{
                        cursor: "pointer",
                      }}
                    >
                      priority_high
                    </Icon>
                  </SoftButton>
                </Tooltip>
              </SoftBox>
            </SoftBox>
          );
        })
      )}
    </>
  );

  return renderPayments();
}

StripePaymentDetails.defaultProps = {
  paymentRequest: PropTypes.object.isRequired,
};

export default StripePaymentDetails;
