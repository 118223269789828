import PropTypes from "prop-types";

import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "src/assets/theme/base/colors";
import { iconButtonStyles } from "src/components/Tables/DragAndDrop/iconButtonStyles";
import {
  HeaderCell,
  HeaderCellText,
} from "src/components/Tables/DragAndDrop/HeaderCell";

import { useState, useMemo } from "react";
import SoftBadge from "src/components/SoftBadge";
import { Collapse } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import CourseTable from "../CourseTable";
import SoftTypography from "src/components/SoftTypography";
import Swal from "sweetalert2";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import {
  getUserCoursesCollection,
  getUserEnrolledCoursesCollection,
  getUserEnrollmentsCollection,
} from "src/features/user/collections";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

// const { borderRadius } = borders;
const { grey } = colors;

function Row({
  enrollment,
  expanded,
  onExpandRow,
  onDeleteClick,
  refreshEnrollmentData,
  onEditEnrollment,
}) {
  const courseNames = useMemo(() => {
    return enrollment?.enrolledCourses
      ?.map((enrolledCourse) => enrolledCourse.course?.label)
      .join(", ");
  }, [enrollment]);

  return (
    <>
      <TableRow
        onClick={onExpandRow}
        sx={{
          cursor: "pointer",
          borderBottom: "unset",
        }}
      >
        <TableCell>
          <SoftTypography fontSize="small">
            {enrollment?.member?.label}
          </SoftTypography>
        </TableCell>
        <TableCell style={{ maxWidth: "400px" }}>
          <SoftTypography
            fontSize="small"
            sx={{
              maxWidth: "100%",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {courseNames}
          </SoftTypography>
        </TableCell>
        <TableCell>
          <SoftTypography fontSize="small">
            {enrollment?.startDate?.toDate()?.toDateString()}
          </SoftTypography>
        </TableCell>
        <TableCell>
          <SoftTypography fontSize="small">
            {enrollment?.graduationDate?.toDate()?.toDateString()}
          </SoftTypography>
        </TableCell>
        <TableCell>
          <SoftBadge
            variant="contained"
            color={enrollment?.status === "active" ? "success" : "warning"}
            size="xs"
            badgeContent={enrollment?.status || "No status"}
            container
            sx={{
              marginRight: 1,
              textTransform: "capitalize",
            }}
          />
        </TableCell>
        <WithPermissions permissions={systemPermissions.EDIT_LMS_ENROLLMENTS}>
          <TableCell>
            <IconButton
              aria-label="edit"
              sx={iconButtonStyles}
              onClick={(e) => {
                e.stopPropagation();
                onEditEnrollment(enrollment.id);
              }}
            >
              <Icon>edit</Icon>
            </IconButton>
            <IconButton
              aria-label="delete"
              sx={iconButtonStyles}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteClick(enrollment.id);
              }}
            >
              <Icon>delete</Icon>
            </IconButton>
          </TableCell>
        </WithPermissions>
      </TableRow>
      <TableRow
        sx={{
          backgroundColor: grey[100],
        }}
      >
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            marginTop: "-10px",
            height: "0px",
            borderBottom: expanded ? `1px solid ${grey[300]}` : "unset",
          }}
          colSpan={6}
        >
          <Collapse in={expanded} timeout="auto">
            <SoftBox sx={{ marginTop: "-10px" }}>
              <SoftTypography
                fontSize="medium"
                fontWeight="medium"
                sx={{
                  mt: 3,
                  ml: 2,
                  mb: 1,
                  borderBottom: 2,
                  width: "min-content",
                }}
              >
                Courses
              </SoftTypography>
              <CourseTable
                enrolledCourses={enrollment.enrolledCourses}
                refreshEnrollmentData={refreshEnrollmentData}
              />
            </SoftBox>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}
Row.propTypes = {
  enrollment: PropTypes.object.isRequired,
  expanded: PropTypes.bool.isRequired,
  onExpandRow: PropTypes.func,
  onDeleteClick: PropTypes.func,
  refreshEnrollmentData: PropTypes.func,
  onEditEnrollment: PropTypes.func,
};

const EnrollmentTable = ({
  refreshEnrollmentData,
  enrollments,
  onEditEnrollment,
}) => {
  const { deleteData } = entityCrudUtils();
  const [selectedEnrollmentId, setSelectedEnrollmentId] = useState("");

  const handleExpandRow = (enrollmentId) => {
    if (enrollmentId && selectedEnrollmentId !== enrollmentId) {
      setSelectedEnrollmentId(enrollmentId);
    } else {
      setSelectedEnrollmentId("");
    }
  };

  const handleDeleteClick = (enrollmentId) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "This will also delete any progress the student has made in the associated courses.",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          const enrollment = enrollments.find((e) => e.id === enrollmentId);
          const studentId = enrollment?.member?.value;
          if (!enrollment || !studentId) {
            console.error("Error deleting enrollment ", enrollmentId);
            return;
          }
          if (enrollment?.enrolledCourses) {
            deleteData({
              entity: getUserEnrollmentsCollection(studentId),
              pathSegmentsArr: [enrollmentId],
            });
            enrollment.enrolledCourses.forEach((enrolledCourse) => {
              deleteData({
                entity: getUserEnrolledCoursesCollection(
                  studentId,
                  enrollmentId
                ),
                pathSegmentsArr: [enrolledCourse.id],
              });
            });
          } else {
            deleteData({
              entity: getUserCoursesCollection(studentId),
              pathSegmentsArr: [enrollmentId],
            });
          }
          refreshEnrollmentData();
        }
      });
  };

  return (
    <TableContainer
      sx={{
        maxHeight: "calc(100vh - 210px)",
        boxShadow: "none",
        borderRadius: "0",
        marginY: 3,
      }}
    >
      <Table
        stickyHeader
        aria-label="collapsible table"
        style={{ borderSpacing: "0" }}
      >
        <TableHead>
          <TableRow
            sx={{
              "& > *": {
                backgroundColor: "white!important",
                borderBottom: "unset",
              },
            }}
          >
            <HeaderCell>
              <HeaderCellText>Student</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText>Course(s)</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText>Start Date</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText>Graduation Date</HeaderCellText>
            </HeaderCell>
            <HeaderCell>
              <HeaderCellText>Status</HeaderCellText>
            </HeaderCell>
            <WithPermissions
              permissions={systemPermissions.EDIT_LMS_ENROLLMENTS}
            >
              <HeaderCell>
                <HeaderCellText>Actions</HeaderCellText>
              </HeaderCell>
            </WithPermissions>
          </TableRow>
        </TableHead>
        <TableBody>
          {enrollments?.map((enrollment) => (
            <Row
              enrollment={enrollment}
              key={enrollment.id}
              expanded={selectedEnrollmentId === enrollment.id}
              onExpandRow={() => handleExpandRow(enrollment.id)}
              onDeleteClick={handleDeleteClick}
              onEditEnrollment={onEditEnrollment}
              refreshEnrollmentData={refreshEnrollmentData}
            />
          )) ?? (
            <TableRow>
              <TableCell sx={{ color: grey[500] }}>
                <i>No Enrollments to show.</i>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default EnrollmentTable;
