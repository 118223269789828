import { useEffect, useState, useMemo } from "react";
// import { useNavigate } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

// Data
import { getTableColumns } from "./tableColumns";
import { query, orderBy, where } from "firebase/firestore";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { Modal } from "@mui/material";
import {
  format,
  startOfMonth,
  endOfMonth,
  subMonths,
  startOfDay,
  endOfDay,
} from "date-fns";
import { getClubFuelReimbursements } from "src/features/club/collections";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import { AddFuelReimbursementModal } from "src/modals/AddFuelReimbursementModal";

function FuelReimbursements() {
  // const navigate = useNavigate();
  const {
    selectedClubId,
    selectedClub,
    selectedLocation,
    selectedLocationId,
    clubUsers,
  } = useClubs();
  const [menu, setMenu] = useState(null);
  const [fuelReimbursementsRows, setFuelReimbursementsRows] = useState([]);
  const [openAddNewReimbursement, setOpenAddNewReimbursement] = useState(false);
  const [tableRows, setTableRows] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [dateRange, setDateRange] = useState([
    startOfMonth(subMonths(new Date(), 1)),
    endOfMonth(subMonths(new Date(), 1)),
  ]);

  const updateDateRange = (dates) => {
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = (filter) => {
    setMenu(null);
    if (filter === "approved") {
      setIsFiltered(true);
      const rows = [...fuelReimbursementsRows]
        .filter((row) => row.approved)
        .sort((a, b) => {
          return b.createdAt.toDate() - a.createdAt.toDate();
        });

      setFuelReimbursementsRows(rows);
      return;
    }
    if (filter === "unapproved") {
      setIsFiltered(true);
      const rows = [...allFuelReimbursements]
        .filter((row) => !row.approved)
        .sort((a, b) => {
          return b.createdAt.toDate() - a.createdAt.toDate();
        });

      setFuelReimbursementsRows(rows);
      return;
    }
    const rows = [...allFuelReimbursements].sort((a, b) => {
      return b.createdAt.toDate() - a.createdAt.toDate();
    });
    setFuelReimbursementsRows(rows);
    setIsFiltered(false);
  };

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(menu)}
      onClose={() => closeMenu()}
      keepMounted
    >
      <MenuItem onClick={() => closeMenu("approved")}>
        Status: Approved
      </MenuItem>
      <MenuItem onClick={() => closeMenu("Unapproved")}>
        Status: Unapproved
      </MenuItem>
      <Divider
        sx={{
          margin: "0.5rem 0",
        }}
      />
      <MenuItem onClick={() => closeMenu("all")}>
        <SoftTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

  const clubFuelReimbursementsCollectionRef = query(
    getClubFuelReimbursements(selectedClubId, selectedLocationId),
    where("createdAt", ">=", dateRange[0]),
    orderBy("createdAt", "desc")
  );

  const {
    data: allFuelReimbursements,
    isDataLoaded: isFuelReimbursementsLoaded,
  } = useRealtimeCollectionData(clubFuelReimbursementsCollectionRef, true);

  useEffect(() => {
    if (!allFuelReimbursements && !isFuelReimbursementsLoaded) {
      return;
    }

    console.log("allFuelReimbursements", allFuelReimbursements);

    const rows = [
      ...allFuelReimbursements.map((value) => ({
        ...value,
        member: clubUsers.get(value.userId),
      })),
    ].sort((a, b) => {
      return b.createdAt.toDate() - a.createdAt.toDate();
    });
    setFuelReimbursementsRows(rows);
  }, [
    allFuelReimbursements,
    isFuelReimbursementsLoaded,
    selectedLocationId,
    dateRange,
  ]);

  useEffect(() => {
    updateCsvData(tableRows);
  }, [tableRows]);

  const updateCsvData = (rows) => {
    const csvDataFromRows = rows.map((row) => ({
      ID: row.id,
      Date: row.createdAt
        ? format(row.createdAt.toDate(), "yyyy/MM/dd hh:mm:ss")
        : "---",
      Member: row.member?.displayName || row.pilot?.displayName || "---",
      "Created By": row.createdBy?.name || "---",
      Rate: row?.rate / 100,
      Quantity: row?.quantity,
      Total: row?.total / 100,
      Remaining: Math.round(row.unappliedAmount) / 100,
      Approved: row.approved ? "Yes" : "No",
      "Approved By": row.approvedBy?.name || "---",
    }));

    setCsvData(csvDataFromRows);
  };

  const onRowSelected = (row) => {
    console.log("row", row);
    // navigate({
    //   pathname: `/billing/fuelReimbursements/${row.id}`,
    // });
  };

  const addNewReimbursement = () => {
    setOpenAddNewReimbursement(true);
  };

  const handleNewReimbursementClose = () => {
    setOpenAddNewReimbursement(false);
  };

  const tableColumns = useMemo(() => getTableColumns(), []);
  const tableData = useMemo(
    () => fuelReimbursementsRows,
    [fuelReimbursementsRows]
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox>
            <WithPermissions
              permissions={systemPermissions.ADD_FUEL_REIMBURSEMENTS}
            >
              <SoftButton
                variant="gradient"
                color="info"
                onClick={addNewReimbursement}
              >
                Add Reimbursement
              </SoftButton>
            </WithPermissions>
          </SoftBox>
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftButton
              variant={menu || isFiltered ? "contained" : "outlined"}
              color="dark"
              onClick={openMenu}
            >
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </SoftButton>
            {renderMenu}
            {csvData && (
              <SoftBox ml={1}>
                <SoftBox>
                  <CSVLink
                    data={csvData}
                    filename={`${selectedClub.name}-${
                      selectedLocation?.icao
                    }-billing_invoices-${format(new Date(), "MM/dd/yyyy")}.csv`}
                    target="_blank"
                  >
                    <SoftButton variant="outlined" color="dark">
                      <Icon>description</Icon>
                      &nbsp;export csv
                    </SoftButton>
                  </CSVLink>
                </SoftBox>
              </SoftBox>
            )}
          </SoftBox>
        </SoftBox>
        <Card>
          {fuelReimbursementsRows && fuelReimbursementsRows.length > 0 && (
            <DataTable
              onRowSelect={onRowSelected}
              entriesPerPage={{ defaultValue: 50, entries: [10, 20, 50, 100] }}
              table={{
                columns: tableColumns,
                rows: tableData,
              }}
              onRowsChanged={(rows) => {
                setTableRows(rows.map((row) => row.original));
              }}
              canSearch
            />
          )}
        </Card>
      </SoftBox>
      <Footer />
      <Modal
        open={openAddNewReimbursement}
        onClose={handleNewReimbursementClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SoftBox>
          <AddFuelReimbursementModal
            handleClose={handleNewReimbursementClose}
          />
        </SoftBox>
      </Modal>
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_FUEL_REIMBURSEMENTS,
  FuelReimbursements
);
