// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

function StatusCell({ icon, color, status }) {
  return (
    <SoftBox display="flex" alignItems="center">
      <SoftBox mr={1}>
        <SoftButton
          variant="outlined"
          color={color}
          size="small"
          iconOnly
          circular
        >
          <Icon
            sx={{
              fontWeight: "bold",
            }}
          >
            {icon}
          </Icon>
        </SoftButton>
      </SoftBox>
      <SoftTypography
        variant="caption"
        fontWeight="medium"
        color="text"
        sx={{
          lineHeight: 1.6,
        }}
      >
        {status}
      </SoftTypography>
    </SoftBox>
  );
}

// Typechecking props for the StatusCell
StatusCell.propTypes = {
  icon: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
};

export default StatusCell;
