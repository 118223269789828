import { apiUrls } from "src/config/apiUrls";
import { ApiWrapper } from "./fetchClient";
import { IInvoice } from "src/interfaces";
import { removeUndefined } from "src/utils/utils";

export const createInvoice = async ({
  clubId,
  locationId,
  invoice,
}: {
  clubId: string;
  locationId: string;
  invoice: Partial<IInvoice>;
}): Promise<IInvoice> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.invoice.create(clubId, locationId),
      params: removeUndefined({ ...invoice }),
    })
  ).call();

  return result;
};

export const updateInvoice = async ({
  invoiceId,
  clubId,
  locationId,
  invoice,
}: {
  invoiceId: string;
  clubId: string;
  locationId: string;
  invoice: Partial<IInvoice>;
}): Promise<{ success: boolean }> => {
  const result = await (
    await ApiWrapper.put<any>({
      url: apiUrls.invoice.update(clubId, locationId, invoiceId),
      params: removeUndefined({ ...invoice }),
    })
  ).call();

  return result;
};
