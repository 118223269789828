import { useEffect, useState } from "react";
import { Card } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftSelect from "src/components/SoftSelect";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubMembershipsCollection } from "src/features/club/collections";
import { getMembershipsCollection } from "src/features/user/collections";
import CurrencyFormat from "react-currency-format";
import { getDocs, query, where } from "firebase/firestore";

const schema = yup
  .object({
    club: yup.object().required("Please select a club"),
    membership: yup.object().required("Please select a membership"),
  })
  .required();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  overflow: "visible",
  boxShadow: 24,
  p: 4,
};

export function AddNewMembershipModal({ handleClose, existingMemberships }) {
  const { userId } = useUser();
  const { userClubs } = useClubs();
  const { updateData } = entityCrudUtils();
  const [clubOptions, setClubOptions] = useState([]);
  const [membershipOptions, setMembershipOptions] = useState([]);

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schema),
  });

  const newClub = watch("club");
  const newMembership = watch("membership");

  const getMembershipOptionsForClub = async (clubId, locationId) => {
    const clubMembershipsCollection = await getDocs(
      query(
        getClubMembershipsCollection(clubId, locationId),
        where("deleted", "==", false),
        where("active", "==", true)
      )
    );
    setMembershipOptions(
      clubMembershipsCollection.docs.map((membershipSnap) => ({
        label: membershipSnap.data().label,
        value: membershipSnap.id,
        membershipData: membershipSnap.data(),
      }))
    );
  };

  useEffect(() => {
    const options = [];
    userClubs.map((club) => {
      if (
        existingMemberships.find((membership) => membership.clubId === club.id)
      ) {
        console.log(`${club.data().name} (Membership already added)`);
      } else {
        options.push({
          label: club.data().name,
          value: club.id,
        });
      }
    });
    setClubOptions(options);
  }, []);

  useEffect(() => {
    if (newClub) {
      getMembershipOptionsForClub(newClub.value);
    }
  }, [newClub]);

  const onSubmit = async (data) => {
    const docToUpload = {
      updatedAt: new Date(),
      createdAt: new Date(),
      nextBillDate: new Date(),
      membershipPlan: newMembership.value,
      active: true,
      status: "active",
    };

    await updateData(
      {
        entity: getMembershipsCollection(userId),
        pathSegmentsArr: [data.club.value],
      },
      docToUpload
    );
    handleClose();
  };

  return (
    <Card sx={style}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Select Membership
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Club
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="club"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftSelect
                      options={clubOptions}
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.club?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.club.message}
                  </SoftTypography>
                )}
              </Grid>
            </Grid>
            {/* {hasCost && ( */}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Membership
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="membership"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftSelect
                      options={membershipOptions}
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.membership?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.membership.message}
                  </SoftTypography>
                )}
              </Grid>
              {newMembership && (
                <Grid item xs={12} sm={4}>
                  <SoftBox mb={1} ml={3} lineHeight={0} display="inline-block">
                    <SoftTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      Cost
                    </SoftTypography>
                  </SoftBox>
                  <br />
                  <SoftBox ml={3}>
                    {newMembership.membershipData.cost?.amount ? (
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize"
                      >
                        <CurrencyFormat
                          value={newMembership.membershipData?.cost?.amount}
                          displayType="text"
                          thousandSeparator
                          prefix="$"
                        />
                        &nbsp;({newMembership.membershipData?.cost?.billed})
                      </SoftTypography>
                    ) : (
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                        textTransform="capitalize"
                      >
                        Free
                      </SoftTypography>
                    )}
                  </SoftBox>
                </Grid>
              )}
            </Grid>
            {/* )} */}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    Update Membership Plan
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

AddNewMembershipModal.propTypes = {
  handleClose: PropTypes.func,
  existingMemberships: PropTypes.array,
};
