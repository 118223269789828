import { useMemo, useState } from "react";
import Swal from "sweetalert2";

// @mui core components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";

import { getTableColumns, TableActions } from "./tableColumns";
import DataTable from "src/components/Tables/DataTable";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";

import { AddLessonGradingScaleModal } from "src/modals/AddLessonGradingScaleModal";
import { useLms } from "src/features/lms/LmsProvider";
import { getLocationGradingScales } from "src/features/lms/collections";

function LessonGradingScales() {
  const { selectedClubId, selectedLocationId } = useClubs();
  const { gradingScales, gradingScalesLoaded } = useLms();
  const { deleteData } = entityCrudUtils();
  const [selectedGradingScale, setSelectedGradingScale] = useState(null);
  const [openAddLessonGradingScaleModal, setOpenAddLessonGradingScaleModal] =
    useState(false);

  const deleteLessonGradingScale = (gradingScale) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You will not be able to recover this location! Existing resources and members will be removed from this locaiton.",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          deleteData({
            entity: getLocationGradingScales(
              selectedClubId,
              selectedLocationId
            ),
            pathSegmentsArr: [gradingScale.id],
          });
        }
      });
  };

  const handleTableActions = (action, location) => {
    switch (action) {
      case TableActions.DELETE:
        deleteLessonGradingScale(location);
        break;
      case TableActions.EDIT:
        setSelectedGradingScale(location);
        setOpenAddLessonGradingScaleModal(true);
        break;

      default:
        break;
    }
  };

  const handleAddLessonGradingScaleModalClose = () => {
    setOpenAddLessonGradingScaleModal(false);
    setSelectedGradingScale(null);
  };

  const tableColumns = useMemo(() => getTableColumns(handleTableActions), []);

  return (
    <Card
      id="lesson-grading-scales"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3} lineHeight={1}>
        <SoftBox mb={1}>
          <SoftTypography variant="h5">Lesson Grading Scales</SoftTypography>
        </SoftBox>
        <SoftTypography variant="button" color="text" fontWeight="regular">
          Add and manage LMS Grading Scales.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <SoftBox
          xs={12}
          padding={1}
          sx={{
            maxWidth: "100%",
            overflow: "hidden",
          }}
        >
          {gradingScalesLoaded && (
            <DataTable
              entriesPerPage={false}
              maxWidth="100%"
              table={{
                columns: tableColumns,
                rows: gradingScales,
              }}
            />
          )}
        </SoftBox>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} justifyContent="flex-end" display="flex">
            <SoftButton
              color="primary"
              variant="contained"
              type="submit"
              size="large"
              onClick={() => setOpenAddLessonGradingScaleModal(true)}
            >
              Add New Grading Scale
            </SoftButton>
          </Grid>
        </Grid>
      </SoftBox>
      <Modal
        open={openAddLessonGradingScaleModal}
        onClose={handleAddLessonGradingScaleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <AddLessonGradingScaleModal
          handleClose={handleAddLessonGradingScaleModalClose}
          lessonGradingScaleData={selectedGradingScale}
        />
      </Modal>
    </Card>
  );
}

export default LessonGradingScales;
