import { useEffect, useMemo, useState } from "react";
import {
  Grid,
  Table,
  TableRow,
  TableBody,
  Divider,
  Card,
  AppBar,
  Tab,
  Tabs,
} from "@mui/material";
import TabPanel from "src/components/TabPanel";

import SoftButton from "src/components/SoftButton";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import borders from "src/assets/theme/base/borders";
import colors from "src/assets/theme/base/colors";

import Header from "./components/Header";
import Footer from "src/components/Footer";

import { useNavigate, useParams } from "react-router-dom";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { doc } from "firebase/firestore";
import { formatCurrency } from "src/features/utils";
import CurrencyFormat from "react-currency-format";

import Cube from "src/components/Icons/Cube";
import Document from "src/components/Icons/Document";
import logo from "src/assets/images/logo-inline.svg";

// Soft UI Dashboard PRO React base styles
import breakpoints from "src/assets/theme/base/breakpoints";
import {
  getClubById,
  getClubLocationsCollection,
  getClubPayments,
} from "src/features/club/collections";

import PageLayout from "src/containers/LayoutContainers/PageLayout";
import typography from "src/assets/theme/base/typography";
import { ThreeDots } from "react-loader-spinner";
import { calculateFees } from "src/services/payments.api";
import { getUserById } from "src/features/user/collections";

const loadingStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  overflow: "scroll",
  boxShadow: 24,
  maxHeight: "90vh",
  p: 4,
};

export default function PaymentRequest() {
  const { paymentRequestId, clubId, locationId } = useParams();
  const navigate = useNavigate();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [clubPaysCCFees, setClubPaysCCFees] = useState(false);
  const [clubPaysACHFees, setClubPaysACHFees] = useState(false);
  const [fees, setFees] = useState({ stripeFees: 0, totalAmount: 0 });
  const { borderWidth } = borders;
  const { light } = colors;
  const borderBottom = `${borderWidth[1]} solid ${light.main}`;
  const [loadingTrigger, setLoadingTrigger] = useState(true);
  const [lineItems, setLineItems] = useState([]);

  setTimeout(() => {
    setLoadingTrigger(false);
  }, 3000);

  const { data: paymentRequest, isDataLoaded: paymentRequestLoaded } =
    useRealtimeDocumentData(doc(getClubPayments(clubId), paymentRequestId));

  const { data: selectedClub } = useRealtimeDocumentData(getClubById(clubId));

  const { data: selectedLocation } = useRealtimeDocumentData(
    doc(
      getClubLocationsCollection(clubId || paymentRequest?.clubId || "0"),
      locationId || paymentRequest?.locationId || "0"
    )
  );

  const { data: user } = useRealtimeDocumentData(
    getUserById(paymentRequest?.userId)
  );

  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    if (selectedLocation) {
      setClubPaysCCFees(selectedLocation?.preferences?.clubPaysFees);
      setClubPaysACHFees(selectedLocation?.preferences?.clubPaysAchFees);
    }
  }, [selectedLocation]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);

    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const getCalculatedFees = async (paymentAmount) => {
    setFees(
      await calculateFees({
        amount: paymentAmount,
        dissmissPreFlightFee: true,
        clubId: paymentRequest?.clubId,
        locationId: paymentRequest?.locationId,
        paymentMethod: tabValue === 0 ? "ACH_FWD" : "CARD_FWD",
      })
    );
  };

  useEffect(() => {
    if (paymentRequest?.amount) {
      getCalculatedFees(paymentRequest.amount - paymentRequest.unappliedAmount);
    }
  }, [paymentRequest?.amount, tabValue]);

  useMemo(() => {
    const lineItems = [];
    if (paymentRequest?.description && paymentRequest?.description !== "") {
      lineItems.push({
        item: `${paymentRequest?.description}`,
        price: paymentRequest?.amount / 100,
        qty: 1,
        total: paymentRequest?.amount / 100,
        type: "debit",
      });
    } else {
      lineItems.push({
        item: `Payment Request`,
        price: paymentRequest?.amount / 100,
        qty: 1,
        total: paymentRequest?.amount / 100,
        type: "debit",
      });
    }

    setLineItems(lineItems);
  }, [paymentRequest]);

  const onSubmit = () => {
    setIsSubmitting(true);
    let paymentMethod;
    if (
      paymentRequest?.acceptedPaymentMethods?.includes("ach") ??
      !paymentRequest?.acceptedPaymentMethods
    ) {
      paymentMethod = tabValue === 0 ? "ACH_FWD" : "CARD_FWD";
    } else {
      paymentMethod = "CARD_FWD";
    }
    console.debug("selected payment method", paymentMethod);

    navigate("payment", {
      state: {
        paymentRequest: paymentRequest,
        club: selectedClub,
        location: selectedLocation,
        user: user,
        fees,
        paymentMethod,
      },
    });
    setIsSubmitting(false);
  };

  if ((!paymentRequest && loadingTrigger) || !paymentRequestLoaded) {
    return (
      <Card sx={loadingStyle}>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </Card>
    );
  }

  if (paymentRequest.status === "processing") {
    const { d1, d3, d4, d5 } = typography;
    const handleGoToHome = () => {
      window.location.href = "/";
    };

    return (
      <PageLayout white>
        <SoftBox my={24} height="calc(100vh - 24rem)">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Grid item xs={11} sm={9} container alignItems="center">
              <Grid item xs={12} lg={6}>
                <SoftBox
                  fontSize={{
                    xs: d5.fontSize,
                    sm: d4.fontSize,
                    md: d3.fontSize,
                    lg: d1.fontSize,
                  }}
                  lineHeight={1.2}
                >
                  <SoftTypography
                    variant="inherit"
                    color="primary"
                    fontWeight="bold"
                  >
                    Hold up...
                  </SoftTypography>
                </SoftBox>
                <SoftTypography
                  variant="h2"
                  color="dark"
                  textGradient
                  fontWeight="bold"
                >
                  Payment in progress
                </SoftTypography>
                <SoftBox mt={1} mb={2}>
                  <SoftTypography variant="body1" color="text">
                    You already have a payment in progress. Please wait for the
                    payment to complete before attempting to make another
                    payment.
                  </SoftTypography>
                </SoftBox>
                <SoftBox mt={4} mb={2}>
                  <SoftButton
                    variant="outlined"
                    color="primary"
                    onClick={handleGoToHome}
                  >
                    go to homepage
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </PageLayout>
    );
  }

  if (paymentRequest.status === "succeeded") {
    const { d1, d3, d4, d5 } = typography;
    const handleGoToHome = () => {
      window.location.href = "/";
    };

    return (
      <PageLayout white>
        <SoftBox my={24} height="calc(100vh - 24rem)">
          <Grid
            container
            spacing={3}
            justifyContent="center"
            alignItems="center"
            sx={{
              height: "100%",
            }}
          >
            <Grid item xs={11} sm={9} container alignItems="center">
              <Grid item xs={12} lg={6}>
                <SoftBox
                  fontSize={{
                    xs: d5.fontSize,
                    sm: d4.fontSize,
                    md: d3.fontSize,
                    lg: d1.fontSize,
                  }}
                  lineHeight={1.2}
                >
                  <SoftTypography
                    variant="inherit"
                    color="primary"
                    fontWeight="bold"
                  >
                    Already Paid!
                  </SoftTypography>
                </SoftBox>
                <SoftTypography
                  variant="h2"
                  color="dark"
                  textGradient
                  fontWeight="bold"
                >
                  Nothing more to see here...
                </SoftTypography>
                <SoftBox mt={1} mb={2}>
                  <SoftTypography variant="body1" color="text">
                    You have already paid this payment request. Please wait for
                    the transaction to complete and you will see this in your
                    PreFlight transactions.
                  </SoftTypography>
                </SoftBox>
                <SoftBox mt={4} mb={2}>
                  <SoftButton
                    variant="outlined"
                    color="primary"
                    onClick={handleGoToHome}
                  >
                    go to homepage
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </PageLayout>
    );
  }

  if (!paymentRequest && paymentRequestLoaded) {
    const { d1, d3, d4, d5 } = typography;
    const handleGoToHome = () => {
      window.location.href = "/";
    };
    return (
      <>
        <PageLayout white>
          <SoftBox my={24} height="calc(100vh - 24rem)">
            <Grid
              container
              spacing={3}
              justifyContent="center"
              alignItems="center"
              sx={{
                height: "100%",
              }}
            >
              <Grid item xs={11} sm={9} container alignItems="center">
                <Grid item xs={12} lg={6}>
                  <SoftBox
                    fontSize={{
                      xs: d5.fontSize,
                      sm: d4.fontSize,
                      md: d3.fontSize,
                      lg: d1.fontSize,
                    }}
                    lineHeight={1.2}
                  >
                    <SoftTypography
                      variant="inherit"
                      color="primary"
                      fontWeight="bold"
                    >
                      Error 404
                    </SoftTypography>
                  </SoftBox>
                  <SoftTypography
                    variant="h2"
                    color="dark"
                    textGradient
                    fontWeight="bold"
                  >
                    Payment request not found!
                  </SoftTypography>
                  <SoftBox mt={1} mb={2}>
                    <SoftTypography variant="body1" color="text">
                      It may have been deleted or cancelled by your club. Please
                      reach out to them for more information.
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox mt={4} mb={2}>
                    <SoftButton
                      variant="outlined"
                      color="primary"
                      onClick={handleGoToHome}
                    >
                      go to homepage
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
          <Footer />
        </PageLayout>
      </>
    );
  }

  return (
    <>
      <SoftBox my={7}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={9} mb={1}>
            <SoftBox display="flex" flexDirection="column" alignItems="center">
              <SoftBox component="img" src={logo} width={250} />
            </SoftBox>
          </Grid>
          <Grid item xs={12} lg={9}>
            <Card>
              <SoftBox pt={2} px={2}>
                <Header
                  paymentRequest={paymentRequest}
                  club={selectedClub}
                  user={user}
                />
              </SoftBox>
              <Divider />
              <SoftBox pt={1} pb={3} px={2}>
                <SoftBox mb={3}>
                  <SoftBox p={3}>
                    <SoftBox width="100%" overflow="auto">
                      <Table
                        sx={{
                          minWidth: "32rem",
                        }}
                      >
                        <SoftBox component="thead">
                          <TableRow>
                            <SoftBox
                              component="th"
                              width={{
                                xs: "45%",
                                md: "50%",
                              }}
                              py={1.5}
                              px={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography
                                variant="h6"
                                color="text"
                                fontWeight="medium"
                              >
                                Item
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography
                                variant="h6"
                                color="text"
                                fontWeight="medium"
                              >
                                Qty
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography
                                variant="h6"
                                color="text"
                                fontWeight="medium"
                              >
                                Rate
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="th"
                              py={1.5}
                              pl={3}
                              pr={1}
                              textAlign="left"
                              borderBottom={borderBottom}
                            >
                              <SoftTypography
                                variant="h6"
                                color="text"
                                fontWeight="medium"
                              >
                                Amount
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {paymentRequest?.lineItems?.map((item) => (
                            <TableRow key={item.id}>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                p={1}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {item.item}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {item.qty}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {formatCurrency(
                                    item.type === "credit" ||
                                      item.type === "accountBalance"
                                      ? item.price * -1
                                      : item.price
                                  )}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {item.discount > 0 &&
                                    formatCurrency(item.discount)}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {formatCurrency(
                                    item.type === "credit" ||
                                      item.type === "accountBalance"
                                      ? item.total * -1
                                      : item.total
                                  )}
                                </SoftTypography>
                              </SoftBox>
                            </TableRow>
                          ))}
                          {lineItems?.map((item) => (
                            <TableRow key={item.id}>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                p={1}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {item.item}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {item.qty}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {formatCurrency(
                                    item.type === "credit" ||
                                      item.type === "accountBalance"
                                      ? item.price * -1
                                      : item.price
                                  )}
                                </SoftTypography>
                              </SoftBox>
                              <SoftBox
                                component="td"
                                textAlign="left"
                                py={1}
                                pr={1}
                                pl={3}
                                borderBottom={borderBottom}
                              >
                                <SoftTypography variant="body2" color="text">
                                  {formatCurrency(
                                    item.type === "credit" ||
                                      item.type === "accountBalance"
                                      ? item.total * -1
                                      : item.total
                                  )}
                                </SoftTypography>
                              </SoftBox>
                            </TableRow>
                          ))}
                          <TableRow key={"Total"}>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              p={1}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text" />
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text" />
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                <b>Sub Total:</b>
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign="left"
                              py={1}
                              pr={1}
                              pl={3}
                              borderBottom={borderBottom}
                            >
                              <SoftTypography variant="body2" color="text">
                                {formatCurrency(
                                  lineItems?.reduce(
                                    (acc, item) =>
                                      item.type === "credit" ||
                                      item.type === "accountBalance"
                                        ? acc - item.total
                                        : acc + item.total,
                                    0
                                  )
                                )}
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>
                </SoftBox>
                <Divider />
                <SoftBox mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} />
                    <Grid item xs={12} md={6}>
                      {paymentRequest && (
                        <>
                          <SoftBox mt={2} display="flex">
                            <AppBar position="static">
                              <Tabs
                                orientation={tabsOrientation}
                                value={tabValue}
                                onChange={handleSetTabValue}
                                sx={{
                                  backgroundColor: "transparent",
                                }}
                              >
                                {(paymentRequest.paymentMethods?.includes(
                                  "ach"
                                ) ||
                                  !paymentRequest.paymentMethods) && (
                                  <Tab label="ACH" icon={<Cube />} />
                                )}
                                {(paymentRequest.paymentMethods?.includes(
                                  "cc"
                                ) ||
                                  !paymentRequest.paymentMethods) && (
                                  <Tab
                                    label="Credit Card"
                                    icon={<Document />}
                                  />
                                )}
                              </Tabs>
                            </AppBar>
                          </SoftBox>
                          <SoftBox mt={2}>
                            {(paymentRequest?.paymentMethods?.includes(
                              "ACH_FWD"
                            ) ||
                              !paymentRequest.paymentMethods) && (
                              <TabPanel value={tabValue} index={0}>
                                {!clubPaysACHFees && (
                                  <SoftBox
                                    display="flex"
                                    justifyContent="space-between"
                                    mb={0.5}
                                  >
                                    <SoftBox>
                                      <SoftTypography
                                        variant="button"
                                        fontWeight="regular"
                                        color="text"
                                      >
                                        Payment Fees:
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftBox ml={1}>
                                      <SoftTypography
                                        variant="body2"
                                        fontWeight="medium"
                                      >
                                        <CurrencyFormat
                                          value={(fees?.pilotFee ?? 0) / 100}
                                          displayType="text"
                                          decimalScale={2}
                                          fixedDecimalScale
                                          thousandSeparator
                                          prefix="$"
                                        />
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                )}
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  mt={3}
                                >
                                  <SoftBox>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="light"
                                      color="text"
                                    >
                                      Total:
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox ml={1}>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="medium"
                                    >
                                      <CurrencyFormat
                                        value={(fees?.totalCharge ?? 0) / 100}
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale
                                        thousandSeparator
                                        prefix="$"
                                      />
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                                <SoftBox
                                  display="flex"
                                  justifyContent="flex-end"
                                  mt={3}
                                >
                                  <SoftTypography variant="caption">
                                    <i>
                                      <b>Note:</b> ACH payments may take up to 4
                                      working days.
                                    </i>
                                  </SoftTypography>
                                </SoftBox>
                              </TabPanel>
                            )}
                            {(paymentRequest?.paymentMethods?.includes("cc") ||
                              !paymentRequest.paymentMethods) && (
                              <TabPanel
                                value={tabValue}
                                index={
                                  paymentRequest?.paymentMethods?.includes(
                                    "ach"
                                  ) || !paymentRequest.paymentMethods
                                    ? 1
                                    : 0
                                }
                              >
                                {!clubPaysCCFees && (
                                  <SoftBox
                                    display="flex"
                                    justifyContent="space-between"
                                    mb={0.5}
                                  >
                                    <SoftBox>
                                      <SoftTypography
                                        variant="button"
                                        fontWeight="regular"
                                        color="text"
                                      >
                                        Payment Fees:
                                      </SoftTypography>
                                    </SoftBox>
                                    <SoftBox ml={1}>
                                      <SoftTypography
                                        variant="body2"
                                        fontWeight="medium"
                                      >
                                        <CurrencyFormat
                                          value={(fees?.pilotFee ?? 0) / 100}
                                          displayType="text"
                                          decimalScale={2}
                                          fixedDecimalScale
                                          thousandSeparator
                                          prefix="$"
                                        />
                                      </SoftTypography>
                                    </SoftBox>
                                  </SoftBox>
                                )}
                                <SoftBox
                                  display="flex"
                                  justifyContent="space-between"
                                  mt={3}
                                >
                                  <SoftBox>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="light"
                                      color="text"
                                    >
                                      Total:
                                    </SoftTypography>
                                  </SoftBox>
                                  <SoftBox ml={1}>
                                    <SoftTypography
                                      variant="body1"
                                      fontWeight="medium"
                                    >
                                      <CurrencyFormat
                                        value={(fees?.totalCharge ?? 0) / 100}
                                        displayType="text"
                                        decimalScale={2}
                                        fixedDecimalScale
                                        thousandSeparator
                                        prefix="$"
                                      />
                                    </SoftTypography>
                                  </SoftBox>
                                </SoftBox>
                              </TabPanel>
                            )}
                          </SoftBox>
                          <SoftBox
                            mt={3}
                            width="100%"
                            display="flex"
                            justifyContent="space-between"
                          >
                            <SoftBox />
                            <SoftButton
                              disabled={isSubmitting}
                              type="submit"
                              color="primary"
                              onClick={onSubmit}
                            >
                              Next
                            </SoftButton>
                          </SoftBox>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
            </Card>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </>
  );
}
