import { useState } from "react";

export const usePromiseLoading = <
  T extends (...args: Parameters<T>) => ReturnType<T>
>({
  fn,
  throwError = false,
}: {
  fn: T;
  throwError?: boolean;
}): {
  caller: T;
  isLoading: boolean;
  error: any;
  data: Awaited<ReturnType<T>> | null;
  cancel: () => void;
} => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [cancelFn, setCancelFn] = useState<() => void>(() => {});
  const [data, setData] = useState<Awaited<ReturnType<T>> | null>(null);
  const caller = async (...args: Parameters<T>) => {
    try {
      setIsLoading(true);
      setError(null);
      const promise = fn(...args) as any;
      promise?.cancel && setCancelFn(promise.cancel);
      const awaitResult = await (promise?.data ? promise.data : promise);
      setData(awaitResult as any);
      return awaitResult;
    } catch (err: any) {
      const errParsed = await (err?.json ? err?.json() : err);
      setError(errParsed);
      if (throwError) throw errParsed;
    } finally {
      setIsLoading(false);
    }
  };

  return { caller: caller as T, isLoading, error, data, cancel: cancelFn };
};
