import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

const StripeConnectElementsContext = createContext(null);
StripeConnectElementsContext.displayName = "StripeConnectElementsContext";

function StripeConnectElementsProvider({ children }) {
  const { selectedClub } = useClubs();
  const [stripe, setStripe] = useState(null);

  const setupStripe = async () => {
    if (selectedClub?.stripeChargeAccountId || selectedClub?.stripeAccountId) {
      setStripe(
        await loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY, {
          stripeAccount:
            selectedClub?.stripeChargeAccountId ||
            selectedClub?.stripeAccountId,
        })
      );
    }
  };

  useEffect(() => {
    setupStripe();
  }, [selectedClub]);

  const stripeOptions = {
    // Pass stripe options in here
    // E.G. pass in the client secret obtained from the server
    // clientSecret: '{{CLIENT_SECRET}}',
  };

  const value = {
    stripe,
  };

  if (!stripe) {
    return (
      <StripeConnectElementsContext.Provider value={value}>
        {children}
      </StripeConnectElementsContext.Provider>
    );
  }

  return (
    <StripeConnectElementsContext.Provider value={value}>
      <Elements
        stripe={stripe}
        options={stripeOptions}
        key={
          selectedClub?.stripeChargeAccountId || selectedClub?.stripeAccountId
        }
      >
        {children}
      </Elements>
    </StripeConnectElementsContext.Provider>
  );
}

function useStripeConnectElements() {
  const context = useContext(StripeConnectElementsContext);

  if (!context) {
    throw new Error(
      "useStripeConnectElements should be used inside the StripeConnectElementsProvider."
    );
  }

  return context;
}

StripeConnectElementsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { StripeConnectElementsProvider, useStripeConnectElements };
