import { useSearchParams } from "react-router-dom";
import InvoiceDetailsNew from "./InvoiceDetailsNew";
import InvoiceDetails from "./InvoiceDetails";

const InvoiceDetailWrapper = () => {
  const [searchParams] = useSearchParams();

  const isNewInvoice = searchParams.get("isNewInvoice") ?? false;
  console.log("isNewInvoice", isNewInvoice);
  return isNewInvoice ? <InvoiceDetailsNew /> : <InvoiceDetails />;
};
export default InvoiceDetailWrapper;
