import { collection, collectionGroup } from "firebase/firestore";
import { db } from "src/features/firebase/firestore/utils";

const getClubCoursesCollection = (clubId) =>
  collection(db, `clubs/${clubId}/courses`);

const getCourseStagesCollection = (clubId, courseId) =>
  collection(db, `clubs/${clubId}/courses/${courseId}/stages`);

const getCourseLessonsCollection = (clubId, courseId, stageId) =>
  collection(
    db,
    `clubs/${clubId}/courses/${courseId}/stages/${stageId}/lessons`
  );

const getCourseDocumentsCollection = (clubId, courseId) =>
  collection(db, `clubs/${clubId}/courses/${courseId}/documents`);

const getLessonDocumentsCollection = (clubId, courseId, stageId, lessonId) =>
  collection(
    db,
    `clubs/${clubId}/courses/${courseId}/stages/${stageId}/lessons/${lessonId}/documents`
  );

const getLocationGradingScales = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/gradingScales`);

const getLessonTasksCollection = (clubId, courseId, stageId, lessonId) =>
  collection(
    db,
    `clubs/${clubId}/courses/${courseId}/stages/${stageId}/lessons/${lessonId}/tasks`
  );

const getCourseEnrollmentsCollectionGroup = () =>
  collectionGroup(db, `courseEnrollments`);

const getTasksCollectionGroup = () => collectionGroup(db, `tasks`);

export {
  getClubCoursesCollection,
  getCourseStagesCollection,
  getCourseLessonsCollection,
  getCourseDocumentsCollection,
  getLessonDocumentsCollection,
  getLessonTasksCollection,
  getCourseEnrollmentsCollectionGroup,
  getTasksCollectionGroup,
  getLocationGradingScales,
};
