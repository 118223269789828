import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import CurrencyFormat from "react-currency-format";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { usePermissions } from "src/hooks/usePermissions";

function TransactionSummary({ transaction }) {
  const { hasAccess } = usePermissions();

  const fuelReimbursement = transaction.fuelCost || 0;
  const preHeatReimbursement = transaction.preHeatCost || 0;
  const costToPilot = transaction.fees?.costToPilot || 0;

  return (
    <>
      <SoftBox mb={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          Transaction Summary
        </SoftTypography>
      </SoftBox>
      {transaction.flightCost >= 0 ? (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftBox>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Flight Cost:
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium">
              <CurrencyFormat
                value={transaction.flightCost || 0}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                prefix="$"
              />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      {transaction.type == "accountFunding" ? (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftBox>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Deposit Amount:
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium">
              <CurrencyFormat
                value={transaction.totalCost || 0}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                prefix="$"
              />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      {transaction.instructorCost >= 0 ? (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftBox>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Instructor Cost:
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium">
              <CurrencyFormat
                value={
                  (transaction.instructorCost || 0) +
                  (transaction.instructorCostGround || 0)
                }
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                prefix="$"
              />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      {transaction.shipping > 0 ? (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftBox>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Shipping:
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium">
              <CurrencyFormat
                value={transaction.shipping || 0}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                prefix="$"
              />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      {transaction.discountAmount > 0 ? (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftBox>
            <SoftTypography variant="button" fontWeight="regular" color="error">
              Discount:
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium" color="error">
              <CurrencyFormat
                value={transaction.discountAmount || 0}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                prefix="$"
              />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
        <SoftBox>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            Tax:
          </SoftTypography>
        </SoftBox>
        <SoftBox ml={1}>
          <SoftTypography variant="body2" fontWeight="medium" color="text">
            <CurrencyFormat
              value={transaction.taxes || 0}
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator
              prefix="$"
            />
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      {!hasAccess([systemPermissions.VIEW_FINANCIAL_REPORTS]) &&
      transaction.clubPaysFees ? null : (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftBox>
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color={transaction.clubPaysFees ? "error" : "text"}
            >
              Service Fees:
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={1}>
            <SoftTypography
              variant="body2"
              fontWeight="medium"
              color={transaction.clubPaysFees ? "error" : "text"}
            >
              <CurrencyFormat
                value={(transaction.fees ?? 0) / 100}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                prefix="$"
              />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      {transaction.refunded && transaction.refunded > 0 ? (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftBox>
            <SoftTypography variant="button" fontWeight="regular" color="error">
              Refunded:
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium" color="error">
              <CurrencyFormat
                value={transaction.refunded || 0}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                prefix="$"
              />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      {transaction.fuelCost > 0 ? (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftBox>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Fuel Reimbursement:
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium" color="error">
              <CurrencyFormat
                value={fuelReimbursement * -1}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                prefix="$"
              />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      {transaction.preHeatCost > 0 ? (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftBox>
            <SoftTypography variant="button" fontWeight="regular" color="text">
              Pre-Heat Reimbursement:
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium" color="error">
              <CurrencyFormat
                value={preHeatReimbursement * -1}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                prefix="$"
              />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      <SoftBox display="flex" justifyContent="space-between" mt={3}>
        <SoftBox>
          <SoftTypography variant="body1" fontWeight="light" color="text">
            Total:
          </SoftTypography>
        </SoftBox>
        <SoftBox ml={1}>
          <SoftTypography variant="body1" fontWeight="medium">
            {costToPilot < 0 && "Credit"}
            <CurrencyFormat
              value={(transaction.totalAmount || 0) / 100}
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator
              prefix="$"
            />
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      <SoftBox mt={3}>
        {transaction.payments ? (
          <SoftBox display="flex" justifyContent="space-between">
            <SoftBox>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                Paid:
              </SoftTypography>
            </SoftBox>
            <SoftBox ml={1}>
              <SoftTypography variant="body2" fontWeight="medium">
                <CurrencyFormat
                  value={
                    (transaction.payments.reduce(
                      (acc, payment) =>
                        payment?.charges && payment?.status === "succeeded"
                          ? acc + (payment?.amount ?? 0) / 100
                          : acc,
                      0
                    ) || 0) - (transaction.refunded || 0)
                  }
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator
                  prefix="$"
                />
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        ) : null}

        {/* <WithPermissions permissions={systemPermissions.VIEW_FINANCIAL_REPORTS}>
          <SoftBox display="flex" justifyContent="space-between">
            <SoftBox>
              <SoftTypography
                variant="button"
                fontWeight="medium"
                color="primary"
              >
                Revenue:
              </SoftTypography>
            </SoftBox>
            <SoftBox ml={1}>
              <SoftTypography
                variant="button"
                fontWeight="medium"
                color="primary"
              >
                <CurrencyFormat
                  value={
                    (transaction.type === "accountFunding"
                      ? transaction.totalCost - transaction.fees?.applicationFee
                      : clubRevenue) - (transaction.refunded || 0)
                  }
                  displayType="text"
                  decimalScale={2}
                  fixedDecimalScale
                  thousandSeparator
                  prefix="$"
                />
              </SoftTypography>
            </SoftBox>
          </SoftBox>
        </WithPermissions> */}
      </SoftBox>
    </>
  );
}

TransactionSummary.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default TransactionSummary;
