import SoftBox from "src/components/SoftBox";
import SoftSelect from "src/components/SoftSelect";
import { IQuickbookItem } from "src/interfaces/quickbook.interface";
import { LineItem } from "src/interfaces";
import { ArrowDropDown } from "@mui/icons-material";

interface QbkItemProps {
  quickbooksItems: IQuickbookItem[];
  lineItem?: LineItem;
  disabled?: boolean;
  onLineItemEditted: (lineItem: Partial<LineItem>) => void;
}

export const QbkItem = ({
  quickbooksItems,
  lineItem,
  disabled,
  onLineItemEditted,
}: QbkItemProps) => {
  return (
    <SoftBox width={220} sx={{ background: "white" }}>
      <SoftSelect
        isDisabled={disabled}
        options={
          quickbooksItems?.map((qbi) => ({
            label: qbi.Name,
            value: qbi.Id,
          })) ?? []
        }
        customStyles={{
          control: (disabled ? {
            backgroundColor: '#E9ECEF !important' 
          } : {}) as any
        }}
        components={{
          DropdownIndicator: ({ isDisabled, isFocused, innerProps }) => {
            return !isDisabled ? (
              <div {...innerProps}>
                <ArrowDropDown
                  sx={{
                    margin: "10px",
                    marginTop: "20px",
                    width: "18px",
                    height: "18px",
                    transform: isFocused ? "rotate(180deg)" : "",
                  }}
                />
              </div>
            ) : null;
          },
        }}
        value={
          lineItem?.qboItem?.name
            ? {
                label: lineItem?.qboItem?.name,
                value: lineItem?.qboItem?.id,
              }
            : null
        }
        placeholder="Select Item"
        onChange={(e) =>
          onLineItemEditted({
            ...(lineItem ? lineItem : {}),
            qboItem: {
              id: e.value,
              name: e.label,
            },
          })
        }
      />
    </SoftBox>
  );
};
