import { apiUrls } from "src/config/apiUrls";
import { ApiWrapper } from "./fetchClient";
import { ITransaction } from "src/interfaces";
import { removeUndefined } from "src/utils/utils";
import { parse } from "date-fns";

export const getTransactions = async ({
  userId,
  clubId,
  locationId,
  startDate,
  endDate,
}: {
  userId: string;
  clubId: string;
  locationId?: string;
  startDate?: Date;
  endDate?: Date;
}): Promise<ITransaction[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.transactions.get(userId, clubId),
      params: removeUndefined({ locationId, startDate, endDate }),
    })
  ).call();

  return result.map((transaction: any) => ({
    ...transaction,
    // 2024-09-23T00:02:51.307Z
    date: parse(transaction.date, "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'", new Date()),
  }));
};
