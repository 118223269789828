import CoverLayout from "src/pages/auth/Layout";
import loginBg2 from "src/assets/images/login-bg2.jpg";
import SoftTypography from "src/components/SoftTypography";
import PropTypes from "prop-types";

export function NoticePage({ text }) {
  return (
    <CoverLayout
      title="Launch Delay"
      description={text ?? "Due to some technical set setbacks."}
      image={loginBg2}
    >
      <SoftTypography component="label" variant="caption" fontWeight="bold">
        We want to ensure you have the best experience possible with PreFlight.
        Due to some technical issues, we are delaying the launch of the CAA
        integration. If you have any questions, please contact Centennial at +1
        (678) 686-9086.
      </SoftTypography>
    </CoverLayout>
  );
}

NoticePage.propTypes = {
  text: PropTypes.string,
};
