import { useEffect, useMemo, useState } from "react";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import SoftBox from "src/components/SoftBox";
import Card from "@mui/material/Card";
import SoftTypography from "src/components/SoftTypography";
import DataTable from "src/components/Tables/DataTable";
import Footer from "src/components/Footer";
import { getInstructorsCollection } from "src/features/instructor/collections";
import { useClubs } from "src/features/club/ClubProvider";
import DualView from "src/components/DualView/DualView";
import InstructorInfo from "src/pages/resources/instructors/InstructorInfo";
import Modal from "@mui/material/Modal";
import NewInstructorDetails from "src/pages/resources/instructors/NewInstructorDetails";

import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import Swal from "sweetalert2";
import { getTableColumns, TableActions } from "./tableColumns";
import { doc, updateDoc, setDoc } from "firebase/firestore";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import { usePermissions } from "src/hooks/usePermissions";

function InstructorResources() {
  const [showPanel, setShowPanel] = useState(false);
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [showNewInstructorModal, setShowNewInstructorModal] = useState(false);
  const [instructors, setInstructors] = useState([]);
  const {
    selectedClubId,
    selectedLocationId,
    instructors: clubInstructors,
    instructorPreferences: clubInstructorPrefs,
  } = useClubs();

  const { hasAccess } = usePermissions();

  const fetchInstructorData = async (instructorsList) => {
    const instructorsData = instructorsList.map((instructor) => {
      const instructorPrefs = clubInstructorPrefs.get(instructor.uid);
      return {
        ...instructor,
        preferences: instructorPrefs ?? {},
        index: instructorPrefs?.index ?? 0,
      };
    });

    setInstructors(instructorsData).sort((a, b) => a.index - b.index);

    if (selectedInstructor) {
      const found = instructorsData.find((a) => a.id === selectedInstructor.id);
      if (found) {
        setSelectedInstructor(found);
      }
    } else {
      setSelectedInstructor(instructors[0]);
    }
  };

  useEffect(() => {
    if (clubInstructors?.length > 0) {
      fetchInstructorData(clubInstructors);
    }
  }, [clubInstructors, clubInstructorPrefs]);

  const handleRowSelection = (instructor) => {
    setSelectedInstructor(instructor);
    if (hasAccess([systemPermissions.VIEW_INSTRUCTOR_DETAILS]))
      setShowPanel(true);
    else setShowPanel(false);
  };

  const handlePanelClose = () => {
    setShowPanel(false);
  };

  const handleOrderInstructors = async (rows) => {
    const { updateData } = entityCrudUtils();

    rows.forEach(async (element, index) => {
      console.log("Updating Row", element, index);
      await updateData(
        {
          entity: getInstructorsCollection(selectedClubId, selectedLocationId),
          pathSegmentsArr: [element.id],
        },
        { index }
      );
    });
  };

  const deleteInstructor = (instructor) => {
    const newSwal = Swal.mixin({
      customClass: {
        cancelButton: "button",
        confirmButton: "button button-error",
      },
      buttonsStyling: false,
    });

    newSwal
      .fire({
        title: "Are you sure?",
        text: "You can re-invite this instructor at any time.",
        showCancelButton: true,
        confirmButtonText: "Yes, delete them!",
        cancelButtonText: "No, cancel!",
        reverseButtons: true,
        icon: "warning",
      })
      .then((result) => {
        if (result.isConfirmed) {
          updateDoc(
            doc(
              getInstructorsCollection(selectedClubId, selectedLocationId),
              instructor.uid
            ),
            {
              deletedAt: new Date(),
              deleted: true,
            }
          );
        }
      });
  };

  const toggleHideInstructor = (instructor) => {
    const { preferences } = instructor;
    preferences.hidden = !preferences.hidden;
    setDoc(
      doc(
        getInstructorsCollection(selectedClubId, selectedLocationId),
        instructor.uid
      ),
      {
        hidden: preferences.hidden,
      },
      { merge: true }
    );
  };

  const handleTableActions = (action, instructor) => {
    switch (action) {
      case TableActions.DELETE:
        deleteInstructor(instructor);
        break;

      case TableActions.TOGGLE:
        toggleHideInstructor(instructor);
        break;

      default:
        break;
    }
  };

  const handleNewInstructorModalClose = () => setShowNewInstructorModal(false);
  const tableColumns = useMemo(
    () => getTableColumns(handleTableActions, hasAccess),
    []
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Modal
        open={showNewInstructorModal}
        onClose={handleNewInstructorModalClose}
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <NewInstructorDetails onCompleted={handleNewInstructorModalClose} />
      </Modal>
      <SoftBox my={3}>
        <Card>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            p={3}
          >
            <SoftBox lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                Instructors
              </SoftTypography>
              <SoftTypography
                variant="button"
                fontWeight="regular"
                color="text"
              >
                Manage all instructors here.
              </SoftTypography>
            </SoftBox>
          </SoftBox>
          <DualView
            onClosePanel={handlePanelClose}
            showPanel={showPanel}
            rightMinWidth={620}
          >
            <DataTable
              table={{ columns: tableColumns, rows: instructors }}
              entriesPerPage={{
                defaultValue: 7,
                entries: [5, 7, 10, 15, 20, 25],
              }}
              canSearch
              onRowSelect={handleRowSelection}
              selectedRowId={selectedInstructor?.id}
              canDrag
              onRowMove={handleOrderInstructors}
            />
            <InstructorInfo instructor={selectedInstructor} />
          </DualView>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_INSTRUCTORS,
  InstructorResources
);
