import * as toastr from "toastr";
import { useEffect, useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { addDoc, doc, setDoc } from "firebase/firestore";
import { SketchPicker } from "react-color";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { lighten } from "color-manipulation-utils";
import { Stack } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import { getLocationReservationTypesCollection } from "src/features/location/collections";

const schema = yup
  .object({
    label: yup.string().required("A label is required."),
    enabledOptions: yup.object({
      instructor: yup.object({
        taxable: yup.boolean(),
        enabled: yup.boolean(),
        nonRev: yup.boolean(),
        split: yup.number().min(0).max(100),
      }),
      aircraft: yup.object({
        taxable: yup.boolean(),
        enabled: yup.boolean(),
        fuelReimbursement: yup.boolean(),
        nonRev: yup.boolean(),
      }),
      member1: yup.object({
        enabled: yup.boolean(),
        split: yup.number().min(0).max(100),
      }),
      member2: yup.object({
        enabled: yup.boolean(),
        split: yup.number().min(0).max(100),
      }),
      meetingRoom: yup.object({
        enabled: yup.boolean(),
      }),
      checkRide: yup.object({
        enabled: yup.boolean(),
      }),
      preHeat: yup.object({
        enabled: yup.boolean(),
      }),
      minAircraftTime: yup.object({
        enabled: yup.boolean(),
        hours: yup
          .number()
          .min(0, "Minimum billed flight time must be greater than 0"),
      }),
      minInstructorTime: yup.object({
        enabled: yup.boolean(),
        hours: yup
          .number()
          .min(0, "Minimum billed ground time must be greater than 0"),
      }),
    }),
  })
  .required();

interface AddReservationTypeModalProps {
  handleClose: () => void;
  reservationType: any;
}

export function AddReservationTypeModal({
  handleClose,
  reservationType,
}: AddReservationTypeModalProps) {
  const [anchor, setAnchor] = useState<null | HTMLElement>(null);
  const [selectedColor, setSelectedColor] = useState(
    reservationType?.color || "#f44336"
  );
  const [selectedColorSecondary, setSelectedColorSecondary] = useState(
    reservationType?.colorSecondary || lighten("#f44336", 0.2)
  );
  const [selectedColorCompleted, _setSelectedColorCompleted] =
    useState("#5d5d5d");

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchor(anchor ? null : event.currentTarget);
  };

  useEffect(() => {
    setSelectedColorSecondary(lighten(selectedColor, 0.2));
  }, [selectedColor]);

  const open = Boolean(anchor);
  const id = open ? "simple-popup" : undefined;

  const onColorSelected = (color: any) => {
    setSelectedColor(color.hex);
  };

  const { selectedLocationId, selectedClubId } = useClubs();

  const defaultValues = {
    label: reservationType?.label,
    enabledOptions: {
      instructor: {
        taxable: reservationType?.enabledOptions?.instructor?.taxable ?? false,
        nonRev: reservationType?.enabledOptions?.instructor?.nonRev ?? false,
        enabled: reservationType?.enabledOptions?.instructor?.enabled ?? true,
        split: reservationType?.enabledOptions?.instructor?.split ?? 0,
      },
      aircraft: {
        taxable: reservationType?.enabledOptions?.aircraft?.taxable ?? false,
        nonRev: reservationType?.enabledOptions?.aircraft?.nonRev ?? false,
        enabled: reservationType?.enabledOptions?.aircraft?.enabled ?? true,
        fuelReimbursement:
          reservationType?.enabledOptions?.aircraft?.fuelReimbursement ?? false,
      },
      member1: {
        enabled: reservationType?.enabledOptions?.member1?.enabled ?? true,
        split: reservationType?.enabledOptions?.member1?.split ?? 100,
      },
      member2: {
        enabled: reservationType?.enabledOptions?.member2?.enabled ?? false,
        split: reservationType?.enabledOptions?.member2?.split ?? 0,
      },
      meetingRoom: {
        enabled: reservationType?.enabledOptions?.meetingRoom?.enabled ?? false,
      },
      checkRide: {
        enabled: reservationType?.enabledOptions?.checkRide?.enabled ?? false,
      },
      preHeat: {
        enabled: reservationType?.enabledOptions?.preHeat?.enabled ?? false,
      },
      minAircraftTime: {
        enabled:
          reservationType?.enabledOptions?.minAircraftTime?.enabled ?? false,
        hours: reservationType?.enabledOptions?.minAircraftTime?.hours ?? 0,
      },
      minInstructorTime: {
        enabled:
          reservationType?.enabledOptions?.minInstructorTime?.enabled ?? false,
        hours: reservationType?.enabledOptions?.minInstructorTime?.hours ?? 0,
      },
    },
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const formValues = watch();

  const onSubmit = async (data: any) => {
    const newReservationType = {
      label: data.label,
      enabledOptions: data.enabledOptions,
      color: selectedColor,
      colorSecondary: selectedColorSecondary,
      colorCompleted: selectedColorCompleted,
      deleted: false,
    };

    if (reservationType?.id) {
      await setDoc(
        doc(
          getLocationReservationTypesCollection(
            selectedClubId,
            selectedLocationId
          ),
          reservationType.id
        ),
        newReservationType,
        { merge: true }
      );
    } else {
      await addDoc(
        getLocationReservationTypesCollection(
          selectedClubId,
          selectedLocationId
        ),
        newReservationType
      );
    }
    toastr.success("Reservation Type added successfully.");
    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {reservationType ? "Edit Reservation Type" : "Add Reservation Type"}
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={1}>
              <Grid item xs={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Label
                  </SoftTypography>
                </SoftBox>
                <SoftInput
                  placeholder="Eg. Dual Instruction"
                  {...register("label", {
                    required: true,
                  })}
                />
                {errors?.label?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.label.message}
                  </SoftTypography>
                )}
              </Grid>

              <Grid item xs={4}>
                <SoftBox mt={2} mb={1}>
                  <Stack direction="row" spacing={2}>
                    <Stack spacing={2} textAlign="right">
                      <SoftTypography variant="caption">Own:</SoftTypography>
                      <SoftTypography variant="caption">Others:</SoftTypography>
                    </Stack>
                    <Stack spacing={2}>
                      <SoftBox
                        sx={{
                          display: "inline-block",
                          width: "60px",
                          height: "20px",
                          backgroundColor: selectedColor,
                          borderRadius: "5px",
                        }}
                        onClick={handleClick}
                      />
                      <SoftBox
                        sx={{
                          display: "inline-block",
                          width: "60px",
                          height: "20px",
                          backgroundColor: selectedColorSecondary,
                          borderRadius: "5px",
                        }}
                      />
                    </Stack>
                  </Stack>
                  <BasePopup
                    id={id}
                    open={open}
                    anchor={anchor}
                    style={{ zIndex: 99999 }}
                  >
                    <SketchPicker
                      color={selectedColor}
                      presetColors={[
                        "#329baa",
                        "#fbcf33",
                        "#f44336",
                        "#4caf50",
                        "#2196f3",
                        "#9c27b0",
                        "#ff5722",
                        "#c68f2a",
                        "#000000",
                      ]}
                      onChange={onColorSelected}
                    />
                  </BasePopup>
                </SoftBox>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Enabled Resources
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <Stack>
                  <SoftBox ml={0.5} display="inline-block">
                    <Controller
                      control={control}
                      {...register("enabledOptions.aircraft.enabled", {
                        required: true,
                      })}
                      name="enabledOptions.aircraft.enabled"
                      render={({ field: { onChange, ref, value } }) => (
                        <Checkbox
                          inputRef={ref}
                          checked={value}
                          onChange={onChange}
                        />
                      )}
                    />

                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      onClick={() =>
                        setValue(
                          "enabledOptions.aircraft.enabled",
                          !formValues?.enabledOptions?.aircraft?.enabled
                        )
                      }
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Aircraft
                    </SoftTypography>
                    <SoftBox></SoftBox>
                  </SoftBox>
                  {formValues?.enabledOptions?.aircraft?.enabled && (
                    <>
                      <SoftBox ml={4} display="inline-block">
                        <Controller
                          control={control}
                          name="enabledOptions.aircraft.nonRev"
                          render={({ field: { onChange, ref, value } }) => (
                            <Checkbox
                              inputRef={ref}
                              checked={value}
                              onChange={onChange}
                            />
                          )}
                        />

                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          onClick={() =>
                            setValue(
                              "enabledOptions.aircraft.nonRev",
                              !formValues?.enabledOptions?.aircraft?.nonRev
                            )
                          }
                          sx={{
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                        >
                          Non Rev Aircraft Costs
                        </SoftTypography>
                      </SoftBox>
                      {!formValues?.enabledOptions?.aircraft?.nonRev && (
                        <SoftBox ml={4} display="inline-block">
                          <Controller
                            control={control}
                            name="enabledOptions.aircraft.taxable"
                            render={({ field: { onChange, ref, value } }) => (
                              <Checkbox
                                inputRef={ref}
                                checked={value}
                                onChange={onChange}
                              />
                            )}
                          />

                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            onClick={() =>
                              setValue(
                                "enabledOptions.aircraft.taxable",
                                !formValues?.enabledOptions?.aircraft?.taxable
                              )
                            }
                            sx={{
                              cursor: "pointer",
                              userSelect: "none",
                            }}
                          >
                            Charge Tax on Aircraft Costs
                          </SoftTypography>
                        </SoftBox>
                      )}
                      <SoftBox ml={4} mb={1} display="inline-block">
                        <Controller
                          control={control}
                          name="enabledOptions.aircraft.fuelReimbursement"
                          render={({ field: { onChange, ref, value } }) => (
                            <Checkbox
                              inputRef={ref}
                              checked={value}
                              onChange={onChange}
                            />
                          )}
                        />

                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          onClick={() =>
                            setValue(
                              "enabledOptions.aircraft.fuelReimbursement",
                              !formValues?.enabledOptions?.aircraft
                                ?.fuelReimbursement
                            )
                          }
                          sx={{
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                        >
                          Enable Fuel Reimbursement
                        </SoftTypography>
                      </SoftBox>
                    </>
                  )}
                  <SoftBox ml={0.5} display="inline-block">
                    <Controller
                      control={control}
                      name="enabledOptions.instructor.enabled"
                      render={({ field: { onChange, ref, value } }) => (
                        <Checkbox
                          inputRef={ref}
                          checked={value}
                          onChange={onChange}
                        />
                      )}
                    />

                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      onClick={() =>
                        setValue(
                          "enabledOptions.instructor.enabled",
                          !formValues?.enabledOptions?.instructor?.enabled
                        )
                      }
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Instructor
                    </SoftTypography>
                  </SoftBox>
                  {formValues?.enabledOptions?.instructor?.enabled && (
                    <>
                      <SoftBox ml={4} display="inline-block">
                        <Controller
                          control={control}
                          name="enabledOptions.instructor.nonRev"
                          render={({ field: { onChange, ref, value } }) => (
                            <Checkbox
                              inputRef={ref}
                              checked={value}
                              onChange={onChange}
                            />
                          )}
                        />

                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          onClick={() =>
                            setValue(
                              "enabledOptions.instructor.nonRev",
                              !formValues?.enabledOptions?.instructor?.nonRev
                            )
                          }
                          sx={{
                            cursor: "pointer",
                            userSelect: "none",
                          }}
                        >
                          Non Rev Instructor Costs
                        </SoftTypography>
                      </SoftBox>
                      {!formValues?.enabledOptions?.instructor?.nonRev && (
                        <SoftBox ml={4} display="inline-block">
                          <Controller
                            control={control}
                            name="enabledOptions.instructor.taxable"
                            render={({ field: { onChange, ref, value } }) => (
                              <Checkbox
                                inputRef={ref}
                                checked={value}
                                onChange={onChange}
                              />
                            )}
                          />

                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            onClick={() =>
                              setValue(
                                "enabledOptions.instructor.taxable",
                                !formValues?.enabledOptions?.instructor?.taxable
                              )
                            }
                            sx={{
                              cursor: "pointer",
                              userSelect: "none",
                            }}
                          >
                            Charge Tax on Instructor Costs
                          </SoftTypography>
                        </SoftBox>
                      )}
                      <SoftBox ml={4} mb={1} display="inline-block">
                        <Stack direction="row" ml={3} width={300} spacing={2}>
                          <SoftTypography
                            variant="button"
                            fontWeight="regular"
                            sx={{
                              cursor: "pointer",
                              userSelect: "none",
                              alignContent: "center",
                            }}
                          >
                            Percentage Billed
                          </SoftTypography>
                          <SoftBox width={100}>
                            <Controller
                              control={control}
                              name="enabledOptions.instructor.split"
                              render={({
                                field: { onChange, ref, value, ...field },
                              }) => (
                                <SoftInput
                                  {...field}
                                  style={{ paddingLeft: "20px" }}
                                  endAdornment={
                                    <InputAdornment
                                      sx={{
                                        position: "absolute",
                                        right: 0,
                                        paddingRight: 1,
                                      }}
                                      position="end"
                                      component={"span"}
                                    >
                                      <SoftTypography
                                        variant="button"
                                        fontWeight="regular"
                                        color="text"
                                      >
                                        %
                                      </SoftTypography>
                                    </InputAdornment>
                                  }
                                  placeholder="0"
                                  onChange={onChange}
                                  inputRef={ref}
                                  value={value}
                                  error={
                                    !!errors?.enabledOptions?.instructor?.split
                                  }
                                />
                              )}
                            />
                          </SoftBox>
                          {errors?.enabledOptions?.instructor?.split && (
                            <SoftTypography
                              marginTop={1}
                              fontSize={12}
                              color="error"
                            >
                              {errors.enabledOptions.instructor.split}
                            </SoftTypography>
                          )}
                        </Stack>
                      </SoftBox>
                    </>
                  )}
                  <SoftBox ml={0.5} display="inline-block">
                    <Controller
                      control={control}
                      name="enabledOptions.member1.enabled"
                      render={({ field: { onChange, ref, value } }) => (
                        <Checkbox
                          inputRef={ref}
                          checked={value}
                          onChange={onChange}
                        />
                      )}
                    />

                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      onClick={() =>
                        setValue(
                          "enabledOptions.member1.enabled",
                          !formValues?.enabledOptions?.member1?.enabled
                        )
                      }
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Member 1
                    </SoftTypography>
                  </SoftBox>
                  {formValues?.enabledOptions?.member1?.enabled && (
                    <SoftBox ml={4} mb={1} display="inline-block">
                      <Stack direction="row" ml={3} width={300} spacing={2}>
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          sx={{
                            cursor: "pointer",
                            userSelect: "none",
                            alignContent: "center",
                          }}
                        >
                          Percentage Billed
                        </SoftTypography>
                        <SoftBox width={100}>
                          <Controller
                            control={control}
                            name="enabledOptions.member1.split"
                            render={({
                              field: { onChange, ref, value, ...field },
                            }) => (
                              <SoftInput
                                {...field}
                                style={{ paddingLeft: "20px" }}
                                endAdornment={
                                  <InputAdornment
                                    sx={{
                                      position: "absolute",
                                      right: 0,
                                      paddingRight: 1,
                                    }}
                                    position="end"
                                    component={"span"}
                                  >
                                    <SoftTypography
                                      variant="button"
                                      fontWeight="regular"
                                      color="text"
                                    >
                                      %
                                    </SoftTypography>
                                  </InputAdornment>
                                }
                                placeholder="0"
                                onChange={onChange}
                                inputRef={ref}
                                value={value}
                                error={!!errors?.enabledOptions?.member1?.split}
                              />
                            )}
                          />
                        </SoftBox>
                        {errors?.enabledOptions?.member1?.split && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.enabledOptions.member1.split}
                          </SoftTypography>
                        )}
                      </Stack>
                    </SoftBox>
                  )}
                  <SoftBox ml={0.5} display="inline-block">
                    <Controller
                      control={control}
                      name="enabledOptions.member2.enabled"
                      render={({ field: { onChange, ref, value } }) => (
                        <Checkbox
                          inputRef={ref}
                          checked={value}
                          onChange={onChange}
                        />
                      )}
                    />

                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      onClick={() =>
                        setValue(
                          "enabledOptions.member2.enabled",
                          !formValues?.enabledOptions?.member2?.enabled
                        )
                      }
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Member 2
                    </SoftTypography>
                  </SoftBox>
                  {formValues?.enabledOptions?.member2?.enabled && (
                    <SoftBox ml={4} mb={1} display="inline-block">
                      <Stack direction="row" ml={3} width={300} spacing={2}>
                        <SoftTypography
                          variant="button"
                          fontWeight="regular"
                          sx={{
                            cursor: "pointer",
                            userSelect: "none",
                            alignContent: "center",
                          }}
                        >
                          Percentage Billed
                        </SoftTypography>
                        <SoftBox width={100}>
                          <Controller
                            control={control}
                            name="enabledOptions.member2.split"
                            render={({
                              field: { onChange, ref, value, ...field },
                            }) => (
                              <SoftInput
                                {...field}
                                style={{ paddingLeft: "20px" }}
                                endAdornment={
                                  <InputAdornment
                                    sx={{
                                      position: "absolute",
                                      right: 0,
                                      paddingRight: 1,
                                    }}
                                    position="end"
                                    component={"span"}
                                  >
                                    <SoftTypography
                                      variant="button"
                                      fontWeight="regular"
                                      color="text"
                                    >
                                      %
                                    </SoftTypography>
                                  </InputAdornment>
                                }
                                placeholder="0"
                                onChange={onChange}
                                inputRef={ref}
                                value={value}
                                error={!!errors?.enabledOptions?.member2?.split}
                              />
                            )}
                          />
                        </SoftBox>
                        {errors?.enabledOptions?.member2?.split && (
                          <SoftTypography
                            marginTop={1}
                            fontSize={12}
                            color="error"
                          >
                            {errors.enabledOptions.member2.split}
                          </SoftTypography>
                        )}
                      </Stack>
                    </SoftBox>
                  )}
                  <SoftBox ml={0.5} display="inline-block">
                    <Controller
                      control={control}
                      name="enabledOptions.meetingRoom.enabled"
                      render={({ field: { onChange, ref, value } }) => (
                        <Checkbox
                          inputRef={ref}
                          checked={value}
                          onChange={onChange}
                        />
                      )}
                    />

                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      onClick={() =>
                        setValue(
                          "enabledOptions.meetingRoom.enabled",
                          !formValues?.enabledOptions?.meetingRoom?.enabled
                        )
                      }
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Meeting Room
                    </SoftTypography>
                  </SoftBox>
                </Stack>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Advanced Options
                  </SoftTypography>
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <Stack>
                  <SoftBox ml={0.5} display="inline-block">
                    <Controller
                      control={control}
                      {...register("enabledOptions.checkRide.enabled", {
                        required: true,
                      })}
                      name="enabledOptions.checkRide.enabled"
                      render={({ field: { onChange, ref, value } }) => (
                        <Checkbox
                          inputRef={ref}
                          checked={value}
                          onChange={onChange}
                        />
                      )}
                    />

                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      onClick={() =>
                        setValue(
                          "enabledOptions.checkRide.enabled",
                          !formValues?.enabledOptions?.checkRide?.enabled
                        )
                      }
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Enable Check-Ride Tracking
                    </SoftTypography>
                    <SoftBox></SoftBox>
                  </SoftBox>

                  <SoftBox ml={0.5} display="inline-block">
                    <Controller
                      control={control}
                      {...register("enabledOptions.preHeat.enabled", {
                        required: true,
                      })}
                      name="enabledOptions.preHeat.enabled"
                      render={({ field: { onChange, ref, value } }) => (
                        <Checkbox
                          inputRef={ref}
                          checked={value}
                          onChange={onChange}
                        />
                      )}
                    />

                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      onClick={() =>
                        setValue(
                          "enabledOptions.preHeat.enabled",
                          !formValues?.enabledOptions?.preHeat?.enabled
                        )
                      }
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Enable Pre-Heat Reimbursement
                    </SoftTypography>
                  </SoftBox>

                  <SoftBox ml={0.5} display="inline-block">
                    <Controller
                      control={control}
                      {...register("enabledOptions.minAircraftTime.enabled", {
                        required: true,
                      })}
                      name="enabledOptions.minAircraftTime.enabled"
                      render={({ field: { onChange, ref, value } }) => (
                        <Checkbox
                          inputRef={ref}
                          checked={value}
                          onChange={onChange}
                        />
                      )}
                    />

                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      onClick={() =>
                        setValue(
                          "enabledOptions.minAircraftTime.enabled",
                          !formValues?.enabledOptions?.minAircraftTime?.enabled
                        )
                      }
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Minimum Aircraft Time Billing
                    </SoftTypography>
                    {formValues?.enabledOptions?.minAircraftTime?.enabled && (
                      <SoftBox width={100} ml={3} display="inline-block">
                        <Controller
                          control={control}
                          name="enabledOptions.minAircraftTime.hours"
                          render={({
                            field: { onChange, ref, value, ...field },
                          }) => (
                            <SoftInput
                              {...field}
                              style={{ paddingLeft: "20px" }}
                              endAdornment={
                                <InputAdornment
                                  sx={{
                                    position: "absolute",
                                    right: 0,
                                    paddingRight: 1,
                                  }}
                                  position="end"
                                  component={"span"}
                                >
                                  <SoftTypography
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                  >
                                    {formValues?.enabledOptions?.minAircraftTime
                                      ?.hours === "1"
                                      ? "hr"
                                      : "hrs"}
                                  </SoftTypography>
                                </InputAdornment>
                              }
                              placeholder="0"
                              onChange={onChange}
                              inputRef={ref}
                              value={value}
                              error={
                                !!errors?.enabledOptions?.minAircraftTime?.hours
                              }
                            />
                          )}
                        />
                      </SoftBox>
                    )}
                  </SoftBox>

                  <SoftBox ml={0.5} display="inline-block">
                    <Controller
                      control={control}
                      {...register("enabledOptions.minInstructorTime.enabled", {
                        required: true,
                      })}
                      name="enabledOptions.minInstructorTime.enabled"
                      render={({ field: { onChange, ref, value } }) => (
                        <Checkbox
                          inputRef={ref}
                          checked={value}
                          onChange={onChange}
                        />
                      )}
                    />

                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      onClick={() =>
                        setValue(
                          "enabledOptions.minInstructorTime.enabled",
                          !formValues?.enabledOptions?.minInstructorTime
                            ?.enabled
                        )
                      }
                      sx={{
                        cursor: "pointer",
                        userSelect: "none",
                      }}
                    >
                      Minimum Instructor Time Billing
                    </SoftTypography>
                    {formValues?.enabledOptions?.minInstructorTime?.enabled && (
                      <SoftBox width={100} ml={3} display="inline-block">
                        <Controller
                          control={control}
                          name="enabledOptions.minInstructorTime.hours"
                          render={({
                            field: { onChange, ref, value, ...field },
                          }) => (
                            <SoftInput
                              {...field}
                              style={{ paddingLeft: "20px" }}
                              endAdornment={
                                <InputAdornment
                                  sx={{
                                    position: "absolute",
                                    right: 0,
                                    paddingRight: 1,
                                  }}
                                  position="end"
                                  component={"span"}
                                >
                                  <SoftTypography
                                    variant="button"
                                    fontWeight="regular"
                                    color="text"
                                  >
                                    {formValues?.enabledOptions
                                      ?.minInstructorTime?.hours === "1"
                                      ? "hr"
                                      : "hrs"}
                                  </SoftTypography>
                                </InputAdornment>
                              }
                              placeholder="0"
                              onChange={onChange}
                              inputRef={ref}
                              value={value}
                              error={
                                !!errors?.enabledOptions?.minInstructorTime
                                  ?.hours
                              }
                            />
                          )}
                        />
                      </SoftBox>
                    )}
                  </SoftBox>
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {reservationType?.id ? "Save" : "Add"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

AddReservationTypeModal.propTypes = {
  handleClose: PropTypes.func,
  reservationType: PropTypes.object,
};
