// @mui material components
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { styled } from '@mui/material/styles';

export default styled(TextareaAutosize)(({ theme, ownerState }) => {
  const { palette, functions, borders } = theme;
  const { disabled } = ownerState;

  const { inputColors, grey, white } = palette;
  const { pxToRem } = functions;
  const { borderWidth, borderRadius } = borders;

  return {
    display: 'flex !important',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    padding: `${pxToRem(8)} ${pxToRem(28)} ${pxToRem(2)} ${pxToRem(12)} !important`,
    border: `${borderWidth[1]} solid ${inputColors.borderColor.main}`,
    borderRadius: `${borderRadius.md} !important`,

    '& fieldset': {
      border: 'none',
    },

    // textarea: {
    // height: pxToRem(22),
    //   width: 'max-content !important',
    // },

    backgroundColor: disabled ? `${grey[200]} !important` : white.main,
    pointerEvents: disabled ? 'none' : 'auto',

    '&.Mui-focused': {
      outline: 0,
    },

    '&.MuiInputBase-multiline': {
      padding: `${pxToRem(10)} ${pxToRem(12)}`,
    },
  };
});
