// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";
import { TableCell } from "@mui/material";

function MxTableCell({
  width = "auto",
  noBorder = false,
  color,
  primaryText = "-",
  secondaryText,
}) {
  const { border, error } = colors;
  const { borderWidth } = borders;

  return (
    <TableCell
      width={width}
      align="center"
      padding="none"
      border={noBorder ? 0 : `${borderWidth[1]} solid ${border.light}`}
      style={{ padding: 0 }}
    >
      <SoftBox
        sx={{
          backgroundColor: color ?? "none",
          borderRadius: "5px",
          display: "inline-flex",
          whiteSpace: "nowrap",
        }}
        p="2px 8px"
        m="5px"
      >
        {primaryText !== undefined ? (
          <SoftTypography
            sx={{
              fontSize: "12px",
              fontWeight: 500,
              color: color === error.main ? "#fff" : "text",
            }}
            variant="body2"
          >
            {primaryText || "-"}
          </SoftTypography>
        ) : null}
        {secondaryText && secondaryText !== undefined ? (
          <SoftTypography
            sx={{
              fontSize: "10px",
              lineHeight: 1.8,
              marginLeft: "3px",
              color: color === error.main ? "#fff" : "text",
            }}
            variant="body2"
          >
            ({secondaryText}h)
          </SoftTypography>
        ) : null}
      </SoftBox>
    </TableCell>
  );
}

// Typechecking props for TableCell
MxTableCell.propTypes = {
  width: PropTypes.string,
  primaryText: PropTypes.string,
  secondaryText: PropTypes.string,
  color: PropTypes.string,
  noBorder: PropTypes.bool,
};

export default MxTableCell;
