// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
// import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";

import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { where, orderBy, query } from "firebase/firestore";
import { useMemo } from "react";
import { getClubInvoices } from "src/features/club/collections";
import { IInvoice, IUseClub } from "src/interfaces";
import { groupTransactionsByDate } from "../helpers/transaction.helper";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { InvoiceStatusIndicator } from "src/components/InvoiceStatusIndicator/InvoiceStatusIndicator";
import { formatCurrency } from "src/features/utils";
import { InvoicePdf } from "src/pages/club-billing/invoices/invoice-edit/components/InvoicePdf";
import { pdf } from "@react-pdf/renderer";
import pdfSvg from "src/assets/images/transactions/pdf.svg";
import eyeSvg from "src/assets/images/transactions/eye.svg";
import { createSearchParams, useNavigate } from "react-router-dom";

interface InvoicesProps {
  userId: string;
}

function Invoices({ userId }: InvoicesProps) {
  const { selectedClub, selectedLocation, selectedClubId } =
    useClubs() as IUseClub;
  const navigate = useNavigate();

  const clubsInvoicesCollectionRef = query(
    getClubInvoices(selectedClubId),
    where("memberId", "==", userId),
    orderBy("createdAt", "desc")
  );
  const { data: invoices } = useRealtimeCollectionData(
    clubsInvoicesCollectionRef,
    true
  );

  const groupedInvoices = useMemo(() => {
    console.log("invoices", invoices);
    return groupTransactionsByDate(invoices as IInvoice[]);
  }, [invoices]);

  const openPdf = async (invoice: IInvoice) => {
    const doc = (
      <InvoicePdf
        invoice={invoice!}
        club={selectedClub}
        location={selectedLocation}
      />
    );
    const asPdf = pdf(doc);
    const blob = await asPdf.toBlob();
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const openInvoice = (invoice: IInvoice) => {
    navigate({
      pathname: `/billing/invoice/${invoice.id}`,
      search: createSearchParams({
        isNewInvoice: "true",
      }).toString(),
    });
  };

  return (
    <>
      <Card sx={{ marginBottom: "12px" }}>
        <SoftBox
          py={3}
          px={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            boxShadow: "0px 8px 24px 0px rgba(149, 157, 165, 0.1)",
          }}
        >
          <SoftTypography variant="body1" fontWeight="medium">
            Invoices
          </SoftTypography>
        </SoftBox>
        <SoftBox p={2} pt={0}>
          {invoices.length === 0 && (
            <SoftBox p={2} mx={3} display="flex" justifyContent="center">
              <SoftTypography variant="caption" color="text" textAlign="center">
                <i>There are no invoices yet..</i>
              </SoftTypography>
            </SoftBox>
          )}
          {groupedInvoices.length > 0 && (
            <Table
              sx={{
                minWidth: "32rem",
              }}
            >
              <TableHead>
                <TableRow sx={{ borderBottom: "none" }}>
                  <TableCell sx={{ borderBottom: "transparent" }} />
                  <TableCell sx={{ borderBottom: "transparent" }} />
                  <TableCell sx={{ borderBottom: "transparent" }} />
                  <TableCell sx={{ borderBottom: "transparent" }} />
                  <TableCell sx={{ borderBottom: "transparent" }} />
                </TableRow>
              </TableHead>
              <TableBody>
                {groupedInvoices.map((trd) => (
                  <>
                    <TableRow key={trd.label}>
                      <TableCell colSpan={4}>
                        <SoftTypography
                          fontSize="14px"
                          fontWeight="bold"
                          sx={{ padding: "20px 0" }}
                        >
                          {trd.label}
                        </SoftTypography>
                      </TableCell>
                    </TableRow>
                    {trd.items.map((invoice) => (
                      <TableRow
                        key={invoice.id}
                        sx={{
                          cursor: "pointer",
                        }}
                        onClick={() => openInvoice(invoice)}
                      >
                        <TableCell>
                          <SoftTypography fontSize="14px" fontWeight="bold">
                            Invoice {invoice.transactionNumber || invoice.id}
                          </SoftTypography>
                        </TableCell>
                        <TableCell width="120px">
                          <InvoiceStatusIndicator status={invoice.status} />
                        </TableCell>
                        <TableCell width="150px">
                          <SoftTypography fontSize="16px" fontWeight="bold">
                            {formatCurrency(invoice.totalAmount, true)}
                          </SoftTypography>
                        </TableCell>
                        <TableCell width="40px">
                          <SoftBox
                            display="flex"
                            alignItems="center"
                            lineHeight={1}
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              openPdf(invoice);
                            }}
                          >
                            <img src={pdfSvg} />
                          </SoftBox>
                        </TableCell>
                        <TableCell width="40px">
                          <SoftBox
                            display="flex"
                            alignItems="center"
                            lineHeight={1}
                            sx={{
                              cursor: "pointer",
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              openInvoice(invoice);
                            }}
                          >
                            <img src={eyeSvg} />
                          </SoftBox>
                        </TableCell>
                      </TableRow>
                    ))}
                  </>
                ))}
              </TableBody>
            </Table>
          )}
        </SoftBox>
      </Card>
    </>
  );
}

export default Invoices;
