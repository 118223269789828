import { useEffect, useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Divider from "@mui/material/Divider";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";

import { query, where, getDocs, getDoc, doc } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import {
  getAircraftCollection,
  getSimulatorsCollection,
} from "src/features/aircraft/collections";

import { isBefore } from "date-fns";

import ReservationDate from "src/components/ReservationDate";
import AircraftDataForm from "./components/AircraftDataForm";
import InstructorDataForm from "./components/InstructorDataForm";
import FuelReimbursementForm from "./components/FuelReimbursementForm";
import PreHeatReimbursementForm from "./components/PreHeatReimbursementForm";
import SquawksList from "src/components/SquawksList";
import { ThreeDots } from "react-loader-spinner";
import { BookingSelector } from "src/components/BookingSelector";
import { ArrowBack } from "@mui/icons-material";
import { v4 as uuidv4 } from "uuid";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { completeBooking } from "src/services/booking.api";
import { openModal$ } from "../modalConfiguration";
import PassFailForm from "./components/PassFailForm";
import * as toastr from "toastr";
import { useUser } from "src/features/user/UserProvider";
import SimulatorDataForm from "./components/SimulatorDataForm";

export function CheckInModal({ handleClose, selectedBooking }) {
  const {
    selectedClub,
    selectedClubId,
    selectedLocationId,
    clubUsers,
    selectedLocation,
  } = useClubs();
  const { userId } = useUser();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAircraft, setIsLoadingAircraft] = useState(false);
  const [isLoadingInstructor, setIsLoadingInstructor] = useState(false);
  const [isLoadingMember, setIsLoadingMember] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [booking, setBooking] = useState();
  const [aircraft, setAircraft] = useState();
  const [instructor, setInstructor] = useState();
  const [totalHobbs, setTotalHobbs] = useState();
  const [totalTach, setTotalTach] = useState();
  const [hobbsStart, setHobbsStart] = useState();
  const [hobbsEnd, setHobbsEnd] = useState();
  const [tachStart, setTachStart] = useState();
  const [tachEnd, setTachEnd] = useState();
  const [fuelReimbursement, setFuelReimbursement] = useState();
  const [receiptError, setReceiptError] = useState(null);
  const [receipt, setReceipt] = useState(null);
  const [addPreHeatFee, setAddPreHeatFee] = useState(false);
  const [totalFuelCost, setTotalFuelCost] = useState(0);
  const [flightInstructionHours, setFlightInstructionHours] = useState(null);
  const [groundInstructionHours, setGroundInstructionHours] = useState(null);
  const [bookingsToSelectFrom, setBookingsToSelectFrom] = useState();
  const [passCheckride, setPassCheckride] = useState();

  useEffect(() => {
    if (selectedBooking) {
      setBooking(selectedBooking);
    }
  }, []);

  const onAddReceipt = (file) => {
    setReceiptError(null);
    setReceipt(file);
  };

  const calculateTotalHobbs = (start, end) => {
    if (!start || !end) {
      setTotalHobbs(null);
      return;
    }

    const previousHobbs = Number.parseFloat(start);
    const newHobbs = Number.parseFloat(end);

    setHobbsStart(previousHobbs);
    setHobbsEnd(newHobbs);
    setTotalHobbs(Math.round((newHobbs - previousHobbs) * 10) / 10);
  };

  const calculateTotalTach = (start, end) => {
    if (!start || !end) {
      setTotalTach(null);
      return;
    }

    const previousTach = Number.parseFloat(start);
    const newTach = Number.parseFloat(end);

    setTachStart(previousTach);
    setTachEnd(newTach);
    setTotalTach(Math.round((newTach - previousTach) * 100) / 100);
  };

  const calculateTotalInstructor = (data) => {
    if (!data) {
      setGroundInstructionHours(0);
      return;
    }

    const newGround = Number.parseFloat(data);

    setGroundInstructionHours(newGround);
  };

  const onFuelAmountChanged = (data) => {
    console.log("Fuel Data", data);
    if (!data) {
      setFuelReimbursement(null);
      setTotalFuelCost(0);
      return;
    }

    if (data.type === "dollar") {
      const fuelAmount = Number.parseFloat(data.amount);
      const fuelQty = Math.max(Number.parseFloat(data.quantity), 0);
      const maxPerGallon =
        selectedLocation.preferences?.fuelReimbursement?.maxAmount || 0;
      const fuelRate = Math.max(
        maxPerGallon > 0 ? Math.min(fuelAmount, maxPerGallon) : fuelAmount,
        0
      );
      setFuelReimbursement(data);
      setTotalFuelCost(fuelRate * fuelQty);
    } else {
      const fuelAmount = Math.max(Number.parseFloat(data.quantity), 0);

      setFuelReimbursement(data);
      setTotalFuelCost(
        Math.round(
          fuelAmount *
            (selectedLocation.preferences?.fuelReimbursement?.rate ?? 0) *
            100
        ) / 100
      );
    }
  };

  const onAddPreHeatChanged = (data) => {
    if (!data) {
      setAddPreHeatFee(false);
      return;
    }
    const addPreHeat = !addPreHeatFee;

    setAddPreHeatFee(addPreHeat);
  };

  useEffect(() => {
    if (
      totalHobbs &&
      totalHobbs > 0 &&
      booking?.extendedProps?.instructor?.value
    ) {
      setFlightInstructionHours(Math.round(totalHobbs * 10) / 10);
    }
  }, [totalHobbs]);

  const fetchBooking = async () => {
    const q = query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("dispatched", "==", true),
      where("completed", "==", false),
      where("cancelled", "==", false)
    );
    const docs = await getDocs(q);

    const bookingsList = [];
    if (docs.docs.length > 0) {
      docs.docs.map((document) => {
        const bookingData = document.data();
        if (
          bookingData?.extendedProps?.pilot?.value === userId ||
          bookingData?.extendedProps?.pilot2?.value === userId ||
          bookingData?.extendedProps?.instructor?.value === userId
        ) {
          bookingsList.push({
            id: document.id,
            ...bookingData,
            snapshot: document,
          });
        }
      });

      const sortedBookings = bookingsList.sort((a, b) =>
        isBefore(a.start.toDate(), b.start.toDate()) ? -1 : 1
      );
      if (bookingsList.length > 1) {
        setBookingsToSelectFrom(sortedBookings);
      } else if (bookingsList.length === 1) {
        setIsLoadingAircraft(true);
        setIsLoadingInstructor(true);
        setIsLoadingMember(true);
        setBooking(sortedBookings[0]);
      }
    }
    setIsLoading(false);
  };

  const fetchAircraft = async () => {
    if (
      booking?.extendedProps?.aircraft?.value &&
      booking?.extendedProps?.aircraft?.value !== ""
    ) {
      let document = await getDoc(
        doc(
          getAircraftCollection(booking?.extendedProps?.clubId),
          booking?.extendedProps?.aircraft?.value
        )
      );

      if (!document.data()) {
        document = await getDoc(
          doc(
            getSimulatorsCollection(booking?.extendedProps?.clubId),
            booking?.extendedProps?.aircraft?.value
          )
        );
      }

      setAircraft(document);
    }
  };

  const fetchInstructor = async () => {
    if (!booking?.extendedProps?.instructor?.value) return;
    const document = clubUsers.get(booking?.extendedProps?.instructor?.value);
    setInstructor(document);
  };

  useEffect(() => {
    if (!selectedBooking) fetchBooking();
    else setIsLoading(false);
  }, []);

  useEffect(() => {
    if (!booking || booking?.extendedProps?.aircraft?.value === "") {
      setIsLoadingAircraft(false);
      return;
    }

    fetchAircraft().then(() => {
      setIsLoadingAircraft(false);
    });
  }, [booking]);

  useEffect(() => {
    if (!booking || booking?.extendedProps?.instructor?.value === "") {
      setIsLoadingInstructor(false);
      return;
    }

    fetchInstructor().then(() => {
      setIsLoadingInstructor(false);
    });
  }, [booking]);

  useEffect(() => {
    if (!booking || booking?.extendedProps?.pilot?.value === "") {
      setIsLoadingMember(false);
      return;
    }
  }, [booking]);

  useEffect(() => {
    if (!aircraft || !totalHobbs || totalHobbs < 0) return;
    if (aircraft?.data()?.tachFollowsHobbs) {
      const originalTach = aircraft?.data()?.tach1;
      calculateTotalTach(originalTach + totalHobbs);
    }
  }, [aircraft, totalHobbs]);

  const handleCheckIn = async (complete) => {
    setIsSubmitting(true);

    if (
      aircraft?.data() &&
      !aircraft?.data()?.simulator &&
      !aircraft?.data()?.tachFollowsHobbs &&
      (!tachEnd || tachEnd < tachStart || !hobbsEnd || hobbsEnd < hobbsStart)
    ) {
      setIsSubmitting(false);
      toastr.error("Please enter a valid hobbs and tach value");
      return;
    }

    if (
      (aircraft?.data() &&
        (totalHobbs < 0 ||
          (selectedLocation.preferences?.limitMaxHobbsTach &&
            totalHobbs > selectedLocation.preferences?.maxHobbsTach) ||
          (!aircraft?.data()?.simulator &&
            !aircraft?.data()?.tachFollowsHobbs &&
            (totalTach < 0 ||
              (selectedLocation.preferences?.limitMaxHobbsTach &&
                totalTach > selectedLocation.preferences?.maxHobbsTach))))) ||
      (booking?.extendedProps?.instructor?.value &&
        !flightInstructionHours &&
        !groundInstructionHours)
    ) {
      setIsSubmitting(false);
      return;
    }

    if (
      selectedLocation.preferences?.fuelReimbursement?.forceReceipt &&
      (fuelReimbursement?.amount > 0 || fuelReimbursement?.quantity > 0) &&
      !receipt
    ) {
      setReceiptError("Please add a receipt");
      setIsSubmitting(false);
      return;
    }

    let receiptData;
    if (receipt) {
      const storage = getStorage();
      const fileName = uuidv4() + "_" + receipt.name;
      const storageRef = ref(
        storage,
        `clubs/${selectedClubId}/receipts/${booking.id}/${fileName}`
      );
      const fi = await uploadBytes(storageRef, receipt);
      const url = await getDownloadURL(ref(storage, fi.ref.fullPath));
      receiptData = {
        createdAt: new Date(),
        documentType: receipt.fileType,
        url,
        storagePath: fi.ref.fullPath,
        fileType: receipt.type.split("/")[1],
        fileName: fi.ref.name,
      };
    }

    completeBooking({
      bookingId: booking.id,
      clubId: booking?.extendedProps?.clubId,
      locationId: booking?.extendedProps?.locationId,
      fuelReimbursement: fuelReimbursement,
      addPreHeatFee,
      hobbsStart,
      hobbsEnd,
      tach1Start: tachStart,
      endBooking: complete || false,
      tach1End: tachEnd,
      instructionHours: flightInstructionHours,
      instructionHoursGround: groundInstructionHours,
      receipt: receiptData,
      checkrideOutcome: passCheckride?.value,
    })
      .then(({ invoice }) => {
        setIsSubmitting(false);
        console.log("invoice", invoice);
        if (invoice) {
          openModal$.next({
            modalName: "PAYMENT_MODAL",
            modalProps: { invoice },
          });
        }
        handleClose(true);
      })
      .catch((error) => {
        console.error("error", error);
        toastr.error("Error checking in. Please try again.");
        setIsSubmitting(false);
      });
  };

  const renderBackButton = () => {
    if (bookingsToSelectFrom) {
      return (
        <SoftButton
          onClick={() => setBooking(undefined)}
          sx={{ left: 0, top: 1, position: "absolute" }}
          color="dark"
          variant="text"
        >
          <ArrowBack fontSize="medium" lineHeight={2} marginRight={2} />

          <SoftTypography variant="h6" fontWeight="light">
            &nbsp;&nbsp;&nbsp;Back to list
          </SoftTypography>
        </SoftButton>
      );
    }

    return null;
  };

  if (
    isLoading ||
    isLoadingAircraft ||
    isLoadingInstructor ||
    isLoadingMember
  ) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox
          p={3}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </ModalContainer>
    );
  }

  if (!booking) {
    if (bookingsToSelectFrom) {
      return (
        <ModalContainer handleClose={handleClose}>
          <SoftBox
            p={2}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <BookingSelector
              bookings={bookingsToSelectFrom}
              onSelectBooking={(bk) => setBooking(bk)}
            />
          </SoftBox>
        </ModalContainer>
      );
    }

    return (
      <SoftBox>
        <ModalContainer handleClose={handleClose}>
          <SoftBox p={2}>
            <SoftTypography variant="h4" fontWeight="bold">
              You don&rsquo;t have any dispatched bookings...
            </SoftTypography>
          </SoftBox>
        </ModalContainer>
      </SoftBox>
    );
  }

  return (
    <SoftBox>
      <ModalContainer handleClose={handleClose}>
        <SoftBox p={2}>
          <SoftBox>
            <SoftBox
              display="flex"
              justifyContent="center"
              position="relative"
              alignItems="center"
            >
              {renderBackButton()}
              <SoftTypography variant="h4" fontWeight="bold" lineHeight={2}>
                Check-In
              </SoftTypography>
            </SoftBox>
            <Divider />
            <ReservationDate
              start={booking.start.toDate()}
              end={booking.end.toDate()}
            />
            <Divider />
            {aircraft?.data() && (
              <>
                {aircraft?.data()?.simulator ? (
                  <SimulatorDataForm
                    aircraft={aircraft.data()}
                    onHobbsChanged={calculateTotalHobbs}
                  />
                ) : (
                  <AircraftDataForm
                    aircraft={aircraft.data()}
                    onHobbsChanged={calculateTotalHobbs}
                    totalHobbs={totalHobbs}
                    onTachChanged={calculateTotalTach}
                    totalTach={totalTach}
                  />
                )}
                <Divider />
              </>
            )}
            {instructor && (
              <>
                <InstructorDataForm
                  instructor={instructor}
                  instructorGroundHours={groundInstructionHours}
                  onInstructorGroundTimeChanged={calculateTotalInstructor}
                />
                <Divider />
                {booking?.extendedProps?.type?.value === "checkride" ||
                  (booking?.extendedProps?.type?.enabledOptions?.checkRide
                    ?.enabled && (
                    <>
                      <PassFailForm
                        value={passCheckride}
                        onChange={setPassCheckride}
                      />
                      <Divider />
                    </>
                  ))}
              </>
            )}
            {selectedLocation?.preferences?.fuelReimbursement?.enabled &&
              aircraft &&
              !aircraft?.data()?.simulator &&
              booking?.extendedProps?.type?.enabledOptions?.aircraft
                ?.fuelReimbursement && (
                <>
                  <FuelReimbursementForm
                    booking={booking}
                    totalFuelCost={totalFuelCost}
                    onFuelAmountChanged={onFuelAmountChanged}
                    receipt={receipt}
                    onAddReceipt={onAddReceipt}
                    receiptError={receiptError}
                  />
                  <Divider />
                </>
              )}
            {selectedClub?.preferences?.preHeatFee?.enabled &&
              aircraft &&
              !aircraft?.data()?.simulator &&
              booking?.extendedProps?.type?.enabledOptions?.preHeat
                ?.enabled && (
                <>
                  <PreHeatReimbursementForm
                    addPreHeatFee={addPreHeatFee}
                    onAddPreHeatChanged={onAddPreHeatChanged}
                  />
                  <Divider />
                </>
              )}
            {aircraft && (
              <SquawksList
                clubId={booking?.extendedProps?.clubId}
                aircraft={{ ...aircraft.data(), id: aircraft.id }}
              />
            )}
            <SoftBox
              mt={4}
              width="100%"
              display="flex"
              justifyContent="space-between"
            >
              <SoftButton variant="outlined" color="dark" onClick={handleClose}>
                Close
              </SoftButton>
              <SoftBox>
                <SoftButton
                  disabled={
                    isSubmitting ||
                    (aircraft?.data() &&
                      ((!totalHobbs && !totalHobbs === 0) ||
                        totalHobbs < 0 ||
                        !hobbsEnd)) ||
                    (aircraft?.data() &&
                      !aircraft?.data().simulator &&
                      ((!totalTach && !totalTach === 0) ||
                        totalTach < 0 ||
                        !hobbsEnd)) ||
                    (booking?.extendedProps?.instructor?.value &&
                      !flightInstructionHours &&
                      !groundInstructionHours)
                  }
                  type="submit"
                  onClick={() => handleCheckIn(false)}
                  variant={
                    booking.start.toDate() < new Date() &&
                    booking.end.toDate() > new Date()
                      ? "outlined"
                      : "gradient"
                  }
                  color="error"
                >
                  check in
                </SoftButton>
                {booking.start.toDate() < new Date() &&
                  booking.end.toDate() > new Date() && (
                    <SoftButton
                      disabled={
                        isSubmitting ||
                        (aircraft?.data() &&
                          ((!totalHobbs && !totalHobbs === 0) ||
                            totalHobbs < 0 ||
                            !hobbsEnd)) ||
                        (aircraft?.data() &&
                          !aircraft?.data().simulator &&
                          ((!totalTach && !totalTach === 0) ||
                            totalTach < 0 ||
                            !hobbsEnd)) ||
                        (booking?.extendedProps?.instructor?.value &&
                          !flightInstructionHours &&
                          !groundInstructionHours)
                      }
                      style={{ marginLeft: "15px" }}
                      type="submit"
                      onClick={() => handleCheckIn(true)}
                      variant="gradient"
                      color="error"
                    >
                      Check In & Complete
                    </SoftButton>
                  )}
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </ModalContainer>
    </SoftBox>
  );
}

CheckInModal.propTypes = {
  handleClose: PropTypes.func,
  selectedBooking: PropTypes.object,
};
