import IdCell from "./components/IdCell";
import DefaultCell from "./components/DefaultCell";
import StatusCell from "./components/StatusCell";
import CustomerCell from "./components/CustomerCell";
import CurrencyCell from "./components/CurrencyCell";

import { format } from "date-fns";
import { getProfileImageURL } from "src/features/utils";
import { InvoiceStatus } from "src/interfaces";
import { toTitleCase } from "src/features/utils";

export const TableActions = {
  SELECTED: "SELECTED",
};

export const getTableColumns = () => [
  {
    Header: "id",
    accessor: "transactionNumber",
    width: 50,
    Cell: ({ value }) => <IdCell id={value} />,
  },
  {
    Header: "date",
    accessor: "createdAt",
    Cell: ({ value }) => (
      <DefaultCell value={format(value.toDate(), "eee, MMM do yyyy")} />
    ),
  },
  {
    Header: "status",
    width: 100,
    accessor: "payment",
    Cell: ({ row: { original } }) => {
      let status;
      const amountPaid =
        original?.payments?.reduce((acc, payment) => {
          if (payment?.status === "succeeded")
            return acc + (payment?.amount ?? 0) / 100;
          else return acc;
        }, 0) ?? 0;

      const totalCost = Math.round(original.totalCost * 100) / 100 || 0;

      if (original.status === InvoiceStatus.OPEN) {
        status = <StatusCell icon="close" color="error" status="Unpaid" />;
      } else if (original.status === InvoiceStatus.DISPUTED) {
        status = <StatusCell icon="close" color="error" status="Disputed" />;
      } else if (original.status === InvoiceStatus.DRAFT) {
        status = <StatusCell icon="close" color="dark" status="Draft" />;
      } else if (original.status === InvoiceStatus.OVERDUE) {
        status = <StatusCell icon="close" color="error" status="Overdue" />;
      } else if (original.status === InvoiceStatus.REFUNDED) {
        status = <StatusCell icon="replay" color="dark" status="Refunded" />;
      } else if (original.status === InvoiceStatus.SENT) {
        status = <StatusCell icon="replay" color="warning" status="Sent" />;
      } else if (original.status === InvoiceStatus.CANCELLED) {
        status = <StatusCell icon="close" color="dark" status="Cancelled" />;
      } else if (
        original.fuelCost >= totalCost ||
        original.status === InvoiceStatus.PAID
      ) {
        status = <StatusCell icon="done" color="success" status="Paid" />;
      } else if (
        amountPaid >= totalCost - (original.fuelCost ?? 0) &&
        (!original.refunded || original.refunded === 0) &&
        !original.invoiceNumber // TODO: Tweak this
      ) {
        status = <StatusCell icon="done" color="success" status="Paid" />;
      } else if (
        (amountPaid < totalCost &&
          amountPaid > 0 &&
          (!original.refunded || original.refunded === 0)) ||
        original.status === InvoiceStatus.PARTIALLY_PAID
      ) {
        status = (
          <StatusCell icon="close" color="warning" status="Partially Paid" />
        );
      } else if (
        (original.refunded && original.refunded >= totalCost) ||
        original.status === InvoiceStatus.REFUNDED
      ) {
        status = <StatusCell icon="replay" color="dark" status="Refunded" />;
      } else if (
        (original.refunded && original.refunded < totalCost) ||
        original.status === InvoiceStatus.PARTIALLY_REFUNDED
      ) {
        status = (
          <StatusCell
            icon="replay"
            color="warning"
            status="Partially Refunded"
          />
        );
      } else if (
        original.payments?.[original.payments.length - 1]?.status ===
        "processing"
      ) {
        status = (
          <StatusCell icon="done" color="warning" status="Processing Payment" />
        );
      } else if (
        original.payments?.[original.payments.length - 1]?.status !==
          "succeeded" &&
        containsValue(
          original.payments?.[original.payments.length - 1]?.charges?.data,
          "status",
          "failed"
        )
      ) {
        status = <StatusCell icon="close" color="error" status="Failed" />;
      } else {
        status = <StatusCell icon="close" color="error" status="Unpaid" />;
      }

      return status;
    },
  },
  {
    Header: "member",
    accessor: ({ member, pilot }) => member?.displayName || pilot?.displayName,
    Cell: ({ row: { original } }) => {
      const { pilot, member, email } = original;
      return (
        <CustomerCell
          image={getProfileImageURL({ pilot })}
          color="dark"
          name={pilot?.displayName || member?.displayName || email}
        />
      );
    },
  },
  {
    Header: "transaction type",
    accessor: "type",
    Cell: ({ value }) => {
      if (!value) return <DefaultCell value="---" />;
      const [name, data] = value;

      return (
        <DefaultCell
          value={typeof value === "string" ? toTitleCase(value) : name}
          suffix={data.suffix || false}
        />
      );
    },
  },
  {
    Header: "amount",
    accessor: "totalCost",
    width: 100,
    Cell: ({ value, row: { original } }) => {
      if (original.totalAmount) {
        return (
          <CurrencyCell
            value={
              (original.totalAmount +
                original.lineItems.reduce((acc, item) => {
                  if (item.payment && !item.payment.clubPaysFees)
                    return acc + (item.fees || 0) + (item.payment?.fees || 0);
                  return acc;
                }, 0)) /
              100
            }
          />
        );
      }
      return <CurrencyCell value={value} />;
    },
  },
];

const containsValue = (arr, key, value) => {
  return arr?.some((obj) =>
    JSON.stringify(obj).includes(`"${key}":"${value}"`)
  );
};
