import { useEffect, useState } from "react";
import { useDocument } from "react-firebase-hooks/firestore";

function useRealtimeDocumentData<T>(docRef: any): {
  data: T | null;
  isDataLoaded: boolean;
  getData: () => void;
} {
  const [isDataLoaded, setDataIsLoaded] = useState(false);
  const [data, setData] = useState<T | null>(null);

  const [snapshot] = useDocument(docRef, {
    snapshotListenOptions: {
      includeMetadataChanges: false,
    },
  });

  const getData = () => {
    if (!snapshot?.data()) return null;
    return { ...snapshot.data(), id: snapshot.id };
  };

  const loadData = async () => {
    const newData = getData();
    setData(newData as T);
    setDataIsLoaded(true);
  };

  useEffect(() => {
    loadData();
  }, [JSON.stringify(getData())]);

  return {
    data,
    isDataLoaded,
    getData,
  };
}

export default useRealtimeDocumentData;
