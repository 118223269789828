import { Subject } from "rxjs";

export enum ModalType {
  ADD_SQUAWK = "ADD_SQUAWK",
  VIEW_SQUAWK = "VIEW_SQUAWK",
  UPLOAD_DOCUMENT = "UPLOAD_DOCUMENT",
  NEW_UPLOAD_DOCUMENT = "NEW_UPLOAD_DOCUMENT",
  DOCUMENT_VIEWER = "DOCUMENT_VIEWER",
  ROLE_MANAGER = "ROLE_MANAGER",
  CHECKIN_MODAL = "CHECKIN_MODAL",
  BOOKING_INFO_MODAL = "BOOKING_INFO_MODAL",
  PAYMENT_MODAL = "PAYMENT_MODAL",
  INVOICE_REFUND_MODAL = "INVOICE_REFUND_MODAL",
  PAYMENT_REFUND_MODAL = "PAYMENT_REFUND_MODAL",
  ADD_CARD_MODAL = "ADD_CARD_MODAL",
  INTRO_FLIGHT_MODAL = "INTRO_FLIGHT_MODAL",
  TRANSFER_BOOKING_MODAL = "TRANSFER_BOOKING_MODAL",
  CONFIRM_BOOKING_MODAL = "CONFIRM_BOOKING_MODAL",
  ERROR_MODAL = "ERROR_MODAL",
  MERGE_USER_MODAL = "MERGE_USER_MODAL",
}
export interface IModalBase {
  isOpen: boolean;
  onClose: () => void;
  onDone?: (params: any) => void;
}

export type IModalConfig = {
  [key in ModalType]: { isOpen: boolean; props: any };
};
export const modalTypesObject = Object.values(ModalType).reduce(
  (previous, current) => {
    previous[current] = { isOpen: false, props: {} };
    return previous;
  },
  {} as IModalConfig
);

export const openModal$ = new Subject<{
  modalName: keyof typeof ModalType;
  modalProps: any;
  isOpen?: boolean;
}>();
