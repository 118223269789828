import { useEffect, useState } from "react";
import { Radio } from "@mui/material";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftButton from "src/components/SoftButton";
import { useForm } from "react-hook-form";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "src/components/TableCell";

import { addDoc } from "firebase/firestore";
import { getUserTaskGradingsCollection } from "src/features/user/collections";

export function GradeTasksModal({ handleClose, tasks, studentId }) {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { handleSubmit } = useForm({
    defaultValues: {},
  });

  const [taskGrades, setTaskGrades] = useState({});

  const handleChangeTaskGrade = (event) => {
    setTaskGrades({ ...taskGrades, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    const taskGradesObj = {};
    tasks.forEach((task) => {
      if (task.grade) {
        taskGradesObj[task.id] = task.grade.grade;
      }
    });
    setTaskGrades(taskGradesObj);
  }, [tasks]);

  const onSubmit = async () => {
    setIsSubmitting(true);

    const newTasks = await Promise.all(
      tasks.map(async (task) => {
        const oldTaskGrade = task.grade?.grade || null;
        if (taskGrades[task.id] !== oldTaskGrade) {
          const newGrade = {
            taskId: task.id,
            grade: taskGrades[task.id],
            deleted: false,
            enrollmentId: task.enrollmentId,
            lessonId: task.lessonId,
            studentId: studentId,
            createdAt: new Date(),
          };
          const newGradeId = await addDoc(
            getUserTaskGradingsCollection(studentId, task.enrollmentId),
            newGrade
          );
          return {
            ...task,
            grade: {
              ...newGrade,
              id: newGradeId,
            },
          };
        }
        return task;
      })
    );

    setIsSubmitting(false);
    handleClose(newTasks);
  };

  const controlProps = (id, value) => ({
    checked: taskGrades?.[id] === value,
    onChange: handleChangeTaskGrade,
    value,
    name: id,
    inputProps: { "aria-label": value },
  });

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" color="primary" fontWeight="bold">
            Grade Tasks
          </SoftTypography>
          <SoftBox mt={2}>
            <Grid container spacing={1}>
              {tasks?.length > 0 && (
                <Grid item xs={12} sm={12}>
                  <SoftBox pb={2}>
                    <SoftBox
                      minWidth="auto"
                      sx={{
                        overflow: "scroll",
                      }}
                    >
                      <Table
                        sx={{
                          minWidth: "36rem",
                        }}
                      >
                        <SoftBox component="thead">
                          <TableRow>
                            <TableCell
                              width="100%"
                              padding={[1.5, 3, 1.5, 0.5]}
                            >
                              Task
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              1
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              2
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              3
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              4
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              5
                            </TableCell>
                            <TableCell
                              align="center"
                              padding={[1.5, 3, 1.5, 3]}
                            >
                              I
                            </TableCell>
                          </TableRow>
                        </SoftBox>
                        <TableBody>
                          {tasks?.map((task) => (
                            <TableRow key={task.id}>
                              <TableCell padding={[1, 1, 1, 0.5]}>
                                <SoftBox lineHeight={1.4}>
                                  <SoftTypography
                                    display="block"
                                    variant="button"
                                    fontWeight="regular"
                                  >
                                    {task.title}
                                  </SoftTypography>
                                  {/* <SoftTypography variant="caption" color="text" fontWeight="regular">
                                  Notify when another user makes a booking for/with you.
                                </SoftTypography> */}
                                </SoftBox>
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio
                                  {...controlProps(task.id, "1")}
                                  color="success"
                                />
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio {...controlProps(task.id, "2")} />
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio {...controlProps(task.id, "3")} />
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio {...controlProps(task.id, "4")} />
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio
                                  {...controlProps(task.id, "5")}
                                  color="error"
                                />
                              </TableCell>
                              <TableCell align="center" padding={[1, 1, 1, 1]}>
                                <Radio
                                  {...controlProps(task.id, "I")}
                                  color="warning"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={3} />
              <Grid item xs={3} />
              <Grid item xs={3}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    disabled={isSubmitting}
                    fullWidth
                    type="submit"
                  >
                    Submit
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

GradeTasksModal.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  studentId: PropTypes.string.isRequired,
  handleClose: PropTypes.func,
};
