import { Box, Grid, Stack } from "@mui/material";
import { Reorder } from "framer-motion";
import { ChangeEvent, useEffect, useMemo, useState } from "react";
import {
  createSearchParams,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import SoftBox from "src/components/SoftBox";
import SoftLoader from "src/components/SoftLoader/SoftLoader";
import SoftTypography from "src/components/SoftTypography";
import DashboardNavbar from "src/containers/DashboardNavbar";
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import { useClubs } from "src/features/club/ClubProvider";
import { getClubById, getClubInvoiceById } from "src/features/club/collections";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import {
  IInvoice,
  IUseClub,
  IUser,
  LineItem,
  LineItemType,
  discountType,
} from "src/interfaces";
import InvoiceLineItem from "./components/LineItem";
import { v4 as uuidv4 } from "uuid";
import { NewLineItem } from "./components/NewLineItem";
import {
  Add,
  Check,
  ChevronLeft,
  DomainVerificationOutlined,
} from "@mui/icons-material";
import { SoftCard } from "src/components/SoftCard/SoftCard";
import SoftButton from "src/components/SoftButton";
import { formatCurrency } from "src/features/utils";
import classes from "./InvoiceEdit.module.css";
import { usePromiseLoading } from "src/hooks/usePromiseLoading";
import {
  getQuickbookClasses,
  getQuickbookItems,
} from "src/services/quickbooks.api";
import {
  calculateInvoiceSummary,
  getLineItemTotal,
} from "src/utils/invoice.utils";
import { getDownloadURL, getStorage, ref, uploadBytes } from "firebase/storage";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import SoftSelect from "src/components/SoftSelect";
import { updateInvoice, createInvoice } from "src/services/invoice.api";
import { format } from "date-fns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { InvoiceStatusIndicator } from "src/components/InvoiceStatusIndicator/InvoiceStatusIndicator";
import { formatPhoneNumber } from "src/utils/number.utils";
import { openModal$ } from "src/modals/modalConfiguration";

const DividerInvoice = () => {
  return (
    <SoftBox
      sx={{
        margin: "26px -26px",
        borderBottom: "1px solid rgba(201, 204, 211, 1)",
      }}
    />
  );
};

function InvoiceEdit() {
  const {
    selectedClubId,
    selectedLocationId,
    selectedLocation,
    selectedClub,
    clubUsers,
  } = useClubs() as IUseClub;
  const navigate = useNavigate();
  const hasQuickbooks = !!selectedClub?.quickbooks?.items;
  const params = useParams();
  const [searchParams] = useSearchParams();
  const invoiceId = params?.invoiceId ?? "";
  const isNewInvoice = searchParams.get("isNewInvoice") ?? false;
  const storage = getStorage();
  const { updateDataFromRef } = entityCrudUtils();

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if ((file && file.size > 1024 * 1024) || !file) return;
    const fileExtension = file?.name
      .slice(file.name.lastIndexOf(".") + 1)
      .toLowerCase();
    const storageRef = ref(
      storage,
      `clubs/${selectedClubId}/logo.${fileExtension}`
    );
    const fi = await uploadBytes(storageRef, file);
    const logoUrl = await getDownloadURL(fi.ref);
    await updateDataFromRef(getClubById(selectedClubId), {
      logoUrl: logoUrl,
    });
  };

  const { caller: fnHandleFileChange, isLoading: isUploadingFile } =
    usePromiseLoading({ fn: handleFileChange });

  const [invoiceData, setInvoiceData] = useState<IInvoice>({
    dueDate: new Date(),
    createdAt: new Date(),
  } as IInvoice);
  const invoiceMetadata =
    isNewInvoice && getClubInvoiceById(selectedClubId, invoiceId);

  const goBack = () => {
    if (!invoiceId) {
      navigate("/billing/invoices");
      return;
    }

    invoiceData &&
      navigate(
        {
          pathname: `/billing/invoice/${invoiceData.id}`,
          search: createSearchParams({
            isNewInvoice: "true",
          }).toString(),
        },
        { replace: true }
      );
  };

  const { caller: createInvoiceFn, isLoading: isLoadingCreate } =
    usePromiseLoading({
      fn: createInvoice,
      throwError: true,
    });
  const { caller: updateInvoiceFn, isLoading: isLoadingUpdate } =
    usePromiseLoading({
      fn: updateInvoice,
    });

  const saveInvoiceAction = async () => {
    if (!invoiceData) return;
    if (!invoiceData.id) {
      try {
        const newInvoice = await createInvoiceFn({
          clubId: selectedClubId,
          locationId: selectedLocationId,
          invoice: invoiceData,
        });
        return newInvoice;
      } catch (error: any) {
        openModal$.next({
          modalName: "ERROR_MODAL",
          modalProps: {
            subtitle: "There was an error while creating the invoice",
            bodyMessage: error.message,
          },
        });
        return false;
      }
    }

    const updateResult = await updateInvoiceFn({
      invoiceId: invoiceData.id,
      clubId: selectedClubId,
      locationId: selectedLocationId,
      invoice: invoiceData,
    });

    return updateResult;
  };

  const saveInvoice = async () => {
    const updateResult = await saveInvoiceAction();
    if (updateResult) {
      goBack();
    }
  };

  const saveAndSendInvoice = async () => {
    const updateResult = await saveInvoiceAction();
    // TODO: Send invoice to customer to pay
    if (updateResult) {
      goBack();
    }
  };

  const { data: invoiceFbData, isDataLoaded: loadedInvoiceData } =
    useRealtimeDocumentData(invoiceMetadata) as unknown as {
      data: IInvoice;
      isDataLoaded: boolean;
    };

  const {
    caller: fnGetQuickbookItems,
    data: quickbooksItems,
    isLoading: isQbkItemLoading,
  } = usePromiseLoading({ fn: getQuickbookItems });

  const {
    caller: fnGetQuickbookClasses,
    data: quickbooksClasses,
    isLoading: isQbkClassLoading,
  } = usePromiseLoading({ fn: getQuickbookClasses });

  useEffect(() => {
    if (hasQuickbooks) {
      fnGetQuickbookItems({ selectedClubId });
      fnGetQuickbookClasses({ selectedClubId });
    }
  }, []);

  useEffect(() => {
    if (!invoiceId) {
      setInvoiceData({
        lineItems: [],
        dueDate: new Date(),
        createdAt: new Date(),
        paymentCurrency: "USD",
        type: LineItemType.MANUAL,
      } as unknown as IInvoice);
      return;
    }
    if (invoiceFbData && invoiceId) {
      setInvoiceData({
        ...invoiceFbData,
      });
    }
    // TODO: merge with local LineItems
  }, [invoiceId, invoiceFbData]);

  const updateLineItems = (updateFn: (lineItems: LineItem[]) => LineItem[]) => {
    setInvoiceData(
      (prev) =>
        ({
          ...prev,
          lineItems: updateFn(prev?.lineItems ?? []),
        } as IInvoice)
    );
  };

  const focusElementById = (elementId: string) => {
    setTimeout(() => {
      const element = document.getElementById(elementId);
      element?.focus();
    }, 0);
  };

  const onLineItemAdded = (lineItem: Partial<LineItem>) => {
    console.log("lineItem", lineItem);
    const newLineItem: Partial<LineItem> = {
      description: lineItem.description ?? "",
      quantity: lineItem.quantity ?? 0,
      rate: lineItem.rate ?? 0,
      id: uuidv4(),
      discount: {
        amount: 0,
        type: discountType.PERCENTAGE,
      },
      type: LineItemType.MANUAL,
      ...lineItem,
    };
    const elementId = `${newLineItem.id}-${Object.keys(lineItem)[0]}`;
    updateLineItems((lineItems) => lineItems.concat(newLineItem as LineItem));
    focusElementById(elementId);
  };

  const onLineItemEdit = (lineItem: LineItem) => {
    console.log("lineItem edit", lineItem);
    const index = invoiceData?.lineItems.findIndex(
      (li) => li.id === lineItem.id
    );
    if (index === -1 || index === undefined) return;
    updateLineItems((lineItems) => [
      ...lineItems.slice(0, index),
      {
        ...lineItem,
        total: getLineItemTotal(
          lineItem,
          selectedLocation?.preferences?.clubPaysFees ?? false
        ),
      },
      ...lineItems.slice(index + 1),
    ]);
  };

  const onDelete = (lineItem: LineItem) => {
    const index = invoiceData?.lineItems.findIndex(
      (li) => li.id === lineItem.id
    );
    if (index === -1 || index === undefined) return;
    updateLineItems((lineItems) => [
      ...lineItems.slice(0, index),
      ...lineItems.slice(index + 1),
    ]);
  };

  const summary = useMemo(
    () => calculateInvoiceSummary(invoiceData),
    [invoiceData?.lineItems]
  );

  return (
    <DashboardLayout>
      <>
        <DashboardNavbar />

        <SoftCard
          styles={{
            flexDirection: "row",
            alignItems: "center",
            fontWeight: 600,
            marginTop: "16px",
            marginBottom: "12px",
          }}
        >
          <ChevronLeft sx={{ cursor: "pointer" }} onClick={goBack} /> Invoices
        </SoftCard>

        <SoftLoader
          sx={{ margin: "auto", width: "100%" }}
          isLoading={
            !invoiceData ||
            !loadedInvoiceData ||
            (hasQuickbooks && (isQbkItemLoading || isQbkClassLoading))
          }
        >
          <SoftCard styles={{ fontSize: "16px", marginTop: 0 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <SoftBox
                sx={{
                  display: "inline-flex",
                  gap: "8px",
                  alignItems: "center",
                }}
              >
                Invoice{" "}
                <SoftTypography color="primary">
                  {invoiceData.id}
                </SoftTypography>
                {invoiceData.status && (
                  <InvoiceStatusIndicator status={invoiceData.status} />
                )}
              </SoftBox>

              {selectedClub.logoUrl ? (
                <img src={selectedClub.logoUrl} alt="Logo" width="102px" />
              ) : (
                <SoftBox mb={2}>
                  <input
                    type="file"
                    accept="image/*"
                    style={{ display: "none" }}
                    id="file-input"
                    onChange={fnHandleFileChange}
                  />
                  <label htmlFor="file-input">
                    <SoftButton
                      variant="contained"
                      component="span"
                      disabled={isUploadingFile}
                      sx={{
                        padding: "8px 16px 8px 16px",
                        borderRadius: "200px",
                        border: "1.5px solid #414041",
                        textTransform: "none",
                      }}
                      startIcon={<Add />}
                    >
                      Add logo /Max 1mb
                    </SoftButton>
                  </label>
                </SoftBox>
              )}
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={{
                backgroundColor: "rgba(234, 243, 245, 1)",
                padding: "22px",
                margin: "16px -26px",
                fontSize: "12px",
              }}
            >
              <SoftBox
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                Due date:{" "}
                <b>
                  {invoiceData?.dueDate &&
                    format(invoiceData.dueDate, "EEE, MMM do yyyy")}
                </b>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={invoiceData?.dueDate}
                    onChange={(newValue) =>
                      newValue &&
                      setInvoiceData(
                        (prev) => ({ ...prev, dueDate: newValue } as IInvoice)
                      )
                    }
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <input
                          ref={inputRef}
                          {...inputProps}
                          style={{ visibility: "hidden", width: 0 }}
                        />
                        {InputProps?.endAdornment}
                      </Box>
                    )}
                  />
                </LocalizationProvider>
              </SoftBox>

              <SoftBox
                sx={{
                  display: "inline-flex",
                  alignItems: "center",
                }}
              >
                Date:{" "}
                <b>
                  {invoiceData?.createdAt &&
                    format(invoiceData.createdAt, "EEE, MMM do yyyy")}
                </b>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    value={invoiceData?.createdAt}
                    onChange={(newValue) =>
                      newValue &&
                      setInvoiceData(
                        (prev) =>
                          ({
                            ...prev,
                            createdAt: newValue,
                          } as IInvoice)
                      )
                    }
                    renderInput={({ inputRef, inputProps, InputProps }) => (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <input
                          ref={inputRef}
                          {...inputProps}
                          style={{ visibility: "hidden", width: 0 }}
                        />
                        {InputProps?.endAdornment}
                      </Box>
                    )}
                  />
                </LocalizationProvider>
              </SoftBox>
            </Stack>
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              sx={{ marginTop: "12px" }}
            >
              <Grid item>
                <SoftTypography sx={fontTitle}>
                  {selectedClub.name}
                </SoftTypography>
                <SoftTypography sx={{ fontSize: "14px" }}>
                  {selectedLocation.address.addressLine1}
                </SoftTypography>
                <SoftTypography sx={{ fontSize: "14px" }}>
                  {selectedLocation.address.city},{" "}
                  {selectedLocation.address.state}{" "}
                  {selectedLocation.address.zip}
                </SoftTypography>
                <SoftTypography sx={{ fontSize: "14px" }}>
                  {formatPhoneNumber(selectedClub.number)}
                </SoftTypography>
              </Grid>
              <Grid item>
                <SoftTypography sx={fontTitle}>Billed to</SoftTypography>
                <SoftSelect
                  options={
                    Array.from(clubUsers?.values?.() ?? []).map(
                      (cUser: IUser) => ({
                        label: cUser.displayName,
                        value: cUser.uid,
                      })
                    ) ?? []
                  }
                  value={
                    invoiceData?.member
                      ? {
                          label: invoiceData?.member.displayName,
                          value: invoiceData?.member.uid,
                        }
                      : null
                  }
                  placeholder="Select User"
                  onChange={(e) => {
                    const user = clubUsers.get(e.value);
                    setInvoiceData(
                      (prev) =>
                        ({
                          ...prev,
                          memberId: user?.uid,
                          member: user,
                        } as IInvoice)
                    );
                  }}
                />
                <Stack direction="row" gap="8px">
                  <SoftTypography
                    sx={{
                      fontSize: "14px",
                      marginTop: "8px",
                      padding: "8px 12px",
                      borderRadius: "200px",
                    }}
                  >
                    Email:
                  </SoftTypography>
                  <SoftTypography
                    sx={{
                      fontSize: "14px",
                      marginTop: "8px",
                      backgroundColor: "#F7F7F8",
                      padding: "8px 12px",
                      borderRadius: "200px",
                    }}
                  >
                    {invoiceData?.member?.email ??
                      clubUsers.get(invoiceData?.memberId ?? "")?.email}
                  </SoftTypography>
                </Stack>
              </Grid>
            </Grid>
            <DividerInvoice />
            <SoftTypography sx={fontTitle} mb="12px">
              Items
            </SoftTypography>

            <table className={classes.EditInvoice}>
              <thead>
                <tr>
                  <th style={{ width: "20px" }} />
                  <th style={{ width: "20px" }}>#</th>
                  {hasQuickbooks && <th style={{ width: "200px" }}>Item</th>}
                  <th>Description</th>
                  {hasQuickbooks && <th style={{ width: "200px" }}>Class</th>}
                  <th style={{ width: "70px" }}>Qty</th>
                  <th style={{ width: "100px" }}>Rate </th>
                  <th style={{ width: "90px" }}>Discount</th>
                  <th style={{ width: "100px" }}>Amount</th>
                  <th style={{ width: "20px" }}>Tax</th>
                  <th style={{ width: "20px" }} />
                </tr>
              </thead>
              <Reorder.Group
                as="tbody"
                axis="y"
                style={{ listStyle: "none" }}
                onReorder={(items) =>
                  setInvoiceData(
                    (prev) => ({ ...prev, lineItems: items } as any)
                  )
                }
                values={invoiceData?.lineItems ?? []}
              >
                {invoiceData.lineItems?.map((item, i) => (
                  <InvoiceLineItem
                    key={item.id}
                    lineItem={item}
                    order={i + 1}
                    onDelete={onDelete}
                    onLineItemEdit={onLineItemEdit}
                    hasQuickbooks={hasQuickbooks}
                    quickbooksItems={quickbooksItems ?? []}
                    quickbooksClasses={quickbooksClasses ?? []}
                  />
                ))}
                <NewLineItem
                  onLineItemAdded={onLineItemAdded}
                  hasQuickbooks={hasQuickbooks}
                  quickbooksItems={quickbooksItems ?? []}
                  quickbooksClasses={quickbooksClasses ?? []}
                />
              </Reorder.Group>
            </table>

            <DividerInvoice />
            <Stack direction="column" ml="auto" mb={1}>
              <Stack direction="row" justifyContent="flex-end" gap="8px">
                <SoftTypography fontWeight="bold">
                  Tax ({selectedLocation.preferences?.defaultTaxRate || 0}%):
                </SoftTypography>
                <SoftTypography>
                  {formatCurrency(summary.tax, true)}
                </SoftTypography>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" gap="8px">
                <SoftTypography fontWeight="bold">Fees:</SoftTypography>
                <SoftTypography>
                  {formatCurrency(summary.fees, true)}
                </SoftTypography>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" gap="8px">
                <SoftTypography fontWeight="bold">Discount:</SoftTypography>
                <SoftTypography>
                  {formatCurrency(summary.discount, true)}
                </SoftTypography>
              </Stack>
              <Stack direction="row" justifyContent="flex-end" gap="8px">
                <SoftTypography fontWeight="bold">Total:</SoftTypography>
                <SoftTypography fontWeight="bold">
                  {formatCurrency(summary.total, true)}
                </SoftTypography>
              </Stack>
            </Stack>

            {invoiceData?.notes && (
              <>
                <DividerInvoice />

                <SoftTypography>Notes</SoftTypography>
                <SoftBox
                  sx={{
                    padding: "16px",
                    borderRadius: "20px",
                    backgroundColor: "#F7F7F8",
                    maxWidth: "372px",
                    maxHeight: "90px",
                    overflowY: "auto",
                  }}
                >
                  {invoiceData.notes}
                </SoftBox>
              </>
            )}
            <DividerInvoice />
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="stretch"
              py={2}
            >
              <Grid item>
                <SoftButton variant="outlined" color="error" onClick={goBack}>
                  Cancel
                </SoftButton>
              </Grid>
              <Grid item>
                <Stack direction="row" gap="20px">
                  <SoftButton
                    disabled={isLoadingUpdate || isLoadingCreate}
                    onClick={saveInvoice}
                  >
                    <Check /> Save
                  </SoftButton>
                  <SoftButton
                    color="primary"
                    disabled={isLoadingUpdate || isLoadingCreate}
                    onClick={saveAndSendInvoice}
                  >
                    <DomainVerificationOutlined /> Save & Send
                  </SoftButton>
                </Stack>
              </Grid>
            </Grid>
          </SoftCard>
        </SoftLoader>
      </>
    </DashboardLayout>
  );
}
export default InvoiceEdit;

const fontTitle = {
  fontWeight: 600,
  fontSize: "16px",
};
