// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import {
  CourseCard,
  CourseCardFooter,
  CourseCardHeader,
  CourseCardTitle,
} from "src/pages/lms/components";

//utils
import { getAccentColor, getCourseStatus } from "src/pages/lms/utils";
import { useMemo } from "react";
import SoftBadge from "src/components/SoftBadge";

function ClassroomCourseCard({
  enrolledCourse,
  onCardClick = () => {},
  showStudent = false,
}) {
  const {
    id: enrolledCourseId,
    member,
    progress,
    enrollmentId,
  } = enrolledCourse;

  const status = useMemo(() => {
    const text = getCourseStatus(progress);
    return {
      text,
      color: getAccentColor(text),
    };
  }, [enrolledCourse]);

  return (
    <CourseCard
      onClick={() => onCardClick(member?.value, enrollmentId, enrolledCourseId)}
      courseId={enrolledCourseId}
    >
      <CourseCardHeader
        createdAt={enrolledCourse.createdAt}
        title={showStudent ? enrolledCourse.title : ""}
      >
        {enrolledCourse?.isPart141 ? (
          <SoftBadge badgeContent="p141" size="sm" color="secondary" />
        ) : null}
      </CourseCardHeader>
      <CourseCardTitle color={status.color}>
        {showStudent ? member.label : enrolledCourse.title}
      </CourseCardTitle>
      <CourseCardFooter
        currentLesson={progress.lessons.completed}
        totalLessons={progress.lessons.total}
        status={status}
      />
    </CourseCard>
  );
}

// Typechecking props for the CourseCard
ClassroomCourseCard.propTypes = {
  enrolledCourse: PropTypes.object.isRequired,
  onCardClick: PropTypes.func,
  showStudent: PropTypes.bool,
};

export default ClassroomCourseCard;
