import { useState, useRef } from "react";
import {
  Card,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftEditor from "src/components/SoftEditor";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubCoursesCollection } from "src/features/lms/collections";
import { useNavigate } from "react-router-dom";

const schema = yup
  .object({
    title: yup.string().required("A lesson title is required."),
  })
  .required();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
};

export function AddCourseModal({ handleClose, courseData }) {
  const { selectedClubId } = useClubs();
  const navigate = useNavigate();
  const { addData, updateData } = entityCrudUtils();

  const editorRefDescription = useRef(null);
  const editorRefObjective = useRef(null);
  const editorRefCompletion = useRef(null);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: courseData?.title,
      isPart141: courseData?.isPart141 || false,
    },
    resolver: yupResolver(schema),
  });

const [isPart141, setIsPart141] = useState(courseData?.isPart141 || false);
const handleChangeIsPart141 = (e) => setIsPart141(e.target.value);

  const onSubmit = async (data) => {
    if (courseData?.id) {
      await updateData(
        {
          entity: getClubCoursesCollection(selectedClubId),
          pathSegmentsArr: [courseData.id],
        },
        {
          ...courseData,
          ...data,
          description: editorRefDescription.current.getContent(),
          descriptionText: editorRefDescription.current.getContent({
            format: "text",
          }),
          completionStandards: editorRefCompletion.current.getContent(),
          completionStandardsText: editorRefCompletion.current.getContent({
            format: "text",
          }),
          objective: editorRefObjective.current.getContent(),
          objectiveText: editorRefObjective.current.getContent({
            format: "text",
          }),
        },
        courseData.id
      );
      handleClose();
    } else {
      const newCourse = await addData(
        getClubCoursesCollection(selectedClubId),
        {
          ...data,
          description: editorRefDescription.current.getContent(),
          descriptionText: editorRefDescription.current.getContent({
            format: "text",
          }),
          completionStandards: editorRefCompletion.current.getContent(),
          completionStandardsText: editorRefCompletion.current.getContent({
            format: "text",
          }),
          objective: editorRefObjective.current.getContent(),
          objectiveText: editorRefObjective.current.getContent({
            format: "text",
          }),
          createdAt: new Date(),
        }
      );

      newCourse && navigate(`/lms/courses/${newCourse.id}`);
      handleClose();
    }
  };

  return (
    <Card sx={style}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {courseData?.id ? "Edit" : "Add New"} Course
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Title
                  </SoftTypography>
                </SoftBox>
                <SoftBox pl={2} pt={2}>
                  <SoftInput
                    {...register("title", {
                      required: true,
                    })}
                    placeholder="Eg. Private Pilot - Part 141"
                  />
                  {errors?.title?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.title.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Description
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "100%" }}>
                  <SoftEditor
                    onInit={(_evt, editor) =>
                      (editorRefDescription.current = editor)
                    }
                    initialValue={courseData?.description}
                    fileStoagePath={`clubs/${selectedClubId}/courses`}
                    init={{
                      height: 250,
                    }}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Objective
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "100%" }}>
                  <SoftEditor
                    onInit={(_evt, editor) =>
                      (editorRefObjective.current = editor)
                    }
                    initialValue={courseData?.objective}
                    fileStoagePath={`clubs/${selectedClubId}/courses`}
                    init={{
                      height: 250,
                    }}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Completion Standards
                  </SoftTypography>
                </SoftBox>
                <SoftBox p={2} sx={{ height: "100%" }}>
                  <SoftEditor
                    onInit={(_evt, editor) =>
                      (editorRefCompletion.current = editor)
                    }
                    initialValue={courseData?.completionStandards}
                    fileStoagePath={`clubs/${selectedClubId}/courses`}
                    init={{
                      height: 250,
                    }}
                  />
                </SoftBox>
              </Grid>
              <Grid item xs={12} mt={2}>
                <SoftBox
                  ml={0.5}
                  lineHeight={0}
                  display="flex"
                  alignItems="center"
                  gap={1.5}
                >
                  <SoftTypography
                    component="label"
                    fontSize="small"
                    fontWeight="medium"
                    sx={{ mr: 2 }}
                  >
                    Is this course part 141?
                  </SoftTypography>
                  <FormControl>
                    <RadioGroup
                      row
                      value={isPart141}
                      onChange={handleChangeIsPart141}
                    >
                      <FormControlLabel
                        value={false}
                        control={<Radio />}
                        label={
                          <SoftTypography fontSize="small" fontWeight="regular">
                            No
                          </SoftTypography>
                        }
                      />
                      <FormControlLabel
                        value={true}
                        control={<Radio />}
                        label={
                          <SoftTypography fontSize="small" fontWeight="regular">
                            Yes
                          </SoftTypography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {courseData?.id ? "Update Course" : "Add Course"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

AddCourseModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  courseData: PropTypes.object,
};
