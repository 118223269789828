import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "src/assets/theme/base/colors";
import {
  HeaderCell,
  HeaderCellText,
} from "src/components/Tables/DragAndDrop/HeaderCell";
import Grade from "src/pages/lms/components/Grade";

import SoftBox from "src/components/SoftBox";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "@mui/material";
import SoftTypography from "src/components/SoftTypography";
import { ExpandMore } from "@mui/icons-material";

const { grey, light } = colors;

function Row({ task }) {
  return (
    <>
      <TableRow>
        <TableCell>
          <Grade value={task.grade?.grade} />
        </TableCell>
        <TableCell>
          <SoftTypography fontSize="small" fontWeight="regular">
            {task?.title}
          </SoftTypography>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  task: PropTypes.object.isRequired,
};

export default function TaskTable({ tasks, taskListId }) {
  if (!taskListId) {
    return null;
  }

  return (
    <Accordion elevation={0} sx={{ border: 1, borderColor: light.main }}>
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{
          background: "#E9ecef",
        }}
      >
        <SoftTypography variant="h6" fontWeight="medium">
          Lesson Tasks
        </SoftTypography>
      </AccordionSummary>
      <AccordionDetails>
        <SoftBox borderRadius="md">
          <TableContainer
            sx={{
              maxHeight: "100%",
              boxShadow: "none",
              borderRadius: "0",
              marginTop: 1,
              overflowY: "scroll",
            }}
          >
            <Table
              stickyHeader
              aria-label="collapsible table"
              style={{ borderSpacing: "0 0.2rem" }}
            >
              <TableHead>
                {tasks?.length ? (
                  <TableRow
                    sx={{
                      "& > *": { backgroundColor: "white!important" },
                    }}
                  >
                    <HeaderCell>
                      <HeaderCellText>Grade</HeaderCellText>
                    </HeaderCell>
                    <HeaderCell>
                      <HeaderCellText>Task</HeaderCellText>
                    </HeaderCell>
                  </TableRow>
                ) : (
                  <TableRow
                    sx={{
                      "& > *": { backgroundColor: "white!important" },
                    }}
                  >
                    <HeaderCell>
                      <HeaderCellText>
                        <i>No Tasks</i>
                      </HeaderCellText>
                    </HeaderCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {tasks?.map((task) => <Row task={task} key={task.id} />) ?? (
                  <TableRow>
                    <TableCell sx={{ color: grey[500] }}>
                      <i>No Tasks Added</i>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </SoftBox>
      </AccordionDetails>
    </Accordion>
  );
}

TaskTable.propTypes = {
  tasks: PropTypes.array.isRequired,
  taskListId: PropTypes.string.isRequired,
};
