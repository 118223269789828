import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

function GradingScale({ gradingScale }) {
  return (
    <SoftBox
      sx={{
        border: "1px solid #e0e0e0",
        borderRadius: "5px",
        padding: "10px",
        width: "fit-content",
      }}
    >
      <SoftTypography
        variant="caption"
        textTransform="capitalize"
        fontWeight="bold"
        mb={2}
      >
        {gradingScale?.label}
      </SoftTypography>
    </SoftBox>
  );
}

GradingScale.propTypes = {
  gradingScale: PropTypes.object,
};

export default GradingScale;
