import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";

import { formatCurrency, toTitleCase } from "src/features/utils";

function GenericPaymentDetails({ payment }) {
  const { borderWidth, borderColor } = borders;

  console.log("DATA", payment);

  const renderPayments = () => (
    <>
      <SoftTypography variant="h6" fontWeight="medium">
        Payment Details
      </SoftTypography>
      {payment?.paymentData?.map((paymentData) => (
        <SoftBox
          border={`${borderWidth[1]} solid ${borderColor}`}
          borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          key={paymentData.id}
          p={2}
          mt={2}
        >
          <SoftBox display="flex" flexDirection="column" alignItems="start">
            <SoftTypography
              display="block"
              style={{ marginBottom: "5px" }}
              variant="caption"
              fontWeight="medium"
            >
              {toTitleCase(payment.type.split("_")[0])} Payment:{" "}
              {formatCurrency(paymentData.amount / 100)}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ))}
    </>
  );

  return renderPayments();
}

GenericPaymentDetails.defaultProps = {
  payment: PropTypes.object.isRequired,
};

export default GenericPaymentDetails;
