import { useEffect, useState } from "react";

// react-router-dom components
import { useLocation, NavLink } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Link from "@mui/material/Link";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

// Soft UI Dashboard PRO React example components
import SidenavCollapse from "./SidenavCollapse";
import SidenavList from "./SidenavList";
import SidenavItem from "./SidenavItem";

// Custom styles for the Sidenav
import SidenavRoot from "./SidenavRoot";
import sidenavLogoLabel from "./styles/sidenav";

import { collapseLogo } from "./styles/sidenavCollapse";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setMiniSidenav } from "src/context";
import SidenavClubSelect from "./SidenavClubSelect";
import SidenavUserProfile from "./SidenavUserProfile";
import SidenavAccountBalance from "./SidenavAccountBalance";
import SidenavCheckInOut from "./SidenavCheckInOut";

import { useClubs } from "src/features/club/ClubProvider";
import { useLms } from "src/features/lms/LmsProvider";
import { usePermissions } from "src/hooks/usePermissions";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";

function Sidenav({ brandIcon = "", brand = "", brandName, routes, ...rest }) {
  const [openCollapse, setOpenCollapse] = useState(false);
  const [openNestedCollapse, setOpenNestedCollapse] = useState(false);
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav, transparentSidenav } = controller;
  const location = useLocation();
  const { pathname } = location;
  const collapseName = pathname.split("/").slice(1)[0];
  const itemName = pathname.split("/").slice(1)[1];
  const { memberHasNoClub, selectedClub } = useClubs();
  const { isStudent } = useLms();
  const [savedPathname, setSavedPathname] = useState(pathname);
  const { hasAccess } = usePermissions();

  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobileSize = width < 768;

  const closeSidenav = () => setMiniSidenav(dispatch, true);

  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    if (pathname !== savedPathname) {
      setSavedPathname(pathname);
      setMiniSidenav(dispatch, window.innerWidth < 1200);
    }
    function handleMiniSidenav() {
      if (width !== window.innerWidth) {
        setMiniSidenav(dispatch, window.innerWidth < 1200);
      }
    }

    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);

    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
  }, [dispatch, location]);

  // Render all the nested collapse items from the routes.js
  const renderNestedCollapse = (collapse) => {
    const template = collapse.map(({ name, route, key, href }) =>
      href ? (
        <Link
          key={key}
          href={href}
          target="_blank"
          rel="noreferrer"
          sx={{
            textDecoration: "none",
          }}
        >
          <SidenavItem name={name} nested />
        </Link>
      ) : (
        <NavLink
          to={route}
          key={key}
          sx={{
            textDecoration: "none",
          }}
        >
          <SidenavItem name={name} active={route === pathname} nested />
        </NavLink>
      )
    );

    return template;
  };

  // Render the all the collpases from the routes.js
  const renderCollapse = (collapses) =>
    collapses
      .map(({ name, permissionKey, collapse, route, href, key }) => {
        let returnValue;

        if (!permissionKey || hasAccess([permissionKey])) {
          if (collapse) {
            returnValue = (
              <SidenavItem
                key={key}
                name={name}
                active={key === itemName}
                open={openNestedCollapse === name}
                onClick={() =>
                  openNestedCollapse === name
                    ? setOpenNestedCollapse(false)
                    : setOpenNestedCollapse(name)
                }
              >
                {renderNestedCollapse(collapse)}
              </SidenavItem>
            );
          } else {
            returnValue = href ? (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{
                  textDecoration: "none",
                }}
              >
                <SidenavItem name={name} active={key === itemName} />
              </Link>
            ) : (
              <NavLink
                to={route}
                key={key}
                sx={{
                  textDecoration: "none",
                }}
              >
                <SidenavItem name={name} active={key === itemName} />
              </NavLink>
            );
          }
          return <SidenavList key={key}>{returnValue}</SidenavList>;
        }
        return null;
      })
      .filter((n) => n);

  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map(
    ({
      type,
      name,
      permissionKey,
      icon,
      title,
      collapse,
      noCollapse,
      key,
      href,
      route,
    }) => {
      let returnValue;

      if (name === "Training" && !isStudent) {
        return null;
      }

      let collapseItems = null;
      if (collapse) {
        collapseItems = renderCollapse(collapse);
      }

      if (!permissionKey || hasAccess([permissionKey])) {
        if (type === "collapse") {
          if (href) {
            returnValue = (
              <Link
                href={href}
                key={key}
                target="_blank"
                rel="noreferrer"
                sx={{
                  textDecoration: "none",
                }}
              >
                <SidenavCollapse
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  noCollapse={noCollapse}
                />
              </Link>
            );
          } else if (noCollapse && route) {
            if (collapseItems?.length > 0 || !collapse) {
              returnValue = (
                <NavLink to={route} key={key}>
                  <SidenavCollapse
                    name={name}
                    icon={icon}
                    noCollapse={noCollapse}
                    active={key === collapseName}
                  >
                    {collapseItems}
                  </SidenavCollapse>
                </NavLink>
              );
            }
          } else {
            if (collapseItems?.length > 0 || !collapse) {
              returnValue = (
                <SidenavCollapse
                  key={key}
                  name={name}
                  icon={icon}
                  active={key === collapseName}
                  open={openCollapse === key}
                  onClick={() =>
                    openCollapse === key
                      ? setOpenCollapse(false)
                      : setOpenCollapse(key)
                  }
                >
                  {collapseItems}
                </SidenavCollapse>
              );
            }
          }
        } else if (type === "title") {
          returnValue = (
            <SoftTypography
              key={key}
              display="block"
              variant="caption"
              fontWeight="bold"
              textTransform="uppercase"
              opacity={0.6}
              pl={3}
              mt={2}
              mb={1}
              ml={1}
            >
              {title}
            </SoftTypography>
          );
        } else if (type === "divider") {
          returnValue = <Divider key={key} />;
        }
      }

      return returnValue;
    }
  );

  return (
    <SidenavRoot
      {...rest}
      variant="permanent"
      ownerState={{
        transparentSidenav,
        miniSidenav,
      }}
    >
      <SoftBox
        sx={{
          position: "sticky",
          top: 0,
          zIndex: 999,
          backgroundColor: "#f8f9fa",
        }}
      >
        <SoftBox pt={1} pb={1} px={4} textAlign="center">
          <SoftBox
            display={{
              xs: "block",
              xl: "none",
            }}
            position="absolute"
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{
              cursor: "pointer",
            }}
          >
            <SoftTypography variant="h6" color="secondary">
              <Icon
                sx={{
                  fontWeight: "bold",
                }}
              >
                close
              </Icon>
            </SoftTypography>
          </SoftBox>
          <SoftBox
            component={NavLink}
            to="/"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {selectedClub?.logoUrl && !miniSidenav && (
              <SoftBox
                sx={(theme) =>
                  collapseLogo(theme, {
                    miniSidenav,
                  })
                }
                component="img"
                ml={0}
                mb={1}
                src={selectedClub.logoUrl}
                alt="Client Logo"
                width="8rem"
              />
            )}
            {!brandName && brand && miniSidenav && (
              <SoftBox
                component="img"
                src={selectedClub.logoUrl}
                alt="PreFlight Logo"
                width="3rem"
              />
            )}
          </SoftBox>
          <Divider />
          <SoftBox
            component={NavLink}
            to="/"
            display="flex"
            alignItems="center"
          >
            {brandName && brand && (
              <SoftBox
                component="img"
                src={brand}
                alt="PreFlight Logo"
                width="2rem"
              />
            )}
            {!brandName && brand && !miniSidenav && (
              <SoftBox
                sx={(theme) =>
                  collapseLogo(theme, {
                    miniSidenav,
                  })
                }
                component="img"
                ml={0}
                src={brand}
                alt="PreFlight Logo"
                width="11rem"
              />
            )}
            {!brandName && brand && miniSidenav && (
              <SoftBox
                component="img"
                src={brandIcon}
                alt="PreFlight Logo"
                width="3rem"
              />
            )}
            {brandName && (
              <SoftBox
                width={!brandName && "100%"}
                sx={(theme) =>
                  sidenavLogoLabel(theme, {
                    miniSidenav,
                  })
                }
              >
                <SoftTypography
                  component="h6"
                  variant="button"
                  fontWeight="medium"
                >
                  {brandName}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        </SoftBox>
        <Divider style={{ marginBottom: 0 }} />
      </SoftBox>
      <SoftBox
        mt={2}
        sx={{
          position: "sticky",
          top: selectedClub?.logoUrl ? 245 : 35,
          zIndex: 998,
          backgroundColor: "#f8f9fa",
        }}
      >
        <SidenavUserProfile />
        {isMobileSize && <SidenavClubSelect />}
      </SoftBox>
      <SoftBox
        mt={2}
        sx={{
          position: "sticky",
          top: selectedClub?.logoUrl ? 225 : 104,
          zIndex: 998,
          backgroundColor: "#f8f9fa",
        }}
      >
        <WithPermissions permissions={systemPermissions.VIEW_OWN_BALANCE}>
          <Divider style={{ marginBottom: 0, marginTop: 0 }} />
          <SidenavAccountBalance />
        </WithPermissions>
        {!memberHasNoClub && <SidenavCheckInOut />}
        <WithPermissions permissions={["PREFLIGHT_ADMIN"]}>
          <Divider />
        </WithPermissions>
      </SoftBox>
      <List style={{ zIndex: 998, backgroundColor: "#f8f9fa" }}>
        {renderRoutes}
      </List>
      <SoftBox pt={2} my={2} mx={2}>
        {/* <SidenavCard /> */}
      </SoftBox>
    </SidenavRoot>
  );
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandIcon: PropTypes.string,
  brandName: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;
