import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import colors from "src/assets/theme/base/colors";
import borders from "src/assets/theme/base/borders";
import { iconButtonStyles } from "src/components/Tables/DragAndDrop/iconButtonStyles";
import {
  HeaderCell,
  HeaderCellText,
} from "src/components/Tables/DragAndDrop/HeaderCell";
import Grade from "src/pages/lms/components/Grade";

import { useMemo, useState } from "react";
import SoftBox from "src/components/SoftBox";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import SoftTypography from "src/components/SoftTypography";
import { ExpandMore } from "@mui/icons-material";
import SoftSelect from "src/components/SoftSelect";
import SoftGroupedOption from "src/components/SoftGroupedOption";

const { grey } = colors;

function Row({ task, handleDeleteTask }) {
  return (
    <>
      <TableRow>
        <TableCell>
          <Grade value={task.grade?.grade} />
        </TableCell>
        <TableCell>
          <SoftTypography fontSize="small" fontWeight="regular">
            {task?.label}
          </SoftTypography>
        </TableCell>
        <TableCell align="right" sx={{ pr: 3 }}>
          <IconButton
            aria-label="delete"
            sx={iconButtonStyles}
            onClick={() => handleDeleteTask(task)}
          >
            <Icon>close</Icon>
          </IconButton>
        </TableCell>
      </TableRow>
    </>
  );
}

Row.propTypes = {
  task: PropTypes.object.isRequired,
  handleDeleteTask: PropTypes.func.isRequired,
};

export default function TaskTable({ tasks, options, onChange }) {
  const [selectedTask, setSelectedTask] = useState(null);

  const taskIds = useMemo(() => tasks.map((task) => task.value), [tasks]);
  const filteredOptions = useMemo(
    () => options.filter((option) => !taskIds.includes(option.value)),
    [options, taskIds]
  );

  const handleDeleteTask = (task) => {
    onChange(tasks.filter((t) => task.value !== t.value));
  };

  const handleSelectTask = (task) => {
    setSelectedTask(task);
    onChange([...tasks, task]);
    setSelectedTask(null);
  };

  if (!tasks) {
    return null;
  }

  return (
    <Accordion
      elevation={0}
      sx={{
        border: 1,
        borderColor: grey[400],
        borderRadius: borders.borderRadius.sm,
      }}
    >
      <AccordionSummary display="flex" expandIcon={<ExpandMore />}>
        <SoftTypography fontSize="small" fontWeight="medium">
          Select Tasks
        </SoftTypography>
      </AccordionSummary>
      <AccordionDetails sx={{ pt: 0 }}>
        <SoftBox>
          <SoftSelect
            options={filteredOptions}
            components={{ Option: SoftGroupedOption }}
            onChange={handleSelectTask}
            value={selectedTask}
            placeholder="Search for tasks..."
          />
          <TableContainer
            sx={{
              maxHeight: "100%",
              boxShadow: "none",
              borderRadius: "0",
              overflowY: "scroll",
            }}
          >
            <Table
              stickyHeader
              aria-label="collapsible table"
              style={{ borderSpacing: "0 0.2rem" }}
            >
              <TableHead>
                {tasks.length ? (
                  <TableRow
                    sx={{
                      "& > *": { backgroundColor: "white!important" },
                    }}
                  >
                    <HeaderCell>
                      <HeaderCellText>Grade</HeaderCellText>
                    </HeaderCell>
                    <HeaderCell>
                      <HeaderCellText>Task</HeaderCellText>
                    </HeaderCell>
                    <HeaderCell alignRight>
                      <HeaderCellText alignRight>Actions</HeaderCellText>
                    </HeaderCell>
                  </TableRow>
                ) : (
                  <TableRow
                    sx={{
                      "& > *": { backgroundColor: "white!important" },
                    }}
                  >
                    <HeaderCell>
                      <HeaderCellText>
                        <i>No Tasks</i>
                      </HeaderCellText>
                    </HeaderCell>
                  </TableRow>
                )}
              </TableHead>
              <TableBody>
                {tasks?.map((task) => (
                  <Row
                    task={task}
                    key={task.value}
                    handleDeleteTask={handleDeleteTask}
                  />
                )) ?? (
                  <TableRow>
                    <TableCell sx={{ color: grey[500] }}>
                      <i>No Tasks Added</i>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </SoftBox>
      </AccordionDetails>
    </Accordion>
  );
}

TaskTable.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired,
};
