export enum Currency {
  USD = "USD",
  EUR = "EUR",
}

export const DEFAULT_CURRENCY: Currency = Currency.USD;

/**
 * Represents an array where both the array itself and each of its elements are immutable.
 * Once created, the elements of this array cannot be modified, added, or removed.
 *
 * @typeparam T - The type of elements contained in the array. Each element is treated as `Readonly`.
 */
export type ImmutableArray<T> = ReadonlyArray<Readonly<T>>;
