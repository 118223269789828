import { IPermission } from "./permission.interface";
export enum userManagementRoleCategory {
  MEMBERS = "MEMBERS",
}

export enum userManagementPermissionTypes {
  VIEW_MEMBERS = "VIEW_MEMBERS",
  VIEW_INACTIVE_MEMBERS = "VIEW_INACTIVE_MEMBERS",
  ADD_MEMBERS = "ADD_MEMBERS",
  DELETE_MEMBERS = "DELETE_MEMBERS",
  EDIT_MEMBERS = "EDIT_MEMBERS",
  MANAGE_MEMBERSHPS = "MANAGE_MEMBERSHPS",
  EDIT_MEMBER_ROLES = "EDIT_MEMBER_ROLES",
  EDIT_MEMBER_BADGES = "EDIT_MEMBER_BADGES",
  VIEW_MEMBER_DETAILS = "VIEW_MEMBER_DETAILS",
  VIEW_MEMBER_DOCUMENTS = "VIEW_MEMBER_DOCUMENTS",
  UPLOAD_MEMBER_DOCUMENTS = "UPLOAD_MEMBER_DOCUMENTS",
  VIEW_AIRCRAFT_CHECKOUTS = "VIEW_AIRCRAFT_CHECKOUTS",
  GIVE_AIRCRAFT_CHECKOUTS = "GIVE_AIRCRAFT_CHECKOUTS",
  UPLOAD_OWN_DOCUMENTS = "UPLOAD_OWN_DOCUMENTS",
  DELETE_USER_DOCUMENTS = "DELETE_USER_DOCUMENTS",
}

export const userManagementPermissions: IPermission[] = [
  {
    group: userManagementRoleCategory.MEMBERS,
    label: "Members",
    tooltipGroup: "",
    permissions: {
      [userManagementPermissionTypes.VIEW_MEMBERS]: {
        label: "View All Members",
        tooltip:
          "Disabling this permission will hide the Members menu option from People menu",
      },
      [userManagementPermissionTypes.VIEW_MEMBER_DETAILS]: {
        label: "View Member Details",
        tooltip: "Allows users to select a member and view further details",
      },
      [userManagementPermissionTypes.VIEW_INACTIVE_MEMBERS]: {
        label: "View Inactive Members",
        tooltip: "",
      },
      [userManagementPermissionTypes.ADD_MEMBERS]: {
        label: "Add Members",
        tooltip: "",
      },
      [userManagementPermissionTypes.EDIT_MEMBERS]: {
        label: "Edit Members",
        tooltip: "",
      },
      [userManagementPermissionTypes.MANAGE_MEMBERSHPS]: {
        label: "Edit Memberships",
        tooltip: "",
      },
      [userManagementPermissionTypes.EDIT_MEMBER_ROLES]: {
        label: "Edit Member Roles",
        tooltip: "",
      },
      [userManagementPermissionTypes.EDIT_MEMBER_BADGES]: {
        label: "Edit Member Badges",
        tooltip: "",
      },
      [userManagementPermissionTypes.VIEW_AIRCRAFT_CHECKOUTS]: {
        label: "View Aircraft Checkouts",
        tooltip: "",
      },
      [userManagementPermissionTypes.GIVE_AIRCRAFT_CHECKOUTS]: {
        label: "Give Aircraft Checkouts",
        tooltip: "",
      },
      [userManagementPermissionTypes.VIEW_MEMBER_DOCUMENTS]: {
        label: "View/Download Member Documents",
        tooltip: "",
      },
      [userManagementPermissionTypes.UPLOAD_MEMBER_DOCUMENTS]: {
        label: "Upload Member Documents",
        tooltip: "",
      },
      [userManagementPermissionTypes.UPLOAD_OWN_DOCUMENTS]: {
        label: "Upload Own Documents",
        tooltip: "",
      },
      [userManagementPermissionTypes.DELETE_USER_DOCUMENTS]: {
        label: "Delete User Documents",
        tooltip: "",
      },
      [userManagementPermissionTypes.DELETE_MEMBERS]: {
        label: "Delete Members",
        tooltip: "",
      },
    },
  },
];
