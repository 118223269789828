import { useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import BasicInfo from "src/pages/user/account/settings/components/BasicInfo";

export function EditUserModal({ handleClose, memberId }) {
  const { userId } = useUser();
  const { clubUsers } = useClubs();
  const [user] = useState(clubUsers.get(memberId || userId));

  return (
    <ModalContainer handleClose={handleClose} padding={0} py={0}>
      <BasicInfo user={user} onClose={handleClose} />
    </ModalContainer>
  );
}

EditUserModal.propTypes = {
  handleClose: PropTypes.func,
  memberId: PropTypes.string,
};
