import * as toastr from "toastr";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftCurrencyInput from "src/components/SoftCurrencyInput";

// Settings page components
import StripeDetails from "./components/StripeDetails";

// Data
import { doc, updateDoc } from "firebase/firestore";
import { useClubs } from "src/features/club/ClubProvider";
import {
  // getClubsCollection,
  getClubLocationsCollection,
} from "src/features/club/collections";
import SoftCurrencyInputv2 from "src/components/SoftCurrencyInput/SoftCurrencyInputv2";
import SoftSelect from "src/components/SoftSelect";

function ClubBilling() {
  const { selectedClub, selectedClubId, selectedLocation } = useClubs();
  const { preferences } = selectedClub;
  const { preferences: locationPreferences = {} } = selectedLocation;

  // const updatePreference = async (key, value) => {
  //   preferences[key] = value;
  //   try {
  //     await updateDoc(doc(getClubsCollection(), selectedClubId), {
  //       preferences,
  //     });
  //     toastr.success("Your preferences have been updated.", "Success");
  //   } catch (e) {
  //     console.error(e);
  //     toastr.error("There was an error updating your preferences.", "Error");
  //   }
  // };

  const updateLocationPreference = async (key, value) => {
    locationPreferences[key] = value;
    try {
      await updateDoc(
        doc(getClubLocationsCollection(selectedClubId), selectedLocation.id),
        {
          preferences: locationPreferences,
        }
      );
      toastr.success("Your preferences have been updated.", "Success");
    } catch (e) {
      console.error(e);
      toastr.error("There was an error updating your preferences.", "Error");
    }
  };

  const toggleAutoBillEnabledByDefault = (event) => {
    updateLocationPreference("autoBillEnabledByDefault", event.target.checked);
  };
  const setAutoBillFrequency = (event) => {
    console.log(event.value);
    updateLocationPreference("deafultAutoBillFrequency", event);
  };

  const toggleClubPaysCCFees = (event) => {
    updateLocationPreference("clubPaysFees", event.target.checked);
  };
  const toggleClubPaysACHFees = (event) => {
    updateLocationPreference("clubPaysAchFees", event.target.checked);
  };

  const toggleCanPayOnAccount = (event) => {
    updateLocationPreference("canPayOnAccount", event.target.checked);
  };

  const toggleEnableSplitPayout = (event) => {
    updateLocationPreference("enableSplitPayout", event.target.checked);
  };

  const toggleClubPaysFeesOnAccount = (event) => {
    updateLocationPreference("clubPaysFeesOnAccount", event.target.checked);
  };

  const toggleDisableAddFunds = (event) => {
    updateLocationPreference("disableAddFunds", event.target.checked);
  };

  const toggleDisableCardPayments = (event) => {
    updateLocationPreference("disableCardPayments", event.target.checked);
  };

  const toggleDisableAircraftCheckoutFlightsPayment = (event) => {
    updateLocationPreference("disableCheckoutPayments", event.target.checked);
  };

  const toggleAllowInstructorsToModifyFees = (event) => {
    updateLocationPreference("instructorsModifyFees", event.target.checked);
  };

  const toggleAllowWithUnpaidInvoice = (event) => {
    updateLocationPreference("bookWithUnpaidInvoice", event.target.checked);
  };

  const toggleAllowWithPendingPayment = (event) => {
    updateLocationPreference("bookWithPendingPayment", event.target.checked);
  };

  const toggleRequireCardOnFile = (event) => {
    updateLocationPreference("requireCardOnFile", event.target.checked);
  };

  // const toggleEnableLineitemPayment = (event) => {
  //   updateLocationPreference("enableLineitemPayment", event.target.checked);
  // };

  const setDefaultInstructorHourlyRate = (valueInCents) => {
    updateLocationPreference("defaultInstructorHourlyRate", valueInCents);
  };

  const setFuelPrice = (valueInCents) => {
    updateLocationPreference("fuelPrice", valueInCents);
  };

  const setDefaultTaxRate = (event) => {
    updateLocationPreference("defaultTaxRate", +event.target.value ?? 0);
  };

  const toggleMaxHobbsTachHours = (event) => {
    updateLocationPreference("limitMaxHobbsTach", event.target.checked);
  };

  const toggleChargeConvenienceFee = (event) => {
    updateLocationPreference("chargeConvenienceFee", event.target.checked);
  };
  const setCreditCardConvenienceFee = (event) => {
    updateLocationPreference(
      "creditCardConvenienceFee",
      +event.target.value ?? 0
    );
  };
  const setAchConvenienceFee = (event) => {
    updateLocationPreference("achConvenienceFee", +event.target.value ?? 0);
  };
  const setAccountConvenienceFee = (event) => {
    updateLocationPreference("accountConvenienceFee", +event.target.value ?? 0);
  };

  const setMaxHobbsTach = (event) => {
    updateLocationPreference("maxHobbsTach", event.target.value);
  };

  return (
    <Card
      id="club-billing"
      sx={{
        overflow: "visible",
      }}
    >
      <SoftBox p={3}>
        <SoftTypography variant="h5">Billing</SoftTypography>
        <SoftTypography variant="button" fontWeight="regular" color="text">
          Update payout information and club wide billing preferences.
        </SoftTypography>
      </SoftBox>
      <SoftBox pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <StripeDetails />
          </Grid>
          <Grid item xs={12} md={6}>
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              height="100%"
            >
              <SoftBox pt={2} px={2}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Billing Settings
                </SoftTypography>
              </SoftBox>
              <SoftBox p={2}>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Enable Auto-Bill by default
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={preferences?.autoBillEnabledByDefault}
                      onChange={toggleAutoBillEnabledByDefault}
                    />
                  </SoftBox>
                </SoftBox>

                {preferences?.autoBillEnabledByDefault && (
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    lineHeight={1}
                  >
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Auto-Bill Frequency
                    </SoftTypography>
                    <SoftBox ml={2} mr={1}>
                      <SoftSelect
                        options={[
                          {
                            label: "End of Day",
                            value: "daily",
                          },
                          {
                            label: "End of Week",
                            value: "weekly",
                          },
                          {
                            label: "End of Month",
                            value: "monthly",
                          },
                        ]}
                        value={preferences?.deafultAutoBillFrequency}
                        onChange={(e) => setAutoBillFrequency(e)}
                        label="Select Frequency"
                      />
                    </SoftBox>
                  </SoftBox>
                )}

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Club/School covers fees for Credit Card payments
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.clubPaysFees}
                      onChange={toggleClubPaysCCFees}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Club/School covers fees for ACH payments
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.clubPaysAchFees}
                      onChange={toggleClubPaysACHFees}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Club/School covers fees for account payments
                    (Cash/Check/Finance)
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.clubPaysFeesOnAccount}
                      onChange={toggleClubPaysFeesOnAccount}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Enable Pay On Account
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.canPayOnAccount}
                      onChange={toggleCanPayOnAccount}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Disable Add Funds
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.disableAddFunds}
                      onChange={toggleDisableAddFunds}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Disable Card Payments
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.disableCardPayments}
                      onChange={toggleDisableCardPayments}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Disable payments for aircraft checkout flights
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.disableCheckoutPayments}
                      onChange={toggleDisableAircraftCheckoutFlightsPayment}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Enable split payouts for Instructors
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.enableSplitPayout}
                      onChange={toggleEnableSplitPayout}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Allow Instructors to adjust their own hourly rate
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.instructorsModifyFees}
                      onChange={toggleAllowInstructorsToModifyFees}
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Allow members to book with an unpaid invoice
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.bookWithUnpaidInvoice}
                      onChange={toggleAllowWithUnpaidInvoice}
                    />
                  </SoftBox>
                </SoftBox>

                {!locationPreferences?.bookWithUnpaidInvoice && (
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    lineHeight={1}
                  >
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Allow members to book with a payment pending
                    </SoftTypography>
                    <SoftBox ml={2} mr={1}>
                      <Switch
                        checked={locationPreferences?.bookWithPendingPayment}
                        onChange={toggleAllowWithPendingPayment}
                      />
                    </SoftBox>
                  </SoftBox>
                )}

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Require a card on file to book
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.requireCardOnFile}
                      onChange={toggleRequireCardOnFile}
                    />
                  </SoftBox>
                </SoftBox>

                {/* <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Enable advance billing features
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.enableLineitemPayment}
                      onChange={toggleEnableLineitemPayment}
                    />
                  </SoftBox>
                </SoftBox> */}

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Default instructor hourly rate:
                  </SoftTypography>
                  <SoftBox ml={2} mr={1} width={100}>
                    <SoftCurrencyInputv2
                      currencySymbol="$"
                      placeholder="0"
                      decimalPlaces={0}
                      decimalPlacesShownOnBlur={0}
                      decimalPlacesShownOnFocus={0}
                      outputFormat="number"
                      value={locationPreferences?.defaultInstructorHourlyRate}
                      onBlurChangedValue={({ valueInCents }) => {
                        setDefaultInstructorHourlyRate(valueInCents);
                      }}
                      allowValueUpdate
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Default fuel cost for wet rates:
                  </SoftTypography>
                  <SoftBox ml={2} mr={1} width={100}>
                    <SoftCurrencyInputv2
                      currencySymbol="$"
                      cents
                      placeholder="0"
                      decimalPlaces={0}
                      decimalPlacesShownOnBlur={0}
                      decimalPlacesShownOnFocus={0}
                      outputFormat="number"
                      value={locationPreferences?.fuelPrice}
                      onBlurChangedValue={({ valueInCents }) => {
                        setFuelPrice(valueInCents);
                      }}
                      allowValueUpdate
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Default tax rate:
                  </SoftTypography>
                  <SoftBox ml={2} mr={1} width={100}>
                    <SoftCurrencyInput
                      placeholder="0.00"
                      decimalPlaces={2}
                      value={locationPreferences?.defaultTaxRate}
                      onBlur={setDefaultTaxRate}
                      currencySymbol="%"
                      outputFormat="number"
                      adornmentOnRight
                    />
                  </SoftBox>
                </SoftBox>

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Limit Max Hobbs/Tach Hours
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      checked={locationPreferences?.limitMaxHobbsTach}
                      onChange={toggleMaxHobbsTachHours}
                    />
                  </SoftBox>
                </SoftBox>

                {locationPreferences?.limitMaxHobbsTach && (
                  <SoftBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={2}
                    lineHeight={1}
                  >
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                    >
                      Max Allowed Hobbs/Tach Hours:
                    </SoftTypography>
                    <SoftBox ml={2} mr={1} width={100}>
                      <SoftCurrencyInput
                        placeholder="0"
                        currencySymbol=""
                        decimalPlaces={0}
                        decimalPlacesShownOnBlur={0}
                        decimalPlacesShownOnFocus={0}
                        outputFormat="number"
                        value={locationPreferences?.maxHobbsTach}
                        onBlur={setMaxHobbsTach}
                      />
                    </SoftBox>
                  </SoftBox>
                )}

                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={2}
                  lineHeight={1}
                >
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                  >
                    Charge pilots a convenience fee
                  </SoftTypography>
                  <SoftBox ml={2} mr={1}>
                    <Switch
                      disabled={
                        locationPreferences?.clubPaysFees &&
                        locationPreferences?.clubPaysAchFees &&
                        locationPreferences?.clubPaysFeesOnAccount
                      }
                      checked={locationPreferences?.chargeConvenienceFee}
                      onChange={toggleChargeConvenienceFee}
                    />
                  </SoftBox>
                </SoftBox>

                {locationPreferences?.chargeConvenienceFee && (
                  <>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        Credit Card Convenience Fee:
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={100}>
                        <SoftCurrencyInput
                          placeholder="0.00"
                          decimalPlaces={2}
                          value={locationPreferences?.creditCardConvenienceFee}
                          onBlur={setCreditCardConvenienceFee}
                          currencySymbol="%"
                          outputFormat="number"
                          adornmentOnRight
                          disabled={locationPreferences?.clubPaysFees}
                        />
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        ACH Convenience Fee:
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={100}>
                        <SoftCurrencyInput
                          placeholder="0.00"
                          decimalPlaces={2}
                          value={locationPreferences?.achConvenienceFee}
                          onBlur={setAchConvenienceFee}
                          currencySymbol="%"
                          outputFormat="number"
                          adornmentOnRight
                          disabled={locationPreferences?.clubPaysAchFees}
                        />
                      </SoftBox>
                    </SoftBox>
                    <SoftBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mb={2}
                      lineHeight={1}
                    >
                      <SoftTypography
                        variant="button"
                        fontWeight="regular"
                        color="text"
                      >
                        Account Payment Convenience Fee:
                      </SoftTypography>
                      <SoftBox ml={2} mr={1} width={100}>
                        <SoftCurrencyInput
                          placeholder="0.00"
                          decimalPlaces={2}
                          value={locationPreferences?.accountConvenienceFee}
                          onBlur={setAccountConvenienceFee}
                          currencySymbol="%"
                          outputFormat="number"
                          adornmentOnRight
                          disabled={locationPreferences?.clubPaysFeesOnAccount}
                        />
                      </SoftBox>
                    </SoftBox>
                  </>
                )}
              </SoftBox>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </Card>
  );
}

export default ClubBilling;
