import { components } from "react-select";
import SoftTypography from "src/components/SoftTypography";

// To use: add the `isDisabled: true` field to heading options
export default function SoftGroupedOption(props: any) {
  return (
    <SoftTypography
      fontSize="small"
      fontWeight={props.isDisabled ? "light" : "regular"}
      sx={{ ml: props.isDisabled ? 0 : 2 }}
      component="div"
    >
      <components.Option {...props} />
    </SoftTypography>
  );
}
