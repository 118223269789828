// @mui material components
import Card from "@mui/material/Card";
// import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
// import SoftButton from "src/components/SoftButton";

import { useClubs } from "src/features/club/ClubProvider";

import { format } from "date-fns";

// Billing page components
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { where, orderBy, query } from "firebase/firestore";
import { useMemo, useState } from "react";
import { getClubPayments } from "src/features/club/collections";
import { IUseClub, IPaymentComplete } from "src/interfaces";
import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { formatCurrency, toTitleCase } from "src/features/utils";
import eyeSvg from "src/assets/images/transactions/eye.svg";
import { SoftCard } from "src/components/SoftCard/SoftCard";
import { SoftTab, SoftTabs } from "src/components/SoftTabs/SoftTabs";
import { useNavigate } from "react-router-dom";
import { PaymentStatusIndicator } from "src/components/PaymentStatusIndicator/PaymentStatusIndicator";

interface PaymentsProps {
  userId: string;
  accountId: string | null;
}

function Payments({ userId, accountId }: PaymentsProps) {
  const { selectedClubId, clubAccounts } = useClubs() as IUseClub;
  const navigate = useNavigate();

  console.log("clubAccounts", accountId);

  const [tabValue, setTabValue] = useState(0);

  const selectedClubAccount = useMemo(() => {
    if (tabValue === 0) return "standard";
    return clubAccounts[tabValue - 1];
  }, [tabValue, clubAccounts]);

  const clubsPaymentsCollectionRef = query(
    getClubPayments(selectedClubId),
    where("userId", "==", userId),
    orderBy("createdAt", "desc")
  );

  const { data: payments } = useRealtimeCollectionData(
    clubsPaymentsCollectionRef,
    true
  ) as { data: IPaymentComplete[] };

  const filteredPayments = useMemo(() => {
    if (!payments?.length) return [];
    return payments.filter((p) => {
      if (selectedClubAccount === "standard") return !p?.accountId;
      else return p?.accountId === selectedClubAccount.id;
    });
  }, [payments, selectedClubAccount]);

  const viewPaymentDetails = (paymentId: string) => {
    navigate({
      pathname: `/billing/payments/${paymentId}`,
    });
  };

  // const openPdf = async (payment: IPaymentComplete) => {
  //   // const doc = (
  //   //   <InvoicePdf
  //   //     invoice={invoice!}
  //   //     club={selectedClub}
  //   //     location={selectedLocation}
  //   //   />
  //   // );
  //   // const asPdf = pdf(doc);
  //   // const blob = await asPdf.toBlob();
  //   // const url = URL.createObjectURL(blob);
  //   // window.open(url, "_blank");
  // };

  // const openPayment = (payment: IPaymentComplete) => {
  //   // navigate({
  //   //   pathname: `/billing/invoice/${invoice.id}`,
  //   //   search: createSearchParams({
  //   //     isNewInvoice: "true",
  //   //   }).toString(),
  //   // });
  // };

  return (
    <>
      <Card sx={{ marginBottom: "12px" }}>
        <SoftBox
          py={3}
          px={2}
          sx={{
            boxShadow: "0px 8px 24px 0px rgba(149, 157, 165, 0.1)",
          }}
          display="flex"
          width="100%"
          justifyContent="space-between"
          alignItems="center"
        >
          <SoftTypography variant="body1" fontWeight="medium">
            Payments
          </SoftTypography>
          <SoftCard
            styles={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "12px",
              margin: 0,
              padding: "6px 0",
              paddingBottom: "6px",
              width: "auto",
            }}
          >
            <SoftTabs
              value={tabValue}
              variation="secondary"
              onChange={(_, newValue) => setTabValue(newValue)}
              sx={{ height: "36px" }}
            >
              <SoftTab label="Standard" />
              {clubAccounts.map((account) => (
                <SoftTab key={account.id} label={`${account.name}`} />
              ))}
            </SoftTabs>
          </SoftCard>
        </SoftBox>

        <SoftBox p={2} pt={0}>
          {filteredPayments.length === 0 && (
            <SoftBox p={2} mx={3} display="flex" justifyContent="center">
              <SoftTypography variant="caption" color="text" textAlign="center">
                <i>There are no payments yet..</i>
              </SoftTypography>
            </SoftBox>
          )}
          {filteredPayments.length > 0 && (
            <Table
              sx={{
                minWidth: "32rem",
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell />
                  <TableCell />
                  <TableCell>Total</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredPayments.map((payment) => {
                  let description = "Payment";
                  switch (payment.type) {
                    case "CARD_FWD":
                      description = `${toTitleCase(
                        payment?.paymentData?.[0]?.payment_method?.card
                          ?.brand || "Card"
                      )} - ${
                        payment.paymentData?.[0]?.payment_method?.card
                          ?.last_four_digits
                      }`;
                      break;
                    case "CARD_STRIPE":
                      description = `Card Payment (${toTitleCase(
                        payment.paymentData?.[0]?.charges?.data?.[0]
                          ?.payment_method_details?.card?.brand || "Card"
                      )} - ${
                        payment.paymentData?.[0]?.charges?.data?.[0]
                          ?.payment_method_details?.card?.last4
                      } - Exp. ${
                        payment.paymentData?.[0]?.charges?.data?.[0]
                          ?.payment_method_details?.card?.exp_month
                      }/${
                        payment.paymentData?.[0]?.charges?.data?.[0]
                          ?.payment_method_details?.card?.exp_year
                      })`;
                      break;

                    case "ACH_FWD":
                    case "ACH_STRIPE":
                      description = `Bank Transfer (ACH)`;
                      break;

                    default:
                      description = `${toTitleCase(
                        payment.type || "Other"
                      )} Payment`;
                      break;
                  }

                  return (
                    <TableRow
                      key={payment.id}
                      style={{ cursor: "pointer" }}
                      onClick={() => viewPaymentDetails(payment.id)}
                    >
                      <TableCell width="70px">
                        <SoftTypography fontSize="14px">
                          # {payment.transactionNumber}
                        </SoftTypography>
                      </TableCell>
                      <TableCell width="70px">
                        <SoftTypography fontSize="14px">
                          {format(
                            payment.createdAt.toDate(),
                            "MM/dd/yyyy hh:mm a"
                          )}
                        </SoftTypography>
                      </TableCell>
                      <TableCell>
                        <SoftTypography fontSize="14px" fontWeight="bold">
                          {description}
                        </SoftTypography>
                      </TableCell>
                      <TableCell width="150px">
                        <SoftTypography fontSize="16px" fontWeight="bold">
                          {formatCurrency(payment.totalAmount, true)}
                        </SoftTypography>
                      </TableCell>
                      <TableCell width="40px">
                        <PaymentStatusIndicator status={payment.status} />
                      </TableCell>
                      <TableCell width="70px">
                        <Stack direction="row" gap="12px">
                          {/* <img src={pdfSvg} /> */}
                          <img
                            src={eyeSvg}
                            style={{ cursor: "pointer" }}
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              viewPaymentDetails(payment.id);
                            }}
                          />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          )}
        </SoftBox>
      </Card>
    </>
  );
}

export default Payments;
