// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Settings page components
import ClubInfo from "./components/ClubInfo";
import BaseLayout from "src/components/BaseLayout";
import Sidenav from "./components/Sidenav";
import Notifications from "./components/Notifications";
import Membership from "./components/Membership";
import CancellationReasons from "./components/CancellationReasons/CancellationReasons";
import ReservationTypes from "./components/ReservationTypes/ReservationTypes";
import Locations from "./components/Locations";
import Scheduling from "./components/Scheduling";
import Documents from "./components/Documents";
import TimeOffApproval from "./components/TimeOffApproval";
import SquawkStatuses from "./components/SquawkStatuses/SquawkStatuses";
import HobbsMultiplier from "./components/HobbsMultiplier/HobbsMultiplier";
import LessonGradingScales from "./components/LessonGradingScales";

function ClubSettings() {
  return (
    <BaseLayout stickyNavbar>
      <SoftBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <SoftBox mb={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <ClubInfo />
                </Grid>
                <Grid item xs={12}>
                  <Locations />
                </Grid>
                <Grid item xs={12}>
                  <Membership />
                </Grid>
                <Grid item xs={12}>
                  <CancellationReasons />
                </Grid>
                <Grid item xs={12}>
                  <LessonGradingScales />
                </Grid>
                <Grid item xs={12}>
                  <SquawkStatuses />
                </Grid>
                <Grid item xs={12}>
                  <TimeOffApproval />
                </Grid>
                <Grid item xs={12}>
                  <ReservationTypes />
                </Grid>
                <Grid item xs={12}>
                  <Scheduling />
                </Grid>
                <Grid item xs={12}>
                  <HobbsMultiplier />
                </Grid>
                <Grid item xs={12}>
                  <Documents />
                </Grid>
                <Grid item xs={12}>
                  <Notifications />
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </BaseLayout>
  );
}

export default ClubSettings;
