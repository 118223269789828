import PropTypes from "prop-types";

// @mui material components
import { Grid, Icon, Chip, Link } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftButton from "src/components/SoftButton";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { format } from "date-fns";
import { openModal$ } from "src/modals/modalConfiguration";
import { InvoiceStatus, NOT_PAID_INVOICE } from "src/interfaces";
import { createSearchParams, useNavigate } from "react-router-dom";
import { toTitleCase, isDev } from "src/features/utils";
import logoQuickbooks from "src/assets/images/small-logos/logo-quickbooks.svg";
import { isInvoicePaid } from "src/utils/invoice.utils";

function Header({ invoice, onCancelinvoice, onResendinvoice, onMarkAsPaid }) {
  const navigate = useNavigate();
  const paid = isInvoicePaid(invoice);

  const formatStatusLabel = (status) => {
    return toTitleCase(status.replace(/_/g, " "));
  };

  return (
    <SoftBox>
      <Grid container justifyContent="space-between">
        <Grid item xs={12}>
          <SoftBox display="flex" justifyContent="flex-end">
            <Chip
              color={
                invoice?.status === InvoiceStatus.PAID
                  ? "success"
                  : invoice?.status === InvoiceStatus.PARTIALLY_PAID
                  ? "warning"
                  : "error"
              }
              variant="outlined"
              label={formatStatusLabel(invoice?.status)}
            />
          </SoftBox>
        </Grid>
        <Grid item xs={12} md={4}>
          {/* <SoftBox component="img" src={logoCT} width="25%" p={1} mb={1} /> */}
          <SoftTypography variant="h4" fontWeight="medium">
            {invoice?.club?.name}
          </SoftTypography>
          {invoice?.club?.number && (
            <SoftBox mt={1}>
              <SoftTypography display="block" variant="body2" color="secondary">
                Tel: {invoice.club.number}
              </SoftTypography>
            </SoftBox>
          )}
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
          <SoftBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
            {invoice?.pilot?.displayName && (
              <SoftBox mt={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Billed to: {invoice.pilot.displayName}
                </SoftTypography>
              </SoftBox>
            )}
            {invoice?.pilot?.email && (
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="secondary">
                  {invoice.pilot.email}
                </SoftTypography>
              </SoftBox>
            )}
            {invoice?.pilot?.address && (
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="secondary">
                  {invoice?.pilot?.address?.addressLine1}
                  <br />
                  {invoice?.pilot?.address?.addressLine2 && (
                    <>
                      {invoice?.pilot?.address?.addressLine2}
                      <br />
                    </>
                  )}
                  {invoice?.pilot?.address?.city},{" "}
                  {invoice?.pilot?.address?.state},{" "}
                  {invoice?.pilot?.address?.zip}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
        <Grid item xs={12}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox>
              <SoftBox mb={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Invoice - #{invoice?.invoiceNumber}
                </SoftTypography>
              </SoftBox>
              <SoftTypography
                component="p"
                variant="button"
                fontWeight="bold"
                color="text"
              >
                ID -{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {invoice?.id}
                </span>
              </SoftTypography>
              <SoftTypography
                component="p"
                variant="button"
                fontWeight="bold"
                color="text"
                pt={1}
              >
                Date:{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {invoice?.createdAt
                    ? format(invoice.createdAt.toDate(), "eee, MMM do yyyy")
                    : "N/A"}
                </span>
              </SoftTypography>
              {invoice?.qboInvoiceId && (
                <SoftBox
                  mt={1}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Link
                    href={`https://app.${
                      isDev ? "sandbox." : ""
                    }qbo.intuit.com/app/invoice?txnId=${invoice?.qboInvoiceId}`}
                    target="_blank"
                    lineHeight={1}
                  >
                    <SoftBox component="img" src={logoQuickbooks} width={35} />
                  </Link>
                  <SoftTypography
                    component="p"
                    variant="button"
                    fontWeight="bold"
                    color="text"
                  >
                    Quickbooks No. -{" "}
                    <span
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {invoice?.qboInvoiceId}
                    </span>
                  </SoftTypography>
                </SoftBox>
              )}
            </SoftBox>
            <SoftBox>
              {NOT_PAID_INVOICE.includes(invoice.status) && (
                <>
                  <SoftButton
                    color="info"
                    variant="text"
                    startIcon={<Icon>edit</Icon>}
                    onClick={() =>
                      navigate({
                        pathname: `/billing/invoice/edit/${invoice.id}`,
                        search: createSearchParams({
                          isNewInvoice: true,
                        }).toString(),
                      })
                    }
                    style={{ marginLeft: "10px" }}
                  >
                    Edit
                  </SoftButton>
                  <SoftButton
                    color="info"
                    variant="text"
                    startIcon={<Icon>payment</Icon>}
                    onClick={() =>
                      openModal$.next({
                        modalName: "PAYMENT_MODAL",
                        modalProps: { invoice: invoice },
                      })
                    }
                    style={{ marginLeft: "10px" }}
                  >
                    Add Payment
                  </SoftButton>
                </>
              )}
              {onMarkAsPaid && !paid && (
                <SoftButton
                  color="success"
                  variant="text"
                  startIcon={<Icon>credit_score</Icon>}
                  onClick={onMarkAsPaid}
                  style={{ marginLeft: "10px" }}
                >
                  Mark as Paid
                </SoftButton>
              )}
              {onResendinvoice && !paid && (
                <SoftButton
                  color="info"
                  onClick={onResendinvoice}
                  style={{ marginLeft: "10px" }}
                >
                  Resend
                </SoftButton>
              )}
              {onCancelinvoice && !paid && (
                <SoftButton
                  variant="outlined"
                  color="error"
                  onClick={onCancelinvoice}
                  style={{ marginLeft: "10px" }}
                >
                  Cancel
                </SoftButton>
              )}
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

Header.propTypes = {
  invoice: PropTypes.object,
  onResendinvoice: PropTypes.func,
  onMarkAsPaid: PropTypes.func,
  onCancelinvoice: PropTypes.func,
};

export default Header;
