import { useEffect, useState } from "react";

// @mui material components
import {
  Divider,
  MenuItem,
  Modal,
  Menu,
  Icon,
  Card,
  Checkbox,
} from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftBadge from "src/components/SoftBadge";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";
import SquawkInfo from "src/modals/SquawksModal";

import { useClubs } from "src/features/club/ClubProvider";
import { format } from "date-fns";
import { query, orderBy, where } from "firebase/firestore";
import { getAircraftSquawksCollectionGroup } from "src/features/aircraft/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { WithPermissions } from "src/components/WithPermissions/WithPermissions";
import { systemPermissions } from "src/interfaces/roles/role.interface";
import { openModal$ } from "src/modals/modalConfiguration";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";

const columns = [
  {
    Header: "Aircraft",
    accessor: "tailNumber",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Description",
    accessor: "description",
    Cell: ({ value }) => (
      <DefaultCell
        value={
          value?.length > 150 ? value.substring(0, 150) + "..." : value || "---"
        }
      />
    ),
  },
  {
    Header: "Reported By",
    accessor: "reporter.displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Created",
    accessor: "date",
    Cell: ({ value }) => (
      <DefaultCell
        value={`${value?.toDate() ? format(value.toDate(), "Pp") : "Never"}`}
      />
    ),
  },
  {
    Header: "Grounded",
    accessor: "grounded",
    Cell: ({ row: { original } }) => (
      <SoftBadge
        variant="contained"
        color={original.grounded ? "error" : "success"}
        size="xs"
        badgeContent={original.grounded ? "Yes" : "No"}
        container
        sx={{
          marginRight: 1,
        }}
      />
    ),
  },
  {
    Header: "Status",
    accessor: "status",
    Cell: ({ value }) => (
      <DefaultCell textTransform="capitalize" value={value || "---"} />
    ),
  },
];

function Squawks() {
  const { selectedClubId } = useClubs();
  const [menu, setMenu] = useState(null);
  const [rows, setRows] = useState([]);
  const [openSquawkInfo, setOpenSquawkInfo] = useState(false);
  const [selectedSquawk, setSelectedSquawk] = useState(null);
  const [showResolved, setShowResolved] = useState(false);

  const toggleShowResolved = () => setShowResolved(!showResolved);

  const closeSquawkModal = () => setOpenSquawkInfo(false);

  // const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  const squawksCollectionRef = query(
    getAircraftSquawksCollectionGroup(),
    where("clubId", "==", selectedClubId),
    orderBy("date", "asc")
  );
  const { data: allSquawks, isDataLoaded: squawksLoaded } =
    useRealtimeCollectionData(squawksCollectionRef, false);

  useEffect(() => {
    if (squawksLoaded && allSquawks?.length > 0) {
      if (showResolved) {
        setRows(allSquawks);
      } else {
        setRows(allSquawks.filter((squawk) => !squawk.resolved));
      }
    }
  }, [allSquawks, squawksLoaded, showResolved]);

  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Status: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Status: Canceled</MenuItem>
      <Divider
        sx={{
          margin: "0.5rem 0",
        }}
      />
      <MenuItem onClick={closeMenu}>
        <SoftTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </SoftTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <WithPermissions
              permissions={[systemPermissions.ADD_AIRCRAFT_SQUAWKS]}
            >
              <SoftBox display="flex" alignItems="center" pr={3}>
                <SoftButton
                  onClick={() => openModal$.next({ modalName: "ADD_SQUAWK" })}
                  color="primary"
                >
                  Add Squawk
                </SoftButton>
              </SoftBox>
            </WithPermissions>

            <WithPermissions
              permissions={[systemPermissions.VIEW_MX_RESOLVED_SQUAWKS]}
            >
              <SoftBox display="flex" alignItems="center" pr={3}>
                <Checkbox
                  defaultChecked={showResolved}
                  onChange={toggleShowResolved}
                />
                <SoftTypography variant="button" fontWeight="medium">
                  Show Resolved
                </SoftTypography>
              </SoftBox>
            </WithPermissions>
            {/* <SoftButton variant={menu ? 'contained' : 'outlined'} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </SoftButton> */}
            {renderMenu}
            <WithPermissions
              permissions={[systemPermissions.EXPORT_MX_SQUAWKS]}
            >
              <SoftBox ml={1}>
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </SoftBox>
            </WithPermissions>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            onRowSelect={(row) => {
              setSelectedSquawk(row);
              setOpenSquawkInfo(true);
            }}
            canSearch
          />
        </Card>
      </SoftBox>
      <Footer />
      <Modal
        open={openSquawkInfo}
        onClose={closeSquawkModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <SquawkInfo
          selectedSquawk={selectedSquawk}
          handleClose={closeSquawkModal}
        />
      </Modal>
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_MX_SQUAWKS,
  Squawks
);
