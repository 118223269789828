import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

function BillingInformation({ transaction }) {
  return (
    <>
      <SoftTypography variant="h6" fontWeight="medium">
        Billing Information
      </SoftTypography>

      {transaction.paymentData?.map((payment) => {
        if (!payment.billing_details) return null;
        return (
          <SoftBox
            component="li"
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            bgColor="grey-100"
            borderRadius="lg"
            key={payment.id}
            p={3}
            mt={2}
          >
            <SoftBox
              width="100%"
              display="flex"
              flexDirection="column"
              lineHeight={1}
            >
              {payment.billing_details?.name && (
                <SoftBox mb={2}>
                  <SoftTypography
                    variant="button"
                    fontWeight="medium"
                    textTransform="capitalize"
                  >
                    {payment.billing_details?.name}
                  </SoftTypography>
                </SoftBox>
              )}
              {payment.billing_details?.address?.line1 && (
                <SoftBox mb={1} lineHeight={0}>
                  <SoftTypography variant="caption" color="text">
                    Address Line 1:&nbsp;&nbsp;&nbsp;
                    <SoftTypography
                      variant="caption"
                      fontWeight="medium"
                      textTransform="capitalize"
                    >
                      {payment.billing_details?.address?.line1}
                    </SoftTypography>
                  </SoftTypography>
                </SoftBox>
              )}
              {payment.billing_details?.address?.line2 && (
                <SoftBox mb={1} lineHeight={0}>
                  <SoftTypography variant="caption" color="text">
                    Address Line 2:&nbsp;&nbsp;&nbsp;
                    <SoftTypography variant="caption" fontWeight="medium">
                      {payment.billing_details?.address?.line2}
                    </SoftTypography>
                  </SoftTypography>
                </SoftBox>
              )}
              <SoftBox display="flex" flexDirection="row" lineHeight={0}>
                {payment.billing_details?.address?.city && (
                  <SoftBox mb={1} lineHeight={0} pr={3}>
                    <SoftTypography variant="caption" color="text">
                      City:&nbsp;&nbsp;&nbsp;
                      <SoftTypography variant="caption" fontWeight="medium">
                        {payment.billing_details?.address?.city}
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                )}
                {payment.billing_details?.address?.state && (
                  <SoftBox mb={1} lineHeight={0} pr={3}>
                    <SoftTypography variant="caption" color="text">
                      State:&nbsp;&nbsp;&nbsp;
                      <SoftTypography variant="caption" fontWeight="medium">
                        {payment.billing_details?.address?.state}
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                )}
                {payment.billing_details?.address?.postal_code && (
                  <SoftBox mb={1} lineHeight={0}>
                    <SoftTypography variant="caption" color="text">
                      Zip:&nbsp;&nbsp;&nbsp;
                      <SoftTypography variant="caption" fontWeight="medium">
                        {payment.billing_details?.address?.postal_code}
                      </SoftTypography>
                    </SoftTypography>
                  </SoftBox>
                )}
              </SoftBox>
            </SoftBox>
          </SoftBox>
        );
      })}
    </>
  );
}

BillingInformation.propTypes = {
  transaction: PropTypes.object.isRequired,
};

export default BillingInformation;
