import { Chip } from "@mui/material";
import { PaymentStatus, paymentStatusDisplay } from "src/interfaces";

interface PaymentStatusIndicatorProps {
  status: PaymentStatus;
  customText?: string;
}

export const PaymentStatusIndicator = ({
  customText,
  status,
}: PaymentStatusIndicatorProps) => {
  return (
    <Chip
      label={customText ?? paymentStatusDisplay[status]?.text}
      sx={{
        ...style.defaultStyle,
        ...style.secondary,
        ...(paymentStatusDisplay[status]?.color === "error" && style.error),
        ...(paymentStatusDisplay[status]?.color === "success" && style.success),
        ...(paymentStatusDisplay[status]?.color === "warning" && style.warning),
        ...(paymentStatusDisplay[status]?.color === "dark" && style.dark),
      }}
    />
  );
};

const style = {
  defaultStyle: {
    fontWeight: 600,
    fontSize: "12px",
  },
  error: {
    background: "#C90000",
    color: "#eaf5ee",
  },
  success: {
    color: "#52a855",
    background: "#eaf5ee",
  },
  warning: {
    color: "#a8a852",
    background: "#f4f5ea",
  },
  secondary: {
    color: "#5299A8",
    background: "#EAF3F5",
  },
  dark: {
    color: "#adadad",
    background: "#3b3b3b",
  },
};
