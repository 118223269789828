import PropTypes from "prop-types";

// @mui material components
import { Grid, Link } from "@mui/material";

// Soft UI Dashboard PRO React components
// import SoftButton from "src/components/SoftButton";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { format } from "date-fns";
// import { openModal$ } from "src/modals/modalConfiguration";
// import { createSearchParams, useNavigate } from "react-router-dom";
import { isDev } from "src/features/utils";
import logoQuickbooks from "src/assets/images/small-logos/logo-quickbooks.svg";
import { useClubs } from "src/features/club/ClubProvider";

function Header({ payment }) {
  // const navigate = useNavigate();
  const { selectedClub, clubUsers } = useClubs();
  const user = clubUsers.get(payment?.userId);

  return (
    <SoftBox>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={4}>
          <SoftTypography variant="h4" fontWeight="medium">
            {selectedClub?.name}
          </SoftTypography>
          {selectedClub?.number && (
            <SoftBox mt={1}>
              <SoftTypography display="block" variant="body2" color="secondary">
                Tel: {selectedClub.number}
              </SoftTypography>
            </SoftBox>
          )}
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
          <SoftBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
            {user?.displayName && (
              <SoftBox mt={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Billed to: {user.displayName}
                </SoftTypography>
              </SoftBox>
            )}
            {user?.email && (
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="secondary">
                  {user.email}
                </SoftTypography>
              </SoftBox>
            )}
            {user?.address && (
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="secondary">
                  {user?.address?.addressLine1}
                  <br />
                  {user?.address?.addressLine2 && (
                    <>
                      {user?.address?.addressLine2}
                      <br />
                    </>
                  )}
                  {user?.address?.city}, {user?.address?.state},{" "}
                  {user?.address?.zip}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
        <Grid item xs={12}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox>
              <SoftBox mb={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Payment - #{payment?.transactionNumber}
                </SoftTypography>
              </SoftBox>
              <SoftTypography
                component="p"
                variant="button"
                fontWeight="bold"
                color="text"
              >
                ID -{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {payment?.id}
                </span>
              </SoftTypography>
              <SoftTypography
                component="p"
                variant="button"
                fontWeight="bold"
                color="text"
                pt={1}
              >
                Date:{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {payment?.createdAt
                    ? format(payment.createdAt.toDate(), "eee, MMM do yyyy")
                    : "N/A"}
                </span>
              </SoftTypography>
              {payment?.qboPaymentId && (
                <SoftBox
                  mt={1}
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                >
                  <Link
                    href={`https://app.${
                      isDev ? "sandbox." : ""
                    }qbo.intuit.com/app/payment?txnId=${payment?.qboPaymentId}`}
                    target="_blank"
                    lineHeight={1}
                  >
                    <SoftBox component="img" src={logoQuickbooks} width={35} />
                  </Link>
                  <SoftTypography
                    component="p"
                    variant="button"
                    fontWeight="bold"
                    color="text"
                  >
                    Quickbooks No. -{" "}
                    <span
                      style={{
                        fontWeight: "400",
                      }}
                    >
                      {payment?.qboPaymentId}
                    </span>
                  </SoftTypography>
                </SoftBox>
              )}
            </SoftBox>
            {/* <SoftBox>
              <SoftButton
                color="info"
                variant="text"
                startIcon={<Icon>edit</Icon>}
                onClick={() =>
                  navigate({
                    pathname: `/billing/payment/edit/${payment.id}`,
                    search: createSearchParams({
                      isNewPayment: true,
                    }).toString(),
                  })
                }
                style={{ marginLeft: "10px" }}
              >
                Edit
              </SoftButton>
              <SoftButton
                color="info"
                variant="text"
                startIcon={<Icon>payment</Icon>}
                onClick={() =>
                  openModal$.next({
                    modalName: "PAYMENT_MODAL",
                    modalProps: { payment: payment },
                  })
                }
                style={{ marginLeft: "10px" }}
              >
                Add Payment
              </SoftButton>
            </SoftBox> */}
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

Header.propTypes = {
  payment: PropTypes.object,
  onResendpayment: PropTypes.func,
  onMarkAsPaid: PropTypes.func,
  onCancelpayment: PropTypes.func,
};

export default Header;
