import { query, where, onSnapshot } from "firebase/firestore";
import { useEffect, useState } from "react";
import { ISquawk } from "src/interfaces";
import { isBefore } from "date-fns";
import { getAircraftSquawksCollection } from "src/features/aircraft/collections";

interface IUseSquawks {
  clubId: string;
  aircraftIds: string[]; // Now an array of aircraft IDs
  queryConfig?: {
    resolved?: boolean;
    deleted?: boolean;
  };
}

/**
 * It's only intended for passing an array of aircrafts and listen for their squawk changes
 * @param param0
 * @returns
 */
function useAircraftSquawks({ clubId, aircraftIds, queryConfig }: IUseSquawks) {
  const [squawks, setSquawks] = useState<Array<ISquawk>>([]);
  const [loadingSquawks, setLoadingSquawks] = useState<boolean>(true);

  useEffect(() => {
    if (!clubId || aircraftIds.length === 0) {
      setSquawks([]);
      setLoadingSquawks(false);
      return;
    }

    const unsubscribeListeners: Array<() => void> = [];

    // Function to handle squawks from each listener separately
    const updateSquawks = (aircraftId: string, newSquawks: Array<ISquawk>) => {
      setSquawks((prevSquawks) => {
        const filteredSquawks = prevSquawks.filter(
          (sq) => sq.aircraftId !== aircraftId
        );
        return [...filteredSquawks, ...newSquawks].sort((a, b) =>
          isBefore(a.date.toDate(), b.date.toDate()) ? 1 : -1
        );
      });
    };

    const fetchSquawks = () => {
      setLoadingSquawks(true);

      // Listen for changes for each aircraftId
      aircraftIds.forEach((aircraftId) => {
        const squawksQuery = query(
          getAircraftSquawksCollection(clubId, aircraftId),
          where("resolved", "==", !!queryConfig?.resolved),
          where("deleted", "==", !!queryConfig?.deleted)
        );

        // Set up real-time listener with onSnapshot
        const unsubscribe = onSnapshot(squawksQuery, (querySnapshot) => {
          const newSquawks: Array<ISquawk> = [];

          querySnapshot.forEach((doc) => {
            const squawk: any = doc.data();
            newSquawks.push({ id: doc.id, aircraftId, ...squawk });
          });

          updateSquawks(aircraftId, newSquawks);
          setLoadingSquawks(false);
        });

        unsubscribeListeners.push(unsubscribe);
      });
    };

    fetchSquawks();

    return () => {
      unsubscribeListeners.forEach((unsubscribe) => unsubscribe());
    };
  }, [clubId, aircraftIds, queryConfig]);

  return { squawks, loadingSquawks };
}

export default useAircraftSquawks;
