import { Chip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  InvoiceStatus,
  invoiceStatusDisplayText,
  PAID_INVOICE,
} from "src/interfaces";

interface InvoiceStatusIndicatorProps {
  status: InvoiceStatus;
  customText?: string;
  paymentId?: string;
}

const isNotPaid = (status: InvoiceStatus) =>
  [InvoiceStatus.PARTIALLY_PAID].includes(status);
const isPaid = (status: InvoiceStatus) => PAID_INVOICE.includes(status);

export const InvoiceStatusIndicator = ({
  customText,
  paymentId,
  status,
}: InvoiceStatusIndicatorProps) => {
  const navigate = useNavigate();
  return (
    <Chip
      label={customText ?? invoiceStatusDisplayText[status]}
      onClick={() => paymentId && navigate(`/billing/payments/${paymentId}`)}
      sx={{
        ...style.defaultStyle,
        ...(isNotPaid(status) && style.notPaid),
        ...(isPaid(status) && style.Paid),
      }}
    />
  );
};

const style = {
  defaultStyle: {
    fontWeight: 600,
    fontSize: "12px",
  },
  notPaid: {
    color: "#C90000",
    background: "#C9000026",
  },
  Paid: {
    color: "#5299A8",
    background: "#EAF3F5",
  },
};
