import Grid from "@mui/material/Grid";

import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";

import { useParams } from "react-router-dom";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { doc } from "firebase/firestore";

// OrderDetails page components
import Header from "../components/Header";
import TransactionInfo from "../components/TransactionInfo";
import PaymentDetails from "src/pages/club-billing/invoices/transaction-details/components/PaymentDetails";
import BillingInformation from "src/pages/club-billing/invoices/transaction-details/components/BillingInformation";
import TransactionSummary from "src/pages/club-billing/invoices/transaction-details/components/TransactionSummary";
import PaymentError from "src/pages/club-billing/invoices/transaction-details/components/PaymentError";

import "./checkmark.scss";
import logo from "src/assets/images/logo-inline.svg";
import { getClubPayments } from "src/features/club/collections";

export default function InvoicePaymentConfirmation() {
  const { clubId, clubBillingEntryId } = useParams();

  const { data: billingEntry, isDataLoaded: loadedBillingEntry } =
    useRealtimeDocumentData(doc(getClubPayments(clubId), clubBillingEntryId));

  // const navigate = useNavigate();

  if (!billingEntry?.createdAt && !billingEntry?.id) {
    return (
      <SoftBox my={7}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <SoftBox pt={2} px={2}>
              <SoftTypography>Can not find this transaction...</SoftTypography>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    );
  }

  return (
    <SoftBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{
        maxWidth: "100vw",
      }}
    >
      <SoftBox
        mt={6}
        mx={6}
        sx={{
          maxWidth: "100vw",
        }}
      >
        {loadedBillingEntry && billingEntry && (
          <Grid container spacing={3} justifyContent="center">
            <Grid
              item
              xs={12}
              lg={4}
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <SoftBox
                display="flex"
                flexDirection="column"
                alignItems="center"
                pb={3}
              >
                <SoftBox component="img" src={logo} width={250} />
              </SoftBox>
              <SoftTypography variant="h3" p={2}>
                Payment Confirmation
              </SoftTypography>
              <SoftBox mt={{ sm: 3, lg: 10 }}>
                <SoftBox className="dummy-positioning d-flex">
                  <SoftBox className="success-icon">
                    <div className="success-icon__tip" />
                    <div className="success-icon__long" />
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  mt={6}
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                >
                  <SoftTypography variant="h6" fontWeight="bold">
                    Thank you for your payment!
                  </SoftTypography>
                  <SoftBox
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    mt={4}
                    lineHeight={1}
                  >
                    <SoftTypography variant="body2" textAlign="center">
                      Here are your payment details. You will receive an email
                      confirmation shortly!
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Card>
                <SoftBox pt={2} px={2}>
                  <Header paymentRequest={billingEntry} />
                </SoftBox>
                <Divider />
                <SoftBox pt={1} pb={3} px={2}>
                  <SoftBox mb={3}>
                    <TransactionInfo transaction={billingEntry} />
                  </SoftBox>
                  <SoftBox mt={3}>
                    <Grid container spacing={6}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{
                          ml: "auto",
                        }}
                      >
                        <TransactionSummary transaction={billingEntry} />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <PaymentDetails transaction={billingEntry} />
                        {billingEntry.paymentData?.[0]?.status && (
                          <SoftBox mt={3}>
                            <BillingInformation transaction={billingEntry} />
                          </SoftBox>
                        )}
                        {billingEntry.payments?.[0]?.last_payment_error && (
                          <SoftBox mt={3}>
                            <PaymentError transaction={billingEntry} />
                          </SoftBox>
                        )}
                      </Grid>
                    </Grid>
                  </SoftBox>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        )}
      </SoftBox>
    </SoftBox>
  );
}
