import SoftBox from "../SoftBox";

interface SoftCardProps {
  children: React.ReactNode;
  styles?: React.CSSProperties
}

export const SoftCard = ({ children, styles = {} }: SoftCardProps) => {
  return (
    <SoftBox
      my={7}
      sx={{
        boxShadow: "0px 8px 24px 0px rgba(149, 157, 165, 0.1)",
        background: "white",
        padding: "26px",
        paddingBottom: "12px",
        borderRadius: "20px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        ...styles
      }}
    >
      {children}
    </SoftBox>
  );
};
