import * as toastr from "toastr";
import WithPermissionWrapper from "src/components/WithPermissions/WithPermissionWrapper";
import { systemPermissions } from "src/interfaces/roles/role.interface";

import { useMemo, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";

import { query } from "firebase/firestore";
import { getClubIntroFlightsCollection } from "src/features/club/collections";
import useRealtimeCollectionData from "src/features/firebase/firestore/useRealtimeCollectionData";
import { openModal$ } from "src/modals/modalConfiguration";
import { IUseClub } from "src/interfaces";
import ActionsCell from "src/components/ActionsCell";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { IIntroFlight } from "src/interfaces/introFlight.interface";
import { usePromiseLoading } from "src/hooks/usePromiseLoading";
import CurrencyCell from "src/pages/club-billing/invoices/components/CurrencyCell";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SoftTypography from "src/components/SoftTypography";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const columns = [
  {
    Header: "Title",
    accessor: "title",
    Cell: ({ value }: any) => (value ? <DefaultCell value={value} /> : null),
  },
  {
    Header: "Max Pilot Weight",
    accessor: "maxWeight",
    Cell: ({ value }: any) => (value ? <DefaultCell value={value} /> : null),
  },
  {
    Header: "Cost",
    accessor: "totalPrice",
    Cell: ({ value }: any) => <CurrencyCell value={value || "---"} />,
  },
  {
    Header: "Flight Time",
    accessor: "flightTime",
    Cell: ({ value }: any) => <DefaultCell value={`${value || "---"} mins`} />,
  },
  {
    Header: "Ground Time",
    accessor: "groundTime",
    Cell: ({ value }: any) => <DefaultCell value={`${value || "---"} mins`} />,
  },
  {
    Header: "Actions",
    width: 75,
    accessor: "actions",
  },
];

function IntroFlightsList() {
  const { selectedClubId, selectedLocationId } = useClubs() as IUseClub;
  const { updateData } = entityCrudUtils();
  const [rows, setRows] = useState<any>([]);

  const introFlightsCollectionRef = query(
    getClubIntroFlightsCollection(selectedClubId, selectedLocationId)
  );

  const { data: introFlights, isDataLoaded: introFlightsLoaded } =
    useRealtimeCollectionData(introFlightsCollectionRef, false);

  useMemo(() => {
    if (!introFlightsLoaded || !introFlights) return;
    setRows(
      introFlights.map((introFlight: any) => ({
        ...introFlight,
        actions: (
          <ActionsCell
            onEdit={() => fnEdit(introFlight)}
            onDelete={() => fnDelete(introFlight)}
            copyText={`${window.location.protocol}//${window.location.host}/intro-flight/${selectedClubId}/${selectedLocationId}/${introFlight.id}`}
          />
        ),
      }))
    );
  }, [introFlightsLoaded, introFlights]);

  const addNewIntroFlight = (introFlight: any) => {
    openModal$.next({
      modalName: "INTRO_FLIGHT_MODAL",
      modalProps: { introFlight },
    });
  };

  const handleEditIntroFlight = (introFlight: any) => {
    openModal$.next({
      modalName: "INTRO_FLIGHT_MODAL",
      modalProps: { introFlight },
    });
  };

  const handleDelete = async (introFlight: IIntroFlight) => {
    // TODO: What to do with users with that role?
    await updateData(
      {
        entity: getClubIntroFlightsCollection(
          selectedClubId,
          selectedLocationId
        ),
        pathSegmentsArr: [introFlight.id],
      },
      { deleted: true }
    );
  };

  const { caller: fnEdit } = usePromiseLoading({
    fn: handleEditIntroFlight,
  });

  const { caller: fnDelete } = usePromiseLoading({
    fn: handleDelete,
  });

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox
            display="flex"
            alignContent="center"
            alignItems="center"
            sx={{ cursor: "copy" }}
          >
            <CopyToClipboard
              text={`${window.location.protocol}//${window.location.host}/intro-flight/${selectedClubId}/${selectedLocationId}`}
              onCopy={() => toastr.success("Copied to clipboard")}
            >
              <SoftTypography variant="caption">
                <strong>Public URL:</strong>{" "}
                {`${window.location.protocol}//${window.location.host}/intro-flight/${selectedClubId}/${selectedLocationId}`}
                <ContentCopyIcon sx={{ marginLeft: 1 }} />
              </SoftTypography>
            </CopyToClipboard>
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <SoftButton
                variant="gradient"
                color="info"
                onClick={addNewIntroFlight}
              >
                + New Intro Flight
              </SoftButton>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            onRowSelect={(introFlight) => {
              openModal$.next({
                modalName: "INTRO_FLIGHT_MODAL",
                modalProps: { introFlight },
              });
            }}
            canSearch
            selectedRowId={undefined}
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default WithPermissionWrapper(
  systemPermissions.VIEW_INTRO_FLIGHT_SETTINGS,
  IntroFlightsList
);
