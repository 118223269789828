import { Icon } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";

export function GradingScaleInput({ onDelete, onChange, value }) {
  return (
    <SoftBox
      px={1}
      pt={1}
      width={58}
      height={58}
      sx={{
        "&:hover .delete-button": {
          visibility: "visible",
          opacity: 1,
        },
        overflow: "visible",
      }}
    >
      <SoftInput
        key={value.index}
        sx={{ input: { textAlign: "center" }, zIndex: 2 }}
        placeholder=""
        onChange={(e) => onChange(value.index, e.target.value)}
        value={value.label}
      />
      <SoftBox
        className="delete-button"
        sx={{
          border: "1px solid #e0e0e0",
          borderTop: "none",
          borderRadius: "8px",
          top: -15,
          zIndex: 1,
          backgroundColor: "#f1f1f1",
          paddingTop: "10px",
          position: "relative",
          visibility: "hidden",
          opacity: 0,
          transition: "opacity 0.3s, visibility 0.3s",
        }}
      >
        <SoftButton
          variant="text"
          color="error"
          sx={{ paddingLeft: 0, paddingRight: 0, minWidth: 42 }}
          onClick={() => onDelete(value.index)}
        >
          <Icon>close</Icon>
        </SoftButton>
      </SoftBox>
    </SoftBox>
  );
}

GradingScaleInput.propTypes = {
  onDelete: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.object,
};
