import { currencyTypes } from "./currency.interface";
import { IBooking } from "./booking.interface";
import { IUser } from "./user.interface";
import { AdditionalData, Discount, paymentTypes } from "./payment.interface";

export enum InvoiceStatus {
  DRAFT = "DRAFT",
  SENT = "SENT",
  OPEN = "OPEN",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PAID = "PAID",
  OVERDUE = "OVERDUE",
  DISPUTED = "DISPUTED",
  CANCELLED = "CANCELLED",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  REFUNDED = "REFUNDED",
  WRITE_OFF = "WRITE_OFF",
}

export const invoiceStatusDisplayText = {
  [InvoiceStatus.DRAFT]: "Draft",
  [InvoiceStatus.SENT]: "Sent",
  [InvoiceStatus.OPEN]: "Open",
  [InvoiceStatus.PARTIALLY_PAID]: "Unpaid",
  [InvoiceStatus.PAID]: "Paid",
  [InvoiceStatus.OVERDUE]: "Overdue",
  [InvoiceStatus.DISPUTED]: "Disputed",
  [InvoiceStatus.CANCELLED]: "Cancelled",
  [InvoiceStatus.PARTIALLY_REFUNDED]: "Partially refunded",
  [InvoiceStatus.REFUNDED]: "Refunded",
  [InvoiceStatus.WRITE_OFF]: "Write off",
};

export const NOT_PAID_INVOICE = [
  InvoiceStatus.OPEN,
  InvoiceStatus.PARTIALLY_PAID,
];

export const PAID_INVOICE = [InvoiceStatus.PAID];

export enum InvoiceType {
  PRODUCT = "PRODUCT",
  SERVICE = "SERVICE",
  CHARGE = "CHARGE",
  ACCOUNT_DEPOSIT = "ACCOUNT_DEPOSIT",
  BOOKING = "BOOKING",
}

export enum LineItemType {
  AIRCRAFT_RENTAL = "AIRCRAFT_RENTAL",
  GROUND_INSTRUCTION = "GROUND_INSTRUCTION",
  FLIGHT_INSTRUCTION = "FLIGHT_INSTRUCTION",
  CREDIT = "CREDIT",
  DEBIT = "DEBIT",
  MANUAL = "MANUAL",
  FUEL_CHARGE = "FUEL_CHARGE",
  FUEL_REIMBURSEMENT = "FUEL_REIMBURSEMENT",
}

export const FUEL_TYPES = [
  LineItemType.FUEL_CHARGE,
  LineItemType.FUEL_REIMBURSEMENT,
];

export interface BaseLineItem {
  description: string;
  rate: number;
  quantity: number;
  qty?: number; // <== TODO: Review this. Used at invoice-details\InvoiceDetails.tsx
}

export interface RefundLineItem {
  lineItemId: string;
  amount: number;
  type: string;
}

export interface LineItem extends BaseLineItem {
  id: string;
  total: number;
  tax: number;
  refunded: number;
  payment?: {
    id: string;
    fees: number;
    paidBy: {
      id: string;
      name: string;
    };
    paidAt: string;
    clubPaysFees: boolean;
  };
  fees?: number;
  discount?: Discount;
  aircraftId?: string;
  rateId?: string;
  type: LineItemType;
  isDerived?: boolean;
  originalQty?: number;
  originalRate?: number;
  displayQty?: number;
  originalTotal?: number;
  derivedPaymentId?: string;
  derivedType?: paymentTypes;
  qboClass?: { id: string; name: string };
  qboItem?: { id: string; name: string };
  qboId?: string;
  item?: any; // <== TODO: Review this. Used at invoice-details\InvoiceDetails.tsx
  derivedLineItem?: LineItem;
  parentDerivedLineItem?: LineItem;
  paymentData?: AdditionalData;
}

export interface IInvoice {
  balance: number;
  bookingId: string;
  booking: IBooking;
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
  dueDate: Date;
  id: string;
  transactionNumber: number;
  lineItems: LineItem[];
  linkedPaymentIds: string[];
  member: IUser;
  pilot?: {
    firstName: string;
  };
  memberId: string;
  status: InvoiceStatus;
  paymentCurrency: currencyTypes;
  type: InvoiceType;
  totalAmount: number;
  clubPaysFees: boolean;
  notes?: string;
  payments?: Array<{
    status: string;
    amount: number;
  }>;
  totalCost?: number;
}

export const isNewInvoice = (invoice: any) => !!invoice?.lineItems?.length;
