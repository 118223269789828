// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { Card, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SoftBox from "../SoftBox";
// import Draggable from "react-draggable";

function ModalContainer({
  handleClose,
  width = 800,
  children,
  overflow = "scroll",
  showCloseButton = true,
  padding = { xs: 1, sm: 4 },
  py = { xs: 3, sm: 4 },
  extraStyles = {},
}) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width,
    maxWidth: "100vw",
    bgcolor: "background.paper",
    border: "none",
    borderRadius: "10px",
    overflow: overflow,
    maxHeight: "90vh",
  };

  return (
    // <Draggable handle=".drag-handle" positionOffset={{ x: "-50%", y: "-50%" }}>
    <Card sx={{ ...style, ...extraStyles }}>
      {showCloseButton && (
        <SoftBox
          sx={{
            position: "sticky",
            top: 0,
            right: 0,
            color: "text.secondary",
            zIndex: 100,
          }}
        >
          <IconButton
            aria-label="delete"
            className="modal-close-btn"
            onClick={handleClose}
            size="large"
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        </SoftBox>
      )}
      <SoftBox className="drag-handle" sx={{ padding, py }}>
        {children}
      </SoftBox>
    </Card>
    // </Draggable>
  );
}

// Typechecking props for BaseLayout
ModalContainer.propTypes = {
  handleClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  overflow: PropTypes.string,
  width: PropTypes.number || PropTypes.string,
};

export default ModalContainer;
