import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import SendIcon from "@mui/icons-material/Send";
import AirplanemodeActiveIcon from "@mui/icons-material/AirplanemodeActive";
import AirplaneTicketIcon from "@mui/icons-material/AirplaneTicket";
import ErrorOutline from "@mui/icons-material/ErrorOutline";

export const BookingInfoDynamicProps = [
  {
    label: "Aircraft",
    accessor: (booking) => booking.extendedProps.aircraft?.label || "Ground",
    icon: AirplanemodeActiveIcon,
    aircraftSquawkIcon: (
      <ErrorOutline
        sx={{
          color: "red",
          width: "16px",
          height: "16px",
          marginLeft: "-17px",
          position: "absolute",
        }}
      />
    ),
  },
  {
    label: "Reservation #",
    accessor: (booking) => booking.bookingNumber || booking.id,
    icon: ConfirmationNumberIcon,
  },
  {
    label: "Confirmed",
    accessor: (booking) => (booking.confirmed ? "Yes" : "No"),
    icon: ThumbUpAltIcon,
  },
  {
    label: "Completed",
    accessor: (booking) => (booking.completed ? "Yes" : "No"),
    icon: CheckCircleIcon,
  },
  {
    label: "Dispatched",
    accessor: (booking) => (booking.dispatched ? "Yes" : "No"),
    icon: SendIcon,
  },
  {
    label: "Type",
    accessor: (booking) => booking.extendedProps.type?.label,
    icon: AirplaneTicketIcon,
  },
];
