import { useMemo, useEffect, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-table components
import {
  useTable,
  usePagination,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// @mui material components
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftSelect from "src/components/SoftSelect";
import SoftInput from "src/components/SoftInput";
import SoftPagination from "src/components/SoftPagination";

// Soft UI Dashboard PRO React example components
import DataTableHeadCell from "./DataTableHeadCell";
import DataTableBodyCell from "./DataTableBodyCell";

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // styles we need to apply on draggables
  ...draggableStyle,

  ...(isDragging && {
    background: "rgb(235,235,235)",
  }),
});

const DraggableComponent = (id, index, canDrag) =>
  function (props) {
    if (!canDrag) {
      return <TableRow {...props}>{props.children}</TableRow>;
    }
    return (
      <Draggable draggableId={id} index={index} isDragDisabled={!canDrag}>
        {(provided, snapshot) => (
          <TableRow
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
            {...props}
          >
            {props.children}
          </TableRow>
        )}
      </Draggable>
    );
  };

const DroppableComponent = (onDragEnd) =>
  function (props) {
    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="1" direction="vertical">
          {(provided) => (
            <TableBody
              ref={provided.innerRef}
              {...provided.droppableProps}
              {...props}
            >
              {props.children}
              {provided.placeholder}
            </TableBody>
          )}
        </Droppable>
      </DragDropContext>
    );
  };

function DataTable({
  entriesPerPage = {
    defaultValue: 10,
    entries: [5, 10, 15, 20, 25],
  },
  canSearch = false,
  showTotalEntries = true,
  table,
  pagination = {
    variant: "gradient",
    color: "info",
  },
  isSorted = true,
  noEndBorder = false,
  onRowSelect,
  selectedRowId,
  onRowMove,
  onRowsChanged,
  canDrag = false,
}) {
  const defaultValue = entriesPerPage.defaultValue
    ? entriesPerPage.defaultValue
    : 10;
  const entries = entriesPerPage.entries
    ? entriesPerPage.entries
    : [5, 10, 15, 20, 25];
  const columns = useMemo(() => table.columns, [table]);
  const dataRaw = useMemo(() => table.rows, [table]);
  const [data, setData] = useState(dataRaw);

  useEffect(() => {
    setData(dataRaw);
  }, [dataRaw]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  useEffect(() => {
    if (onRowsChanged) {
      onRowsChanged(rows);
    }
  }, [rows]);

  // Set the default value for the entries per page when component mounts
  useEffect(() => setPageSize(defaultValue || 10), [defaultValue]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      data,
      result.source.index,
      result.destination.index
    ).map((item, index) => ({ ...item, index }));

    setData(items);

    onRowMove(items, result.source.index, result.destination.index);
  };

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SoftPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SoftPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0
      ? gotoPage(0)
      : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);

  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) =>
    gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart =
    pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;

  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }

  return (
    <TableContainer
      sx={{
        boxShadow: "none",
        maxWidth: "100%",
        overflow: "scroll",
        borderRadius: "0px",
      }}
    >
      {entriesPerPage || canSearch ? (
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          p={3}
        >
          {entriesPerPage && (
            <SoftBox display="flex" alignItems="center">
              <SoftBox sx={{ width: "min-content" }}>
                <SoftSelect
                  defaultValue={{
                    value: defaultValue,
                    label: defaultValue,
                  }}
                  options={entries.map((entry) => ({
                    value: entry,
                    label: entry,
                  }))}
                  onChange={setEntriesPerPage}
                  size="small"
                />
              </SoftBox>
              <SoftBox sx={{ display: { md: "block", xs: "none" } }}>
                <SoftTypography variant="caption" color="secondary">
                  &nbsp;&nbsp;entries per page
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          )}
          {canSearch && (
            <SoftBox
              width="12rem"
              ml="auto"
              sx={{ sm: { marginLeft: "10px" } }}
            >
              <SoftInput
                placeholder="Search..."
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </SoftBox>
          )}
        </SoftBox>
      ) : null}
      <Table {...getTableProps()}>
        <SoftBox component="thead">
          {headerGroups.map((headerGroup) => {
            const { key, ...otherProps } = headerGroup.getHeaderGroupProps();
            return (
              <TableRow key={key} {...otherProps}>
                {headerGroup.headers.map((column) => {
                  const { key: key2, ...otherProps2 } = column.getHeaderProps(
                    isSorted && column.getSortByToggleProps()
                  );
                  return (
                    <DataTableHeadCell
                      key={key2}
                      {...otherProps2}
                      width={column.width ? column.width : "auto"}
                      align={column.align ? column.align : "left"}
                      sorted={setSortedValue(column)}
                    >
                      {column.render("Header")}
                    </DataTableHeadCell>
                  );
                })}
              </TableRow>
            );
          })}
        </SoftBox>
        <TableBody
          {...getTableBodyProps()}
          component={DroppableComponent(onDragEnd)}
        >
          {page.map((row) => {
            prepareRow(row);
            const { key, ...otherProps } = row.getRowProps();
            return (
              <TableRow
                component={DraggableComponent(
                  row.original.id,
                  row.original.index,
                  canDrag
                )}
                selected={selectedRowId === row.original.id}
                hover={onRowSelect && true}
                sx={{
                  cursor: onRowSelect && "pointer",
                }}
                key={key}
                {...otherProps}
              >
                {row.cells.map((cell) => {
                  const { key: key2, ...otherProps2 } = cell.getCellProps();
                  return (
                    <DataTableBodyCell
                      key={key2}
                      noBorder={noEndBorder && rows.length - 1 === key}
                      align={cell.column.align ? cell.column.align : "left"}
                      onClick={onRowSelect}
                      {...otherProps2}
                    >
                      {cell.render("Cell")}
                    </DataTableBodyCell>
                  );
                })}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      <SoftBox
        display="flex"
        flexDirection={{
          xs: "column",
          sm: "row",
        }}
        justifyContent="space-between"
        alignItems={{
          xs: "flex-start",
          sm: "center",
        }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <SoftBox
            mb={{
              xs: 3,
              sm: 0,
            }}
          >
            <SoftTypography
              variant="button"
              color="secondary"
              fontWeight="regular"
            >
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </SoftTypography>
          </SoftBox>
        )}
        {pageOptions.length > 1 && (
          <SoftPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <SoftPagination item onClick={() => previousPage()}>
                <Icon
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  chevron_left
                </Icon>
              </SoftPagination>
            )}
            {renderPagination.length > 6 ? (
              <SoftBox width="5rem" mx={1}>
                <SoftInput
                  inputProps={{
                    type: "number",
                    min: 1,
                    max: customizedPageOptions.length,
                  }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </SoftBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <SoftPagination item onClick={() => nextPage()}>
                <Icon
                  sx={{
                    fontWeight: "bold",
                  }}
                >
                  chevron_right
                </Icon>
              </SoftPagination>
            )}
          </SoftPagination>
        )}
      </SoftBox>
    </TableContainer>
  );
}

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
  onRowMove: PropTypes.func,
  onRowSelect: PropTypes.func,
  onRowsChanged: PropTypes.func,
  canDrag: PropTypes.bool,
  selectedRowId: PropTypes.string
};

export default DataTable;
