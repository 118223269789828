import { useEffect, useState } from "react";
import InvoicePaymentDetails from "./InvoicePaymentDetails";
import { useLocation } from "react-router-dom";
import { createPaymentIntent } from "src/services/payments.api";

export default function InvoicePaymentDetailsWrapper({ ...rest }) {
  const [paymentIntent, setPaymentIntent] = useState(null);
  const location = useLocation();
  const { paymentRequest, fees, paymentMethod, user } = location.state;

  useEffect(() => {
    if (paymentRequest?.totalAmount) {
      createPaymentIntent({
        amount: fees.totalCharge,
        currency: "USD",
        referenceId: paymentRequest.id,
        userId: user.uid,
        clubId: paymentRequest.clubId,
        locationId: paymentRequest.locationId,
        description: "Add Funds",
        fees,
        paymentMethodTypes: paymentMethod === "ACH_FWD" ? ["bank"] : ["card"],
        capture: false,
      })
        .then((paymentIntent) => {
          setPaymentIntent(paymentIntent);
        })
        .catch((error) => {
          console.error("error", error);
        });
    }
  }, [paymentRequest, fees]);

  return paymentIntent && paymentRequest ? (
    <InvoicePaymentDetails
      paymentRequest={paymentRequest}
      paymentIntent={paymentIntent}
      paymentMethod={paymentMethod}
      fees={fees}
      user={user}
      {...rest}
    />
  ) : null;
}
