import { useEffect, useState } from "react";
import { ElementsConsumer, PaymentElement } from "@stripe/react-stripe-js";
import toastr from "toastr";
import Footer from "src/components/Footer";
import Grid from "@mui/material/Grid";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import { FormControlLabel, FormGroup } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CurrencyFormat from "react-currency-format";
import { getFunctions, httpsCallable } from "firebase/functions";
import { getClubLocationsCollection } from "src/features/club/collections";
import useRealtimeDocumentData from "src/features/firebase/firestore/useRealtimeDocumentData";
import { doc } from "firebase/firestore";
import { calculateFees } from "src/features/utils";
import { ArrowBack } from "@mui/icons-material";

function RenderInput({
  name,
  label,
  errors,
  control,
  fullWidth = false,
  type = "text",
  disabled = false,
}) {
  return (
    <Grid item xs={12} md={fullWidth ? 12 : 6}>
      <SoftBox mb={0.1} lineHeight={0}>
        <SoftBox mb={0.5} ml={0.5}>
          <SoftTypography component="label" variant="caption" fontWeight="bold">
            {label}
          </SoftTypography>
        </SoftBox>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value, ref: inputRef } }) => (
            <SoftInput
              onChange={onChange}
              inputRef={inputRef}
              value={value}
              disabled={disabled}
              type={type}
            />
          )}
        />
        {errors?.[name]?.message && (
          <SoftTypography marginTop={1} fontSize={12} color="error">
            {errors[name].message}
          </SoftTypography>
        )}
      </SoftBox>
    </Grid>
  );
}

function IntroFlightsPayment({
  stripe,
  elements,
  bookingData,
  paymentIntentId,
}) {
  const { clubId, locationId } = useParams();
  const navigate = useNavigate();
  const [createAccount, setCreateAccount] = useState(false);
  const [preTaxCost, setPreTaxCost] = useState(0);
  const [taxCost, setTaxCost] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { introFlight } = bookingData;

  const { data: location } = useRealtimeDocumentData(
    doc(getClubLocationsCollection(clubId), locationId)
  );

  useEffect(() => {
    const priceBeforeTax = introFlight?.totalPrice || 0;
    setPreTaxCost(priceBeforeTax);

    if (location?.preferences) {
      const taxRate = introFlight.taxRate / 100 || 0;
      const taxFees = priceBeforeTax * taxRate;

      const fees = calculateFees(
        priceBeforeTax + taxFees,
        undefined,
        undefined,
        location.preferences.clubPaysFees,
        false,
        "pm_"
      );
      setTaxCost(fees.chargeToPilot - priceBeforeTax);
      setTotalCost(fees.chargeToPilot);
    }
  }, [location]);

  const schema = yup
    .object({
      password: yup.string().when("createAccount", {
        is: true,
        then: yup
          .string()
          .min(6, "Password must be at least 6 characters")
          .required("A password is required."),
      }),
    })
    .required();

  const {
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {},
  });

  const onSubmit = async (data) => {
    if (!stripe || !elements) {
      return;
    }
    setIsSubmitting(true);

    const functions = getFunctions();
    const createIntroFlightUser = httpsCallable(
      functions,
      "createIntroFlightUser"
    );
    const { data: userId } = await createIntroFlightUser({
      clubId,
      locationId,
      bookingData: {
        ...bookingData,
        ...data,
        createAccount,
      },
    });

    const stripeResult = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/intro-flight/${clubId}/${locationId}/payment`,
        payment_method_data: {
          billing_details: {
            name: bookingData.passengerData.name,
            email: bookingData.passengerData.email,
          },
          allow_redisplay: "always",
        },
      },
      redirect: "if_required",
    });

    if (stripeResult.error) {
      console.error("Stripe Payment Error:", stripeResult.error.message);
      toastr.error(stripeResult.error.message);
      setIsSubmitting(false);
    } else {
      const createIntroFlightBooking = httpsCallable(
        functions,
        "createIntroFlightBooking"
      );
      createIntroFlightBooking({
        clubId,
        locationId,
        userId,
        bookingData: {
          ...bookingData,
          ...data,
          createAccount,
        },
        stripePaymentIntentId: paymentIntentId,
      }).then((result) => {
        setIsSubmitting(false);
        navigate(
          `/intro-flight/${clubId}/confirmation/${result.data.clubBillingEntryId}`,
          {
            replace: true,
          }
        );
      });
    }
  };

  const handleAccountCreationToggle = (event) => {
    setCreateAccount(event.target.checked);
  };

  return (
    <SoftBox
      display="flex"
      flexDirection="column"
      alignItems="center"
      component="form"
      role="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <SoftButton
        onClick={() => navigate(-1)}
        sx={{ left: 0, top: 1, position: "absolute" }}
        color="dark"
        variant="button"
      >
        <ArrowBack fontSize="medium" lineHeight={2} marginRight={2} />

        <SoftTypography variant="h6" fontWeight="light">
          &nbsp;&nbsp;&nbsp;Back
        </SoftTypography>
      </SoftButton>
      <Grid container spacing={4} width={0.8} p={5}>
        <Grid item md={12}>
          <SoftTypography variant="h3" p={2} alignSelf="flex-start">
            Checkout/Payment
          </SoftTypography>
        </Grid>
        <Grid item md={6} sm={12}>
          <SoftBox bgColor="white" borderRadius="12px" m={2} p={2}>
            <SoftBox mt={2} mb={2}>
              <SoftTypography mb={1} variant="h5">
                Your Order
              </SoftTypography>
              <hr />
            </SoftBox>

            <SoftBox mb={2}>
              <SoftTypography variant="h6" fontWeight="medium">
                Cost Summary
              </SoftTypography>
            </SoftBox>
            <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
              <SoftBox>
                <SoftTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                >
                  Flight Cost:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body2" fontWeight="medium">
                  <CurrencyFormat
                    value={Math.round((preTaxCost || 0) * 100) / 100 || 0}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
            {taxCost > 0 && (
              <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
                <SoftBox>
                  <SoftTypography
                    variant="button"
                    fontWeight="regular"
                    // color={transaction.clubPaysFees ? 'error' : 'text'}
                  >
                    Taxes & Fees:
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml={1}>
                  <SoftTypography
                    variant="body2"
                    fontWeight="medium"
                    // color={transaction.clubPaysFees ? 'error' : 'text'}
                  >
                    <CurrencyFormat
                      value={Math.round(taxCost * 100) / 100}
                      displayType="text"
                      decimalScale={2}
                      fixedDecimalScale
                      thousandSeparator
                      prefix="$"
                    />
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
            )}
            <SoftBox display="flex" justifyContent="space-between" mt={3}>
              <SoftBox>
                <SoftTypography variant="body1" fontWeight="light" color="text">
                  Total:
                </SoftTypography>
              </SoftBox>
              <SoftBox ml={1}>
                <SoftTypography variant="body1" fontWeight="medium">
                  <CurrencyFormat
                    value={Math.round(totalCost * 100) / 100}
                    displayType="text"
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    prefix="$"
                  />
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <SoftBox bgColor="white" borderRadius="12px" m={2} p={2}>
            <SoftBox mt={2} mb={1}>
              <SoftTypography mb={1} variant="h5">
                Create account (optional)
              </SoftTypography>
              <hr />
              <SoftTypography variant="caption" mt={2}>
                Creating an account will allow you to view your flight/payment
                history and manage your booking
              </SoftTypography>
              <SoftBox p={2} />
            </SoftBox>

            <Grid container spacing={2}>
              <SoftBox px={4}>
                <Controller
                  control={control}
                  name="createAccount"
                  render={({ field: { onChange, ref: inputRef } }) => (
                    <FormGroup>
                      <FormControlLabel
                        inputRef={inputRef}
                        control={<Checkbox defaultChecked />}
                        checked={createAccount}
                        onChange={(e) => {
                          onChange(e);
                          handleAccountCreationToggle(e);
                        }}
                        label="Do you want to create an account?"
                      />
                    </FormGroup>
                  )}
                />
              </SoftBox>

              <RenderInput
                name="password"
                label="Password"
                errors={errors}
                control={control}
                type="password"
                disabled={!createAccount}
                fullWidth
              />
            </Grid>
          </SoftBox>
        </Grid>
        <Grid item md={6} sm={12}>
          <SoftBox bgColor="white" borderRadius="12px" m={2} p={2}>
            <SoftBox mx={4} my={2}>
              <PaymentElement />
              <SoftBox display="flex" flexDirection="row-reverse" mt={4}>
                <SoftButton
                  type="submit"
                  disabled={isSubmitting}
                  color="primary"
                >
                  Proceed with payment
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
      <SoftBox display="flex" justifyContent="center">
        <SoftBox width={450}>
          <Footer />
        </SoftBox>
      </SoftBox>
    </SoftBox>
  );
}

export default function InjectedIntroFlightsPayment({ ...rest }) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <IntroFlightsPayment stripe={stripe} elements={elements} {...rest} />
      )}
    </ElementsConsumer>
  );
}
