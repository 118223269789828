import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useClubs } from "src/features/club/ClubProvider";

// @mui material components
import Card from "@mui/material/Card";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

// Data
import { getTableColumns } from "./tableColumns";
import { getDocs, where, query } from "firebase/firestore";
import { AppBar } from "@mui/material";

import TabPanel from "src/components/TabPanel";
import { IAccount, IInvoice, ILocationUser, IUseClub } from "src/interfaces";
import { SoftCard } from "src/components/SoftCard/SoftCard";
import { SoftTab, SoftTabs } from "src/components/SoftTabs/SoftTabs";
import { useTabOrientation } from "src/hooks/useTabOrientation";
import {
  getClubInvoices,
  getClubPayments,
} from "src/features/club/collections";

type rowTableType = ILocationUser & {
  showUser: boolean;
  accountBalance?: IAccount;
} & {
  accountId?: string;
};

function AccountPage() {
  const navigate = useNavigate();
  const { locationUsers, selectedClubId, clubAccounts } =
    useClubs() as IUseClub;
  const [tableRows, setTableRows] = useState<Array<rowTableType>>([]);
  // const [locationUsers, setLocationUsers] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const { tabsOrientation } = useTabOrientation();

  const selectedClubAccount = useMemo(() => {
    if (tabValue === 0) return "standard";
    return clubAccounts[tabValue - 1];
  }, [tabValue, clubAccounts]);

  const addUserAccountBalance = async () => {
    const usersWithBalance = await Promise.all(
      locationUsers.map(async (user) => {
        let invoices: IInvoice[] = [];
        let payments: any[] = [];

        if (!selectedClubAccount || selectedClubAccount === "standard") {
          const invoicesRef = await getDocs(
            query(
              getClubInvoices(selectedClubId),
              where("memberId", "==", user.id)
            )
          );
          invoices = invoicesRef.docs
            .filter((doc) => !doc.data().accountId)
            .map(
              (doc) =>
                ({
                  ...doc.data(),
                  id: doc.id,
                } as IInvoice)
            );

          const paymentsRef = await getDocs(
            query(
              getClubPayments(selectedClubId),
              where("userId", "==", user.id)
            )
          );
          payments = paymentsRef.docs
            .filter((doc) => !doc.data().accountId)
            .map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
        } else {
          const invoicesRef = await getDocs(
            query(
              getClubInvoices(selectedClubId),
              where("memberId", "==", user.id),
              where("accountId", "==", selectedClubAccount.id)
            )
          );
          invoices = invoicesRef.docs.map(
            (doc) =>
              ({
                ...doc.data(),
                id: doc.id,
              } as IInvoice)
          );

          const paymentsRef = await getDocs(
            query(
              getClubPayments(selectedClubId),
              where("userId", "==", user.id),
              where("accountId", "==", selectedClubAccount.id)
            )
          );
          payments = paymentsRef.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
        }

        const invoicesBalance = invoices.reduce(
          (acc, invoice) => acc - (invoice?.balance || 0),
          0
        );

        const paymentsBalance = payments.reduce(
          (acc, payment) => acc + (payment?.unappliedAmount || 0),
          0
        );

        const accountBalance = invoicesBalance + paymentsBalance;

        if (invoices.length > 0 || payments.length > 0) {
          return {
            ...user,
            accountBalance: accountBalance ?? 0,
            showUser: true,
          };
        } else {
          return {
            ...user,
            accountBalance: accountBalance ?? 0,
            showUser: false,
          };
        }
      })
    );

    setTableRows(
      usersWithBalance.sort((a, b) => a.accountBalance - b.accountBalance)
    );
  };

  useEffect(() => {
    addUserAccountBalance();
  }, [locationUsers, selectedClubAccount]);

  const onRowSelected = (row: IAccount) => {
    if (row?.accountId)
      navigate(`/billing/accounts/${row.id}?accountId=${row.accountId}`);
    else navigate(`/billing/accounts/${row.id}`);
  };

  const getTableRows = () => {
    return tableRows.filter(
      (user) => selectedClubAccount === "standard" || user.showUser
    );
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftCard
          styles={{
            flexDirection: "row",
            alignItems: "center",
            fontWeight: 600,
            marginBottom: "12px",
            marginTop: 0,
          }}
        >
          {/* <ChevronLeft sx={{ cursor: "pointer" }} onClick={goBack} /> */}
          Accounts
        </SoftCard>
        {/* <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        ></SoftBox> */}
        <Card>
          <SoftBox
            display="inline-flex"
            justifyContent="flex-start"
            alignItems="center"
          >
            {clubAccounts?.length >= 1 && (
              <SoftBox mt={2} display="flex">
                <AppBar position="static">
                  <SoftTabs
                    variation="secondary"
                    orientation={tabsOrientation}
                    value={tabValue}
                    onChange={(_, newValue) => setTabValue(newValue)}
                    sx={{
                      backgroundColor: "transparent",
                      margin: "0px 20px",
                    }}
                  >
                    <SoftTab label="Standard" />
                    {clubAccounts.map((account) => (
                      <SoftTab key={account.id} label={`${account.name}`} />
                    ))}
                  </SoftTabs>
                </AppBar>
              </SoftBox>
            )}
          </SoftBox>
          <SoftBox mt={2}>
            <TabPanel value={tabValue} index={0}>
              {!!tableRows?.length && (
                <DataTable
                  onRowSelect={onRowSelected}
                  table={{
                    columns: getTableColumns(),
                    rows: getTableRows(),
                  }}
                  entriesPerPage={{
                    defaultValue: 10,
                    entries: [10, 20, 50, 100],
                  }}
                  canSearch
                />
              )}
            </TabPanel>
            {clubAccounts.map((account, index) => {
              return (
                <TabPanel value={tabValue} key={account?.id} index={index + 1}>
                  {!!tableRows?.length && (
                    <DataTable
                      onRowSelect={onRowSelected}
                      table={{
                        columns: getTableColumns(),
                        rows: getTableRows(),
                      }}
                      entriesPerPage={{
                        defaultValue: 10,
                        entries: [10, 20, 50, 100],
                      }}
                      canSearch
                    />
                  )}
                </TabPanel>
              );
            })}
          </SoftBox>
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AccountPage;
