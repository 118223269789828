import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import SoftButton from "src/components/SoftButton";

// Soft UI Dashboard PRO React base styles
import borders from "src/assets/theme/base/borders";

// Images
import masterCardLogo from "src/assets/images/logos/mastercard.png";
import visaCardLogo from "src/assets/images/logos/visa.png";

import { formatCurrency } from "src/features/utils";

function FwdPaymentDetails({ payment }) {
  const { borderWidth, borderColor } = borders;

  const renderPayments = () => (
    <>
      <SoftTypography variant="h6" fontWeight="medium">
        Payment Details
      </SoftTypography>
      {payment?.paymentData?.map((paymentData) => (
        <SoftBox
          border={`${borderWidth[1]} solid ${borderColor}`}
          borderRadius="lg"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          key={paymentData.id}
          p={2}
          mt={2}
        >
          <SoftBox
            component="img"
            src={
              paymentData?.payment_method?.card?.brand === "visa"
                ? visaCardLogo
                : masterCardLogo
            }
            alt={paymentData?.payment_method?.card?.brand}
            width="10%"
            mr={2}
          />
          <SoftBox display="flex" flexDirection="column" alignItems="start">
            <SoftTypography
              display="block"
              style={{ marginBottom: "5px" }}
              variant="caption"
              fontWeight="medium"
            >
              Card Payment: {formatCurrency(paymentData.amount / 100)}
            </SoftTypography>
            <SoftTypography variant="h6" fontWeight="medium">
              ****&nbsp;&nbsp;****&nbsp;&nbsp;****&nbsp;&nbsp;
              {paymentData?.payment_method?.card?.last_four_digits}
            </SoftTypography>
          </SoftBox>
          <SoftBox ml="auto" lineHeight={0}>
            <Tooltip
              title="We do not store card details, just the last 4 digits"
              placement="bottom"
            >
              <SoftButton
                variant="outlined"
                color="secondary"
                size="small"
                iconOnly
                circular
              >
                <Icon
                  sx={{
                    cursor: "pointer",
                  }}
                >
                  priority_high
                </Icon>
              </SoftButton>
            </Tooltip>
          </SoftBox>
        </SoftBox>
      ))}
    </>
  );

  return renderPayments();
}

FwdPaymentDetails.defaultProps = {
  payment: PropTypes.object.isRequired,
};

export default FwdPaymentDetails;
