// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";

// Billing page components
import BaseLayout from "src/containers/LayoutContainers/BaseLayout";
import PendingPayments from "src/pages/user/account/billing/components/PendingPayments";
import Memberships from "src/pages/user/account/billing/components/Memberships";
import PaymentMethods from "src/components/PaymentMethods";
import { useUser } from "src/features/user/UserProvider";
import AccountDetails from "src/pages/club-billing/accounts-new/AccountDetails";

function Billing() {
  const { user, userId } = useUser();
  return (
    <BaseLayout stickyNavbar>
      <SoftBox mt={4}>
        <SoftBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={7}>
              <SoftBox mb={3}>
                <AccountDetails userId={userId} />
              </SoftBox>
            </Grid>
            <Grid item xs={12} md={5}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Memberships />
                </Grid>
                <Grid item xs={12}>
                  <PendingPayments />
                </Grid>
                <Grid item xs={12}>
                  <PaymentMethods user={user} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </SoftBox>
      </SoftBox>
    </BaseLayout>
  );
}

export default Billing;
