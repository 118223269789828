import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import CurrencyFormat from "react-currency-format";
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";
import UserType from "src/features/user-permissions/UserType";

function PaymentSummary({ payment }) {
  const { userPermissions } = useUserPermissions();
  console.log("PAYMENT", payment);
  return (
    <>
      <SoftBox mb={2}>
        <SoftTypography variant="h6" fontWeight="medium">
          Payment Summary
        </SoftTypography>
      </SoftBox>
      <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
        <SoftBox>
          <SoftTypography variant="button" fontWeight="regular" color="text">
            Amount:
          </SoftTypography>
        </SoftBox>
        <SoftBox ml={1}>
          <SoftTypography variant="body2" fontWeight="medium" color="text">
            <CurrencyFormat
              value={(payment?.amount || 0) / 100}
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator
              prefix="$"
            />
          </SoftTypography>
        </SoftBox>
      </SoftBox>
      {userPermissions?.userType !== UserType.SUPERADMIN &&
      userPermissions?.userType !== UserType.ADMIN &&
      userPermissions?.userType !== UserType.RECEPTIONIST &&
      payment.clubPaysFees ? null : (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftBox>
            <SoftTypography
              variant="button"
              fontWeight="regular"
              color={payment.clubPaysFees ? "error" : "text"}
            >
              Service Fees:
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={1}>
            <SoftTypography
              variant="body2"
              fontWeight="medium"
              color={payment.clubPaysFees ? "error" : "text"}
            >
              <CurrencyFormat
                value={(payment?.fees || 0) / 100}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                prefix="$"
              />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      )}
      {payment.refunded && payment.refunded > 0 ? (
        <SoftBox display="flex" justifyContent="space-between" mb={0.5}>
          <SoftBox>
            <SoftTypography variant="button" fontWeight="regular" color="error">
              Refunded:
            </SoftTypography>
          </SoftBox>
          <SoftBox ml={1}>
            <SoftTypography variant="body2" fontWeight="medium" color="error">
              <CurrencyFormat
                value={(payment.refunded || 0) / 100}
                displayType="text"
                decimalScale={2}
                fixedDecimalScale
                thousandSeparator
                prefix="$"
              />
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      ) : null}
      <SoftBox display="flex" justifyContent="space-between" mt={3}>
        <SoftBox>
          <SoftTypography variant="body1" fontWeight="light" color="text">
            Total:
          </SoftTypography>
        </SoftBox>
        <SoftBox ml={1}>
          <SoftTypography variant="body1" fontWeight="medium">
            <CurrencyFormat
              value={(payment?.totalAmount || 0) / 100}
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator
              prefix="$"
            />
          </SoftTypography>
        </SoftBox>
      </SoftBox>
    </>
  );
}

PaymentSummary.propTypes = {
  payment: PropTypes.object.isRequired,
};

export default PaymentSummary;
