import IdCell from "./components/IdCell";
import DefaultCell from "./components/DefaultCell";
import StatusCell from "./components/StatusCell";
import CustomerCell from "./components/CustomerCell";
import CurrencyCell from "./components/CurrencyCell";

import { format } from "date-fns";
import { getProfileImageURL } from "src/features/utils";
import { paymentStatusDisplay } from "src/interfaces";
import { toTitleCase } from "src/features/utils";

export const TableActions = {
  SELECTED: "SELECTED",
};

export const getTableColumns = () => [
  {
    Header: "id",
    accessor: "transactionNumber",
    Cell: ({ value }) => <IdCell id={value} />,
  },
  {
    Header: "date",
    accessor: "date",
    Cell: ({ value }) => (
      <DefaultCell
        value={value ? format(value.toDate(), "eee, MMM do yyyy") : "N/A"}
      />
    ),
  },
  {
    Header: "status",
    width: 100,
    accessor: "status",
    Cell: ({ row: { original } }) => {
      return (
        <StatusCell
          icon={paymentStatusDisplay?.[original.status]?.icon}
          color={paymentStatusDisplay?.[original.status]?.color}
          status={paymentStatusDisplay?.[original.status]?.text}
          circular={paymentStatusDisplay?.[original.status]?.circular}
        />
      );
    },
  },
  {
    Header: "member",
    accessor: ({ member, pilot }) => member?.displayName || pilot?.displayName,
    Cell: ({ row: { original } }) => {
      const { pilot, member } = original;
      return (
        <CustomerCell
          image={getProfileImageURL({ pilot })}
          color="dark"
          name={pilot?.displayName || member?.displayName}
        />
      );
    },
  },
  {
    Header: "Payment Method",
    accessor: "type",
    Cell: ({ value }) => {
      if (!value) return <DefaultCell value="---" />;
      const [name, data] = value;

      return (
        <DefaultCell
          value={
            typeof value === "string"
              ? toTitleCase(value?.split("_")?.[0])
              : name
          }
          suffix={data.suffix || false}
        />
      );
    },
  },
  {
    Header: "amount",
    accessor: "totalCost",
    width: 100,
    Cell: ({ value, row: { original } }) => {
      if (original.totalAmount) {
        return <CurrencyCell value={original.totalAmount / 100} />;
      }
      return <CurrencyCell value={value} />;
    },
  },
  {
    Header: "balance",
    accessor: "unappliedAmount",
    width: 100,
    Cell: ({ value }) => {
      return <CurrencyCell value={value / 100} />;
    },
  },
];
