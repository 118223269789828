import { IPermission } from "./permission.interface";

export enum scheduleRoleCategory {
  SCHEDULE_PAGES = "SCHEDULE_PAGES",
  SCHEDULING = "SCHEDULING",
}

export enum schedulePagesPermissionTypes {
  VIEW_BOOK_A_FLIGHT = "VIEW_BOOK_A_FLIGHT",
  VIEW_MY_SCHEDULE = "VIEW_MY_SCHEDULE",
  VIEW_INSTRUCTORS_SCHEDULE = "VIEW_INSTRUCTORS_SCHEDULE",
  VIEW_AIRCRAFT_SCHEDULE = "VIEW_AIRCRAFT_SCHEDULE",
  VIEW_LOG_BOOK = "VIEW_LOG_BOOK",
}

export enum schedulingPermissionTypes {
  CREATE_BOOKING = "CREATE_BOOKING",
  CREATE_BOOKING_FOR_OTHERS = "CREATE_BOOKING_FOR_OTHERS",
  CREATE_BOOKING_WITH_PILOT2 = "CREATE_BOOKING_WITH_PILOT2",
  CREATE_INTRO_FLIGHT = "CREATE_INTRO_FLIGHT",
  CREATE_MX_BOOKING = "CREATE_MX_BOOKING",
  MODIFY_OWN_BOOKING = "MODIFY_OWN_BOOKING",
  MODIFY_OTHERS_BOOKING = "MODIFY_OTHERS_BOOKING",
  CANCEL_OWN_BOOKING = "CANCEL_OWN_BOOKING",
  CANCEL_OTHERS_BOOKING = "CANCEL_OTHERS_BOOKING",
  VIEW_OWN_BOOKING_DETAILS = "VIEW_OWN_BOOKING_DETAILS",
  VIEW_OTHERS_BOOKING_DETAILS = "VIEW_OTHERS_BOOKING_DETAILS",
  CHECK_OUT_OWN_BOOKING = "CHECK_OUT_OWN_BOOKING",
  CHECK_OUT_OTHERS_BOOKING = "CHECK_OUT_OTHERS_BOOKING",
  CHECK_IN_BOOKINGS_WITHOUT_GRADES = "CHECK_IN_BOOKINGS_WITHOUT_GRADES",
  CHECK_IN_OWN_BOOKING = "CHECK_IN_OWN_BOOKING",
  CHECK_IN_OTHERS_BOOKING = "CHECK_IN_OTHERS_BOOKING",
  OVERRIDE_SCHEDULING_LIMITATIONS = "OVERRIDE_SCHEDULING_LIMITATIONS",
  OVERRIDE_BOOKING_REQUIREMENTS = "OVERRIDE_BOOKING_REQUIREMENTS",
  BYPASS_MEMBERSHIP_REQUIREMENTS = "BYPASS_MEMBERSHIP_REQUIREMENTS",
  VIEW_INTERNAL_NOTES = "VIEW_INTERNAL_NOTES",
  ACCEPT_OTHERS_PENDING_BOOKINGS = "ACCEPT_OTHERS_PENDING_BOOKINGS",
  ALLOW_OVERLAPPING_BOOKINGS = "ALLOW_OVERLAPPING_BOOKINGS",
  ALLOW_BOOKING_PAST = "ALLOW_BOOKING_PAST",
}

export const schedulePermissions: IPermission[] = [
  {
    group: scheduleRoleCategory.SCHEDULE_PAGES,
    label: "Schedule Pages",
    tooltipGroup: "",
    permissions: {
      [schedulePagesPermissionTypes.VIEW_BOOK_A_FLIGHT]: {
        label: "View Book A Flight",
        tooltip: "",
      },
      [schedulePagesPermissionTypes.VIEW_MY_SCHEDULE]: {
        label: "View My Schedule",
        tooltip: "",
      },
      [schedulePagesPermissionTypes.VIEW_INSTRUCTORS_SCHEDULE]: {
        label: "View Instructors Schedule",
        tooltip: "",
      },
      [schedulePagesPermissionTypes.VIEW_AIRCRAFT_SCHEDULE]: {
        label: "View Aircraft Schedule",
        tooltip: "",
      },
      [schedulePagesPermissionTypes.VIEW_LOG_BOOK]: {
        label: "View Log Book",
        tooltip: "",
      },
    },
  },
  {
    group: scheduleRoleCategory.SCHEDULING,
    label: "Scheduling",
    tooltipGroup: "",
    permissions: {
      [schedulingPermissionTypes.VIEW_OWN_BOOKING_DETAILS]: {
        label: "View Own Bookings Details",
        tooltip: "",
      },
      [schedulingPermissionTypes.VIEW_OTHERS_BOOKING_DETAILS]: {
        label: "View All Booking Details",
        tooltip: "",
      },
      [schedulingPermissionTypes.CREATE_BOOKING]: {
        label: "Create Bookings",
        tooltip: "",
      },
      [schedulingPermissionTypes.CREATE_BOOKING_FOR_OTHERS]: {
        label: "Create Bookings for Others",
        tooltip: "",
      },
      [schedulingPermissionTypes.CREATE_BOOKING_WITH_PILOT2]: {
        label: "Create Bookings with Member 2",
        tooltip: "",
      },
      [schedulingPermissionTypes.CREATE_INTRO_FLIGHT]: {
        label: "Create Intro Flight Bookings",
        tooltip: "",
      },
      [schedulingPermissionTypes.CREATE_MX_BOOKING]: {
        label: "Create Maintenance Bookings",
        tooltip: "",
      },
      [schedulingPermissionTypes.MODIFY_OWN_BOOKING]: {
        label: "Modify Own Bookings",
        tooltip: "",
      },
      [schedulingPermissionTypes.MODIFY_OTHERS_BOOKING]: {
        label: "Modify All Bookings",
        tooltip: "",
      },
      [schedulingPermissionTypes.CANCEL_OWN_BOOKING]: {
        label: "Cancel Own Bookings",
        tooltip: "",
      },
      [schedulingPermissionTypes.CANCEL_OTHERS_BOOKING]: {
        label: "Cancel Any Booking",
        tooltip: "",
      },
      [schedulingPermissionTypes.CHECK_OUT_OWN_BOOKING]: {
        label: "Check Out Own Bookings",
        tooltip: "",
      },
      [schedulingPermissionTypes.CHECK_OUT_OTHERS_BOOKING]: {
        label: "Check Out Any Booking",
        tooltip: "",
      },
      [schedulingPermissionTypes.CHECK_IN_OWN_BOOKING]: {
        label: "Check In Own Bookings",
        tooltip: "",
      },
      [schedulingPermissionTypes.CHECK_IN_OTHERS_BOOKING]: {
        label: "Check In Any Booking",
        tooltip: "",
      },
      [schedulingPermissionTypes.CHECK_IN_BOOKINGS_WITHOUT_GRADES]: {
        label: "Check In A Booking Without Grades",
        tooltip:
          "If the Club setting is set to require grading before it can be checked in, this permission will allow the user to check in a booking without a grade.",
      },

      [schedulingPermissionTypes.OVERRIDE_SCHEDULING_LIMITATIONS]: {
        label: "Override Scheduling Limitations",
        tooltip:
          "Scheduling limitations include min/max booking durations, min/max booking times, etc.",
      },
      [schedulingPermissionTypes.OVERRIDE_BOOKING_REQUIREMENTS]: {
        label: "Override Scheduling Compliance",
        tooltip:
          "Scheduling compliance includes membership requirements, currency requirements, MX requirements, etc.",
      },
      [schedulingPermissionTypes.BYPASS_MEMBERSHIP_REQUIREMENTS]: {
        label: "Bypass Membership Requirements for Aircraft",
        tooltip: "",
      },
      [schedulingPermissionTypes.VIEW_INTERNAL_NOTES]: {
        label: "View Internal Booking Notes",
        tooltip: "",
      },
      [schedulingPermissionTypes.ACCEPT_OTHERS_PENDING_BOOKINGS]: {
        label: "Accept Others Pending Bookings",
        tooltip: "",
      },
      [schedulingPermissionTypes.ALLOW_OVERLAPPING_BOOKINGS]: {
        label: "Allow Overlapping Bookings",
        tooltip: "",
      },
      [schedulingPermissionTypes.ALLOW_BOOKING_PAST]: {
        label: "Allow Booking in the past",
        tooltip: "",
      },
    },
  },
];
