import {
  FirestoreDataConverter,
  QueryDocumentSnapshot,
  Timestamp,
  getFirestore,
} from "firebase/firestore";
import { firebaseApp } from "src/features/firebase/index";

export const db = getFirestore(firebaseApp);

const checkForTimestamp = (data: any) => {
  for (const key in data) {
    if (data[key] instanceof Timestamp) {
      data[key] = data[key].toDate();
    }
    if (typeof data[key] === "object") {
      checkForTimestamp(data[key]);
    }
  }
  return {
    ...data,
  };
};

export const dateConverter: FirestoreDataConverter<any> = {
  toFirestore: (item) => item,
  fromFirestore: (snapshot: QueryDocumentSnapshot<any>, options) => {
    const data = snapshot.data(options);
    checkForTimestamp(data);
    return {
      ...data,
    };
  },
};
