import { collection, doc, collectionGroup } from "firebase/firestore";
import { dateConverter, db } from "src/features/firebase/firestore/utils";

const getClubBookingsCollection = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/bookings`);

const getClubIntroFlightsCollection = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/introFlights`);

const getClubMeetingRoomsCollection = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/meetingRooms`);

const getClubBookingAuditTrailCollection = (clubId, locationId, bookingId) =>
  collection(
    db,
    `clubs/${clubId}/locations/${locationId}/bookings/${bookingId}/auditTrail`
  );

const getClubBookingById = (clubId, locationId, bookingId) =>
  doc(db, `clubs/${clubId}/locations/${locationId}/bookings`, bookingId);

const getClubInstructorPreferencesCollection = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/instructors`);

const getClubHobbsMultiplierCollection = (clubId, locationId) => {
  return collection(
    db,
    `clubs/${clubId}/locations/${locationId}/hobbsMultipliers`
  );
};

const getClubsCollection = () => collection(db, "clubs");

const getClubLocationsCollection = (clubId) =>
  collection(db, `clubs/${clubId}/locations`);

const getClubMembershipsCollection = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/membershipPlans`);

// const getClubMembershipsCollection = (clubId) =>
//   collection(db, `clubs/${clubId}/membershipPlans`);

const getClubDocumentTypesCollection = (clubId) =>
  collection(db, `clubs/${clubId}/documentTypes`);

const getClubSquawkStatusesCollection = (clubId) =>
  collection(db, `clubs/${clubId}/squawkStatuses`);

const getClubEventsCollection = (clubId) =>
  collection(db, `clubs/${clubId}/events`);

const getClubCustomRatesCollection = (clubId) =>
  collection(db, `clubs/${clubId}/rentalRates`);

const getClubAccountsCollection = (clubId) =>
  collection(db, `clubs/${clubId}/accounts`);

const getClubWorkOrderCollection = (clubId) =>
  collection(db, `clubs/${clubId}/workOrders`);

const getClubWorkOrderColumnCollection = (clubId) =>
  collection(db, `clubs/${clubId}/workOrderColumns`);

const getClubInvoices = (clubId) => collection(db, `clubs/${clubId}/invoices`);
const getClubInvoiceById = (clubId, invoiceId) =>
  doc(db, `clubs/${clubId}/invoices`, invoiceId).withConverter(dateConverter);

const getClubById = (clubId) => doc(db, `clubs`, clubId);

const getClubPayments = (clubId) => collection(db, `clubs/${clubId}/payments`);

const getClubFlightCredits = (clubId) =>
  collection(db, `clubs/${clubId}/flightCredits`);

const getClubFuelReimbursements = (clubId, locationId) =>
  collection(db, `clubs/${clubId}/locations/${locationId}/fuelReimbursements`);

const getClubUserRoles = (clubId) =>
  collection(db, `clubs/${clubId}/userRoles`);

const getNotesCollectionGroup = () => collectionGroup(db, "notes");

export {
  getClubById,
  getClubLocationsCollection,
  getClubsCollection,
  getClubInstructorPreferencesCollection,
  getClubHobbsMultiplierCollection,
  getClubIntroFlightsCollection,
  getClubBookingsCollection,
  getClubBookingAuditTrailCollection,
  getClubMembershipsCollection,
  getClubDocumentTypesCollection,
  getClubSquawkStatusesCollection,
  getClubMeetingRoomsCollection,
  getClubEventsCollection,
  getClubCustomRatesCollection,
  getClubAccountsCollection,
  getClubWorkOrderCollection,
  getClubWorkOrderColumnCollection,
  getClubInvoices,
  getClubBookingById,
  getClubInvoiceById,
  getClubUserRoles,
  getClubPayments,
  getClubFlightCredits,
  getClubFuelReimbursements,
  getNotesCollectionGroup,
};
