import PropTypes from "prop-types";
import { useClubs } from "src/features/club/ClubProvider";
import { useUser } from "src/features/user/UserProvider";
import {
  fetchAircraftResourceData,
  fetchClubPilotsData,
  fetchInstructorResourceData,
  fetchMeetingRoomResourceData,
} from "src/pages/book-flight/utils";
import { useCollection } from "react-firebase-hooks/firestore";
import { query, where } from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import { add, getDay, startOfToday } from "date-fns";
import {
  getBookingTitle,
  getClassName,
} from "src/pages/book-flight/Calendar/uiHelper";

import SoftBox from "../../SoftBox";
import { Modal } from "@mui/material";

import ListCalendar from "../ListCalendar";

import BookingInfoModal from "src/modals/BookingInfoModal";
import { useEffect, useMemo, useState } from "react";
import { usePermissions } from "src/hooks/usePermissions";
import useClubMeetingRooms from "src/features/meeting-room/meetingRoomsHelper";

const DefaultBookingTypes = ["requestOnly"];

function BookingsListCalendar({
  title,
  excludeTypes = DefaultBookingTypes,
  maxBookingsCount = 4,
}) {
  const {
    selectedClub,
    selectedClubId,
    selectedLocationId,
    locationUsers,
    locationAircraft,
    locationSqauwks,
    clubUsersPermissions,
    instructors: clubInstructors,
    instructorPreferences: clubInstructorPrefs,
  } = useClubs();
  const { meetingRooms } = useClubMeetingRooms();
  const [upComingBookings, setUpComingBookings] = useState([]);
  const { userId } = useUser();
  const { isStaff } = usePermissions();
  const [openBookingInfo, setOpenBookingInfo] = useState(false);
  const [selectedBookingId, setSelectedBookingId] = useState("");
  const [resourceData, setResourceData] = useState([]);
  const [pilotData, setPilotData] = useState([]);

  const instructors = useMemo(() => {
    return fetchInstructorResourceData(clubInstructors, clubInstructorPrefs);
  }, [clubInstructors, clubInstructorPrefs]);

  const fetchData = async () => {
    const aircrafts = await fetchAircraftResourceData(
      selectedClub?.preferences?.calendar?.hideTailNumbers,
      locationAircraft,
      locationSqauwks
    );
    const pilots = fetchClubPilotsData(locationUsers, clubUsersPermissions);
    const meetingRoomData = fetchMeetingRoomResourceData(meetingRooms);
    setResourceData([...instructors, ...aircrafts, ...meetingRoomData]);
    setPilotData(pilots);
  };

  useEffect(() => {
    fetchData();
  }, [
    selectedClubId,
    selectedLocationId,
    instructors,
    meetingRooms,
    locationUsers,
    locationSqauwks,
    clubUsersPermissions,
  ]);

  const [instructorBookingsSnapshot, loadingInstructorBookings] = useCollection(
    query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("end", ">=", startOfToday()),
      where("extendedProps.instructor.value", "==", userId)
    )
  );

  const [pilotBookingsSnapshot, loadingPilotBookings] = useCollection(
    query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("end", ">=", startOfToday()),
      where("extendedProps.pilot.value", "==", userId)
    )
  );

  const [pilot2BookingsSnapshot, loadingPilot2Bookings] = useCollection(
    query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("end", ">=", startOfToday()),
      where("extendedProps.pilot2.value", "==", userId)
    )
  );

  useEffect(() => {
    if (
      loadingInstructorBookings ||
      loadingPilotBookings ||
      loadingPilot2Bookings
    )
      return;

    const instructorBookings =
      instructorBookingsSnapshot?.docs
        ?.filter((b) => {
          return (
            !excludeTypes.includes(b.data()?.extendedProps?.type?.value) &&
            !b.data()?.cancelled
          );
        })
        .map((b) => ({ ...b.data(), id: b.id })) || [];
    const pilotBookings =
      pilotBookingsSnapshot?.docs
        ?.filter(
          (b) =>
            !excludeTypes.includes(b.data()?.extendedProps?.type?.value) &&
            !b.data()?.cancelled
        )
        .map((b) => ({ ...b.data(), id: b.id })) || [];
    const pilot2Bookings =
      pilot2BookingsSnapshot?.docs
        ?.filter(
          (b) =>
            !excludeTypes.includes(b.data()?.extendedProps?.type?.value) &&
            !b.data()?.cancelled
        )
        .map((b) => ({ ...b.data(), id: b.id })) || [];

    const nowDate = new Date();

    let bookings = [];
    if (instructorBookings) {
      instructorBookings.map((booking) => {
        if (
          booking.end.toDate() < nowDate ||
          booking.start.toDate() > add(nowDate, { days: 2 })
        )
          return;

        bookings.push({
          title: getBookingTitle(booking, userId, isStaff(), selectedClub),
          id: booking.id,
          start: booking.start.toDate(),
          end: booking.end.toDate(),
          resourceIds: booking.resourceIds,
          editable: userId === booking.extendedProps.instructor?.value,
          className: getClassName(booking, userId),
          confirmed: booking.confirmed,
          extendedProps: booking.extendedProps,
        });
      });
    }

    if (pilotBookings) {
      pilotBookings.map((booking) => {
        if (
          booking.end.toDate() < nowDate ||
          booking.start.toDate() > add(nowDate, { days: 10 })
        )
          return;

        bookings.push({
          title: getBookingTitle(booking, userId, isStaff(), selectedClub),
          id: booking.id,
          start: booking.start.toDate(),
          end: booking.end.toDate(),
          resourceIds: booking.resourceIds,
          editable:
            userId === booking.extendedProps.pilot?.value ||
            userId === booking.extendedProps.pilot2?.value,
          className: getClassName(booking, userId),
          confirmed: booking.confirmed,
          extendedProps: booking.extendedProps,
        });
      });
    }

    if (pilot2Bookings) {
      pilot2Bookings.map((booking) => {
        if (
          booking.end.toDate() < nowDate ||
          booking.start.toDate() > add(nowDate, { days: 2 })
        )
          return;

        bookings.push({
          title: getBookingTitle(booking, userId, isStaff(), selectedClub),
          id: booking.id,
          start: booking.start.toDate(),
          end: booking.end.toDate(),
          resourceIds: booking.resourceIds,
          editable:
            userId === booking.extendedProps.pilot?.value ||
            userId === booking.extendedProps.pilot2?.value,
          className: getClassName(booking, userId),
          confirmed: booking.confirmed,
          extendedProps: booking.extendedProps,
        });
      });
    }

    bookings = bookings.sort((a, b) => a.start - b.start);
    bookings = bookings.slice(0, maxBookingsCount);
    setUpComingBookings(bookings);
  }, [
    instructorBookingsSnapshot,
    pilotBookingsSnapshot,
    pilot2BookingsSnapshot,
    loadingInstructorBookings,
    loadingPilotBookings,
    loadingPilot2Bookings,
    maxBookingsCount,
    userId,
    selectedClub,
    selectedLocationId,
  ]);

  const handleBookingInfoOpen = (booking) => {
    setSelectedBookingId(booking.id);
    setOpenBookingInfo(true);
  };

  const handleBookingInfoClose = () => {
    setOpenBookingInfo(false);
    setSelectedBookingId(null);
  };

  return (
    <>
      <SoftBox mb={3} position="relative">
        <ListCalendar
          header={{
            title,
          }}
          headerToolbar={false}
          initialView="listWeek"
          firstDay={getDay(startOfToday())}
          initialDate={startOfToday()}
          events={upComingBookings}
          eventClick={(info) => handleBookingInfoOpen(info.event)}
          selectable
          editable
        />
      </SoftBox>

      <Modal
        open={openBookingInfo}
        onClose={handleBookingInfoClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{
          backdropFilter: "blur(2px)",
        }}
      >
        <BookingInfoModal
          bookingId={selectedBookingId}
          handleClose={handleBookingInfoClose}
          pilots={pilotData}
          resources={resourceData}
        />
      </Modal>
    </>
  );
}

BookingsListCalendar.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  excludeTypes: PropTypes.arrayOf(PropTypes.string),
  maxBookingsCount: PropTypes.number,
  cancelled: PropTypes.bool,
  completed: PropTypes.bool,
  confirmed: PropTypes.bool,
};

export default BookingsListCalendar;
