import { useEffect, useState } from "react";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import { useUserPermissions } from "src/features/user-permissions/UserPermissionsProvider";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import { ThreeDots } from "react-loader-spinner";
import ModalContainer from "src/components/ModalContainer";
import AddNewCardModal from "src/modals/AddNewCardModal";

import {
  query,
  where,
  getDocs,
  doc,
  getDoc,
  updateDoc,
} from "firebase/firestore";
import { getClubBookingsCollection } from "src/features/club/collections";
import {
  getAircraftCollection,
  getSimulatorsCollection,
  getAircraftRemindersCollection,
} from "src/features/aircraft/collections";
import { calculateReminderRemainingTime } from "src/features/aircraft/utils";
import { isBefore } from "date-fns";
import { useUserAttributeChecker } from "src/hooks/useUserAttributeChecker";
import { useAircraftDocuments } from "src/hooks/useAircraftDocuments";
import ReservationDate from "src/components/ReservationDate";
import BookingData from "./components/BookingData";
import AircraftData from "./components/AircraftData";
import SquawksList from "src/components/SquawksList";
import MaintenanceReminders from "./components/MaintenanceReminders";
import { BookingSelector } from "src/components/BookingSelector";
import { ArrowBack } from "@mui/icons-material";
import { hasAircraftSquawks } from "src/features/utils/aircraft.utils";
import useSquawks from "src/hooks/useSquawks";
import { Modal } from "@mui/material";
import { UserInfoModal } from "src/modals/UserInfoModal";

export function CheckOutModal({ handleClose, selectedBooking }) {
  const { userId, user } = useUser();
  const { selectedClubId, selectedLocationId } = useClubs();
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingAircraft, setIsLoadingAircraft] = useState(true);
  const [booking, setBooking] = useState();
  const [bookingsToSelectFrom, setBookingsToSelectFrom] = useState();
  const [aircraft, setAircraft] = useState();
  const [reminders, setReminders] = useState([]);
  const [permissionErrors, setPermissionErrors] = useState([]);
  const { canDispatchBooking, canOverrideBooking } = useUserPermissions();
  const [displayAddCard, setDisplayAddCard] = useState(false);
  const [addCardToUserId, setAddCardToUserId] = useState(null);
  const { getAircraftDocsAgainstUser } = useAircraftDocuments();
  const [openUserInfo, setOpenUserInfo] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);

  if (selectedBooking) {
    if (!booking && !bookingsToSelectFrom) {
      setBooking(selectedBooking);
      setIsLoading(false);
    }
  }

  const { squawks } = useSquawks({
    clubId: selectedClubId,
    aircraftId: aircraft?.id,
  });

  const fetchBooking = async () => {
    const q = query(
      getClubBookingsCollection(selectedClubId, selectedLocationId),
      where("dispatched", "==", false),
      where("completed", "==", false),
      where("cancelled", "==", false),
      where("extendedProps.type.value", "not-in", [
        "unavailable",
        "meetingRoom",
        "requestOnly",
      ])
    );

    const docs = await getDocs(q);

    const bookingsList = [];
    if (docs.docs.length > 0) {
      docs.docs.map((document) => {
        const bookingData = document.data();
        if (
          bookingData.extendedProps?.pilot?.value === userId ||
          bookingData.resourceIds.includes(userId)
        ) {
          bookingsList.push({
            id: document.id,
            ...bookingData,
            snapshot: document,
          });
        }
      });

      const sortedBookings = bookingsList.sort((a, b) =>
        isBefore(a.start.toDate(), b.start.toDate()) ? -1 : 1
      );

      if (bookingsList.length > 1) {
        setBookingsToSelectFrom(sortedBookings);
      } else {
        setBooking(sortedBookings[0]);
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } else {
      setIsLoading(false);
    }
  };

  const fetchAircraft = async () => {
    let document = await getDoc(
      doc(
        getAircraftCollection(booking.extendedProps.clubId),
        booking.extendedProps.aircraft.value
      )
    );

    if (!document.data()) {
      document = await getDoc(
        doc(
          getSimulatorsCollection(booking.extendedProps.clubId),
          booking.extendedProps.aircraft.value
        )
      );
    }

    const maintenanceRemindersRef = await getDocs(
      query(
        getAircraftRemindersCollection(
          booking.extendedProps?.clubId,
          booking.extendedProps?.aircraft?.value
        ),
        where("deleted", "==", false)
      )
    );

    setAircraft({ ...document.data(), id: document.id });
    setReminders(
      maintenanceRemindersRef?.docs?.map((reminderDoc) => {
        const reminder = reminderDoc.data();
        const remaining = calculateReminderRemainingTime({
          ...reminder,
          aircraft: document.data(),
          timeBasedLastCompletedOn:
            reminder?.timeBasedLastCompletedOn?.toDate(),
        });

        return {
          ...reminder,
          remaining,
          id: reminderDoc.id,
        };
      })
    );
  };

  useEffect(() => {
    if (!selectedBooking) {
      setIsLoading(true);
      fetchBooking();
    }
  }, []);

  useEffect(() => {
    if (
      booking?.extendedProps?.aircraft?.value &&
      booking?.extendedProps?.aircraft?.value !== ""
    ) {
      fetchAircraft().then(() => {
        setIsLoadingAircraft(false);
      });
    } else {
      setIsLoadingAircraft(false);
      // setIsLoading(false);
    }
  }, [booking]);

  const { getUserMandatoryFields } = useUserAttributeChecker();

  const isSubmitting = false;

  const checkUserDetails = async (id) => {
    if (id) {
      const { errors, hasErrors: showError } = await getUserMandatoryFields(id);

      if (showError) {
        setPermissionErrors((prev) => prev.concat(errors));
        return true;
      }
    }
    return false;
  };

  const handleDispatch = async () => {
    let hasErrors = false;

    const permissionCheck = await canDispatchBooking(booking);
    if (!permissionCheck.allowed) {
      hasErrors = true;
      setPermissionErrors((prev) => prev.concat(permissionCheck.reasons));
    }

    if (hasAircraftSquawks(squawks)) {
      hasErrors = true;
      setPermissionErrors((prev) =>
        prev.concat([
          "You cannot dispatch this aircraft due to grounding squawks.",
        ])
      );
    }
    const userIds = new Set();
    if (booking.extendedProps.member?.value) {
      userIds.add(booking.extendedProps.member?.value);
    }
    if (booking.extendedProps.pilot?.value) {
      userIds.add(booking.extendedProps.pilot?.value);
    }
    if (booking.extendedProps.pilot2?.value) {
      userIds.add(booking.extendedProps.pilot2?.value);
    }
    if (booking.extendedProps.instructor?.value) {
      userIds.add(booking.extendedProps.instructor?.value);
    }

    if (
      booking?.extendedProps?.aircraft?.value &&
      booking?.extendedProps?.aircraft?.value !== ""
    ) {
      const { usersHasAllDocs, expiredAircraftDocs, missingAircraftDocs } =
        await getAircraftDocsAgainstUser({
          aircraftId: booking.extendedProps.aircraft.value,
          userIds: Array.from(userIds),
          clubId: selectedClubId,
        });

      if (!usersHasAllDocs) {
        if (missingAircraftDocs.length) {
          setPermissionErrors((prev) =>
            prev.concat(
              `The following docs are missing ${missingAircraftDocs
                .map((d) => d.name)
                .join(", ")}`
            )
          );
        }
        if (expiredAircraftDocs.length) {
          setPermissionErrors((prev) =>
            prev.concat(
              `The following docs are expired ${expiredAircraftDocs
                .map((d) => d.name)
                .join(", ")}`
            )
          );
        }
        hasErrors = true;
      }
    }

    if (
      booking?.extendedProps?.pilot?.value &&
      (await checkUserDetails(booking?.extendedProps?.pilot?.value))
    ) {
      hasErrors = true;
    }
    if (
      booking?.extendedProps?.pilot2?.value &&
      (await checkUserDetails(booking?.extendedProps?.pilot2?.value))
    ) {
      hasErrors = true;
    }
    if (
      booking?.extendedProps?.instructor?.value &&
      (await checkUserDetails(booking?.extendedProps?.instructor?.value))
    ) {
      hasErrors = true;
    }

    if (hasErrors) {
      return;
    }

    if (booking?.extendedProps?.type?.value !== "maintenance") {
      if (reminders?.length > 0) {
        let blockDispatch = false;
        reminders.forEach((reminder) => {
          if (
            reminder.remaining.percentRemaining <= 0 &&
            !reminder.nonGrounded
          ) {
            blockDispatch = true;
          }
        });
        if (blockDispatch) {
          alert(
            "You cannot dispatch this aircraft due to grounding maintenance tasks."
          );
          return;
        }
      }
    }

    handleSubmit();
  };

  const handleSubmit = async () => {
    await updateDoc(booking.snapshot.ref, {
      dispatched: true,
      dispatchedAt: new Date(),
      dispatchedBy: { id: userId, name: user.displayName },
    });
    handleClose();
  };

  const renderBackButton = () => {
    if (bookingsToSelectFrom) {
      return (
        <SoftButton
          onClick={() => setBooking(undefined)}
          sx={{ left: 0, bottom: -12, position: "absolute" }}
          color="light"
          variant="text"
        >
          <SoftBox mr={1} lineHeight={1}>
            <ArrowBack fontSize="medium" />
          </SoftBox>

          <SoftTypography variant="h6" fontWeight="light">
            Go Back
          </SoftTypography>
        </SoftButton>
      );
    }

    return null;
  };

  if (isLoading || isLoadingAircraft) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox
          p={{ xs: 0, sm: 3 }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <div style={{ transform: "scale(0.75)" }}>
            <ThreeDots
              height="80"
              width="80"
              radius="9"
              color="#329baa"
              ariaLabel="three-dots-loading"
              wrapperStyle={{}}
              wrapperClassName=""
              visible={true}
            />
          </div>
          <SoftTypography variant="h6" fontWeight="bold">
            Loading...
          </SoftTypography>
        </SoftBox>
      </ModalContainer>
    );
  }

  const showAddNewCard = () => {
    setDisplayAddCard(true);
  };
  const handleCloseAddNewCard = (value) => {
    setDisplayAddCard(false);
    if (value.success) {
      handleSubmit(booking);
    }
  };

  const handleUserInfoOpen = (userId) => {
    setSelectedUserId(userId);
    setOpenUserInfo(true);
  };

  const handleUserInfoClose = () => {
    setSelectedUserId(null);
    setOpenUserInfo(false);
  };

  if (displayAddCard) {
    return (
      <AddNewCardModal
        userId={addCardToUserId || booking.extendedProps.pilot.value}
        onClose={handleCloseAddNewCard}
      />
    );
  }

  const renderPermissionErrors = permissionErrors.map((item, key) => {
    const itemKey = `element-${key}`;
    return (
      <SoftBox
        key={itemKey}
        component="li"
        color="text"
        fontSize="1.25rem"
        lineHeight={1.25}
      >
        <SoftTypography
          variant="button"
          color="error"
          fontWeight="regular"
          verticalAlign="middle"
        >
          {item}
        </SoftTypography>
        {item.includes("No card on file") && (
          <SoftButton
            variant="text"
            color="dark"
            onClick={() => {
              if (item.includes(booking.extendedProps.pilot.label))
                setAddCardToUserId(booking.extendedProps.pilot.value);
              else if (item.includes(booking.extendedProps.pilot2.label))
                setAddCardToUserId(booking.extendedProps.pilot2.value);
              showAddNewCard();
            }}
          >
            <Icon
              sx={{
                fontWeight: "bold",
              }}
            >
              add
            </Icon>
            &nbsp;add new card
          </SoftButton>
        )}
      </SoftBox>
    );
  });

  if (permissionErrors && permissionErrors.length > 0) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox p={2} component="form" role="form">
          <SoftBox>
            <SoftTypography variant="h5" fontWeight="bold" mt={5}>
              Checklist Failed
            </SoftTypography>
            <SoftBox mt={1.625}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SoftTypography variant="h6">
                    You are not allowed to check out this reservation for the
                    following reasons:
                  </SoftTypography>
                  <SoftBox mt={1.625}>
                    <SoftBox p={2}>
                      <SoftBox
                        component="ul"
                        m={0}
                        pl={3.25}
                        mb={{
                          xs: 8,
                          sm: 0,
                        }}
                      >
                        {renderPermissionErrors}
                      </SoftBox>
                    </SoftBox>
                  </SoftBox>
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                {canOverrideBooking(booking) ? (
                  <>
                    <Grid item xs={6}>
                      <SoftBox mt={4} mb={1}>
                        <SoftButton
                          onClick={() => setPermissionErrors([])}
                          color="dark"
                          fullWidth
                          variant="outlined"
                        >
                          <ArrowBack
                            fontSize="small"
                            lineHeight={2}
                            marginRight={2}
                          />
                          &nbsp;&nbsp;&nbsp;Return to Check Out
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={6}>
                      <SoftBox mt={4} mb={1}>
                        <SoftButton
                          variant="gradient"
                          color="error"
                          fullWidth
                          onClick={() => handleSubmit(booking)}
                        >
                          Override & Dispatch
                        </SoftButton>
                      </SoftBox>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12}>
                    <SoftBox
                      mt={1}
                      display="flex"
                      alignItems="center"
                      flexDirection="row-reverse"
                    >
                      <SoftButton
                        variant="outlined"
                        color="dark"
                        onClick={handleClose}
                      >
                        Close
                      </SoftButton>
                    </SoftBox>
                  </Grid>
                )}
              </Grid>
            </SoftBox>
          </SoftBox>
        </SoftBox>
      </ModalContainer>
    );
  }

  if (!booking && bookingsToSelectFrom) {
    return (
      <ModalContainer handleClose={handleClose}>
        <SoftBox
          p={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <BookingSelector
            bookings={bookingsToSelectFrom}
            onSelectBooking={(bk) => setBooking(bk)}
          />
        </SoftBox>
      </ModalContainer>
    );
  }

  return (
    <SoftBox>
      {!booking && !isLoading && (
        <ModalContainer handleClose={handleClose}>
          <SoftBox p={2}>
            <SoftTypography variant="h5" fontWeight="bold">
              You don&rsquo;t have any bookings that can be checked out...
            </SoftTypography>
          </SoftBox>
        </ModalContainer>
      )}
      {booking && !isLoading && (
        <ModalContainer handleClose={handleClose}>
          <SoftBox p={2}>
            <SoftBox>
              <SoftBox
                display="flex"
                justifyContent="center"
                position="relative"
                alignItems="center"
              >
                {renderBackButton()}
                <SoftTypography
                  variant="h4"
                  fontWeight="bold"
                  verticalAlign="middle"
                  lineHeight={2}
                >
                  Review and Dispatch
                </SoftTypography>
              </SoftBox>
              <Divider />
              <ReservationDate
                start={booking.start.toDate()}
                end={booking.end.toDate()}
              />
              <Divider />
              <BookingData
                booking={booking}
                userSelected={
                  booking?.extendedProps?.type?.value === "introFlight"
                    ? null
                    : handleUserInfoOpen
                }
              />
              {aircraft && booking.extendedProps.aircraft.value && (
                <>
                  <AircraftData aircraft={aircraft} />
                  <Divider />
                  <SquawksList
                    clubId={booking.extendedProps.clubId}
                    aircraft={aircraft}
                  />
                  <MaintenanceReminders reminders={reminders} />
                </>
              )}
              <SoftBox
                mt={4}
                width="100%"
                display="flex"
                justifyContent="space-between"
              >
                <SoftButton
                  variant="outlined"
                  color="dark"
                  onClick={() => handleClose()}
                >
                  Close
                </SoftButton>
                <SoftButton
                  disabled={isSubmitting}
                  type="submit"
                  onClick={() => handleDispatch()}
                  color="primary"
                >
                  confirm checkout
                </SoftButton>
              </SoftBox>
            </SoftBox>
          </SoftBox>
          <Modal
            open={openUserInfo}
            onClose={handleUserInfoClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{
              backdropFilter: "blur(2px)",
            }}
          >
            <SoftBox>
              <UserInfoModal
                handleClose={handleUserInfoClose}
                userId={selectedUserId}
              />
            </SoftBox>
          </Modal>
        </ModalContainer>
      )}
    </SoftBox>
  );
}

CheckOutModal.propTypes = {
  handleClose: PropTypes.func,
  selectedBooking: PropTypes.object,
};
