import { useEffect, useState } from "react";
import ModalContainer from "src/components/ModalContainer";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftSelect from "src/components/SoftSelect";
import SoftButton from "src/components/SoftButton";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import PropTypes from "prop-types";
import { useUser } from "src/features/user/UserProvider";
import { useClubs } from "src/features/club/ClubProvider";
import { useCollection } from "react-firebase-hooks/firestore";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";
import { getClubMembershipsCollection } from "src/features/club/collections";
import { getMembershipsCollection } from "src/features/user/collections";
import CurrencyFormat from "react-currency-format";
import { query, where, deleteField } from "firebase/firestore";

const schema = yup
  .object({
    membership: yup.object().required("Please select a membership"),
  })
  .required();

export function EditMembershipModal({
  handleClose,
  selectedMembership,
  memberId,
}) {
  const { userId } = useUser();
  const { selectedClubId, selectedLocationId } = useClubs();
  const { updateData } = entityCrudUtils();
  const [membershipOptions, setMembershipOptions] = useState([]);

  const {
    handleSubmit,
    formState: { errors },
    watch,
    control,
  } = useForm({
    defaultValues: {
      membership: {
        label:
          selectedMembership.nextMembershipPlan?.label ||
          selectedMembership.membershipPlan.label,
        value:
          selectedMembership.nextMembershipPlan?.id ||
          selectedMembership.membershipPlan.id,
        membershipData:
          selectedMembership.nextMembershipPlan ||
          selectedMembership.membershipPlan,
      },
    },
    resolver: yupResolver(schema),
  });

  const newMembership = watch("membership");

  const [membershipsListSnapshot, loadingMembershipsList] = useCollection(
    query(
      getClubMembershipsCollection(selectedClubId, selectedLocationId),
      where("deleted", "==", false),
      where("active", "==", true)
    ),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
      },
    }
  );

  useEffect(() => {
    if (loadingMembershipsList) return;
    setMembershipOptions(
      membershipsListSnapshot.docs.map((membershipSnap) => ({
        label: membershipSnap.data().label,
        value: membershipSnap.id,
        membershipData: membershipSnap.data(),
      }))
    );
  }, [membershipsListSnapshot, loadingMembershipsList]);

  const onSubmit = async () => {
    const docToUpload = { updatedAt: new Date() };
    if (
      !newMembership.membershipData.cost ||
      (selectedMembership.membershipPlan.cost &&
        newMembership.membershipData.cost.amount <
          selectedMembership.membershipPlan.cost.amount)
    ) {
      docToUpload.nextMembershipPlan = newMembership.value;
    } else {
      docToUpload.membershipPlan = newMembership.value;
      docToUpload.nextMembershipPlan = deleteField();
    }

    await updateData(
      {
        entity: getMembershipsCollection(memberId || userId),
        pathSegmentsArr: [selectedClubId],
      },
      docToUpload
    );
    handleClose();
  };

  return (
    <ModalContainer handleClose={handleClose}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            Select Membership
          </SoftTypography>
          <SoftBox mt={1.625}>
            {/* {hasCost && ( */}
            <Grid container spacing={1}>
              <Grid item xs={12} sm={8}>
                <SoftBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Membership
                  </SoftTypography>
                </SoftBox>
                <Controller
                  control={control}
                  name="membership"
                  render={({ field: { onChange, value, ref } }) => (
                    <SoftSelect
                      options={membershipOptions}
                      onChange={onChange}
                      inputRef={ref}
                      value={value}
                    />
                  )}
                />
                {errors?.membership?.message && (
                  <SoftTypography marginTop={1} fontSize={12} color="error">
                    {errors.membership.message}
                  </SoftTypography>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <SoftBox mb={1} ml={3} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Cost
                  </SoftTypography>
                </SoftBox>
                <br />
                <SoftBox ml={3}>
                  {newMembership.membershipData.cost?.amount ? (
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize"
                    >
                      <CurrencyFormat
                        value={newMembership.membershipData?.cost?.amount}
                        displayType="text"
                        thousandSeparator
                        prefix="$"
                      />
                      &nbsp;({newMembership.membershipData?.cost?.billed})
                    </SoftTypography>
                  ) : (
                    <SoftTypography
                      variant="button"
                      fontWeight="regular"
                      color="text"
                      textTransform="capitalize"
                    >
                      Free
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
            </Grid>
            {/* )} */}
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={4} mb={1}>
                  <SoftButton
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    Update Membership Plan
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </ModalContainer>
  );
}

EditMembershipModal.propTypes = {
  handleClose: PropTypes.func,
  selectedMembership: PropTypes.object,
  memberId: PropTypes.string,
};
