import { Card, Icon } from "@mui/material";
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import Grid from "@mui/material/Grid";
import SoftInput from "src/components/SoftInput";
import SoftButton from "src/components/SoftButton";
import PropTypes from "prop-types";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { GradingScaleInput } from "./GradingScaleInput";
import { getLocationGradingScales } from "src/features/lms/collections";
import { useClubs } from "src/features/club/ClubProvider";
import { entityCrudUtils } from "src/features/firebase/firestore/entityCrudUtils";

const schema = yup
  .object({
    title: yup.string().required("A name for the grading scale is required."),
  })
  .required();

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  border: "none",
  borderRadius: "10px",
  overflow: "scroll",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
};

export function AddLessonGradingScaleModal({
  handleClose,
  lessonGradingScaleData,
}) {
  const { selectedClubId, selectedLocationId } = useClubs();
  const { addData, updateData } = entityCrudUtils();
  const [gradingScales, setGradingScales] = useState(
    lessonGradingScaleData?.gradingScales || [{ index: 0, label: "" }]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      title: lessonGradingScaleData?.name || "",
    },
    resolver: yupResolver(schema),
  });

  const onChangeScale = (index, value) => {
    const newGradingScales = [...gradingScales];
    newGradingScales[index].label = value;
    setGradingScales(newGradingScales);
  };
  const addScale = () => {
    const newGradingScales = [
      ...gradingScales,
      { index: gradingScales.length, label: "" },
    ];
    setGradingScales(newGradingScales);
  };

  const onDeleteScale = (index) => {
    const newGradingScales = gradingScales.filter(
      (scale) => scale.index !== index
    );
    for (let i = 0; i < newGradingScales.length; i++) {
      newGradingScales[i].index = i;
    }
    setGradingScales(newGradingScales);
  };

  const onSubmit = async (data) => {
    if (lessonGradingScaleData?.id) {
      await updateData(
        {
          entity: getLocationGradingScales(selectedClubId, selectedLocationId),
          pathSegmentsArr: [lessonGradingScaleData.id],
        },
        {
          name: data.title,
          gradingScales,
          deleted: false,
        }
      )
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          console.error("Error adding grading scale", error);
        });
    } else {
      await addData(
        getLocationGradingScales(selectedClubId, selectedLocationId),
        {
          name: data.title,
          gradingScales,
          deleted: false,
        }
      )
        .then(() => {
          handleClose();
        })
        .catch((error) => {
          console.error("Error adding grading scale", error);
        });
    }
  };

  return (
    <Card sx={style}>
      <SoftBox
        p={2}
        component="form"
        role="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <SoftBox>
          <SoftTypography variant="h5" fontWeight="bold">
            {lessonGradingScaleData?.id ? "Edit" : "Add New"} Lesson Grading
            Scale
          </SoftTypography>
          <SoftBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12} mt={2}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                    textTransform="capitalize"
                  >
                    Name
                  </SoftTypography>
                </SoftBox>
                <SoftBox px={2} pt={2}>
                  <SoftInput
                    {...register("title", {
                      required: true,
                    })}
                    placeholder="Eg. Lesson 1 - Ground Training"
                  />
                  {errors?.title?.message && (
                    <SoftTypography marginTop={1} fontSize={12} color="error">
                      {errors.title.message}
                    </SoftTypography>
                  )}
                </SoftBox>
              </Grid>
              <Grid item xs={12}>
                <SoftBox ml={0.5} lineHeight={0} display="inline-block">
                  <SoftTypography
                    component="label"
                    variant="caption"
                    fontWeight="bold"
                  >
                    Grading Scale
                  </SoftTypography>
                </SoftBox>
                <SoftBox px={1} pt={1} display="flex" flexDirection="row">
                  {gradingScales.map((scale) => (
                    <GradingScaleInput
                      key={scale.index}
                      value={scale}
                      onChange={onChangeScale}
                      onDelete={() => onDeleteScale(scale.index)}
                    />
                  ))}
                  <SoftBox px={1} pt={1}>
                    <SoftButton
                      color="primary"
                      variant="outlined"
                      onClick={() => addScale()}
                      sx={{ paddingLeft: 0, paddingRight: 0, minWidth: 42 }}
                    >
                      <Icon>add</Icon>
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </Grid>
            </Grid>
            <Grid container spacing={1} mt={3}>
              <Grid item xs={6}>
                <SoftBox mt={2} mb={1}>
                  <SoftButton
                    variant="outlined"
                    color="dark"
                    fullWidth
                    onClick={handleClose}
                  >
                    Cancel
                  </SoftButton>
                </SoftBox>
              </Grid>
              <Grid item xs={6}>
                <SoftBox mt={2} mb={1}>
                  <SoftButton
                    disabled={
                      gradingScales.length === 0 ||
                      gradingScales.some((scale) => scale.value === "")
                    }
                    variant="gradient"
                    color="info"
                    fullWidth
                    type="submit"
                  >
                    {lessonGradingScaleData?.id
                      ? "Update Grading Scale"
                      : "Add Grading Scale"}
                  </SoftButton>
                </SoftBox>
              </Grid>
            </Grid>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </Card>
  );
}

AddLessonGradingScaleModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  lessonGradingScaleData: PropTypes.object,
};
