import { apiUrls } from "src/config/apiUrls";
import { ApiWrapper } from "./fetchClient";
import { FlightCredit } from "src/interfaces";
import { removeUndefined } from "src/utils/utils";

export const createFlightCredit = async ({
  credits,
  userId,
  clubId,
  locationId,
  description,
}: {
  credits: number;
  clubId: string;
  locationId: string;
  userId: string;
  description?: string;
}): Promise<FlightCredit[]> => {
  const result = await (
    await ApiWrapper.post<any>({
      url: apiUrls.flightCredits.createFlightCredit(clubId, locationId),
      params: removeUndefined({
        description,
        credits,
        userId,
      }),
    })
  ).call();
  return result;
};

export const deletedFlightCredit = async ({
  clubId,
  locationId,
  flightCreditId,
}: {
  clubId: string;
  locationId: string;
  flightCreditId: string;
}): Promise<{ success: boolean }> => {
  const result = await (
    await ApiWrapper.delete<any>({
      url: apiUrls.flightCredits.deleteFlightCredit(
        clubId,
        locationId,
        flightCreditId
      ),
    })
  ).call();
  return { success: result.success };
};
