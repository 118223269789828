import { Timestamp } from "firebase/firestore";
import { format as formatFns } from "date-fns";

export const formatTimestampOrString = (
  date: Timestamp | string | undefined | Date,
  format = "MM/dd/yyyy, HH:hh"
) => {
  let dateValue;
  try {
    if (!date) return "";
    if (typeof date === "string") dateValue = new Date(date);
    else if (date instanceof Date && isFinite(date.getTime())) dateValue = date;
    else if (date instanceof Timestamp) dateValue = date?.toDate();
    if (!dateValue) return "";
  } catch (error) {
    console.error("Error parsing date", error);
    return "";
  }

  return formatFns(dateValue, format);
};

export const parseTimestampOrString = (
  date: Date | Timestamp | string | undefined
): Date => {
  let dateValue: Date = new Date();
  if (date) {
    try {
      if (typeof date === "string") dateValue = new Date(date);
      else if (date instanceof Date && isFinite(date.getTime()))
        dateValue = date;
      else if (date instanceof Timestamp) dateValue = date?.toDate();
    } catch (error) {
      console.error("Error parsing date", error);
    }
  }

  return dateValue;
};
