import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import { format } from "date-fns";

function Header({ paymentRequest, club, user }) {
  return (
    <SoftBox>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={4}>
          {/* <SoftBox component="img" src={logoCT} width="25%" p={1} mb={1} /> */}
          <SoftTypography variant="h4" fontWeight="medium">
            {club?.name}
          </SoftTypography>
          {club?.number && (
            <SoftBox mt={1}>
              <SoftTypography display="block" variant="body2" color="secondary">
                Tel: {club.number}
              </SoftTypography>
            </SoftBox>
          )}
        </Grid>
        <Grid item xs={12} md={7} lg={7}>
          <SoftBox width="100%" textAlign={{ xs: "left", md: "right" }} mt={6}>
            {user?.displayName && (
              <SoftBox mt={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Billed to: {user.displayName}
                </SoftTypography>
              </SoftBox>
            )}
            {user?.email && (
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="secondary">
                  {user.email}
                </SoftTypography>
              </SoftBox>
            )}
            {user?.address && (
              <SoftBox mb={1}>
                <SoftTypography variant="body2" color="secondary">
                  {user?.address?.addressLine1}
                  <br />
                  {user?.address?.addressLine2 && (
                    <>
                      {user?.address?.addressLine2}
                      <br />
                    </>
                  )}
                  {user?.address?.city}, {user?.address?.state},{" "}
                  {user?.address?.zip}
                </SoftTypography>
              </SoftBox>
            )}
          </SoftBox>
        </Grid>
        <Grid item xs={12}>
          <SoftBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SoftBox>
              <SoftBox mb={1}>
                <SoftTypography variant="h6" fontWeight="medium">
                  Payment Request
                </SoftTypography>
              </SoftBox>
              <SoftTypography
                component="p"
                variant="button"
                fontWeight="bold"
                color="text"
              >
                Date:{" "}
                <span
                  style={{
                    fontWeight: "400",
                  }}
                >
                  {paymentRequest?.date
                    ? format(paymentRequest.date.toDate(), "eee, MMM do yyyy")
                    : "N/A"}
                </span>
              </SoftTypography>
              <SoftBox mt={1}>
                <SoftTypography
                  component="p"
                  variant="button"
                  fontWeight="bold"
                  color="text"
                >
                  Transaction no.{" "}
                  <span
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    #{paymentRequest?.transactionNumber}
                  </span>
                </SoftTypography>
                <SoftTypography
                  component="p"
                  variant="caption"
                  color="text"
                  mt={1}
                >
                  ID:{" "}
                  <span
                    style={{
                      fontWeight: "400",
                    }}
                  >
                    {paymentRequest?.id}
                  </span>
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </SoftBox>
  );
}

Header.propTypes = {
  paymentRequest: PropTypes.object,
  onRefundTransaction: PropTypes.func,
  onMarkAsPaid: PropTypes.func,
  adjustInvoice: PropTypes.func,
};

export default Header;
