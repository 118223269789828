import { format, parse } from "date-fns";
import { Timestamp } from "firebase/firestore";

type DateType = {
  createdAt?: Date | Timestamp;
  date?: Timestamp | Date;
};

export type groupedTransactions<T extends DateType> = {
  date: Date;
  label: string;
  items: T[];
};

export const groupTransactionsByDate = <T extends DateType>(items: T[]) => {
  const groupedItems: Record<string, groupedTransactions<T>> = {};

  console.log("items", items);

  items.forEach((item) => {
    let dateKey;

    if (item.date) {
      console.log("item.date", item.date, item);
      if (item.date instanceof Timestamp) {
        console.log("IS TIMESTAMP");
        dateKey = format(item.date.toDate(), "yyyy-MM-dd");
      } else if (item.date instanceof Date) {
        console.log("IS DATE", item.date.toISOString());
        dateKey = format(item.date, "yyyy-MM-dd");
      } else if (typeof item.date === "string") {
        console.log("IS STRING");
        dateKey = (item.date as string).split("T")[0];
      }
    } else {
      if (item.createdAt instanceof Timestamp) {
        console.log("IS TIMESTAMP");
        dateKey = item.createdAt.toDate().toISOString().split("T")[0];
      } else if (item.createdAt?.toISOString) {
        console.log("IS DATE");
        dateKey = item.createdAt?.toISOString().split("T")[0];
      }
    }

    console.log("dateKey", dateKey);

    if (dateKey) {
      if (!groupedItems[dateKey]) {
        const date = parse(dateKey, "yyyy-MM-dd", new Date());
        console.log("date", date);
        groupedItems[dateKey] = {
          date: date,
          label: format(date, "EEE, MMM do yyyy"),
          items: [],
        };
      }

      groupedItems[dateKey].items.unshift(item);
    }
  });

  return Object.values(groupedItems);
};
