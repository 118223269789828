import { useEffect, useRef } from "react";
import md5 from "md5";

import brand from "src/assets/images/logo.png";
import brandIcon from "src/assets/images/logo-icon.png";
import brandXmas from "src/assets/images/themed-logos/Santa.png";
import brandIconXmas from "src/assets/images/themed-logos/Santa-Icon.png";
import brandValentines from "src/assets/images/themed-logos/Valentines.png";
import brandIconValentines from "src/assets/images/themed-logos/Valentines-Icon.png";
import brandNewYears from "src/assets/images/themed-logos/NewYears.png";
import brandIconNewYears from "src/assets/images/themed-logos/NewYears-Icon.png";

const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const formatCurrency = (amount, cents) =>
  cents
    ? currencyFormatter.format(amount / 100)
    : currencyFormatter.format(amount);

const isDev = () =>
  !import.meta.env.VITE_ENV ||
  import.meta.env.VITE_ENV === "development" ||
  import.meta.env.VITE_ENV === "staging";

const getBrandIcons = () => {
  const date = new Date();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  if (month === 12) {
    return { brand: brandXmas, brandIcon: brandIconXmas };
  }
  if (month === 1 && day <= 7) {
    return { brand: brandNewYears, brandIcon: brandIconNewYears };
  }
  if (month === 2 && day === 14) {
    return { brand: brandValentines, brandIcon: brandIconValentines };
  }
  return { brand, brandIcon };
};

const stringToPastelColor = (str) => {
  const md5Hash = str;
  let hash = 0;
  for (let i = 0; i < md5Hash.length; i += 1) {
    hash = md5Hash.charCodeAt(i) + ((hash << 6) - hash);
    hash &= hash;
  }
  const shortened = hash % 360;
  return `hsl(${shortened},100%,40%)`;
};

const toTitleCase = (str) =>
  str.replace(
    /\w\S*/g,
    (txt) => txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase()
  );

const convertStringToCamelCase = (sentence) =>
  sentence.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (camelCaseMatch, i) => {
    if (+camelCaseMatch === 0) return "";
    return i === 0 ? camelCaseMatch.toLowerCase() : toTitleCase(camelCaseMatch);
  });

/**
 * Function to get the original amount without fees and taxes if getTax function is provided
 * @param {*} finalAmount
 * @param {*} clubPaysFees
 * @param {*} dismissPreFlightFee
 * @param {*} paymentMethodType
 * @param {*} getTax
 * @returns
 */
const calculateOriginalAmount = (
  finalAmount,
  clubPaysFees,
  dismissPreFlightFee,
  paymentMethodType,
  getTax
) => {
  let estimatedAmount = finalAmount;
  let originalAmount = finalAmount;

  const MAX_ITERATIONS = 100;
  const TOLERANCE = 1;

  for (let i = 0; i < MAX_ITERATIONS; i++) {
    const fees = calculateFees(
      estimatedAmount,
      clubPaysFees,
      dismissPreFlightFee,
      paymentMethodType
    );
    const taxes = getTax?.(estimatedAmount) ?? 0;
    originalAmount = clubPaysFees
      ? finalAmount - fees.applicationFee - taxes
      : finalAmount - fees.applicationFee - taxes;

    if (Math.abs(originalAmount - estimatedAmount) < TOLERANCE) {
      break;
    }

    estimatedAmount = originalAmount;
  }

  return Math.round(originalAmount);
};

const calculateFees = (
  amount,
  clubPaysFees,
  dismissPreFlightFee,
  paymentMethodType
) => {
  if (amount === 0) {
    return {
      amount: 0,
      preflightFees: 0,
      stripeConnectFees: 0,
      stripeCCFees: 0,
      totalStripeFees: 0,
      applicationFee: 0,
      chargeToClub: 0,
      chargeToPilot: 0,
      clubRevenue: 0,
    };
  }
  let preflightFees = dismissPreFlightFee ? 0 : Math.round(amount * 0.005);
  if (preflightFees < 50 && clubPaysFees) {
    preflightFees = 0;
  }

  const stripeConnectFees = Math.round(
    amount <= 0 ? 0 : amount * 0.0025 + 0.25
  );

  const subTotal = clubPaysFees
    ? amount
    : amount + preflightFees + stripeConnectFees;

  let stripeCCFees = 0;
  let totalStripeFees = 0;
  let applicationFee = 0;
  switch (paymentMethodType) {
    case "ACH_STRIPE":
      return calculateAchFees(amount, clubPaysFees);
    case "TERMINAL_STRIPE":
    case "CARD_STRIPE":
      if (subTotal > 0) {
        if (paymentMethodType === "CARD_STRIPE") {
          stripeCCFees = Math.round(
            subTotal <= 0
              ? (1000 * preflightFees + 300) / 971 - preflightFees
              : (1000 * subTotal + 300) / 971 - subTotal
          );
        } else if (paymentMethodType === "TERMINAL_STRIPE") {
          stripeCCFees = Math.round(
            subTotal <= 0
              ? (1000 * preflightFees + 50) / 973 - preflightFees
              : (1000 * subTotal + 50) / 973 - subTotal
          );
        }
      }

      totalStripeFees = stripeCCFees + stripeConnectFees;
      applicationFee = totalStripeFees + preflightFees;

      return {
        amount,
        preflightFees,
        stripeConnectFees,
        stripeCCFees,
        totalStripeFees,
        applicationFee,
        chargeToClub: clubPaysFees ? applicationFee : 0,
        chargeToPilot: clubPaysFees ? amount : amount + applicationFee,
        clubRevenue: clubPaysFees ? amount - applicationFee : amount,
      };
    case "CARD_FWD":
      if (subTotal > 0) {
        stripeCCFees = Math.round(
          subTotal <= 0
            ? (1000 * preflightFees) / 973 - preflightFees
            : (1000 * subTotal) / 973 - subTotal
        );
      }

      totalStripeFees = stripeCCFees + stripeConnectFees;
      applicationFee = totalStripeFees + preflightFees;

      return {
        amount,
        preflightFees,
        stripeConnectFees,
        stripeCCFees,
        totalStripeFees,
        applicationFee,
        chargeToClub: clubPaysFees ? applicationFee : 0,
        chargeToPilot: clubPaysFees ? amount : amount + applicationFee,
        clubRevenue: clubPaysFees ? amount - applicationFee : amount,
      };
    case "ACCOUNT":
    case "PAYMENT":
      applicationFee = preflightFees;

      return {
        amount,
        preflightFees,
        stripeConnectFees: 0,
        stripeCCFees: 0,
        totalStripeFees: 0,
        applicationFee,
        chargeToClub: clubPaysFees ? applicationFee : 0,
        chargeToPilot: clubPaysFees ? amount : amount + applicationFee,
        clubRevenue: clubPaysFees ? amount - applicationFee : amount,
      };
    default:
      return {
        amount,
        preflightFees,
        stripeConnectFees,
        stripeCCFees,
        totalStripeFees,
        applicationFee,
        chargeToClub: clubPaysFees ? preflightFees + stripeConnectFees : 0,
        chargeToPilot: subTotal,
        clubRevenue: clubPaysFees
          ? amount - preflightFees - stripeConnectFees
          : amount,
      };
  }
};

const calculateAchFees = (amount, clubPaysFees) => {
  const totalCharge = Math.round(amount);
  const preflightFees = 0;
  const stripeConnectFees = Math.round(
    totalCharge <= 0 ? 0 : totalCharge * 0.0025 + 0.25
  );
  // const stripeConnectFees = 0;

  if (clubPaysFees) {
    const stripeAchFees = Math.min(
      Math.round(totalCharge * 0.008 * 100) / 100,
      5
    );
    const stripeCCFees = 0;
    const totalStripeFees = stripeCCFees + stripeConnectFees + stripeAchFees;
    const applicationFee = totalStripeFees + preflightFees;

    const costToPilot = totalCharge;
    const clubRevenue = Math.round(totalCharge - applicationFee);
    const chargeToClub = 0;
    const chargeToPilot = costToPilot;

    return {
      totalCharge,
      preflightFees,
      stripeConnectFees,
      stripeCCFees,
      stripeAchFees,
      totalStripeFees,
      applicationFee,
      costToPilot,
      clubRevenue,
      chargeToClub,
      chargeToPilot,
    };
  }

  const subTotal = totalCharge + preflightFees + stripeConnectFees;
  const stripeAchFees = Math.min(Math.round(subTotal * 0.008 * 100) / 100, 5);
  const stripeCCFees = 0;
  const totalStripeFees = stripeCCFees + stripeConnectFees + stripeAchFees;
  const applicationFee = totalStripeFees + preflightFees;

  const costToPilot = totalCharge + applicationFee;
  const clubRevenue = totalCharge;
  const chargeToClub = 0;
  const chargeToPilot = Math.max(costToPilot, applicationFee);

  return {
    totalCharge,
    preflightFees,
    stripeConnectFees,
    stripeCCFees,
    stripeAchFees,
    totalStripeFees,
    applicationFee,
    costToPilot,
    clubRevenue,
    chargeToClub,
    chargeToPilot,
  };
};

function useWhyDidYouUpdate(name, props) {
  // Get a mutable ref object where we can store props ...
  // ... for comparison next time this hook runs.
  const previousProps = useRef();
  useEffect(() => {
    if (previousProps.current) {
      // Get all keys from previous and current props
      const allKeys = Object.keys({ ...previousProps.current, ...props });
      // Use this object to keep track of changed props
      const changesObj = {};
      // Iterate through keys
      allKeys.forEach((key) => {
        // If previous is different from current
        if (previousProps.current[key] !== props[key]) {
          // Add to changesObj
          changesObj[key] = {
            from: previousProps.current[key],
            to: props[key],
          };
        }
      });
      // If changesObj not empty then output to console
      if (Object.keys(changesObj).length) {
        console.debug("[why-did-you-update]", name, changesObj);
      }
    }
    // Finally update previousProps with current props for next hook call
    previousProps.current = props;
  });
}

function getProfileImageURL({ user }) {
  if (!user) return "";
  if (user.photoURL) return user.photoURL;

  const emailHash = user.email ? md5(user.email) : "";
  return `https://www.gravatar.com/avatar/${emailHash}?d=mp`;
}

const getYearsSelectData = (startYear = 1900) => {
  const endYear = new Date().getFullYear();
  const totalYears = endYear - startYear + 1;
  return Array.from(new Array(totalYears), (val, index) => endYear - index).map(
    (x) => ({
      value: x,
      label: x,
    })
  );
};

export {
  formatCurrency,
  isDev,
  useWhyDidYouUpdate,
  toTitleCase,
  getBrandIcons,
  convertStringToCamelCase,
  calculateFees,
  calculateAchFees,
  stringToPastelColor,
  getProfileImageURL,
  getYearsSelectData,
  calculateOriginalAmount,
};
