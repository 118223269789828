import * as React from "react";
import { styled } from "@mui/material/styles";
import Tabs, { TabsTypeMap } from "@mui/material/Tabs";
import Tab, { TabTypeMap } from "@mui/material/Tab";

type CustomTabProps = {
  variation?: "primary" | "secondary";
};

export const SoftTabs = styled(
  (props: TabsTypeMap["props"] & CustomTabProps) => (
    <Tabs
      {...props}
      TabIndicatorProps={{
        children: <span className="MuiTabs-indicatorSpan" />,
      }}
    />
  )
)(({ variation }) => ({
  backgroundColor: "#FFF",
  border: variation === "secondary" ? "1px solid rgba(0, 0, 0, 0.15)" : "none",
  borderRadius: variation === "primary" ? "0px" : "8px",
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    boxShadow: "none !important",
  },
  "& .MuiTabs-indicatorSpan": {
    //backgroundColor: "#FFF",
    backgroundColor: variation === "secondary" ? "#F4F9FA" : "#FFF",
    width: "100%",
    border: variation === "secondary" ? "none" : "2px solid #529AA8",
    borderRadius: variation === "secondary" ? "6px" : "30px",
  },
}));

export const SoftTab = styled((props: TabTypeMap["props"] & CustomTabProps) => (
  <Tab disableRipple {...props} />
))(() => ({
  textTransform: "none",
  padding: "5px 20px",
  "&.Mui-selected": {
    color: "#529AA8 !important",
  },
  "&.Mui-focusVisible": {
    backgroundColor: "#FFF",
  },
}));
