import { useMotionValue, Reorder, useDragControls } from "framer-motion";
import { useRaisedShadow } from "src/hooks/useRaisedShadow";
import { LineItem } from "src/interfaces";
import { DragIndicator } from "@mui/icons-material";
import { EditableLineItem } from "./EditableLineItem";
import {
  IQuickbookClass,
  IQuickbookItem,
} from "src/interfaces/quickbook.interface";

interface IInvoiceLineItem {
  lineItem: LineItem;
  order: number;
  hasQuickbooks: boolean;
  quickbooksItems?: IQuickbookItem[];
  quickbooksClasses?: IQuickbookClass[];
  onLineItemEdit: (lineItem: LineItem) => void;
  onDelete: (lineItem: LineItem) => void;
}
function InvoiceLineItem({
  lineItem,
  order,
  hasQuickbooks,
  quickbooksItems,
  quickbooksClasses,
  onLineItemEdit,
  onDelete,
}: IInvoiceLineItem) {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const dragControls = useDragControls();

  return (
    <Reorder.Item
      as="tr"
      value={lineItem}
      id={lineItem.description}
      style={{ boxShadow, y, height: "50px", padding: "10px" }}
      dragListener={false}
      dragControls={dragControls}
    >
      <td style={{ padding: 0 }}>
        <DragIndicator
          onPointerDown={(event) => {
            dragControls.start(event);
            event.stopPropagation();
          }}
          sx={{ cursor: "grab", marginTop: "8px" }}
        />
      </td>
      <td style={{ padding: "0 12px" }}>{order}</td>
      <EditableLineItem
        lineItem={lineItem}
        disableEdit={!!lineItem?.payment}
        hasQuickbooks={hasQuickbooks}
        onLineItemEditted={onLineItemEdit}
        onDelete={onDelete}
        quickbooksItems={quickbooksItems}
        quickbooksClasses={quickbooksClasses}
      />
    </Reorder.Item>
  );
}
export default InvoiceLineItem;
