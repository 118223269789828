import {
  FormControl,
  MenuItem,
  Select,
  Typography,
  styled,
} from "@mui/material";
import { IQuickbookClass } from "src/interfaces/quickbook.interface";
import SoftBox from "src/components/SoftBox";
import { LineItem } from "src/interfaces";

const TruncatedTypography = styled(Typography)({
  display: "inline-flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  fontSize: "12px",
});

const findItemById = (
  items: IQuickbookClass[],
  id: string
): IQuickbookClass | undefined => {
  for (const item of items) {
    if (item.Id === id) {
      return item;
    }

    if (item.children && item.children.length > 0) {
      const found = findItemById(item.children, id);
      if (found) {
        return found;
      }
    }
  }

  return undefined;
};

interface QbkClassProps {
  quickbooksClasses: IQuickbookClass[];
  onLineItemEditted: (lineItem: Partial<LineItem>) => void;
  lineItem?: LineItem;
  disabled?: boolean;
}

export const QbkClass = ({
  quickbooksClasses,
  lineItem,
  disabled,
  onLineItemEditted,
}: QbkClassProps) => {
  const renderMenuItems = (
    classes: IQuickbookClass[],
    level = 0,
    parentName: string = ""
  ): any => {
    return classes.map((qbClass) => {
      const isSelected = lineItem?.qboClass?.id === qbClass.Id;
      const displayParentName =
        isSelected || !parentName ? "" : `Sub-class of ${parentName}`;
      return [
        <MenuItem value={qbClass.Id} style={{ paddingLeft: level * 26 }}>
          <SoftBox
            sx={{
              display: "flex",
              alignItems: "center",
              maxWidth: "100%",
              width: "100%",
            }}
          >
            <TruncatedTypography>
              {qbClass.Name}
              <span
                style={{
                  marginLeft: "60px",
                  fontSize: "12px",
                  maxWidth: "140px",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  display: "block",
                }}
              >
                {displayParentName}
              </span>
            </TruncatedTypography>
          </SoftBox>
        </MenuItem>,
        qbClass.children && qbClass.children.length > 0
          ? renderMenuItems(qbClass.children, level + 1, qbClass.Name)
          : null,
      ];
    });
  };

  const handleChange = (value: string | null) => {
    if (!value) return;

    onLineItemEditted({
      ...(lineItem ? lineItem : {}),
      qboClass: {
        id: value,
        name: findItemById(quickbooksClasses!, value)?.FullyQualifiedName || "",
      },
    });
  };

  return (
    <FormControl fullWidth variant="outlined">
      <Select
        displayEmpty
        variant="outlined"
        disabled={disabled}
        renderValue={(selected) => {
          if (!selected || !selected?.length || !lineItem?.qboClass?.id) {
            return <em>Select Class</em>;
          }

          return findItemById(quickbooksClasses!, selected)?.FullyQualifiedName;
        }}
        value={lineItem?.qboClass?.id}
        onChange={(e) => handleChange(e.target.value)}
        MenuProps={{
          PaperProps: {
            sx: {
              maxWidth: "300px",
            },
          },
        }}
        sx={{
          ...(disabled ? { backgroundColor: "#E9ECEF !important" } : {}),
          "&.MuiOutlinedInput-root": {
            "& fieldset": {
              border: "0 solid #d2d6da",
            },
            "&:hover fieldset": {
              border: "0 solid #d2d6da",
            },
            "&.Mui-focused fieldset": {
              border: "0 solid #d2d6da",
            },
          },
          "& .MuiInputBase-input": {
            display: "block !important",
            paddingRight: "20px !important",
            width: "calc(100% - 10px) !important",
          },
          "& .MuiSelect-icon": {
            display: "block",
            height: "20px",
            width: "20px",
            top: "calc(50% - 10px)",
          },
          "& legend": {
            display: "none",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            top: 0,
          },
        }}
      >
        {renderMenuItems(quickbooksClasses!)}
      </Select>
    </FormControl>
  );
};
