import { useState } from "react";
import PropTypes from "prop-types";

import Grid from "@mui/material/Grid";

import SoftBox from "src/components/SoftBox";
import SoftTypography from "src/components/SoftTypography";
import CurrencyFormat from "react-currency-format";

import SoftCurrencyInput from "src/components/SoftCurrencyInput";
import SoftInput from "src/components/SoftInput";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";

import { useClubs } from "src/features/club/ClubProvider";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { useEffect } from "react";
import SoftSelect from "src/components/SoftSelect";
import { formatCurrency } from "src/features/utils";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

function FuelReimbursementForm({
  booking,
  totalFuelCost,
  onFuelAmountChanged,
  receipt,
  onAddReceipt,
  receiptError,
}) {
  const { selectedLocation } = useClubs();
  const [fuelUser, setFuelUser] = useState({
    label: booking?.extendedProps?.pilot?.label,
    value: booking?.extendedProps?.pilot?.value,
  });
  const [fuelAmount, setFuelAmount] = useState(0);
  const [fuelQty, setFuelQty] = useState(0);

  const renderTotal = () => {
    if (totalFuelCost && totalFuelCost > 0) {
      return (
        <SoftTypography variant="caption" color="text" pl={2} fontSize="0.9rem">
          Reimbursement:{" "}
          <b>
            <CurrencyFormat
              value={totalFuelCost || 0}
              displayType="text"
              decimalScale={2}
              fixedDecimalScale
              thousandSeparator
              prefix="$"
            />
          </b>
        </SoftTypography>
      );
    }
    return null;
  };

  const onFileSelected = (e) => {
    if (e.target?.files?.[0]) {
      onAddReceipt(e.target.files[0]);
    }
  };

  useEffect(() => {
    onFuelAmountChanged({
      type:
        selectedLocation?.preferences?.fuelReimbursement?.type?.value ||
        "dollar",
      amount: fuelAmount ? parseFloat(fuelAmount) : 0,
      quantity: fuelQty ? parseFloat(fuelQty) : 0,
      userId: fuelUser?.value,
    });
  }, [fuelAmount, fuelQty, fuelUser]);

  return (
    <Grid container spacing={1} mb={2} display="flex" justifyContent="center">
      <Grid item xs={12} sm={4} display="flex" alignItems="center">
        <SoftBox display="flex" alignItems="center">
          <SoftBox
            display="grid"
            alignItems="center"
            justifyContent="center"
            width="2rem"
            height="2rem"
            mr={2}
          >
            <LocalGasStationIcon
              sx={{
                display: "grid",
                placeItems: "center",
              }}
              color="dark"
              fontSize="medium"
            />
          </SoftBox>
          <SoftBox display="flex" flexDirection="column">
            <SoftTypography
              variant="button"
              color="dark"
              fontWeight="medium"
              fontSize="0.8rem"
              gutterBottom
            >
              Fuel Reimbursement
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </Grid>
      <Grid item xs={12} sm={8}>
        {booking?.extendedProps?.pilot?.value !== "" &&
          booking?.extendedProps?.pilot2?.value !== "" && (
            <SoftBox
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              py={2}
            >
              <SoftBox display="flex" flexDirection="row">
                <SoftBox ml={1} mr={1}>
                  <SoftTypography
                    variant="caption"
                    color="text"
                    fontWeight="regular"
                  >
                    Reimburse to:
                  </SoftTypography>
                </SoftBox>
                <SoftBox ml={1}>
                  <SoftSelect
                    options={[
                      {
                        label: booking?.extendedProps?.pilot?.label,
                        value: booking?.extendedProps?.pilot?.value,
                      },
                      {
                        label: booking?.extendedProps?.pilot2?.label,
                        value: booking?.extendedProps?.pilot2?.value,
                      },
                    ]}
                    value={fuelUser}
                    onChange={setFuelUser}
                  />
                </SoftBox>
              </SoftBox>
            </SoftBox>
          )}
        <SoftBox
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          py={2}
        >
          {selectedLocation?.preferences?.fuelReimbursement?.type?.value ===
          "dollar" ? (
            <SoftBox>
              <SoftBox display="flex" flexDirection="row">
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                  lineHeight={1}
                >
                  <SoftBox ml={1} mr={1}>
                    <SoftTypography
                      variant="body2"
                      color="text"
                      fontWeight="regular"
                    >
                      Cost per gallon:
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox ml={1} width={80}>
                    <SoftCurrencyInput
                      currencySymbol="$"
                      placeholder="0"
                      decimalPlaces={2}
                      decimalPlacesShownOnBlur={2}
                      decimalPlacesShownOnFocus={2}
                      outputFormat="number"
                      onBlur={(event) =>
                        setFuelAmount(
                          event.target.value > 0 ? event.target.value : 0
                        )
                      }
                      // value={preferences?.fuelReimbursement?.maxAmount ?? 0}
                    />
                  </SoftBox>
                </SoftBox>
                <SoftBox
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  mb={1}
                  lineHeight={1}
                >
                  <SoftBox ml={1} mr={1}>
                    <SoftTypography
                      variant="body2"
                      color="text"
                      fontWeight="regular"
                    >
                      Quantity:
                    </SoftTypography>
                  </SoftBox>
                  <SoftBox ml={1} width={80}>
                    <SoftCurrencyInput
                      currencySymbol=""
                      placeholder="0.00"
                      decimalPlaces={2}
                      decimalPlacesShownOnBlur={2}
                      decimalPlacesShownOnFocus={2}
                      outputFormat="number"
                      onBlur={(event) =>
                        setFuelQty(
                          event.target.value > 0 ? event.target.value : 0
                        )
                      }
                      // value={preferences?.fuelReimbursement?.rate ?? 0}
                    />
                  </SoftBox>
                  <SoftBox ml={1} mr={1}>
                    <SoftTypography
                      variant="caption"
                      color="text"
                      fontWeight="regular"
                    >
                      gal
                    </SoftTypography>
                  </SoftBox>
                </SoftBox>
              </SoftBox>
              <SoftBox display="flex" flexDirection="row-reverse">
                <SoftTypography
                  variant="caption"
                  color={
                    fuelQty >
                    selectedLocation?.preferences?.fuelReimbursement?.maxGallons
                      ? "error"
                      : "text"
                  }
                  pl={1}
                  mt={1}
                >
                  Max Gallons:{" "}
                  {selectedLocation?.preferences?.fuelReimbursement
                    ?.maxGallons ?? "Unlimited"}
                </SoftTypography>
                <SoftTypography
                  variant="caption"
                  color={
                    fuelAmount >
                    selectedLocation?.preferences?.fuelReimbursement?.maxAmount
                      ? "error"
                      : "text"
                  }
                  pl={1}
                  mt={1}
                >
                  Max per Gallon:{" "}
                  {selectedLocation?.preferences?.fuelReimbursement?.maxAmount
                    ? formatCurrency(
                        selectedLocation.preferences.fuelReimbursement.maxAmount
                      )
                    : "Unlimited"}
                </SoftTypography>
              </SoftBox>
            </SoftBox>
          ) : (
            <>
              <SoftBox display="flex" flexDirection="row">
                <SoftInput
                  type="number"
                  bold
                  size="small"
                  onWheel={(event) => event.target.blur()}
                  sx={{ maxWidth: "5rem" }}
                  onChange={(event) =>
                    setFuelQty(
                      event.target.valueAsNumber > 0
                        ? event.target.valueAsNumber
                        : 0
                    )
                  }
                />
                <SoftBox
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <SoftTypography
                    variant="caption"
                    color="text"
                    pl={1}
                    fontSize="0.9rem"
                  >
                    gal
                  </SoftTypography>
                </SoftBox>
              </SoftBox>
              {renderTotal()}
            </>
          )}
        </SoftBox>
        <SoftBox display="flex" flexDirection="row">
          <Button
            component="label"
            role={undefined}
            variant="text"
            sx={({ palette: { primary } }) => ({ color: primary.main })}
            color="primary"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Add Receipt
            <VisuallyHiddenInput type="file" onChange={onFileSelected} />
          </Button>
          {receipt && (
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <SoftTypography
                variant="caption"
                color="text"
                fontWeight="regular"
              >
                {receipt.name}
              </SoftTypography>
            </SoftBox>
          )}
          {receiptError && !receipt && (
            <SoftBox
              display="flex"
              flexDirection="column"
              justifyContent="center"
            >
              <SoftTypography variant="caption" color="error" fontWeight="bold">
                {"<-"} {receiptError}
              </SoftTypography>
            </SoftBox>
          )}
        </SoftBox>
      </Grid>
    </Grid>
  );
}

// Typechecking props for the Calendar
FuelReimbursementForm.propTypes = {
  totalFuelCost: PropTypes.number.isRequired,
  onFuelAmountChanged: PropTypes.func.isRequired,
  receipt: PropTypes.object,
  onAddReceipt: PropTypes.func.isRequired,
  receiptError: PropTypes.string,
};

export default FuelReimbursementForm;
