import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import { Divider } from "@mui/material";

// Soft UI Dashboard PRO React components
import SoftBox from "src/components/SoftBox";
import SoftButton from "src/components/SoftButton";
import SoftDateRangePicker from "src/components/SoftDateRangePicker";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "src/containers/LayoutContainers/DashboardLayout";
import DashboardNavbar from "src/containers/DashboardNavbar";
import Footer from "src/components/Footer";
import DataTable from "src/components/Tables/DataTable";

import DefaultCell from "src/pages/reports/components/DefaultTableCell";

import { useClubs } from "src/features/club/ClubProvider";
import { formatCurrency } from "src/features/utils";
import { format, endOfDay, startOfDay } from "date-fns";
import { query, orderBy, where, getDocs } from "firebase/firestore";
import { getClubTransactionsCollection } from "src/features/transaction/collection";

const columns = [
  {
    Header: "Name",
    accessor: "displayName",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Email",
    accessor: "email",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Pay From",
    accessor: "payFrom",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Pay To",
    accessor: "payTo",
    Cell: ({ value }) => <DefaultCell value={value || "---"} />,
  },
  {
    Header: "Billable Hours (Flight)",
    accessor: "totalBillableHoursFlight",
    Cell: ({ value }) => <DefaultCell value={value.toString() || "---"} />,
  },
  {
    Header: "Billable Hours (Ground)",
    accessor: "totalBillableHoursGround",
    Cell: ({ value }) => <DefaultCell value={value.toString() || "---"} />,
  },
  {
    Header: "Total Billable Hours",
    accessor: "totalBillableHours",
    Cell: ({ value }) => <DefaultCell value={value.toString() || "---"} />,
  },
  {
    Header: "Pay Rate (Billable)",
    accessor: "payrollRate",
    Cell: ({ value }) => <DefaultCell value={formatCurrency(value) || "---"} />,
  },
  {
    Header: "Clerical Hours",
    accessor: "totalNonRevHours",
    Cell: ({ value }) => <DefaultCell value={value.toString() || "---"} />,
  },
  {
    Header: "Pay Rate (Clerical)",
    accessor: "payrollRateNonRev",
    Cell: ({ value }) => <DefaultCell value={formatCurrency(value) || "---"} />,
  },
  {
    Header: "Total To Be Paid",
    accessor: "totalToBePaid",
    Cell: ({ value }) => <DefaultCell value={formatCurrency(value) || "---"} />,
  },
];

function PayrollReport() {
  const {
    selectedClubId,
    selectedClub,
    selectedLocation,
    instructors: clubInstructors,
    instructorPreferences,
  } = useClubs();
  const [rows, setRows] = useState([]);
  const [csvData, setCsvData] = useState([]);
  const [dateRange, setDateRange] = useState([]);
  const [tableRows, setTableRows] = useState([]);

  const updateDateRange = (dates) => {
    console.log("Date Range", dates);
    dates[0] = startOfDay(dates[0]);
    dates[1] = endOfDay(dates[1]);

    setDateRange(dates);
  };

  const addInstructorData = async (instructors) => {
    const rowData = await Promise.all(
      instructors.map(async (instructor) => {
        const instructorPrefsData = instructorPreferences.get(instructor.id);

        const latestTransactions = await getDocs(
          query(
            getClubTransactionsCollection(selectedClubId),
            where("instructor.uid", "==", instructor.id),
            where("createdAt", ">=", dateRange[0] || new Date()),
            orderBy("createdAt", "desc")
          )
        );

        let totalBillableHours = 0;
        let totalBillableHoursFlight = 0;
        let totalBillableHoursGround = 0;
        let totalNonRevHours = 0;
        latestTransactions.docs.forEach((transaction) => {
          // console.log(transaction.data());
          if (
            transaction?.data()?.createdAt?.toDate() >= dateRange[0] &&
            transaction?.data()?.createdAt?.toDate() <= dateRange[1]
          ) {
            if (
              transaction?.data()?.nonRev ||
              transaction?.data()?.booking?.extendedProps?.type?.value ===
                "Z86g1ConHzK0uQtNuLBQ" // Pilot Makers Clerical Hours Booking Type
            ) {
              totalNonRevHours +=
                transaction?.data()?.bookingData?.instructionHours || 0;
              totalNonRevHours +=
                transaction?.data()?.bookingData?.instructionHoursGround || 0;
            } else {
              totalBillableHours +=
                transaction?.data()?.bookingData?.instructionHours || 0;
              totalBillableHours +=
                transaction?.data()?.bookingData?.instructionHoursGround || 0;
              totalBillableHoursFlight +=
                transaction?.data()?.bookingData?.instructionHours || 0;
              totalBillableHoursGround +=
                transaction?.data()?.bookingData?.instructionHoursGround || 0;
            }
          }
        });

        const fullInstructorData = {
          ...instructor,
          payFrom: format(dateRange[0], "MM/dd/yyyy"),
          payTo: format(dateRange[1], "MM/dd/yyyy"),
          totalBillableHoursFlight:
            Math.round(totalBillableHoursFlight * 100) / 100,
          totalBillableHoursGround:
            Math.round(totalBillableHoursGround * 100) / 100,
          totalBillableHours: Math.round(totalBillableHours * 100) / 100,
          totalNonRevHours: Math.round(totalNonRevHours * 100) / 100,
          payrollRate: instructorPrefsData?.payrollRate || 0,
          payrollRateNonRev: instructorPrefsData?.payrollRateNonRev || 0,
          totalToBePaid:
            (instructorPrefsData?.payrollRate
              ? Math.round(
                  totalBillableHours * instructorPrefsData.payrollRate * 100
                ) / 100
              : 0) +
            (instructorPrefsData?.payrollRateNonRev
              ? Math.round(
                  totalNonRevHours * instructorPrefsData.payrollRateNonRev * 100
                ) / 100
              : 0),
        };
        return fullInstructorData;
      })
    );

    setRows(rowData);
  };

  useEffect(() => {
    const csvDataFromRows = tableRows.map((row) => ({
      "First Name": row.firstName,
      "Last Name": row.lastName,
      Email: row.email,
      "Pay From": row.payFrom,
      "Pay To": row.payTo,
      "Billable Hours (Flight)": row.totalBillableHoursFlight,
      "Billable Hours (Ground)": row.totalBillableHoursGround,
      "Billable Hours": row.totalBillableHours,
      "Pay Rate (Billable)": row.payrollRate,
      "Clerical Hours": row.totalNonRevHours,
      "Pay Rate (Clerical)": row.payrollRateNonRev,
      "Total To Be Paid": row.totalToBePaid,
    }));
    setCsvData(csvDataFromRows);
  }, [tableRows]);

  useEffect(() => {
    if (!clubInstructors) return;
    addInstructorData(clubInstructors);
  }, [clubInstructors, dateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox my={3}>
        <SoftBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
          mb={2}
        >
          <SoftBox />
          <SoftBox display="flex">
            <SoftDateRangePicker
              value={dateRange}
              onChange={(newDates) => updateDateRange(newDates)}
            />
            <SoftBox mx={1}>
              <Divider orientation="vertical" />
            </SoftBox>
            <SoftBox>
              <CSVLink
                data={csvData}
                filename={`${selectedClub.name}-${
                  selectedLocation?.icao
                }-instructor_summary-${format(new Date(), "MM/dd/yyyy")}.csv`}
                target="_blank"
              >
                <SoftButton variant="outlined" color="dark">
                  <Icon>description</Icon>
                  &nbsp;export csv
                </SoftButton>
              </CSVLink>
            </SoftBox>
          </SoftBox>
        </SoftBox>
        <Card>
          <DataTable
            table={{ columns, rows }}
            entriesPerPage={{ defaultValue: 10, entries: [10, 20, 50, 100] }}
            canSearch
            onRowsChanged={(rows) => {
              setTableRows(rows.map((row) => row.original));
            }}
          />
        </Card>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default PayrollReport;
